@import 'variables';
@import 'mixins';
/* stylelint-disable no-descending-specificity */

.bc,
.search-box-wrapper {
  //Flat Buttons
  .btn-flat {
    background-color: transparent;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    padding-left: 10px;
    padding-right: 10px;
    height: 38px;

    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .btn-primary {
    color: $blue;
    border: none;
    box-shadow: none;
    background-color: transparent;

    &:focus,
    &:active,
    &:hover,
    &:active:focus,
    &:active:hover {
      color: $blue;
      background-color: transparent;
    }
  }

  .btn-secondary {
    color: $gray-dark2;
    border: none;
    box-shadow: none;
    background-color: transparent;

    &:focus,
    &:active,
    &:hover,
    &:active:focus,
    &:active:hover {
      color: $gray-dark2;
      background-color: transparent;
    }
  }

  .btn-flat[disabled],
  .btn-flat.disabled,
  .btn-flat[disabled]:hover,
  .btn-primary[disabled],
  .btn-primary[disabled]:hover,
  .btn-primary.disabled,
  .btn-primary.disabled:hover,
  .btn-primary.disabled:focus,
  .btn-primary.disabled:active,
  .btn-secondary[disabled],
  .btn-secondary.disabled,
  .btn-secondary[disabled]:hover {
    opacity: 1;
    color: $gray-lighter6;
    background-color: transparent;
    cursor: not-allowed;
  }

  .btn-dark {
    color: $blue3;

    &:focus,
    &:active,
    &:hover {
      color: $blue3;
    }

    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover {
      color: $gray-lighter4;
      opacity: 1;
    }
  }

  .btn-close-dark {
    color: $blue3;

    &:focus,
    &:active,
    &:hover {
      color: $blue-light2;
    }

    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover {
      color: $gray-lighter4;
      opacity: 1;
    }
  }

  .btn-blue-light {
    color: $blue-light;

    &:focus {
      color: $blue-light;
    }

    &:active,
    &:hover {
      color: $blue-light;
    }
  }

  .btn-blue {
    color: $blue-light;

    &:focus {
      color: $blue-light;
    }

    &:active,
    &:hover {
      color: $blue3;
    }

    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover {
      color: $blue-dark;
      opacity: 1;
    }
  }

  .btn-blue2 {
    color: $blue4;

    &:focus {
      color: $blue4;
    }

    &:active,
    &:hover {
      color: $blue4;
    }

    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover {
      color: $blue4;
      opacity: 1;
    }
  }

  .btn-dark-blue {
    color: $blue;

    &:focus {
      color: $blue;
    }

    &:active,
    &:hover {
      color: $blue;
    }

    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover {
      color: $gray-lighter6;
      opacity: 1;
    }
  }

  .btn-icon {
    @include round-btn(48px);
  }

  .btn-icon-24 {
    @include round-btn(24px);

    font-size: 16px;
  }

  .btn-icon-36 {
    @include round-btn(36px);

    font-size: 14px;
  }

  .btn-icon-78 {
    @include round-btn(78px);
  }

  //Icon Buttons - variant of flat buttons
  .x-icon-18-white {
    @include x-btn-icon(17, #ffffff, 1);
  }

  .x-icon-18-black {
    @include x-btn-icon(18, $black1, 2);
  }

  .x-icon-32-cyan {
    @include x-btn-icon(32, $blue-light2, 2);
  }

  .x-icon-65-cyan {
    @include x-btn-icon(65, $blue-light2, 1);
  }

  //Raised Buttons
  .btn-raised {
    background-color: $blue;
    border-radius: 3px;
    cursor: pointer;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    color: white;
    height: 38px;

    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      color: white;
      outline: none;
      box-shadow: 0 0 8px 0 $black-alpha026;
    }

    &:focus {
      background-color: $blue;
    }

    &:active,
    &:hover,
    &:active:focus {
      background-color: $blue-dark;
    }
  }

  .btn-raised[disabled],
  .btn-raised.disabled {
    opacity: 1;
    background-color: $gray-lighter6;
    box-shadow: none;
    cursor: not-allowed;
  }

  .btn-icon-raised {
    box-shadow: 0 0 8px 0 $black-alpha050;

    @include round-btn(60px);

    color: $blue2;

    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      color: $blue2;
    }
  }

  .btn-outline {
    background-color: transparent;
    font-size: $font-size-base;
    color: $gray-dark2;
    border: solid 1px $black1;

    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      background-color: transparent;
      color: $gray-dark2;
      outline: none;
      box-shadow: 0 0 12px 0 $black-alpha014;
    }

    &:active,
    &:active:focus {
      background-color: rgba(216, 216, 216, 0.2);
    }

    &[disabled],
    &.disabled {
      border-color: $gray-lighter6;
      color: $gray-lighter6;
      opacity: 1;
      background-color: transparent;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  .btn-outline-large {
    height: 48px;
    font-size: $font-size-large;
    font-weight: 300;
  }

  .btn-outline-light {
    background-color: $white1;
    color: #232323;
    font-weight: 400;

    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      background-color: $white;
      color: #232323;
      outline: none;
      box-shadow: 0 0 12px 0 $black-alpha014;
    }
  }

  .btn-outline-dark {
    background-color: #444;
    color: #d6d6d6;

    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      background-color: #4e4e4e;
      color: #e1e1e1;
      outline: none;
      box-shadow: 0 0 12px 0 $black-alpha014;
    }
  }

  .btn,
  .ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #000 15%, transparent 15.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform 1s, opacity 1s;
    }

    &:active::after {
      transform: scale(0, 0);
      opacity: 0.2;
      transition: 0s;
    }
  }

  .btn-dark,
  .ripple-light {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #d8d8d8 15%, transparent 15.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform 1s, opacity 1s;
    }

    &:active::after {
      transform: scale(0, 0);
      opacity: 0.4;
      transition: 0s;
    }
  }
}

.x-icon-18-black {
  @include x-btn-icon(18, $black1, 2);
}

.new-basic-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  background: #0f6189;
  color: #fff;

  &.light {
    background: #00b7f2;
  }

  p {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }

  i {
    position: relative;
    top: 2px;
    margin-right: 7px;
  }

  span {
    font-size: 19px;
    top: 1px;
    position: relative;
  }
}
/* stylelint-enable no-descending-specificity */
