@import '~app/styles/themes/base/variables';

.root {
}

.body {
  padding-top: 40px !important;
  align-items: flex-start !important;
}

.footer {
  padding-right: 30px !important;
  padding-bottom: 30px !important;
}

.property {
  text-overflow: ellipsis;
  font-size: $font-size-h1;
  color: $green-dark;
  font-weight: $font-weight-light;
  margin-left: -3px;
  width: 100%;
  display: block;
}

.action {
  font-size: $font-size-large;
  color: $gray-dark2;
  font-weight: $font-weight-medium;
  position: absolute;
  transform: translateY(-9px);
}

.footer {
  padding-right: 30px !important;
  padding-bottom: 30px !important;
}

.aggregation-ddl {
  height: 48px;
  max-width: none !important;

  button {
    height: 48px !important;
  }
}

.apply-to-all {
  padding-top: 20px;
}

.apply-to-all-label {
  font-size: $font-size-large;
  font-weight: $font-weight-light;
  color: $black1;
  margin-left: 10px;
}
