@import '~app/styles/v2/variables';

.item-wrapper {
  width: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  padding: 0 6px;
}

.mask {
  position: relative;
  width: 52px;
  height: 52px;
  background-color: $gray-300;
  border-radius: 50%;
  overflow: hidden;

  .emoji {
    z-index: 2;
    position: absolute;
    top: 7px;
    left: 14px;
    font-size: 24px;
  }

  .bg-emoji {
    z-index: 1;
    position: absolute;
    top: 11px;
    left: 13px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: $gray-300;
  }

  .shadow {
    z-index: 0;
    position: absolute;
    width: 39px;
    height: 39px;
    background-color: $gray-350;
    border-radius: 50%;
    bottom: -17px;
    left: 6px;
  }
}

.name {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: $gray-500;
  margin-top: 3px;

  span[role='img'] {
    font-size: 11px;
    margin-right: 3px;
  }
}

.count {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: $gray-400;
}
