.left-container {
  flex-basis: 30%;
  max-width: 400px;
  min-width: 300px;

  > div {
    margin-bottom: 20px;
  }
}

.event-type-ddl {
  max-width: 250px;
}
