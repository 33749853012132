/* stylelint-disable */
@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

$condition-margin: 10px;

.main {
  background-color: $gray-dark !important;
  padding: 10px 8px 43px 20px;

  .tabs-wrapper {
    margin-bottom: 48px;
  }

  .settings-wrapper {
    > div {
      margin-bottom: 10px;
    }

    .pull-from-top {
      margin-top: -40px;
    }

    .push-bottom {
      margin-bottom: 40px;
    }

    .title {
      position: relative;
      left: 15px;
      display: block;
      margin: 0 0 43px;
      font-size: 30px;
      font-weight: 500;
      color: $azure-500;

      &.toggle {
        cursor: pointer;

        :global {
          .icon {
            position: absolute;
            left: -15px;
            top: 11px;
            font-size: 23px;
          }

          .ion-android-arrow-dropdown {
            left: -19px;
          }
        }
      }
    }
  }

  :global {
    .andt-section-header {
      font-size: 40px !important;
      color: $blue4 !important;

      i {
        font-size: 22px !important;
      }
    }

    .cb2 {
      &.bottom {
        position: absolute;
        bottom: 10px;
      }

      input[type='checkbox'] + label::before {
        color: white !important;
      }

      label {
        @include sub-title();
      }
    }
  }
}

.spacer {
  height: 40px;
  min-height: 40px;
}

.settings-title {
  margin-bottom: -$condition-margin !important;
}

.advanced-title {
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    left: 15px;
  }

  i {
    position: absolute;
    top: -5px !important;
    left: 0 !important;
  }
}

.col2 {
  .row {
    & > div {
      flex-grow: 1;
    }
  }
}

.add-influence-no-data {
  span {
    font-weight: 300;
    color: #fff;
    font-size: 20px;
  }

  i {
    display: inline;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    width: 100%;
    margin-left: 11px;
  }
}
