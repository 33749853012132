@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';

$customer-row-height: 49px;

.table {
  height: 100%;
}

.table-header {
  display: flex;
  flex-shrink: 0;
  margin: 0 10px 0 6px;
  font-weight: $font-weight-medium-500;
  height: $customer-row-height;
}

.table-body {
  overflow: auto;
  height: calc(100% - 82px);
}

.table-row {
  margin: 0 2px;
  flex-shrink: 0;
}

.table-row-header {
  display: flex;
  cursor: pointer;
  max-height: 100px;
  padding: 5px;
  align-items: center;
  height: $customer-row-height;
}

.col-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-name {
  flex: 1 0 100px;
  justify-content: left;
  margin-right: 20px;
  font-weight: $font-weight-medium-500;
}

.col-id {
  flex: 0 0 300px;
  justify-content: left;
}

.col-paying {
  flex: 0 0 100px;
  justify-content: left;
}

.col-self-service {
  flex: 0 0 100px;
  justify-content: center;
  text-align: center;
}

.col-tags {
  flex: 0 0 200px;
  justify-content: left;
}

.col-status {
  flex: 0 0 70px;
  justify-content: left;
}

.customer-disabled {
  .col-name,
  .col-id,
  .col-paying,
  .col-self-service,
  .col-tags,
  .col-status {
    opacity: 0.6;
  }
}

.enforce-visible {
  visibility: visible;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
