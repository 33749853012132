@import '~alerts.management/components/editor/simulationFilters/variables';

.buttons-container {
  display: flex;
  margin-top: 85px;
  justify-content: flex-end;

  & > button {
    margin-left: $btn-margin-right;
    width: $btn-yes-no-width;
    padding-right: 0;
    padding-left: 0;
  }

  & > button:first-child {
    margin-left: 0;
  }
}

.regressors-checkbox-wrapper {
  display: inline;

  &.disabled {
    opacity: 0.5;
  }

  label {
    color: #fff;

    &::before {
      color: #fff !important;
    }
  }
}
