@import '~app/styles/v2/variables.scss';

.input-wrapper {
  position: relative;

  .search-icon {
    position: absolute;
    left: 11px;
    top: 15px;
    font-size: 16px;
    color: $gray-500;
    opacity: 0.7;
  }

  .close-icon {
    position: absolute;
    right: 8px;
    top: 14px;
    font-size: 16px;
    color: $gray-400;
    cursor: pointer;
  }

  .root {
    width: 100%;
    padding: 12px 22px 12px 29px;
  }
}
