@import '~app/styles/themes/base/variables';

.root {
  width: 500px;
}

.type {
  float: left;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.list-header {
  display: flex;
  position: relative;
  left: -40px;
  margin-bottom: 15px;
  align-items: center;
}

.radio-button {
  width: 30px;
}

.sub-title {
}

:global {
  .newCube .dropdown-option {
    padding-left: 40px;
  }

  .newCube i.dropdown-icon {
    position: relative;
    bottom: 1px;
    margin-right: 7px;
    background-size: contain;
    width: 30px;
    height: 30px;
  }

  .newCube .dropdown-header-icons {
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: left;
    position: relative;
    align-items: flex-end;
    font-size: 20px !important;
  }

  .new-cube-dialog-modal {
    .Select {
      height: 100%;
      padding: 9px;

      .Select-control {
        height: 38px;
        border-radius: 3px;
        border: 1px solid #d1d1d1;
        padding: 0;
        background-color: #7ed321;

        .Select-input {
          top: 0;
          border: 0;
          background-color: transparent;
          height: auto;

          input {
            line-height: 38px;
            padding: 0;
          }
        }

        .Select-placeholder {
          line-height: 38px;
        }
      }
    }

    .Select {
      &.is-open {
        .Select-control {
          height: 38px;
          border-radius: 5px;
          border: 1px solid #d1d1d1 !important;
          padding: 0;

          .Select-input {
            top: 0 !important;
            border: 0 !important;
            background-color: transparent !important;
            height: auto;

            input {
              line-height: 38px;
              padding: 0;
            }
          }

          .Select-placeholder {
            line-height: 38px;
          }
        }
      }
    }

    .Select-menu-outer {
      position: relative;
      top: 0 !important;
      border: none;
      height: 0;

      .Select-menu {
        height: 244px !important;
        max-height: 244px !important;
        top: 22px !important;

        .header {
          > div {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: left;
            position: relative;
            align-items: flex-end;

            span {
              font-size: 20px !important;
              color: #222 !important;
            }

            i {
              position: relative;
              bottom: 1px;
              margin-right: 7px;
              background-size: contain;
              width: 30px;
              height: 30px;
            }
          }
        }

        .option-item {
          padding: 9px;

          &.is-focus {
            > div {
              background-color: $gray-lightest;
            }
          }

          &.is-selected {
            > div {
              background-color: $blue-light;

              span {
                width: calc(100% - 41px);
                color: $blue6;
              }

              .check {
                position: absolute;
                display: block;
                top: 13px;
                right: 11px;
              }

              .type {
                color: $blue6;
              }
            }
          }

          div {
            padding: 9px;
            display: flex;
            justify-content: left;
            position: relative;
            border-radius: 3px;
            background-color: white;
            cursor: pointer;
            align-items: center;

            span {
              width: calc(100% - 15px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
            }

            .check {
              display: none;
              color: $blue6;
              vertical-align: bottom;
              line-height: 0;
            }

            .type {
              position: relative;
              margin-right: 7px;
              color: $gray-lighter5;
            }
          }
        }
      }
    }

    .bc.modal-dialog.new-create-cube-modal {
      width: 650px;
      max-height: 850px;
      margin-top: 150px;

      .modal-title {
        .subtitle {
          font-size: 17px;
        }

        input {
          margin: 40px 0;
          width: 200px;
        }
      }

      .modal-content {
        overflow: visible;

        .modal-body {
          padding-top: 10px;
        }

        .modal-footer {
          display: block;
          padding-right: 30px;
          padding-left: 30px;
          position: relative;

          .btn.btn-flat {
            position: absolute;
            left: 30px;
            padding: 0 10px;
            display: flex;
            align-items: center;
          }

          .icn-view-icon {
            font-size: 34px;
            padding-right: 10px;
            transform: translateY(2px);
          }
        }

        i.ion-load-c {
          margin-right: 10px;
        }
      }
    }
  }
}
