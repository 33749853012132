@import '~app/styles/v2/variables.scss';

.root {
  width: 100%;
  min-height: 44px;
  max-height: 155px;
  overflow-y: auto;
  border-radius: 6px;
  background-color: $gray-200;
  display: flex;
  padding: 4px;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;

  :global {
    .icon {
      background:
        linear-gradient(
          90deg,
          rgba(130, 143, 150, 0) 0%,
          rgba(130, 143, 150, 1) 43%,
          rgba(130, 143, 150, 1) 100%
        );
    }

    .chip-container {
      background-color: #828f96;
    }
  }
}

.wrapper {
  :global {
    .andt-dropdown-value-container {
      position: absolute;

      + div {
        width: 100%;
      }
    }
  }
}

.input-field {
  border: none;
  background-color: transparent;
  color: $gray-500;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  font-family: Roboto, sans-serif;
  display: flex;
  height: 26px;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 60px;
  box-sizing: border-box;
  margin: 4px;
  outline: none;
  width: 100%;

  &:placeholder-shown {
    margin-left: 8px;
  }

  &::placeholder {
    color: rgba($gray-500, 0.7);
    font-weight: $font-weight-regular-400;
    font-size: $font-size-16;
    text-align: left;
    vertical-align: middle;
  }
}
