@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  .inputs-wrapper {
    margin-right: 12px;

    & > input {
      margin-bottom: 20px;
      width: 100%;
      display: block;
    }

    button {
      float: right;
      height: 38px;
      min-width: 88px;
    }

    .revert-btn {
      margin-right: 8px;
    }

    .input-title {
      font-size: $font-size-large;
      font-weight: $font-weight-regular;
      display: block;
      color: $black1;
      margin-bottom: 5px;
    }
  }

  .input-description {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    display: block;
    color: $black1;
    line-height: $font-line-height-short;
    margin-bottom: 5px;
  }

  .error {
    height: 75px;
    color: $brand-danger;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    margin-top: 10px;
  }

  .row-error-num {
    margin-right: 20px;
  }
}
