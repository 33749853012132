@import '~app/styles/themes/base/variables';

.header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  min-height: min-content;

  .title-block {
    display: flex;
    flex-direction: column;
  }

  h4 {
    margin-right: 10px;
    font-weight: $font-weight-light;
    color: white;
  }
  small {
    font-size: 12px;
    font-weight: bold;
  }

  .with-subtitle {
    margin-bottom: 2px;
  }
}

.plus {
  font-size: 20px;
  position: relative;
  top: 1px;
}

.panel-titles {
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: hidden;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    color: $gray-lighter4;
    letter-spacing: 0.5px;
    &:first-of-type {
      margin-right: 5px;
    }
  }
}

.panel-popover {
  display: flex;
  max-height: 220px;
}

.popover {
  transition: 0.3s;
}

:global .popover-content {
  padding: 8px;
}

// theme: light

.header.light {
  h4 {
    color: $black1;
  }

  .panel-titles {
    height: 15px;
  }

  :global .popover-content {
    min-width: 250px;
  }
}
