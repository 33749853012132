@import '~app/styles/v2/variables.scss';
@import '~app/styles/themes/base/variables';

.container {
  width: 100%;
  margin: 12px 0 35px 0;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;

  .title {
    font-size: 23px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
}

.accordion-wrapper {
  > h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px 0;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 36px;
  }
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  //border: solid 1px $gray-200;
  background-color: $white-500;
  margin-bottom: 5px;
  cursor: pointer;
}

.headerArrows {
  display: flex;
  justify-content: center;
  width: 48px;
  color: $white-500;
}

.headerTitles {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px 0 0;

  > span {
    width: 200px;
    text-align: left;
    font-weight: 500;

    &:first-child {
      width: 350px;
    }

    &:last-child {
      text-align: right;
    }
  }
}
