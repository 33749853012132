@import '~app/styles/v2/variables';

.root {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  margin-bottom: 50px;
}

.new-what-column {
  width: 50px;
  height: 50px;
  display: block;
}

:global {
  .is-null {
    color: $gray-400;
    font-style: italic;
  }

  .public_fixedDataTableRow_columnsRightShadow,
  .public_fixedDataTable_scrollbarSpacer {
    width: 14px;
    background-color: #fff;
    z-index: 200;
    right: -1px;
    background-image: none !important;
  }
}
