@import '~app/styles/v2/variables';

.refresh-alerts-dd {
  top: 1px;
  left: 8px;
  position: relative;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    color: $gray-400;
    margin-left: 5px;
  }

  i {
    &.active {
      color: $blue-500;
    }

    margin-right: 8px;
    font-size: 24px;
  }

  :global {
    .andt-dropdown-control {
      min-height: 35px !important;
      background-color: transparent !important;
      border: none !important;
      padding: 0 !important;

      > div {
        padding: 0 !important;
      }

      .icon {
        font-size: 16px !important;
        margin-right: 0 !important;
        color: $gray-400 !important;
      }
    }

    input {
      height: 28px !important;
    }
  }
}
