@import '~app/styles/v2/variables.scss';

.root {
  width: 250px;
  height: 90px;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.3;
  position: relative;
}

.title {
  color: $white;
  font-size: $font-size-18;
  font-weight: $font-weight-medium-500;
  padding-top: 8px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-width {
  max-width: 200px;
}

.options-menu-wrapper {
  width: 24px;
  margin-right: 8px;
  pointer-events: none;
  opacity: 0;

  i {
    padding: 2px;
    width: 24px;
    height: 24px;
  }

  i::before {
    margin-left: 2px;
  }
}

.avatar-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 11px;

  .avatar-shift {
    $var: 0;

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        transform: translateX(-#{$var}px);
        $var: $var + 8;
      }
    }
  }
}

.empty-group {
  color: $white;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  bottom: 24px;
}

.highlighted {
  opacity: 1;
}

.highlighted:hover {
  .options-menu-wrapper {
    opacity: 1;
    pointer-events: auto;
  }
}

.show-menu-wrapper {
  opacity: 1;
  pointer-events: auto;
}

/* -- Color Themes -- */
.gray {
  background-color: $gray-400;
}

.rose {
  background-color: $rose-500;
}

.mint {
  background-color: $mint-500;
}

.yellow {
  background-color: $yellow-500;
}

.teal {
  background-color: $teal-500;
}

.indigo {
  background-color: $indigo-400;
}

.lilac {
  background-color: $lilach-500;
}

.tomato {
  background-color: $tomato-500;
}

.eucaliptus {
  background-color: $eucaliptus-500;
}
