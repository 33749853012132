@import '~app/styles/v2/variables.scss';

.sub-text {
  display: block;
  width: 310px;
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  line-height: normal;
  color: $gray-400;
  margin-top: 3px;
}

.invisible {
  visibility: hidden;
}
