@import '~app/styles/v2/variables.scss';

.container {
  position: relative;
  left: 28px;
  width: calc(100% - 28px);
  overflow-y: auto;
  max-height: 325px;

  > b {
    margin-left: 28px;
  }

  table {
    display: table;
    width: 100%;

    thead {
      border-bottom: 1px solid $gray-200;
    }

    tbody {
      tr {
        &:hover {
          background: #fffcb6;
        }

        &:nth-child(even) {
          background: $gray-100;

          &:hover {
            background: #fffcb6;
          }
        }
      }
    }
  }

  td {
    width: 9%;
    display: inline-block;
    padding: 5px 10px !important;
    cursor: pointer;

    &:first-child {
      width: 235px;
    }

    &.small {
      width: 7%;
    }
  }
}
