@import '~app/styles/v2/variables.scss';

.container {
  height: 36px;
  border-radius: 6px;
  background-color: $gray-300;
  font-size: $font-size-16;
  line-height: $font-size-16;
  display: flex;
  align-items: center;
  padding-left: 12px;
  justify-content: space-between;

  &.value {
    color: $indigo-500;
  }

  &.placeholder {
    color: rgba($gray-500, 0.7);
    background-color: $gray-200;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .triangle {
    padding-right: 12px;
    color: $gray-500;
  }
}
