@import '~app/styles/v2/variables';

.row {
  box-sizing: border-box;
  display: flex;
  height: 34px;
  border-radius: 4px;
  border: 1px solid $gray-200;
  font-size: $font-size-14;
  color: $gray-500;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 10px;
  cursor: pointer;
  position: relative;

  .link {
    position: absolute;
    top: 9px;
    right: 23px;
    visibility: hidden;
  }

  &:hover {
    background-color: rgba($blue-500, 0.05);

    .link {
      visibility: visible;
    }
  }
}
