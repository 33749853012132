.main {
  .onoffswitch {
    width: 32px;
    position: relative;
    display: inline-block;
  }

  .onoffswitch.disabled {
    opacity: 0.3;
  }

  .onoffswitch .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin: 0;
  }

  .onoffswitch.disabled .onoffswitch-label {
    cursor: default;
  }

  .onoffswitch .onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    transition: all 0.15s ease-in-out;
  }

  .onoffswitch .onoffswitch-inner .before,
  .onoffswitch .onoffswitch-inner .after {
    float: left;
    width: 50%;
    line-height: 24px;
    height: 8px;
    padding: 0;
    box-sizing: border-box;
  }

  .onoffswitch .onoffswitch-inner .before {
    padding-left: 10px;
  }

  .onoffswitch .onoffswitch-inner .after {
    padding: 1px 8px 0;
    text-align: right;
  }

  .onoffswitch .onoffswitch-switch {
    width: 16px;
    height: 16px;
    margin: 0;
    border-radius: 50%;
    position: absolute;
    transition: right 0.15s ease-in-out;
    right: 16px;
    top: -4px;
  }

  .toggle-group {
    height: 27px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
  }

  .toggle-group input[type='checkbox'] {
    position: absolute;
    left: 10px;
    display: none;
  }

  .toggle-group input[type='checkbox']:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }

  .toggle-group input[type='checkbox']:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
    right: 0;
  }

  .toggle-group input[type='checkbox']:focus ~ .onoffswitch {
    outline: thin dotted #333;
    outline: 0;
  }

  .toggle-group label {
    position: absolute;
    cursor: pointer;
    padding-left: 65px;
    display: inline-block;
    text-align: left;
    line-height: 24px;
    width: 100%;
    z-index: 1;
    height: 24px;
    font-weight: 200;
  }
}
