@import '~app/styles/themes/base/variables';
$unit-select-height: 50px;
$unit-select-input-height: $unit-select-height - 2px;

.root {
  display: inline-block;
  width: 100%;

  :global {
    mark {
      background-color: transparent;
      color: $blue;
    }

    .Select-control {
      border: none;
      cursor: pointer;
      border-radius: 3px;
    }

    .Select-input {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    .Select-placeholder {
      color: $gray-lighter6;
      font-size: $font-size-base;
      font-weight: $font-weight-light;
    }

    .Select-arrow-zone,
    .Select-arrow-zone:hover {
      padding-right: 10px !important;
      .Select-arrow {
        border-width: 4px 4px 0px;
        border-top-color: $black1;
        top: -2px;
      }
    }

    .Select--single.is-open {
      .Select-control {
        border-radius: 3px;
      }

      .Select-input {
        width: 100% !important;
        left: 0px !important;
        top: 52px !important;
        border: none !important;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        input {
          top: 0 !important;
          padding-right: 10px;
          padding-left: 10px;
          color: $blue;
        }
      }

      .Select-arrow-zone,
      .Select-arrow-zone:hover {
        .Select-arrow {
          border-width: 4px 4px 0px;
          border-top-color: $black1;
          top: -2px;
        }
      }
    }

    .is-focused:not(.is-open) {
      .Select-control {
        border: none;
        box-shadow: none !important;
      }
    }

    .is-disabled {
      .Select-control {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    .Select--single {
      .Select-control {
        .Select-value {
          line-height: $unit-select-input-height;
          padding-left: 15px;
          padding-right: 25px;
          .andt-search-selected {
            .andt-search-item {
              font-size: $font-size-base;
              font-weight: $font-weight-light;
              display: inline-block;
              color: $black1;
              position: relative;
              top: -2px;
            }
            .andt-search-title {
              color: $black1;
              font-size: $font-size-small;
              font-weight: $font-weight-bold;
              line-height: $font-size-small;
              position: absolute;
              left: 15px;
              bottom: 8px;
              transform-origin: bottom left;
              transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
            }
          }

          .andt-search-none-selected {
            .andt-search-title {
              transform: scale(1.17) translate(0, -9px);
            }
          }
        }
      }
    }

    .has-value {
    }

    .UnitsSelectOption {
      font-size: $font-size-base;
      color: $gray-dark;
      font-weight: $font-weight-light;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      white-space: nowrap;
    }
    .UnitsSelectFocusedOption {
      background-color: $gray-lightest;
    }
    .UnitsSelectDisabledOption {
      opacity: 0.5;
    }
    .UnitsSelectSelectedOption {
      color: $blue;
      font-weight: $font-weight-medium;
    }

    .white-style.Select--single {
      border-radius: 3px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
      .Select-control:hover {
        box-shadow: none;
      }

      .Select-input {
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.14);
      }

      .Select-menu-outer {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
        border: none;
      }
    }

    .blue-style.Select--single {
      background-color: transparent;
      border-radius: 3px;

      mark {
        color: $blue-dark;
        font-weight: $font-weight-medium;
      }

      .Select-control {
        background-color: transparent;
        .Select-input {
          background-color: $blue3;
          color: white;
        }

        .Select-value {
          background-color: $blue3;
          border-radius: 3px;
          .andt-search-selected {
            .andt-search-item {
              color: white;
            }
            .andt-search-title {
              color: white;
            }
          }
        }

        .Select-arrow-zone,
        .Select-arrow-zone:hover {
          .Select-arrow {
            border-top-color: white;
          }
        }
      }
      .Select-menu-outer {
        background-color: $blue3;
        border: none;
      }

      .Select-noresults {
        color: $blue-dark;
        font-weight: $font-weight-medium;
      }

      .UnitsSelectOption {
        color: white;
      }
      .UnitsSelectFocusedOption {
        background-color: $blue4;
      }
      .UnitsSelectSelectedOption {
        color: white;
        font-weight: $font-weight-medium;
      }
    }
  }
}
