@import '~app/styles/v2/variables';

.container {
  height: 40px;
  margin: 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;

  &:hover {
    background-color: rgba($blue-500, 0.1);
  }

  .label {
    color: $gray-500;
  }

  .ico {
    margin-right: 10px;
  }
}

.container-header {
  height: 40px;
  margin: 3px;

  .list-header {
    color: $gray-400;
    text-transform: uppercase;
    font-weight: $font-weight-bold-700;
    font-size: $font-size-14;
    line-height: 12px;
    position: relative;
  }
}

.group {
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  line-height: 1;
  color: #d4d9e0;
  padding-left: 9px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  flex-grow: 1;
}
