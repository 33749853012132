//== Typography
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-large: 16px;
$font-size-x-large: 18px;

$font-size-h1: 40px;
$font-size-h2: 30px;
$font-size-h3: 22px;
$font-size-h4: 18px;
$font-size-h5: $font-size-base;
$font-size-h6: 12px;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-line-height-short: 1.2;

//== Colors
//
$body-bg: #fdfdfd;

// Gray
$gray-darker: #111;
$gray-dark: #212121; //in use
$gray-dark2: #4a4a4a; //in use
$gray: #5e5e5e;
$gray-light: #b4b4b4;
$gray-lighter: #dbdbdb; //in use
$gray-lighter2: #f6f6f6;
$gray-lighter3: #979797;
$gray-lighter4: #9b9b9b; //in use
$gray-lighter5: #717171;
$gray-lighter6: #c6c6c6; //in use
$gray-lighter7: #898989;
$gray-lighter8: #efefef; //in use
$gray-lightest: #f5f5f5;
$gray-300: #d4d9e0;
$gray-400: rgb(137, 149, 160);

$black-alpha050: rgba(0, 0, 0, 0.5);
$black-alpha020: rgba(0, 0, 0, 0.2);
$black-alpha026: rgba(0, 0, 0, 0.26);
$black-alpha014: rgba(0, 0, 0, 0.14);

// blues
$blue-darker: #093150;
$blue-dark: #066790;
$blue: rgba(9, 130, 182, 100);
$blue1: #0d90c7;
$blue2: #6bbfe9;
$blue3: #2ea3dd;
$blue4: #38aee8;
$blue5: #3a8fb5;
$blue6: #0982b6;
$blue7: #076790;
$blue8: #2693c5;
$blue9: #174070;
$blue-light2: #90cfef;
$blue-light: #cbeeff;
$blue-lighter: #e4f5fd;
$blue500: #2671ff;

//green
$green-darkest: #417505;
$green-dark: #82bd36;

// blacks
$black: #000;
$black1: #222;

// whites
$white: #fff;
$white1: #fafafa;
$white2: #fdfdfd;

//red
$red_100: rgb(250, 231, 235);
$red_300: rgb(237, 159, 175);
$red_400: rgb(222, 87, 115);
$red_500: #d10f37;

// Classic brand colors
$brand-success: #27c24c;
$brand-info: #90cfef;
$brand-warning: #fcc567;
$brand-danger: #d0021b;

// Icon Colors:
$orange-sun: #ff5322;
$blue-moon: $blue2;
$green-init: #7ed321;
$orange-incomplete: #f5a623;
$red-fail: #f00;

//new design color pallet
$blue_light_500: #00b7f1;

$indigo_500: #17406f;

$violet_600: #412ba6;

$blue_100: #e9f0ff;
$blue_500: #2671ff;

$dark_green_500: #287f60;

$black_500: #1f292e;

$white_500: #fff;

$gray_100: #f4f7fb;
$gray_200: #e9e9f0;
$gray_300: #d4d9e0;
$gray_350: #b5bbc3;
$gray_400: #8995a0;
$gray_500: #3d4c59;
$gray_600: #27363e;

$teal_500: #00bbd4;

$mint-500: rgb(26, 227, 149);

$tomato-500: rgb(255, 86, 83);

// Topbar
$topbar-height: 48px;

// Navbar
$navbar-bg: $white; //in use - appNavBar
$navbar-bg-active: $white; //in use - appNavBar button on hover
$navbar-icon-color: $gray_500;
$navbar-icon-color-under: #cedeee;
$navbar-icon-color-hover: $blue_500;
$navbar-menu-item-color: $gray-500;
$navbar-menu-item-color-active: $blue_500;
$navbar-menu-item-bg-active: $blue_100;
$navbar-menu-active-color: $blue_500;
$navbar-menu-hover-color: #0dc4ff;
$navbar-shadow: 0 4px 14px -1px rgba(0, 0, 0, 0.15);
$navbar-separator-color: #737373;
$navbar-submenu-title-color: $gray_350;

//Buttons
$btn-yes-no-width: 78px;

//Scrollbar colors
$cyan-scrollbar: #b6dae9;

//Diametrics color
$metric-color: $green-dark;
$dimension-color: $blue6;

$light-centered-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
$light-centered-shadow-before: 0 0 3px 0 rgba(0, 0, 0, 0.2);

// Preview table
$preview-header-bg: #e9e9f0;
$preview-header-color: #3d4c59;

// error message box
$error-border: 4px solid #d10f37;
