.container {
  display: flex;

  div {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 45px;

    p {
      margin: 0;
      padding: 0 15px;
    }
  }

  div:nth-child(1) {
    padding: 0;
    display: block;
    width: 40px;
  }

  div:nth-child(2) {
    width: calc(40% - 40px);
  }

  div:nth-child(3) {
    width: 20%;
  }

  div:nth-child(4) {
    width: 40%;
  }

  img {
    width: 70%;
    height: auto;
    top: 15%;
    position: relative;
  }
}
