.page-header {
  align-items: baseline;
  display: flex;
  width: 100%;
  justify-content: space-between;

  .container {
    display: flex;
  }

  h1 {
    margin-right: 8px;
  }

  h2 {
    margin-right: 16px;
  }
}

:global {
  .new-alert {
    position: absolute !important;
    top: 58px;
    right: 4%;
  }
}
