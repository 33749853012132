@import '~app/styles/themes/base/variables';

.outer-border {
  width: 79px;
  height: 85px;
  margin: 0 auto;
}

.inner-border {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: relative;
  left: 1px;
  top: 1px;
  background-color: white;
  //clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}
