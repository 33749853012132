@import '~app/styles/themes/base/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  background-size: 100% 100%;
  padding: 0 90px;
  min-width: 1140px;
}

.header {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 50px;
}

.title {
  width: 500px;
  display: flex;
  flex-direction: column;
  color: $black1;
  margin-top: 5px;

  div {
    font-size: $font-size-h3;
    line-height: $font-size-h3;
    font-weight: $font-weight-light;
    color: $black1;
  }
}

.editor {
  display: flex;
  flex: 1 0 0;
  margin-top: 30px;
  overflow-y: auto;
}

.stream-not-found {
  align-self: center;
  padding-top: 20px;
}
