@import "~app/styles/themes/base/variables";

.main {
  font-family: Roboto, sans-serif;
  font-weight: 300;

  display: flex;
  flex-direction: column;

  background-color: $blue;

  border-radius: 3px;

  min-height: min-content;

  .vr {
    width: 1px;
    margin-top: 32px;
    height: 370px;
    border-left: 1px solid $blue1;
  }

  *::-webkit-scrollbar {
    background-color: transparent;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $blue-light;
    border: 0;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: $blue-light2;
    border: 1px solid $blue;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: $blue;
    border: 0;
  }
}

.concepts {
  display: flex;
  min-height: 400px;
}
