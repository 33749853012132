@import '~app/styles/v2/variables.scss';

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  height: 245px;
  border-radius: 5px;
  background-color: $gray-100;
  margin-bottom: 37px;
}

.streamDataContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
