@import '~app/styles/v2/variables.scss';

:global {
  .customer-edit-modal {
    .modal-content {
      border-radius: 8px !important;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.container {
  margin-top: -7px;
  background-color: $white;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: Roboto, sans-serif;
  font-size: $font-size-22;
  font-weight: $font-weight-light-300;
  color: $gray-500;
  line-height: 25px;
  flex-shrink: 0;
}

.content {
  height: 70vh;
  margin: 6px 0;
}

.label {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
  line-height: 14px;
  margin-bottom: 8px;
}

.title {
  color: $gray-500;
  font-size: $font-size-24;
  font-weight: $font-weight-medium-500;
  padding-top: 30px;
}

.error {
  color: $red-500;
}

.sub {
  font-size: $font-size-14;
  color: $gray-300;
}

.row {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
}

.split-row {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;

  .split-field {
    width: 350px;

    .label {
      margin-top: 8px;
    }
  }
}

.time-scales {
  display: flex;
  justify-content: space-between;
}

.footer {
  margin-top: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    min-width: 85px;
  }

  .form-btn {
    margin-right: 16px;
  }
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
}
