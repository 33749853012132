@import '~app/styles/v2/variables';

.container {
  width: 100%;
  height: 100%;

  .title {
    flex: 15.5652 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
  }

  .startTime {
    flex: 0 0 105px;
  }

  .updateTime {
    flex: 0 0 104px;
  }

  .duration {
    flex: 0 0 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .score {
    flex: 0 0 68px;
  }

  .link {
    flex: 0 0 23px;
  }

  header {
    display: flex;
    line-height: 14px;
    padding-bottom: 6px;
    margin-top: 13px;
    font-weight: $font-weight-medium-500;
    color: $gray-400;

    .title {
      font-size: $font-size-16;
      color: $gray-500;
      line-height: $font-size-18;
    }
  }

  .see-all {
    cursor: pointer;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    color: $gray-500;
    padding-top: 9px;
  }
}
