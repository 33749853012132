@import '~app/styles/themes/base/_variables';

.checkbox-container {
  position: absolute;
  right: 0;
}
.panel {
  margin-bottom: 10px;
  margin-right: 10px;
  padding-top: 10px;
  border-top: 1px solid $gray-lighter;
  display: flex;
  position: relative;

  .dimension-panel {
    color: #ffffff;
    background-color: #2ea3dd;
    padding-left: 15px;
    display: flex;
    flex: 1 0 0;
    max-width: 250px;
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 $black-alpha014;

    span {
      flex: 1 0 0;
    }
  }
}

.deselect {
  position: relative;
  top: 2px;
}
