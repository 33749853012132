@import '~app/styles/v2/variables';

.root {
  display: flex;

  .filters-row {
    width: 100%;
    margin-bottom: 20px;

    &.double {
      display: flex;
      justify-content: space-between;
    }

    &.mid {
      width: 215px;
    }

    &.small {
      width: 115px;
    }

    &.feedback-positive {
      margin-bottom: 5px;
    }

    .small {
      width: 115px;
    }

    :global {
      .andt-dropdown-control {
        height: 36px;
        line-height: 36px;
      }
    }
  }

  .filters-row.cols-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .filters-row-header {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: $font-weight-regular-400;
    color: $gray-500;
    display: block;
  }

  .more-filters-btn {
    margin-bottom: 20px;
  }
}
