/* stylelint-disable */

@import '~app/styles/v2/variables';

.legacy {
  background-color: rgba($gray-500, 0.25);

  .value {
    color: $gray-500;
  }
}

.container {
  display: flex;
  align-items: center;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  border-radius: 4px;
  width: min-content;
  position: relative;
  background-color: rgba($gray-600, 0.5);
  padding: 1px;
  margin: 2px;
  height: 22px;
  max-width: 123px;
  flex: 0 1 auto;
  min-width: 27px;

  .value {
    margin: 0 8px 0 8px;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.small {
      margin-right: 20px;
    }
  }

  .close-button {
    color: rgba($gray-600, 0.7);
    position: absolute;
    right: 8px;
    width: 14px;
    padding-left: 5px;
    opacity: 0;
    transition: opacity 0.2s;
    background:
      linear-gradient(
        90deg,
        rgba(158, 164, 173, 0) 0%,
        rgba(158, 164, 173, 1) 43%,
        rgba(158, 164, 173, 1) 100%
      );

    &::before {
      opacity: 0.5;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &.small {
      opacity: 1;

      &::before {
        opacity: 1;
      }
    }
  }

  .chip-icon {
    display: none;
  }

  &.legacy {
    background-color: rgba($gray-500, 0.25);

    .value {
      color: $gray-500;
    }
  }

  &.stand-alone {
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    border-radius: 4px;
    width: min-content;
    height: 28px;
    position: relative;
    white-space: nowrap;
  }

  &.lean {
    border-radius: unset;
    width: unset;
    height: unset;
    background-color: transparent !important;

    .value {
      margin: 0;
    }
  }

  &.measure {
    background-color: rgba($purple-600, 0.2);

    .value {
      color: $purple-600;
    }

    .close-button {
      color: $gray-500;
      background:
        linear-gradient(
          90deg,
          rgba(205, 195, 224, 0) 0%,
          rgba(205, 195, 224, 1) 43%,
          rgba(205, 195, 224, 1) 100%
        );
    }
  }

  &.dimension {
    background-color: rgba($eucaliptus-500, 0.2);
    line-height: 1;

    .value {
      color: $eucaliptus-500;
    }

    .close-button {
      margin-top: 2px;
      color: rgba($gray-500, 0.7);
      cursor: pointer;
      background:
        linear-gradient(
          90deg,
          rgba(198, 227, 217, 0) 0%,
          rgba(198, 227, 217, 1) 43%,
          rgba(198, 227, 217, 1) 100%
        );
    }
  }

  &.filter {
    background-color: rgba($gray-500, 0.25);

    .value {
      color: $gray-500;
    }
  }

  &.mail {
    height: 26px;
    border-radius: 4px;
    margin: 4px;
    background-color: rgb(190, 194, 202);
    color: $gray-500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    box-sizing: border-box;
    max-width: 325px;

    .chip-icon {
      display: block;
      margin-top: 4px;
    }

    .value {
      color: $gray-500;
      line-height: 1;
    }

    .close-button {
      color: $gray-500;
      background:
        linear-gradient(
          90deg,
          rgba(190, 194, 202, 0) 0%,
          rgba(190, 194, 202, 1) 33%,
          rgba(190, 194, 202, 1) 100%
        );
    }
  }

  &.invalid {
    background-color: rgb(227, 179, 194);
    height: 26px;
    border-radius: 4px;
    margin: 4px;
    color: $gray-500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    box-sizing: border-box;

    .chip-icon {
      display: none;
    }

    .value {
      color: $gray-500;
      line-height: 1;
    }

    .close-button {
      color: $gray-500;
      background:
        linear-gradient(
          90deg,
          rgba(227, 179, 194, 0) 0%,
          rgba(227, 179, 194, 1) 33%,
          rgba(227, 179, 194, 1) 100%
        );
    }
  }

  &:hover {
    .close-button {
      opacity: 1;
    }
  }
}
