/* stylelint-disable */
@import '~app/styles/themes/base/variables';
$cell-margin: 8px;

.header-container {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.unique-values-container {
  background-color: $preview-header-bg;
  color: $preview-header-color;
  display: flex;
  flex: 1 0 60px;
  padding-top: 5px;
  padding-left: $cell-margin;

  .unique-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .total {
      font-weight: 400;
      font-size: 26px;
      line-height: 30px;
    }

    .sub-title {
      color: $preview-header-color;
      font-size: $font-size-small;
      font-weight: normal;
    }
  }

  .unique-values-spacer {
    display: flex;
    flex: 1 0 0;
  }
}

.name {
  display: flex;
  width: 100%;
  flex: 1 0 55px;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  padding-left: $cell-margin;
}

.name-dropdown,
.name-dropdown_metric {
  composes: name;
  flex-direction: row;
  align-items: center;
  padding-left: 0 !important;

  div {
    visibility: hidden;
  }

  &:hover {
    div {
      visibility: visible;
    }
  }
}

.name-dropdown_metric {
  margin-left: 20px;
}

.ignored {
  color: $gray-lighter4;
}

.show-dropdown-button {
  div {
    visibility: visible;
  }
}

.name-new-column {
  composes: name;
  flex-direction: row;
  align-items: center;
  padding: 0;

  i {
    font-size: 22px;
    margin-top: 4px;
    margin-left: 1px;
  }
}

.aggregation-box {
  padding: 4px 10px 10px 4px;

  .aggregation-type-line,
  .aggregation-type-line-sel {
    height: 28px;
    width: 150px;
    padding: 6px;
    cursor: pointer;

    &:hover {
      color: $blue-500;
    }
  }

  .aggregation-type-line-sel {
    color: $blue-500;
  }

  i {
    position: absolute;
    right: 10px;
    color: $blue-500;
  }
}

:global {
  .aggregation-target-type {
    position: absolute;
    color: $brand-danger;

    .btn-aggregation {
      width: 16px !important;
      height: 16px !important;
      top: 14px !important;

      .icn-general16-asterisk {
        color: $red-500 !important;
      }

      &:hover {
        .icn-general16-sigma,
        .icn-general16-average {
          color: $blue-500;
        }
      }
    }
  }
}

.missing-target-type {
  position: absolute;
  left: 2px;
  color: $brand-danger;
}

:global .icn-icon-table-aggregation {
  font-size: 20px;
}

.popover {
  margin-top: -10px !important;

  :global {
    .popover-content {
      padding: 4px 5px 0 5px;
    }

    .arrow {
      display: none !important;
    }
  }
}
