@import "~app/styles/themes/base/variables";

.notifications-tc {
  width: 100% !important;
  max-width: 450px !important;
  top: 50px !important;
  transform: translateX(-50%);
  left: 50% !important;
  margin-left: 0 !important;
}

.notification-dismiss {
  display: none !important;
}

.notification-hidden,
.notification.notification-visible {
  border: none !important;
  border-radius: 8px !important;
  min-height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: $black1 !important;
  box-shadow: none !important;
  font-size: $font-size-large !important;
  font-weight: $font-weight-light !important;
  width: initial !important;
}

.notification-error {
  color: $brand-danger !important;
}

.notification-warning {
  color: $brand-warning !important;
}

.notification-info {
  color: $brand-info !important;
}

.notification-success {
  color: $brand-success !important;
}

.notification-message {
  white-space: pre-line;
  word-wrap: break-word;
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

