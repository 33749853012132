.main-container {
  background-color: transparent;
  &.mask {
    background-color: #7ed5f0;
  }
  .titles {
    border-bottom: 1px solid #b4b4b4;
    border-top: 1px solid #b4b4b4;
    padding: 0 8%;
  }
  .loader-wrapper {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .items-container {
    list-style: none;
    margin: 0;
    padding: 0;
    min-height: 150px;
    &.new-item {
      li:first-child {
        background-color: #f0e90755;
      }
    }
    li {
      position: relative;
      padding: 0 8%;
      background-color: #ffffff;
      transition: background-color 0.3s ease-in-out;
      &:nth-child(2n) {
        background-color: #f6f6f6;
      }
      &:hover {
        button {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      top: 2px;
      right: 10px;
      display: none;
    }
  }
  .count-items {
    width: 84%;
    margin: 13px auto;
    display: flex;
    justify-content: flex-end;
  }
}
