@import '~app/styles/themes/base/variables';

.panel {
  margin-bottom: 15px;
  margin-right: 10px;
  min-width: 200px;
  max-width: 50%;
  color: #ffffff;
  background-color: #2ea3dd;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 $black-alpha014;

  span {
    flex: 1 0 0;
  }
}

.deselect {
  position: relative;
  top: 2px;
}
