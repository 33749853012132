@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.input-container {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;

  input {
    margin-right: 5px;
    width: 90px !important;
  }
}

.input-alone {
  margin-top: 50px !important;
}

.buttons-container-yes-no {
  display: flex;
  margin-top: 85px;
  justify-content: flex-end;

  & > button:first-child {
    margin-left: 0px;
  }

  & > button {
    margin-left: $btn-margin-right;
    width: $btn-yes-no-width;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.buttons-container {
  & > button:last-child {
    margin-right: 0px;
  }

  & > button {
    margin-right: $btn-margin-right;
    width: 50px;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.emphasize {
  font-weight: bold;
}
