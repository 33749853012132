@import '~app/styles/v2/variables';

.container {
  cursor: pointer;
  height: 17px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: fit-content;
  padding-top: 2px;
  border-bottom: 2px solid transparent;

  &.disable {
    opacity: 0.5;
  }

  &.error {
    border-bottom: 2px solid $red-500;
  }

  &.pristine {
    border-bottom: 2px solid $blue-500;
  }

  .value {
    display: flex;
    align-items: center;
    color: white;
    margin-right: 2px;
  }

  .triangle {
    display: flex;
    align-items: center;
    color: rgba(white, 0.5);
    padding-top: 1px;
  }
}

.groupByDimension {
  height: 36px;
  background-color: #e9e9f0;
  color: rgb(61, 76, 89);
  width: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  min-width: 40px;
  display: flex;
  align-items: center;

  .value {
    color: rgb(61, 76, 89);
    font-size: $font-size-16;

    &.with-selection {
      color: rgb(23, 64, 111);
    }
  }

  .triangle {
    color: $gray-500;
  }
}
