:global {
  .ftue-main-btn {
    background: url(./images/ftue-star.svg);
    width: 22px;
    height: 22px;
  }
}

.onBoarding-container {
  padding: 48px 48px 20px 48px;
}

.header {
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #17406f;
  }

  span {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 500;
    color: #3d4c59;
    opacity: 0.5;
    transition: opacity 0.175s ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.top-container {
  display: flex;
  position: relative;
  &:hover{
    .skip-btn{
      opacity: .5;
    }
  }
  .skip-btn{
    position: absolute;
    top: 14px;
    right: 22px;
    font-size: 14px;
    font-weight: 500;
    color: #3d4c59;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    &:hover{
      opacity: .75;
    }
  }
  &.mission-accomplished {
    width: 100%;
    flex-direction: column;
    background-color: #fff !important;
    border: solid 2px rgba(48, 222, 80, 0.2);

    > div {
      margin: -16px 0 0 0;
    }

    h1 {
      margin: -20px 0 0 0;
      font-size: 20px;
    }
  }

  .img-wrapper,
  .text-wrapper,
  .btn-wrapper {
    display: flex;
  }

  .img-wrapper {
    &.loading {
      width: 0;
    }

    width: 70px;
    align-items: center;
    justify-content: left;
    padding: 0;
  }

  .main-slide-images {
    background-size: auto;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;

    &.img-1 {
      background-image: url('./images/slides/ftue-1.svg');
    }

    &.img-2 {
      background-image: url('./images/slides/ftue-2.svg');
    }

    &.img-3 {
      background-image: url('./images/slides/ftue-3.svg');
    }

    &.img-4 {
      background-image: url('./images/slides/ftue-4.svg');
    }
  }

  .text-wrapper {
    display: flex;
    align-items: center;
    width: 465px;
    justify-content: center;

    .content-wrapper {
      width: 425px;
      text-align: left;

      h1 {
        color: #17406f;
        font-weight: 500;
        font-size: 20px;
        margin: 0 0 8px 0;
        padding: 0;
      }

      p {
        color: #3d4c59;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }

    &.loading {
      width: calc(450px + 115px);
      justify-content: left;

      .content-wrapper {
        width: 400px;
        height: 82px;
        background: url(./images/big-loader.gif);
        background-repeat: no-repeat;
        background-size: 300px 100%;
        opacity: 0.5;

        h1,
        p {
          display: none;
        }
      }
    }
  }

  .btn-wrapper {
    align-items: flex-end;
    padding: 0;
    justify-content: flex-end;
    width: 125px;

    &.loading {
      button {
        width: 97px;
        height: 35px;
        background: #d4d9e0;
        padding: 7px 12px;
        border-radius: 6px;
        cursor: default;
        border: none;
        color: transparent;
      }
    }

    button {
      background: #2671ff;
      padding: 7px 12px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
      color: #fff;
      text-transform: capitalize;
    }
  }
}

.bottom-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  border-radius: 8px;
  background-color: #fff;

  .org-name {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 400;
    color: #8995a0;
  }

  .gauge-wrapper {
    display: flex;
  }
}

.custom-dots {
  position: relative;
  display: flex;
  margin: 40px 0 0 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  list-style: none;

  &.loading {
    li {
      margin: 0 22px;
    }
  }

  li {
    margin: 0 10px;
    background: #e9e9f0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;

    &.selected-done {
      display: block;
      opacity: 1;
      background: url(./images/done.svg);
      background-repeat: no-repeat;
      background-size: 16px 16px;
      cursor: default;
    }

    &.selected {
      background: #17406f;
      cursor: default;
    }

    &.done {
      display: block;
      background: url(./images/done.svg);
      background-repeat: no-repeat;
      background-size: 16px 16px;
      opacity: 0.45;
    }
  }
}

:global {
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 50%;
    background: none;
    border: 0;
    cursor: pointer;
  }

  .carousel .control-arrow:hover {
  }

  .carousel .control-arrow::before,
  .carousel.carousel-slider .control-arrow::before {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    content: '';
  }

  .carousel .control-disabled.control-arrow {
    cursor: inherit;
    display: none;
  }

  .carousel .control-prev.control-arrow {
    left: -36px;
  }

  .carousel .control-prev.control-arrow::before {
    transform: rotate(-135deg);
  }

  .carousel .control-next.control-arrow {
    right: -36px;
  }

  .carousel .control-next.control-arrow::before {
    transform: rotate(45deg);
  }

  .carousel {
    position: relative;
    width: 100%;
  }

  .carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
  }

  .carousel .carousel {
    position: relative;
  }

  .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
  }

  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
  }

  .carousel .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
  }

  .carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
  }

  .carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
  }

  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 3px solid #333;
  }

  .carousel .thumb img {
    vertical-align: top;
  }

  .carousel.carousel-slider {
    position: relative;
    margin: 0;
  }

  .carousel.carousel-slider .control-arrow {
  }

  .carousel.carousel-slider .control-arrow:hover {
  }

  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    display: flex;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
  }

  .carousel .slider-wrapper.axis-vertical {
    display: flex;
  }

  .carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
  }

  .carousel .slider.animated {
    transition: all 0.35s ease-in-out;
  }

  .carousel-wrapper {
    margin-top: 20px;
  }

  .carousel .slide {
    display: flex;
    justify-content: center;
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
  }

  .carousel-wrapper.top {
    .carousel .control-disabled.control-arrow {
      cursor: inherit;
      display: block;
      opacity: 0.3;
    }

    .slide {
      > div {
        padding: 24px;
        border-radius: 8px;
        background-color: #f4f7fb;
        height: 172px;
        overflow: hidden;
      }
    }
  }

  .carousel-wrapper.bottom {
    .slide {
      > div {
        //padding: 0;
        //border-radius: 8px;
        //background-color: #ffffff;
      }
    }
  }

  .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
  }

  .carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
  }

  .carousel .slide .legend {
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
  }

  .carousel .control-dots {
    position: absolute;
    z-index: 1;
    bottom: -140px;
    margin: 10px 0;
    text-align: center;
    width: 100%;
  }

  //@media (min-width: 960px) {
  //  .carousel .control-dots {
  //    bottom: 0;
  //  }
  //}

  .carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    width: 16px;
    height: 16px;
    background-color: #e9e9f0;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    margin: 0 10px;

    &.selected {
      background-color: #17406f;
    }
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
  }

  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff;
  }

  .carousel:hover .slide .legend {
    opacity: 1;
  }
}
