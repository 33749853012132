@import '~app/styles/themes/base/variables';

.alert-type {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  font-weight: 500;
  padding: 0 5px;
  margin-left: 9px;
  border-radius: 2px;
  height: 16px;
  font-size: $font-size-small;
  background: $gray-200;
  color: $gray-dark;
}
