.feedback-tooltip-content {
  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}
