@import "~app/styles/themes/base/variables";

.ddl-error {
  border: 1px solid $brand-danger;
}

.menu-item {
  display: flex;
  justify-content: space-between;
}
