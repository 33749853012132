@import '~app/styles/v2/variables.scss';

.tooltip {
  [class$='tooltip-inner'] {
    background-color: $navyblue-500 !important;
    border: 0 !important;
    color: $white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    box-shadow: none;
    border-radius: 6px !important;
    padding: 4px 8px;
    max-width: 250px;
    line-height: 1.5;
  }

  [class$='tooltip-arrow'] {
    border: none !important;

    &::before {
      border: none !important;
      width: 14px;
      height: 4px;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iNCIgdmlld0JveD0iMCAwIDE0IDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNCAwSDBIMC4wNzM0OTU4QzEuNzIzNzMgMCAzLjMwNjM4IDAuNzc1MTcyIDQuNDczMjcgMi4xNTQ5OUw1LjM1MDE2IDMuMTkxODhDNi4yNjEzOCA0LjI2OTM3IDcuNzM4NzYgNC4yNjkzNyA4LjY0OTk5IDMuMTkxODhMOS41MjY4NyAyLjE1NDk5QzEwLjY5MzggMC43NzUxNzMgMTIuMjc2NCAwIDEzLjkyNjYgMEgxNFoiIGZpbGw9IiMxRjJDOUMiLz4KPC9zdmc+Cg==');
    }
  }

  &[class$='top'] {
    [class$='tooltip-arrow'] {
      bottom: 0 !important;
      margin-left: 0 !important;

      &::before {
        margin-left: 0 !important;
        transform: translateX(-50%);
      }
    }
  }

  &[class$='bottom'] {
    [class$='tooltip-arrow'] {
      top: 0 !important;
      margin-left: 0 !important;

      &::before {
        margin-left: 0 !important;
        transform: rotate(180deg) translateX(50%);
      }
    }
  }

  &[class$='left'] {
    [class$='tooltip-arrow'] {
      margin-top: 0 !important;

      &::before {
        right: 0 !important;
        margin-top: 0 !important;
        transform: rotate(270deg) translateY(-50%);
      }
    }
  }

  &[class$='right'] {
    [class$='tooltip-arrow'] {
      margin-top: 0 !important;

      &::before {
        left: 0 !important;
        margin-top: 0 !important;
        transform: rotate(90deg) translateY(-50%);
      }
    }
  }

  &.small {
    max-width: 250px;
    font-weight: $font-weight-regular-400 !important;
  }

  &.info {
    &[class$='right'],
    &[class$='left'],
    &[class$='top'],
    &[class$='bottom'] {
      [class$='tooltip-arrow']::before {
        content: none;
      }
    }

    [class$='tooltip-inner'] {
      max-width: 355px;
      text-align: initial;
      font-weight: $font-weight-light-300 !important;
      line-height: 14px;
    }
  }

  &.large {
    [class$='tooltip-inner'] {
      max-width: 300px;
      text-align: initial;
      font-weight: $font-weight-regular-400 !important;
    }
  }

  &.navigation {
    &[class$='right'] {
      margin-left: 0;
      left: 58px;
    }

    &[class$='right'],
    &[class$='left'],
    &[class$='top'],
    &[class$='bottom'] {
      [class$='tooltip-arrow']::before {
        content: none;
      }
    }

    [class$='tooltip-inner'] {
      max-width: 250px;
      background-color: $white !important;
      border-radius: 18px !important;
      box-shadow: 0 4px 14px -1px rgba(0, 0, 0, 0.15);
      line-height: 36px;
      padding: 0 16px !important;
      color: $gray-500 !important;
      animation: navanimation ease-in-out 0.3s;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      text-align: left;
      font-weight: $font-weight-medium-500;
    }
  }

  &.full-alignment {
    [class$='tooltip-inner'] {
      text-align: left;
    }
  }
}

@keyframes navanimation {
  0% {
    opacity: 0;
    transform: translate(-10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
