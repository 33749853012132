@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.basic-values-container {
  display: flex;
  align-items: flex-end;
  margin: 70px 0 25px 0;

  .abs-wrapper {
    position: relative;
    width: 130px;

    .abs-input {
      width: 125px;
      margin-right: $btn-margin-right;
    }

    .abs-input-loading {
      margin-right: -20px;
    }

    label {
      margin: 3px 0 0 0;
      color: $gray-100;
    }
  }

  .percent-wrapper {
    position: relative;
    width: 130px;

    .percent-input {
      width: 125px;
      padding-right: 0 !important;
    }

    .loading-add-on {
      width: 20px;
      height: 45px;
      min-width: min-content;
      left: -6px;
    }

    span {
      max-width: min-content;
      align-items: center;
      position: absolute;
      top: 8px;
      right: 13px;
      font-size: 22px;
      font-weight: 300;
      color: $gray-400;
    }

    label {
      margin: 3px 0 0 0;
      color: $gray-100;
    }
  }

  .auto-chk-wrapper {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    .loader-wrapper {
      position: absolute;
      right: 145px;
      bottom: 7px;
    }

    p {
      margin-left: 7px;
      color: white;
    }
  }
}

.delta-duration-wrapper {
  margin-top: 11px;

  header {
    margin-bottom: 7px;
    color: #fff;
  }

  .delta-duration-checkbox {
    display: flex;

    p {
      line-height: 28px;
      font-size: 16px;
      color: $gray-100;
      margin: 0;
      padding: 0;
    }
  }

  &.disabled {
    .delta-duration-checkbox {
      opacity: 0.5;
    }
  }

  .values {
    > span {
      color: $gray-100;
      font-weight: 300;
    }

    strong {
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .slider-wrapper {
    position: relative;
    display: flex;
    margin: 15px 0 27px 0;

    :global {
      .rangeslider {
        width: 70%;
      }
    }

    .input-wrapper {
      position: absolute;
      right: 0;
      width: 27%;

      input {
        width: 100%;
        color: $gray-100;
        border: 1px solid $gray-500;
      }

      label {
        font-size: 16px;
        color: $gray-100;
        text-transform: capitalize;
        margin-top: 3px;
      }
    }
  }

  .msg-wrapper {
    margin: 12px auto 10px;
    border: 1px dashed #484848;
    padding: 10px 10px;
    width: 98%;
    display: flex;

    .info {
      width: 41px;
      font-size: 18px;
      color: white;
    }

    p {
      line-height: 24px;
      margin: 0;
      font-size: 16px;
      color: white;

      span {
        font-weight: bold;
      }
    }
  }
}
