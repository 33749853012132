@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';
@import '~alerts.console/components/alertsList/alertsListVariables';

$headerHeight: 65px;
$bottomGap: 15px;
$chartHeight: 250px;

.graphs {
  width: 100%;
}

.spinner {
  display: flex;
  align-items: center;
  height: 90vh;
  justify-content: center;
  width: 100%;
}

.header {
  height: 65px;
  padding: 10px 30px;
  width: 100%;
  background: #fff;

  .header-row {
    @include shell;

    font-weight: $font-weight-medium;
    font-size: $font-size-small;
    color: $gray-dark;
    width: 85%;

    :global {
      .metric-name {
        margin-left: 0;
      }
    }

    .row-left {
      @include shell;
    }
  }
}

.chart {
  > div {
    flex: 1 0 0;
  }

  position: relative;
  height: 250px;
  background-color: $white;
}
