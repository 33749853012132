@import '~app/styles/themes/base/variables';

.root {
  color: $black1;
  font-size: $font-size-base;
  border-bottom: 1px solid $gray-lighter;
  text-align: center;
  height: 30px;

  input[type='text'] {
    height: 30px !important;
    width: 17px;
    font-size: $font-size-base !important;
    padding: 0 !important;
    color: $black1 !important;
    border: none !important;
  }

  .am-pm-btn {
    transform: translateY(-2px) !important;
    padding: 0 5px !important;
    height: 20px !important;
    font-weight: 300 !important;
    &:hover {
      background-color: $gray-lightest;
    }
  }
}
