@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.matching-tooltip {
  width: 360px;
  text-align: left;
  margin: 5px;
}

.matching-gif {
  background-image: url('../images/matchingPropertiesTt.gif');
  width: 296px;
  height: 178px;
  margin: 0 auto;
}

.matching-text {
  margin-bottom: 10px;
}

.matching-props-container {
  display: flex;
}

.drop-icon-btn {
  width: 100%;
  left: 0 !important;
  top: 0 !important;
  font-size: 22px;
  font-weight: 300;
  color: $white1;
}

.matching-props-ddl-container {
  display: flex;
  flex-direction: column;

  .matching-props-ddl {
    margin: 5px 0 0 -8px;
  }
}

.single-metric-props {
  display: flex;
  color: $blue4;
  font-weight: bold;
  font-size: 12px;
  margin-top: 7px;
  border: 1px solid $blue4;
  border-radius: 5px;
  padding: 5px;
}

.desc-image {
  background-image: url('../images/matchingProps.png');
  width: 326px;
  height: 81px;
}

.desc-comment {
  font-size: 10px;
  color: $gray-lighter5;
  margin: 2px 0 0 19px;
}

.selected-props {
  display: flex;
  flex-wrap: wrap;
}

.selected-prop {
  display: flex;
  color: $blue4;
  border: 1px solid $blue4;
  margin: 2px;
  padding-left: 15px;
  flex-grow: 0;
  align-items: center;
  font-size: $font-size-large;
  height: 35px;
  border-radius: 3px;

  button,
  i {
    position: absolute;
    top: 5px;
    right: 1px;
    cursor: pointer;
    color: $blue6;
    font-size: 12px;
    overflow: initial !important;

    &:hover {
      color: $blue4;
    }
  }
}
