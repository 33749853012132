@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.left-container {
  flex-basis: 30%;
  max-width: 400px;
  min-width: 300px;

  > div {
    margin-bottom: 20px;
  }
}

.message-type-ddl {
  max-width: 250px;
}

.text-wrap {
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  display: block;
  color: $black1;
  margin-bottom: 20px;
}

.input-wrap {
  margin: 0;
}

.button-wrap {
  margin: 20px 0 0 96px;
}
