/* stylelint-disable */
@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';
@import '~alerts.console/components/alertsList/alertsListVariables';

$headerHeight: 65px;
$bottomGap: 15px;
$chartHeight: 250px;

.status {
  justify-content: center;
}

.chart {
  > div {
    flex: 1 0 0;
  }

  position: relative;
  height: 250px;
  background-color: $white;
  padding: 0 16px 0 8px;

  &.special-height {
    height: 238px;
  }
}

.title {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 12px;
  justify-content: space-between;
  flex-grow: 1;
  width: 357px;

  .text {
    display: flex;
    align-items: center;
  }

  .indicators {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;

    > div {
      margin: 0 0 0 5px;
    }
  }

  .feedback {
    margin-right: 12px;
    margin-left: auto;
  }
}

:global {
  .alert-date-wrapper {
    width: $date-width;
  }

  .alert-duration-wrapper {
    width: $duration-width;
  }
}

.score {
  width: $score-width;
}

.severity-wrapper {
  margin-right: 0;
  width: $severity-width;

  .text {
    text-transform: capitalize;
    margin-left: 7px;
  }

  .color-marker {
    margin-right: 5px !important;
  }
}

.actions-container {
  height: 47px !important;
  margin-right: 0 !important;
  position: absolute !important;
  z-index: 5 !important;
  background: rgb(230, 240, 255);
  right: 0 !important;
  display: flex !important;
  justify-content: flex-end;
  padding-left: 33px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 33px;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(233, 240, 255, 0) 0%, rgb(233, 240, 255) 50%);
  }

  .snooze {
    width: 24px;
    height: 24px;
  }

  .acknowledge {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }

  .more-actions {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 0 16px 0 8px;
  }
}

.alert-content {
  position: absolute;
  top: 46px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  .sep {
    position: relative;
    height: 1px;
    width: 98%;
    left: 1%;
    background: rgba(38, 113, 255, 0.05);
  }
}

.tile {
  height: $chartHeight + $headerHeight + $bottomGap;
  background: rgba(38, 113, 255, 0.05);

  &:last-child {
    height: $chartHeight + $headerHeight;
  }
}

.bottomGap {
  width: 100%;
  height: $bottomGap;
}

.header {
  height: 65px;
  padding: 10px 42px;
  width: 100%;
  background: #fff;

  &.extra-line {
    max-height: 83px;
    height: auto;
  }

  .header-row {
    @include shell;

    font-weight: $font-weight-medium;
    font-size: $font-size-small;
    color: $gray-dark;
    width: 100%;

    :global {
      .metric-name {
        margin-left: 0;
      }
    }

    .row-left {
      @include shell;

      margin-top: 2px;
    }
  }
}

.delta {
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin-right: 2px;

  &.lg {
    .delta-icon {
      font-size: 25px;
    }

    .delta-value {
      align-items: baseline;
      margin-left: 0;

      .percent {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }

  .delta-icon {
    display: flex;
    align-items: center;
    font-size: 17px;
    margin-left: -3px;
  }

  .delta-value {
    display: flex;
    margin-left: 2px;
    font-size: 12px;
    font-weight: 300;
    color: $gray-dark;

    .percent {
      margin-right: 5px;
    }
  }
}

.status-icon {
  width: 16px;
  height: 17px;
}

/* stylelint-enable */
