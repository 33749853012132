@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';
@import './alertsListVariables.scss';

.group-header {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  color: $gray-dark;
  padding-right: $right-space-from-table-container;

  &:nth-child(n + 3) {
    margin-top: 16px;
  }
}

.grouped-alerts-panel {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: 8px;
  //padding-right: $right-space-from-table-container;
}

:global {
  .add-remove-animate-row-enter {
    background-color: transparent;
    height: 0;
    overflow: hidden;

    > div {
      background-color: transparent;
    }
  }

  .add-remove-animate-row-enter.add-remove-animate-row-enter-active {
    height: 54px;
    transition: height 300ms ease-out;

    > div {
      background-color: #e0f3fd;
      transition: background-color 500ms ease-in;
    }
  }

  .add-remove-animate-row-exit {
    opacity: 1;
    height: 54px;
  }

  .add-remove-animate-row-exit.add-remove-animate-row-exit-active {
    height: 0;
    opacity: 0;
    transition: height 200ms 300ms ease-in, opacity 300ms ease-in;
  }
}

.grouped-alerts {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  border: 1px solid $gray-300;
  border-radius: 6px;

  &:hover {
    border-color: $blue-300;

    :global {
      .row-wrapper-global {
        background-color: rgba(233, 240, 255, 0.5);
      }

      .group-sep-global {
        border-color: $blue-300;
      }
    }
  }
}
