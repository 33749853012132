@import '~app/styles/v2/variables';

:global {
  .multi-button-time-scale {
    background-color: transparent;
    height: 0;
    padding: 0;

    button {
      flex-shrink: 0;
      width: auto;
      padding: 3px 4px;
      height: 20px;
      margin-right: 6px;
      font-size: $font-size-14;
      color: $gray-400;

      &:hover {
        background-color: #f4f7fb;
      }

      &.is-selected {
        background-color: rgba($blue-500, 0.1);
        color: $gray-500;
      }
    }

    &.menu-footer {
      height: auto;

      button {
        &:hover {
          background-color: rgba($blue-500, 0.1);
        }

        &.is-selected {
          color: $blue-500;
        }
      }
    }
  }
}
