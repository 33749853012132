@import "~app/styles/themes/base/variables";

@mixin andt-checkbox($text-color) {
  input[type=checkbox] {
    display: none;
  }

  /* to hide the checkbox itself */
  input[type=checkbox] + label:before {
    font-family: andt;
    display: inline-block;
    height: 30px;
  }

  label {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  input[type=checkbox] + label:before {
    content: "\5d";
    font-size: 16px;
    padding-top: 2px;
  }
  /* unchecked icon */

  input[type=checkbox] + label:before {
    letter-spacing: 10px;
    vertical-align: middle;
    color: $text-color;
  }
  /* space between checkbox and label */

  input[type=checkbox]:checked + label:before {
    content: "\38";
    font-size: 16px;
    padding-top: 2px;
  }
  /* checked icon */

  input[type=checkbox]:checked + label:before {
    letter-spacing: 10px;
    vertical-align: middle;
  }
  /* allow space for check mark */

  input[type=checkbox]:disabled + label {
    color: $gray-lighter6;
  }
  input[type=checkbox]:disabled + label:before {
    color: $gray-lighter6;
  }
  /* disabled state to checkbox */
}


.bc {
  .cb2 {
    @include andt-checkbox($black1);
  }
}
