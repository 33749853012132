/* stylelint-disable */
@import '~app/styles/v2/variables.scss';

.container {
  width: 352px;
  display: flex;
  flex-direction: column;

  header {
    font-size: $font-size-22;
    line-height: $font-size-22;
    font-weight: $font-weight-light-300;
    color: $gray-500;
    margin-bottom: 22px;
  }

  .alert-me-on {
    .flex-align {
      display: flex;
    }

    label {
      font-size: $font-size-16;
      line-height: $font-size-16;
      font-weight: $font-weight-medium-500;
      color: $gray-500;
      margin-bottom: 8px;
    }

    .selectors {
      display: flex;
      justify-content: space-between;

      .separator {
        display: flex;
        align-items: center;
        margin: 0 5px 0 5px;
        flex: 0.05 0 0;
      }

      & > div {
        width: 100%;
        flex: 1 0 0;
      }
    }
  }

  .alert-by-dimensions {
    margin: 11px 0 41px 0;
    display: flex;
    align-items: baseline;

    label {
      padding-right: 10px;
    }

    .optional-label {
      font-size: $font-size-12;
      line-height: $font-size-12;
      font-style: italic;
      color: $gray-400;
      padding-left: 6px;
    }
  }

  .actions-footer {
    display: flex;
    justify-content: space-between;

    .create-btn-wrapper {
      display: flex;
    }
  }

  .when-it-is {
    margin-bottom: 18px;
  }

  .duration {
    margin-bottom: 46px;
  }

  .recipients {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    width: 180px;

    & > .line {
      margin-bottom: 9px;
    }
  }

  .line {
    display: flex;
    align-items: baseline;

    span {
      margin-right: 8px;
    }

    .icon-clock,
    .icon-up-down,
    .icon-recipients {
      color: $gray-400;
      padding-right: 7px;
    }
  }

  .hidden {
    display: none;
  }

  .estimation {
    margin-bottom: 34px;
  }

  .required-label {
    color: $red-500;
    font-style: italic;
    font-size: $font-size-12;
    margin: 0 10px;
  }

  .disabled {
    opacity: 0.3;
  }

  .info {
    visibility: hidden;
  }

  .info-container:hover {
    .info {
      visibility: visible !important;
      margin-left: 8px;
    }
  }

  .min-duration {
    margin-right: 10px;
  }
}
