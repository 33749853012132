@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';

.group-title {
  color: $gray-500;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  margin: 20px 0;
}

.tab-container {
  display: flex;
  border-bottom: 1px solid $gray-200;
  margin-bottom: 20px;
}

.tab {
  color: $gray-500;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  margin-right: 16px;
  margin-top: 20px;
  cursor: pointer;

  &.selected {
    color: $blue-500;
    border-bottom: 2px solid $blue-500;
  }
}
