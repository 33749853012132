@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  flex-grow: 2 !important;

  .sections-container {
    margin-top: 20px;
    width: 100%;
    height: 220px;
    max-height: 220px;

    tr {
      width: 100%;
      display: flex;
      height: 100%;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;
    margin-right: 5px;
    flex: 1 0 0;
    margin-bottom: 30px;
  }

  .dm-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 10px;

    .title {
      font-weight: bold;
      min-height: min-content;
      font-size: $font-size-small;
      color: $black1;
    }

    .scroll {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: $font-size-small;
      font-weight: $font-weight-light;
      color: $black1;

      div {
        min-height: min-content;
      }
    }
  }
}
