@import '~app/styles/v2/variables';

:global {
  .domain-item-tt {
    [class$='tooltip-inner'] {
      background-color: white !important;
      border: 0 !important;
      color: $gray-500 !important;
      font-size: $font-size-14 !important;
      border-radius: 6px !important;
      padding: 0 !important;
      max-width: 250px;
      min-width: 200px;
      box-shadow: 0 3px 10px rgba(0, 0, 36, 0.25);
    }

    [class$='tooltip-arrow'],
    [class$='tooltip-arrow']::before {
      border: 0 !important;
    }
  }
}

.search-wrapper {
  input {
    border: solid 1px $gray-200;
    background-color: $white;
  }
}

.domain-carousel {
  min-height: 98px;

  :global {
    .carousel .control-dots {
      bottom: -18px;
      padding-left: 0;
      margin: 0;
    }

    .carousel .control-dots .dot {
      width: 6px;
      height: 6px;
      margin: 0 2px;
      background-color: $gray-350;

      &.selected {
        background-color: $blue-500;
      }
    }
  }
}
