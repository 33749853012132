@import '~app/styles/v2/variables.scss';

.container {
  text-align: center !important;
  width: 115px;
  height: 195px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img {
  width: 70px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.source-name {
  margin: 0 0 15px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: $gray-400;
}

.source-type {
  margin: 0;
}

.active {
  border: 1px solid #d8dcde;
  border-radius: 5px;
  background: #d8dcde;
}
