@import "~app/styles/themes/base/variables";

.root {
  padding: 20px 20px 20px 90px;
  background-color: white;
}

.v2-container {
  padding: 20px;
}

.table-btn-wrapper{
  //button {
  //  width: 60px;
  //  height: 60px;
  //}
}
.blue-bg{
  color: white;
  background-color: $blue;
}
.blue-light-bg{
  background-color: $blue3;
}
.dark-bg{
  color: white;
  background-color: black;
}
.style-guide-dd-icon{
  font-size: 20px;
  font-weight: bold;
}
.dropdown-wrapper > div{
  margin: 5px;
}
.close-buttons > div{
  margin: 5px;
  display: inline-block;
}

.size-78{
  width: 78px !important;
  height: 78px !important;
  border-radius: 50% !important;
}

.dropdown-width {
  max-width: 300px !important;
}

.icons-container {
  display: flex;
  flex-wrap: wrap;

  li {
    padding: 20px 40px 5px 5px;
    display: flex;
    align-items: center;

    input {
      width: 200px;
    }
    div {
      padding-right: 10px;
      font-size: 24px;
    }
  }
}

.margin-bottom {
  margin-bottom: 10px;
}

