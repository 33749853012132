@import '~app/styles/themes/base/variables';

$transition: all 0.3s linear;

.search-box {
  display: flex;
  border: 1px solid $gray-lighter4;
  border-radius: 3px;
  align-items: center;
  padding: 5px;
  transition: $transition;
  height: 35px;

  &.active {
    border-color: $blue;

    .icon-button {
      color: $blue;
    }
  }

  input {
    padding: 5px !important;
    flex: 1 0 0;
    color: $blue;
    border: 0;
    height: initial !important;
    border: initial !important;
    width: 120px;

    &.auto-stretch {
      transition: width 0.4s ease-in-out !important;

      &:focus {
        width: 320px;
      }
    }

    &::placeholder {
      color: $gray-lighter5 !important;
      font-weight: 300;
    }
  }

  button {
    max-width: 30px;
    max-height: 30px;
    padding: 0;

    .icon-button {
      font-size: 18px;
      margin-right: 0;
      margin-top: 5px;
      &.clear {
        font-size: 10px;
      }
    }
  }

  span {
    border-left: 0;
    background-color: transparent;
  }
}

.icon-button {
  color: $gray-lighter4;
  font-size: 18px;
  transition: $transition;
  margin-right: 5px;
}

.add-on {
  max-width: min-content;
  align-items: center;
  width: 30px;
  height: 30px;
}

// Blue Background
.search-box.blue-bg {
  border: 1px solid $blue1;
  &.active {
    border-color: $blue-light;

    .icon-button {
      color: $blue-light;
    }
  }

  input[type='text'] {
    color: $blue-light;
    font-weight: $font-weight-light;
    font-size: $font-size-base;
    &::placeholder {
      color: $blue-light !important;
    }
  }

  .icon-button {
    color: $blue1;
  }
}
