@import '~app/styles/v2/variables';

.root {
  position: relative;
}

.spinner {
  margin: 40vh 0 0 50%;
}

.map-data-is-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 456px;
  background-color: rgba($white, 0.25);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
