@import '~app/styles/v2/variables.scss';

.root {
  width: 100%;
  height: 50px;
  background-color: rgba($blue-500, 0.1);
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 12px;
  border-bottom: 1px solid rgba($blue-500, 0.5);

  i {
    padding-right: 5px;
    font-size: 14px;
    color: rgba($gray-500, 0.7);
  }

  input,
  input::placeholder {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: $font-weight-medium-500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba($gray-500, 0.7);
    border: none;
    background-color: transparent;
    width: 100%;
  }
}
