@import "~app/styles/themes/base/variables";
@import "~app/styles/themes/base/mixins";

.root {
  max-width: 500px;
}


.title {
  font-weight:bold;
  min-height: min-content;
  font-size: $font-size-small;
  color: $black1;
  margin: 10px 0;
}

.tabular-data {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.file-name {

}

.error {
  color: $brand-danger;
}

.row-error-num {
  margin-right: 20px;
}
