@import './../../../app/styles/themes/base/variables';

$segment-width: 30px;

.stream-progress {
  margin: 12px 50px 0 0;
  width: ($segment-width * 5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .progress-title {
    font-size: 9px;
  }

  .progress-bar {
    display: flex;
    align-items: center;
    height: 13px;
    width: ($segment-width * 5);
    padding: 1px 0;
    border-radius: 3px;
  }

  .segment {
    height: 100%;
    width: $segment-width;
    border-radius: 1px;
    margin: 1px;

    &.segment-full {
      background-color: $green-dark;
    }

    &.segment-empty {
      background-color: $gray-lighter;
    }
  }
}
