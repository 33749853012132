@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.title {
  font-weight: bold;
  min-height: min-content;
  font-size: $font-size-small;
  color: $black1;
}

.dm-container-title {
  font-size: $font-size-small;
  color: $blue;
  font-weight: $font-weight-medium;
  margin-top: 32px;
}

.dm-container {
  display: flex;
  flex: 1 0 0;
  margin-top: 10px;
  min-height: 130px;
}

.dm-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
}

.scroll {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  color: $black1;

  div {
    min-height: min-content;
  }
}

.errors-container {
  margin-bottom: 20px;
  margin-top: -20px;
}

.error {
  color: $brand-danger;
}
