@import '~app/styles/v2/variables';

.is-isnot-option {
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba($blue-500, 0.1);
  }

  &.selected {
    color: $blue-500;
  }

  .checkbox {
  }

  .symbol {
    color: $gray-400;
    background-color: $gray-200;
    border-radius: 3px;
    width: 27px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    height: 20px;
    align-items: center;
    font-weight: $font-weight-medium-500;
  }

  .label {
    width: 100px;
  }
}
