/* stylelint-disable */
@import '~app/styles/v2/variables';

$rootWidth: 100px;

:global {
  .feedback-popover {
    min-width: 243px !important;
  }
}

.clear-root {
  margin-left: auto;
}

.root {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-left: auto;
  right: 15px;
  top: 14px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;

  .feedback-wrapper {
    display: flex;
    flex-direction: row;

    .feedback-number-wrapper {
      width: 13px;
      display: flex;
      justify-content: center;

      &.clickable:hover .feedback-number {
        color: $blue-500;
      }

      .feedback-number {
        color: $gray-500;

        &.active {
          color: $blue-500;
        }
      }
    }

    .feedback-ddl {
      &.hover {
        .feedback-btn {
          color: $gray-400;

          &:hover {
            color: $blue-500;
          }
        }
      }
    }

    .feedback-btn {
      color: $gray-300;

      &.active,
      &:hover {
        color: $blue-500;
      }
    }

    &.good-catch {
      .feedback-ddl {
        margin-left: 2px;
        margin-right: 11px;
      }

      &.alone {
        .feedback-ddl {
          margin-right: 3px;
        }
      }
    }

    &.not-interesting {
      .feedback-ddl {
        margin-right: 2px;
      }

      &.alone {
        flex-direction: row-reverse;

        .feedback-ddl {
          margin-left: 3px;
          margin-right: 3px;
        }
      }

      .feedback-number-wrapper {
        &.left {
          display: none;
        }
      }
    }
  }
}

.root.investigation {
  .feedback-number-wrapper {
    align-items: center;

    .feedback-number {
      color: $gray-400;
    }
  }

  .not-interesting {
    .feedback-number-wrapper {
      &.left {
        display: flex;
      }
    }

    .feedback-number-wrapper {
      &.right {
        display: none;
      }
    }
  }

  .feedback-btn {
    font-size: 24px;
  }
}

.content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    border-radius: 4px;
    background-color: $blue-100;
    font-size: 14px;
    font-weight: 500;
    color: $blue-500;
  }

  .wrapper {
    margin-top: 9px;

    p {
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 4px;

      span {
        margin-left: 8px;
      }
    }
  }

  textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
    min-height: 78px;
    border-radius: 4px;
    margin-top: 4px;
    padding: 8px;
    border: 2px solid $gray-300;

    &::placeholder {
      opacity: 0.7;
    }
  }

  .submit-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
/* stylelint-enable */
