$gap-between-cols: 12px;
$date-width: 97px;
$duration-width: 60px;
$score-width: 45px;
$severity-width: 75px;

$status-width: 41px;
$right-space-from-table-container: 0;
$table-titles-padding: 0;

$right-space: 130px;
