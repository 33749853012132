@import "~app/styles/themes/base/variables";

.panel {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 3px;
}

.scroller {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 320px;

  &::-webkit-scrollbar {
    max-height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue3;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: $blue4;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $blue4;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $blue-dark;
  }
}

