@import '~app/styles/v2/variables.scss';

:global {
  .new-user-modal {
    .modal-content {
      border-radius: 8px !important;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.container {
  margin-top: -7px;
  min-height: 480px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  position: relative;
}

.center {
  justify-content: center;
}

.header {
  font-family: Roboto, sans-serif;
  font-size: $font-size-22;
  font-weight: $font-weight-light-300;
  color: $gray-500;
  line-height: 25px;
  flex-shrink: 0;
}

.label {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
  line-height: 14px;
  height: 14px;
  margin-bottom: 8px;

  &.error {
    font-size: $font-size-14;
    color: $red-500;
    margin-bottom: 0;
    line-height: normal;
    height: 19px;
  }

  .sub {
    font-size: $font-size-14;
    color: $gray-300;
    margin-left: 9px;
  }
}

.row {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.message-input {
  border-radius: 6px;
  background-color: $gray-200;
  color: $gray-500;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  border: none;
  padding: 8px;
  resize: none;
  height: 80px;

  &:placeholder-shown {
    padding-left: 12px;
  }

  &::placeholder {
    color: rgba($gray-500, 0.7);
    font-weight: $font-weight-regular-400;
    font-size: $font-size-16;
    text-align: left;
    vertical-align: middle;
  }
}

.roles-group-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .groups {
    width: 196px;
  }

  .roles {
    width: 140px;

    .info-wrapper {
      font-size: 14px;
      margin-left: 5px;
      position: relative;
      top: 1px;
      display: none;
    }

    .info {
      color: $gray-400;

      &:hover {
        color: $gray-500;
      }
    }

    &:hover {
      .info-wrapper {
        display: inline;
      }
    }
  }
}

.default-group-row {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;

  .label {
    margin-bottom: 0;
    margin-right: 12px;
  }
}

.footer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
}

.failures {
  font-weight: $font-weight-bold-700;
}
