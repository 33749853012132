@import '~app/styles/v2/variables';

.container {
  border-bottom: 1px solid $gray-300;
  padding-bottom: 8px;
  margin-bottom: 6px;
}

.main-title {
  font-weight: $font-weight-light-300;
  font-size: $font-size-22;
  color: $gray-500;
  flex-shrink: 0;
}

.top-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.chart-render-button {
  margin-right: 12px;
}

.buttons-container {
  display: flex;
}

.subtitle {
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  color: $gray-400;
  margin-bottom: 6px;
}
