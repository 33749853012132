@import '~app/styles/themes/base/variables';

.root {
  width: 300px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-self: center;
}

.text {
  color: $gray-lighter;
  font-weight: $font-weight-light;
  font-size: 40px;
  margin-bottom: 7px;
  flex-shrink: 0;
  text-align: center;
}

.title {
  font-family: Roboto, sans-serif;
  font-size: $font-size-h4;
  font-weight: $font-weight-medium;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $indigo_500;
  margin-top: 10px;
}

.noSourceText {
  color: $gray_400;
  font-family: Roboto, sans-serif;
  font-size: $font-size-large;
  font-weight: $font-weight-regular;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 20px;
}

.not-found-streams-image2 {
  background-image: url('/bc/images/ftue-empty-streams-2.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 205px;
  height: 122px;
  flex-shrink: 0;
  align-self: center;
  margin: 0 auto 10px auto;
}

.not-found-streams-image {
  background-image: url('/bc/images/ftue-not-found-stream.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 139px;
  height: 110px;
  flex-shrink: 0;
  align-self: center;
  margin: 27px auto 15px auto;
}
