@import '~app/styles/themes/base/variables';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 150px;
  margin-right: 90px;
}

.top {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  color: $black1;

  .header {
    color: #27363e;

    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
    }
    h2 {
      font-size: 30px;
      font-weight: normal;
      margin-top: 5px;
    }
  }
}

.cubes {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}

.user-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 14px;

  .add-cube-holder {
    .add-cube {
      font-size: 22px;
      font-weight: 400;
      color: #0982b6;
      cursor: pointer;

      i {
        font-size: 18px;
        margin-left: 16px;
        position: relative;
        top: 2px;
      }
    }
  }
}
