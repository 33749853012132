@import '~app/styles/v2/variables';

.more-action-wrapper {
  position: absolute;
  top: -4px;
  right: -4px;
}

.button-like-dropdown {
  border-radius: 6px;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  height: 30px;
  width: 32px;
  padding: 0 8px;
  position: relative;
  cursor: pointer;

  &::before {
    position: relative;
    top: 7px;
    width: 16px;
    height: 16px;
    font-size: $font-size-16;
  }

  &:hover {
    color: $blue-500;
  }
}
