@import '~app/styles/v2/variables';

.centered-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 220px;
  padding-right: 40px;
}

.container {
  overflow-x: auto;
  height: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.table-container {
  margin-top: -14px;
  margin-bottom: 18px;
}

.table-container::after {
  border-bottom: 1px solid $gray_200;
  display: block;
  position: relative;
  content: '';
  margin-right: 10px;
  height: 0;
}

.no-stream {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  border-top: 1px solid $gray_200;
  margin-right: 10px;
  padding-top: 12px;
  padding-left: 10px;
  height: 49px;
}

.no-data-container {
  padding-top: 182px;

  .icon {
    text-align: center;
  }

  .title {
    font-family: Roboto, sans-serif;
    font-size: $font-size-18;
    font-weight: $font-weight-medium-500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $indigo_500;
    margin-top: 18px;
  }

  .text {
    color: $gray-400;
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    line-height: $font-size-16;
    font-weight: $font-weight-regular-400;
    text-align: center;
    margin-top: 5px;
  }

  .button {
    text-align: center;
    margin-top: 37px;
  }
}
