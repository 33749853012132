@import '~app/styles/v2/variables';

.container {
  display: flex;
  flex-direction: column;

  .see-all {
    display: none;
    background-color: transparent;
    border: 0;
    color: $blue-500;
    height: 15px;
    font-weight: $font-weight-medium-500;
    font-size: $font-size-14;
    white-space: nowrap;
    position: relative;
    top: -2px;
  }

  .title {
    font-size: $font-size-12;
    color: $gray-400;
    font-weight: $font-weight-regular-400;
    text-transform: uppercase;
    margin-bottom: 9px;
    display: flex;
    white-space: nowrap;

    &.all-list {
      font-size: $font-size-20;
      color: $gray-600;
      margin-bottom: 26px;
    }
  }

  &:hover {
    .see-all {
      display: inline;
    }
  }
}

.tags {
  height: 191px;
  overflow: hidden;

  &.all-list {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    font-weight: $font-weight-medium-500;
  }
}

.row {
  .tags {
    height: auto;
    display: flex;
  }

  .title {
    margin-bottom: 0;
  }
}
