@import '~app/styles/themes/base/variables';

.root {
  width: 100%;

  div,
  .search-input {
    width: 100%;
  }

  .error {
    border-color: $red-500 !important;
  }

  .err_msg {
    color: $brand-danger;
    padding-top: 5px;
  }
}
