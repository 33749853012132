/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 135px;

.header {
  @include widget-title;
}

.body {
  @include body;

  height: $widgetHeight;

  &.gray {
    @include gray;
  }

  &.white {
    @include white;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }
}

.main-container {
  display: flex;
  justify-content: center;

  .chart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;

    .text {
      font-size: 14px;
      font-weight: 500;
      word-break: break-word;
      font-style: normal;
      line-height: normal;
      color: $gray-400;
    }
  }
}
/* stylelint-enable */
