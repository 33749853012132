@import '~app/styles/v2/variables';

:global {
  .andt-user-events-icon {
    color: $gray-500;
    cursor: pointer;
  }

  .andt-user-events-icon:hover {
    color: $blue-500;
  }

  .andt-user-events-icon.selected {
    color: $blue-500;
  }

  .andt-user-events-icon-mask {
    background-color: white;
    display: block;
    width: 5px;
    height: 7px;
    position: absolute;
    top: 3px;
    left: 5px;
    pointer-events: none;
  }

  .anch-chart-events-tooltip {
    display: block;
    min-width: 10px;
    min-height: 10px;
    position: fixed;
    z-index: 1500;
    top: -9999px;
    left: -9999px;
  }
}

// events tooltip
.events-name {
  color: $gray-500;
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  line-height: $font-size-16;
  font-weight: $font-weight-medium-500;
}

.events-date {
  color: $gray-400;
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  line-height: $font-size-16;
  font-weight: $font-weight-regular-400;
  margin-top: $spacing;
}

// events modal
.modal-name {
  font-size: $font-size-24;
  text-align: center;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
}

.search-bar {
  display: flex;
}

.empty-list {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 65px;

  .empty-text {
    color: $indigo-500;
    font-family: Roboto, sans-serif;
    font-weight: $font-weight-medium-500;
    font-size: $font-size-18;
    margin-top: 16px;
    width: 100%;
    text-align: center;
    padding-right: 6px;
  }
}
