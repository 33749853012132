@import '~app/styles/v2/variables';

.modal-content {
  button {
    float: right;
    margin-bottom: 24px;
  }
}

.footer-button-like {
  display: flex;
  align-items: center;
  padding: 2px 20px;
  height: 35px;

  span {
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
  }

  i {
    padding-right: 8px;
    position: relative;
    font-size: $font-size-16;
  }
}

.button-wrapper {
  button {
    font-size: $font-size-16;
  }

  :global {
    .icn-action16-pause {
      top: 0;
    }
  }
}

.delete-body {
  font-size: $font-size-14;
  color: $gray-500;
  font-weight: $font-weight-regular-400;
  margin-bottom: 30px;
}

.primary-group-modal {
  width: 310px;
}
