@import '~app/styles/v2/variables';

.issues-type-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 24px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 4px;

  .issues-ddl-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .issues-label {
    margin-right: 8px;
    margin-left: 8px;
    font-family: Roboto, sans-serif;
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
    line-height: 0.88;
    color: $gray-400;
    flex-shrink: 0;
  }

  .date-range-wrapper {
    max-width: 140px;
  }
}

.chart-wrapper {
  :global {
    .highcharts-loading-inner {
      top: calc(50% - 24px) !important;
      left: calc(50% - 16px) !important;
    }
  }
}
