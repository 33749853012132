/* stylelint-disable no-descending-specificity */
@import '~app/styles/v2/variables';

// same like PageLayout.scss
$menu-width: 60px;
$margin: 90px;
$margin-small: 20px;

/* -- Widths -- */
$max-width: 2560px;
$width-step-1: 1680px;
$min-width: 1200px;

.copyBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px;
  cursor: pointer;

  &.inline {
    position: relative;
    top: 0;
    right: 0;
  }
}

.tokenCopyBtn {
  opacity: 0;
}

.tokenWrapper {
  &:hover {
    .tokenCopyBtn {
      opacity: 1;
    }
  }
}

:global(.copy-container-wrapper.inline .copy-container) {
  right: -10px;
}

:global(.copy-container-wrapper.inline),
:global(.copy-container-wrapper.inline .copy-container) {
  background-color: transparent !important;
}

.contentBottomBar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
  max-width: $width-step-1;
  margin-left: $margin;
  margin-right: $margin;
}

@media screen and (max-width: $min-width + $margin) {
  .contentBottomBar {
    margin-left: $margin-small;
    margin-right: 0;
    padding-right: $spacing-6;
  }
}

:global {
  .bottomBar {
    &-appear {
      height: 0;
    }

    &-appear-active {
      height: 70px;
      transition: height 300ms;
    }

    &-exit {
      height: 70px;
    }

    &-exit-active {
      height: 0;
      transition: height 300ms;
    }

    &-enter-done {
      height: 70px;
    }
  }
}
