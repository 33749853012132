@import '~app/styles/v2/variables';

.render-string {
  box-sizing: border-box;
  padding-bottom: 1px;

  input[type='text'].value-input {
    height: 18px;
    width: 61px;
    border-radius: 4px;
    background-color: #656f79;
    color: white;
    font-size: $font-size-14;
    box-sizing: border-box;
    border: 0;
    padding: 5px;
    font-style: normal;
    font-family: 'Roboto', 'Helvetica', 'Lucida Grande', sans-serif !important;

    &::placeholder,
    &:placeholder-shown {
      color: white;
      font-style: normal !important;
      font-size: $font-size-14;
      font-family: 'Roboto', 'Helvetica', 'Lucida Grande', sans-serif !important;
    }

    &:focus {
      background-color: white;
      border: 2px solid $azure-500;
      color: $gray-500;
    }
  }
}
