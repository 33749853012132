@import '~app/styles/v2/variables.scss';

.header {
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  line-height: 18px;
  margin-top: 12px;
  margin-bottom: 3px;
}

.user-assets {
  display: flex;
  flex-direction: column;

  .bold {
    font-weight: $font-weight-bold-700;
  }

  .table {
    display: flex;
    flex-direction: row;
  }

  .col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .is-loading {
    height: 17px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 9px;
    margin-right: 20px;
    background-image:
      linear-gradient(
        100deg,
        rgb(233, 233, 240) 3%,
        rgb(212, 224, 242) 61%,
        rgb(233, 233, 240) 94%,
        rgb(233, 233, 240) 94%
      );
  }
}
