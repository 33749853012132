@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.input-container {
  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
}

.abs-input {
  width: 110px;
  max-width: 110px;
  margin-right: $btn-margin-right;
}

.abs-input-loading {
  margin-right: -20px;
}

.percent-container {
  display: flex;
  margin-right: -15px;
}

.percent-input {
  width: 140px;
  max-width: 140px;
  padding-right: 0 !important;
}

.add-on {
  max-width: min-content;
  align-items: center;
  position: relative;
  top: 1px;
  left: -35px;
  font-size: 22px;
  font-weight: 300;
  color: $white;
}

.loading-add-on {
  width: 20px;
  height: 45px;
  min-width: min-content;
  left: -6px;
}

.auto-chk {
  align-self: flex-end;
  margin-top: 5px;
}
