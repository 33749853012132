.container {
  width: 250px;

  &.loading {
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  h3 {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    margin: 37px 0 0 0;
  }
}
