@import '~app/styles/v2/variables';

.number {
  width: 23px;
  height: 23px;
  background-color: $indigo-500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 14px;
  color: $white;
  font-weight: bold;
  margin-right: 8px;
}

.label {
  font-size: 22px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: normal;
  color: $gray-500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin: 3px 8px 0 8px;
  font-size: 16px;
}

.icon-small {
  margin: 3px 8px 0 0;
  font-size: 14px;
}

.box {
  border-radius: 10px;
  background-color: $gray-100;
  border: 2px solid $gray-100;
  padding: 10px 14px;
  max-height: 50px;

  &.opened {
    max-height: none;
    background-color: transparent;
    border: 2px solid $gray-300;
  }
}

.box-header {
  display: flex;
  justify-content: space-between;
}

.small-label {
  font-size: 16px;
  font-weight: 500;
  color: $gray-500;
}

.add-button {
  margin-top: -5px;
  margin-right: -16px;
}
