@import "~app/styles/themes/base/variables";

.groups {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 2.5px;

  &::-webkit-scrollbar {
    max-width: 5px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $blue-light2;
  }
}
