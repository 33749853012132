/* stylelint-disable */
@import '~app/styles/v2/variables';

.disabled {
  opacity: 0.25;
}

.main-container {
  :global {
    .condition-title {
      left: 30px !important;
      top: 10px !important;
      font-weight: 500 !important;
    }
  }
}

.values-wrapper {
  p {
    font-weight: 300;
    font-size: 16px;
    margin: 0 6px 0 0;
    color: white;
    line-height: 33px;
  }

  > div {
    display: flex;
    margin-top: 0;
    align-items: baseline;
    justify-content: flex-end;

    :global {
      .select-menu {
        flex-shrink: 0;
        margin-right: 7px;
      }
    }

    p {
      flex-shrink: 0;
      margin: 0 6px 0 0;
    }

    .input-wrapper {
      margin-left: 3px;

      input {
        width: 175px;
        height: 32px;
        color: white;

        &:disabled {
          border-color: $gray-500;
        }
      }

      .no-value-wrapper {
        display: flex;
        border: solid 1px #484848;
        background-color: rgba(235, 111, 7, 0.2);
        width: 85px;
        height: 25px;
        justify-content: center;
        align-items: center;

        i {
          color: $orange-500;
        }
      }
    }
  }
}

.automatic-calc-wrapper {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  align-items: center;

  .loader-wrapper {
    position: absolute;
    right: 145px;
    bottom: 14px;
  }

  p {
    margin-left: 7px;
    color: white;
  }
}

.msg-wrapper {
  margin-top: 10px;
  border: 1px dashed #484848;
  padding: 10px 10px;

  h3,
  p {
    line-height: 24px;
    margin: 0;
    font-size: 16px;
    color: white;
  }

  a {
    font-weight: bold;
  }

  h3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
    font-weight: 400;
  }

  p {
    font-weight: 300;
  }

  .info {
    color: white;
  }

  .warning {
    color: $orange-500;
  }

  .close {
    cursor: pointer;
    color: $gray-400;
  }
}
/* stylelint-enable */
