@import "~app/styles/themes/base/variables";

.root {
  .title {
    font-weight: bold;
    font-size: $font-size-small;
    color: $black1;
    padding-right: 5px;
  }

  .data {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    color: $black1;
  }
}

.root.disabled {
  opacity: 0.5;
}
