/* stylelint-disable */
@import '~app/styles/v2/variables';

@mixin widget-title {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
  color: $gray-400;
}

@mixin body {
  width: 100%;
  min-height: 100px;
  padding: 8px 12px;
  margin-bottom: 20px;
  border-radius: 10px;
}

@mixin gray {
  background-color: $gray-100;
  border: 2px solid $gray-100;
}

@mixin white {
  background-color: #fff;
  border: 2px solid $gray-200;
}

@mixin loader {
  background-image: url('../images/widget_loader_cssanimation.svg');
  background-size: cover;
  background-position: top left, 0 0;
  background-repeat: no-repeat, repeat;
  width: 100%;
}

.header {
  @include widget-title;
}

.body {
  @include body;

  &.gray {
    @include gray;
  }
}

.title-category {
  display: flex;

  h1 {
    margin: -6px 0 16px 0;
    font-size: 26px;
    font-weight: 400;
    color: $gray-500;
  }

  h2 {
    margin: 0 0 8px 0;
    font-size: 26px;
    font-weight: 400;
    color: $black-500;
  }

  i {
    position: relative;
    top: -6px;
    left: -5px;
    font-size: 24px;
    color: $gray-500;
  }
}
/* stylelint-enable */
