@import "~app/styles/themes/base/variables";

.root {
  width: 100%;
}

.no-items {
  margin: 50px auto;
}

.no-items {
  color: $gray-lighter6;
  .main {
    font-size: $font-size-h1;
    font-weight: $font-weight-light;
    text-align: center;
  }

  .sub {
    font-size: $font-size-h2;
    font-weight: $font-weight-light;
    text-align: center;
  }

  .no-data-pos {
    position: static;
    margin: 0 auto;
  }
}
