@import '~app/styles/v2/variables';

.modal-footer {
  display: flex;
  justify-content: space-around;
}

:global {
  .first-time-modal-data-manager {
    width: 378px !important;
    height: 195px !important;
    margin-top: 250px !important;

    .modal-title {
      padding: 24px 4px 9px 24px;
      font-size: $font-size-16 !important;
      line-height: $font-size-16;
      font-weight: $font-weight-medium-500;
    }

    .modal-footer {
      text-align: center !important;
      background: #fff !important;
      border-top: none !important;
    }

    .modal-body {
      align-items: flex-start !important;
      padding: 4px 24px 24px 24px !important;

      p {
        font-size: 14px;
      }
    }

    .ftue-img-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 8px;

      .ftue-img {
        background-image: url('../images/popup-alerts.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 480px;
        height: 208px;
      }
    }
  }
}

.page-content-wrapper {
  display: flex;
  flex: 1 0 0;
}

.filters-wrapper {
  flex-shrink: 0;
  width: 0;
  margin-right: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  overflow-y: auto;

  &.open {
    width: 285px;
    margin-right: 20px;
  }
}

.table-wrapper {
  flex-grow: 1;
  display: flex;
  overflow: hidden;

  /* min-width: 1200px; */
}

.spinner {
  margin: 400px 0 0 50%;
}

.page-container {
  flex: 1 1 0;
  overflow: hidden;
  position: relative;
}

.page-footer-container {
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.party-icon {
  background: url('../images/party-icon.png') no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 3px;
  left: 4px;
}
