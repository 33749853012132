@import '~app/styles/v2/variables';

.container {
  width: 185px;
  height: 35px;
  border-radius: 6px;
  border: solid 2px $blue-300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
}

.filled-bar {
  position: absolute;
  background-color: $blue-300;
  left: 0;
  top: 0;
  height: 100%;
}

.label {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f51b6;
  z-index: 1;
}

.toggle-wrapper {
  margin-top: 3px;
  z-index: 1;
}

.spinner-wrapper {
  position: absolute;
  right: 37px;
  z-index: 1;
}
