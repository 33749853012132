@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  flex-grow: 2 !important;

  &.vertical {
    padding-bottom: 0;
    margin-bottom: 50px;
    overflow-y: auto;
  }
}

.sections-container {
  width: 100%;

  tr {
    width: 100%;
    display: flex;
    height: 100%;
  }
}

.disabled {
  opacity: 0.5;
}

.section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  margin-right: 5px;
  flex: 1 0 0;
  margin-bottom: 20px;

  &.date {
    //flex-basis: 20%;
    //min-width: 100px;
    //flex-grow: 0;
  }
}

.vertical-section {
  margin-bottom: 15px;
}

.title {
  font-weight: bold;
  min-height: min-content;
  font-size: $font-size-small;
  color: $black1;
}

.list-item {
  padding-right: 5px;
}

.errors-container {
  margin-bottom: 20px;
  margin-top: -20px;
  border: 1px solid $brand-danger;
  padding: 5px;
  border-radius: 5px;
}

.error {
  color: $brand-danger;
}

.json-viewer {
  width: 100%;
  margin-right: 20px;
}
