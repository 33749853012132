@import "~app/styles/themes/base/variables";

.root {
  display: flex;
  flex-direction:column;
  flex: 1 0 0;
}

.dm-container {
  display: flex;
  flex: 1 0 0;
  align-content: space-between;
}

.dm-panel {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
}

.dm-copyright {
  position: absolute;
  bottom: 10px;
  right: 10px;
  line-height: 1;
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  color: #FFF;

  &:hover, &:focus {
    color: #FFF;
  }
}
