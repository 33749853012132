@import '~app/styles/themes/base/variables';

.root {
  :global {
    .public_fixedDataTable_main {
      font-size: 12px;
      border: 1px solid $gray-lighter;
      border-radius: 3px;
    }
    .fixedDataTableLayout_main {
      margin-left: 0 !important;
    }
    .public_fixedDataTable_header {
      .fixedDataTableCellLayout_main {
        background-color: $white;
        font-weight: 500;
        background-image: none;
      }
      .fixedDataTableCellGroupLayout_cellGroupWrapper {
        background-color: $white;
      }
    }

    .public_fixedDataTableCell_cellContent {
      top: 0;
      padding: 0 10px;
      height: 50px;
      display: flex;
      align-items: center;
    }

    .fixedDataTableCellLayout_main {
      border-left: 1px solid $blue-light;
    }

    //scrollbars
    .public_Scrollbar_main,
    .public_Scrollbar_main.public_Scrollbar_mainActive {
      border-left: none;
    }

    .ScrollbarLayout_mainHorizontal {
      border-left: none;
      height: 15px !important;
      .ScrollbarLayout_faceHorizontal {
        left: -2px;
      }
      .ScrollbarLayout_faceHorizontal:after {
        background-color: $blue-light;
        bottom: 4px;
        top: 7px;
      }
    }

    .public_Scrollbar_mainActive,
    .ScrollbarLayout_mainHorizontal:hover {
      .ScrollbarLayout_faceHorizontal:after {
        background-color: $blue-light;
        bottom: 4px;
        top: 7px;
      }
    }

    .ScrollbarLayout_mainVertical {
      .ScrollbarLayout_faceVertical:after {
        background-color: $blue-light;
        right: 0px;
        left: 11px;
      }
    }

    .ScrollbarLayout_mainVertical:hover {
      .ScrollbarLayout_faceVertical:after {
        background-color: $blue-light;
        right: 0px;
        left: 11px;
      }
    }
  }
}
