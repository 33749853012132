@import '~app/styles/v2/variables.scss';

.container {
  overflow: hidden;
}

.pane {
  transition: all 0.2s ease-in-out;
  position: relative;
  display: flex;

  &.first {
    left: 0;
  }

  &.second {
    left: -100%;
  }
}

.list-header {
  display: flex;
  font-size: $font-size-14;
  align-items: center;
  justify-content: center;
  background-color: rgba($blue-500, 0.1);
  color: $gray-500;
  height: 50px;
  border-bottom: 1px solid rgba($blue-500, 0.5);
  position: relative;
  padding: 0;
  margin: 0;

  span {
    margin-right: 5px;
  }
}

.first-tab {
  overflow: hidden;

  .list-header {
    justify-content: initial;
  }
}

.second-tab {
  overflow: hidden;
}

.with-chevron {
  cursor: pointer;
  color: $blue-500;
  font-size: $font-size-16;

  &::before {
    font-family: 'andt';
    content: '\E93F';
    color: $blue-500;
    position: absolute;
    left: 13px;
    top: 19px;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.empty-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;

  span {
    color: $gray-400;
    font-weight: $font-weight-regular-400;
    margin-top: 30px;
  }
}
