@import '~app/styles/v2/variables';

.container {
  height: 40px;
  margin: 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;

  &:hover {
    background-color: rgba($blue-500, 0.1);
  }

  .label {
    color: $gray-500;
  }

  .ico {
    margin-right: 10px;
  }
}
