@import '~app/styles/v2/variables.scss';

.root {
  display: flex;
}

.color-item {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-left: 8px;
  line-height: 1;
  color: $white;
  cursor: pointer;

  i {
    margin-top: 1px;
    margin-left: 1px;
  }
}

/* -- Color Themes -- */
.gray {
  background-color: $gray-400;
}

.rose {
  background-color: $rose-500;
}

.mint {
  background-color: $mint-500;
}

.yellow {
  background-color: $yellow-500;
}

.teal {
  background-color: $teal-500;
}

.indigo {
  background-color: $indigo-400;
}

.lilac {
  background-color: $lilach-500;
}

.tomato {
  background-color: $tomato-500;
}

.eucaliptus {
  background-color: $eucaliptus-500;
}
