@import '~app/styles/themes/base/variables';

.root {
  display: flex;
  flex: 1 0 0;
  position: fixed;
  z-index: 2; //hide the footer wizard steps
  left: 60px;
  right: 0;
  bottom: 0;
  background-color: rgba(35, 35, 35, 0.2);
}

.editor {
  visibility: hidden;
  position: absolute;
  height: calc(100% - 50px);
  left: 0;
  right: 0;
  bottom: 0;
  max-height: calc(100% - 50px);
  background-color: $blue;
  transform: translateY(100%);
}

.btnClose {
  position: absolute !important;
  right: 10px;
  top: 10px;
  font-size: 24px !important;

  i {
    position: absolute;
    top: 3px;
    right: 8px;
  }
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  99% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

.animate {
  &.root {
    top: 0;
  }

  .editor {
    visibility: visible;
    -webkit-animation: slide-in 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: slide-in 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
}
