@import '~app/styles/v2/variables';

.pop-over-container {
  cursor: pointer;
}

.footer-component {
  position: relative;
}

.scroll-container {
  overflow-y: auto;
  max-height: 500px;
  padding: 4px 0;

  &.flexible {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 8px;
  }
}

.is-loading {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
