@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

.header {
  margin: 40px 0;
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
