.container {
  margin-bottom: 35px;
}

.section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;

  .title {
    font-size: 23px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
}

.centered-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
