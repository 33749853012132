@import '~app/styles/v2/variables.scss';

.container {
  width: 100%;

  .section-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    .title {
      font-size: 23px;
      font-weight: 300;
      margin: 0;
      padding: 0;
    }
  }
}

.accordion-header {
  margin-bottom: 24px;

  &:first-child {
    margin-top: 0;
  }

  .main-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 11px;

    h3 {
      color: $gray-400;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.streamRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.streamTitle {
  color: #2671ff !important;
  text-decoration: underline !important;
}

.taskLogContainer {
  display: flex;
  justify-content: center;

  > table {
    width: 100%;
  }
}

.taskLogRow:hover {
  color: #cedeee;
  background-color: #072548 !important;
}

.streamsContainer {
  margin: 0;
  padding: 0 0 0 23px;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
}

.img {
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.source-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.kibana-logs-container {
  margin: 20px 0 20px 0 !important;
  text-align: left;

  h4 {
    margin: 0 !important;
  }
}

.kibanaLogsTable {
  width: 100%;

  td {
    padding: 0 5px 0 5px;
  }
}
