@import '~app/styles/themes/base/variables';

.root {
  .measures-wrapper {
    border-top: 1px solid $gray-lighter;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    & > div {
      flex: 1 0 0;
      margin-right: 10px;
      max-width: 250px;
      &:last-child {
        margin-right: 0;
      }
    }

    .label {
      border-radius: 3px;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      max-width: 100px;
      & > span {
        width: 100%;
        text-align: center;
      }
    }
  }

  .dim-popover-select {
    margin-left: 0;
    margin-right: 0;
  }
}
