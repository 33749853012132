@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';

.container {
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 43px;
  width: 183px;
  color: $gray-500;
  padding: 17px;
  margin-bottom: 5px;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &.selected,
  &:hover {
    background-color: $blue-100;
    color: $blue-500;
  }

  .new {
    background-color: $green-500;
    border-radius: 4px;
    color: $white;
    padding: 0 4px;
    height: 20px;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    margin-left: 8px;
    min-width: 36px;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
