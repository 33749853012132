@import 'variables';


.bc {
  .andt-page-header {
    font-size: $font-size-h2;
    font-weight: $font-weight-regular;
    color: $gray_600;
  }

  .andt-section-header {
    font-size: $font-size-h3;
    font-weight: $font-weight-light;
    color: $blue;

    i {
      font-size: 18px;
      position: relative;
      top: 2px;
      left: 10px;
    }
  }

  .andt-section-header-h4 {
    font-size: $font-size-h4;
    font-weight: $font-weight-light;
    color: $black1;

    i {
      font-size: 16px;
      position: relative;
    }
  }

  .andt-section-sub-header {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    color: $gray-dark;
  }
}
