/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 108px;

.header {
  @include widget-title;
}

.body {
  @include body;

  height: $widgetHeight;

  &.gray {
    @include gray;
  }

  &.white {
    @include white;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }
}

.counters-container {
  display: flex;
  justify-content: center;

  .no-open-alerts {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 52px;
    background-image: url('../../images/nnn.png');
    background-size: 28px;
    background-position: top center, 0 0;
    background-repeat: no-repeat, repeat;

    span {
      font-size: 14px;
      font-weight: 500;
      color: $gray-400;
    }
  }

  .counter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    margin-top: 3px;
    cursor: pointer;

    .number {
      font-size: 38px;
      font-weight: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $gray-500;
      margin-right: 10px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      word-break: break-word;
      font-style: normal;
      line-height: normal;
      color: $gray-400;
    }

    &.red {
      .number {
        color: $red-500;
      }
    }
  }
}
/* stylelint-enable */
