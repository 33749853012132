@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.input-container {
  display: flex;
  flex-direction: row-reverse;
}

.slider-container {
  margin: $slider-container-margins;
  margin-top: 26px;
}

.buttons-container {
  & > button:last-child {
    margin-right: 0px;
  }

  & > button {
    margin-right: $btn-margin-right;
    width: $btn-time-scale-size;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.value-label {
  display: flex;
  flex-direction: row-reverse;
  font-size: $value-label-font-size;
  color: $white;
  margin: -5px 5px 0 0;
}

.empty-container {
  width: 100%;
  height: 50px;
}
