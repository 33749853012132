@import "~app/styles/themes/base/variables";

@for $i from 1 through 6 {
    @keyframes preload-show-#{$i}{
      from {
        transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
        border-left-color: $blue-darker;
      }
    }
    @keyframes preload-hide-#{$i}{
      to {
        transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
        border-left-color: $blue-darker;
      }
    }

    @keyframes preload-cycle-#{$i}{

      $startIndex: $i*5;
      $reverseIndex: (80 - $i*5);

      #{$startIndex * 1%} {
        transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
        border-left-color: $blue-darker;
      }
      #{$startIndex + 5%},
      #{$reverseIndex * 1%} {
        transform: rotateZ(60* $i + deg) rotateY(0) rotateX(0deg);
        border-left-color: $blue-dark;
      }

      #{$reverseIndex + 5%},
      100% {
        transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
        border-left-color: $blue-darker;
      }
    }
}

@keyframes preload-flip{
  0%{
    transform: rotateY(0deg) rotateZ(-60deg);
  }
  100%{
    transform: rotateY(360deg) rotateZ(-60deg);
  }
}

.preloader{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 22px;
  display: block;
  width: 3.75em;
  height: 4.25em;
  margin-left: -1.875em;
  margin-top: -2.125em;
  transform-origin: center center;
  transform: rotateY(180deg) rotateZ(-60deg);

  &.loader-sm {
    font-size: 12px;
  }

  &.loader-lg {
    font-size: 28px;
  }

  &.loader-xlg {
    font-size: 36px;
  }

  .slice{
    border-top: 1.125em solid transparent;
    border-right: none;
    border-bottom: 1em solid transparent;
    border-left: 1.875em solid $blue;
    position: absolute;
    top: 0px;
    left: 50%;
    transform-origin: left bottom;
    border-radius: 3px 3px 0 0;
  }

  @for $i from 1 through 6 {
    .slice:nth-child(#{$i}) {
      transform: rotateZ(60* $i + deg) rotateY(0deg) rotateX(0);
      animation: .15s linear .9 - $i*.08s preload-hide-#{$i} both 1;
    }
  }


  &.loading{
    animation: 6s preload-flip steps(2) infinite both;
    @for $i from 1 through 6 {
      .slice:nth-child(#{$i}) {
        transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0);
        animation: 3s preload-cycle-#{$i} linear infinite both;
      }
    }
  }
}
