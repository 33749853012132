@import '~app/styles/v2/variables.scss';

.tab-item {
  width: 100%;
  height: 43px;
  border-radius: 6px;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    background-color: $gray-100;
  }
}

.label {
  margin-left: 12px;
}

.selected {
  color: $blue-500;
  background-color: $blue-100;
  font-weight: $font-weight-medium-500;

  &:hover {
    background-color: $blue-100;
    cursor: auto;
  }
}
