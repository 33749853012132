@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';

// needed so the marker images will be available
:global {
  #topology-map {
    position: relative;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: $gray-500;
  }

  g.d3-overlay * {
    pointer-events: visiblePainted;
  }
}

.hide-svg-defs {
  height: 0;
}

.tp-arrow-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(-6px);
}

.tp-arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 11px solid #123;
}

.tp-tooltip {
  display: inline-block;
  background-color: #123;
  color: white;
  border-radius: 6px;
  width: 270px;
  height: 150px;
  overflow-y: auto;

  .tp-content {
    padding: 10px;
  }

  .row {
    display: flex;
    align-items: center;

    span {
      padding-right: 4px;
    }
  }

  .inter-row {
    justify-content: space-between;
  }

  .inter-indicator {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .good {
    background-color: $green-500;
  }

  .bad {
    background-color: $red-500;
  }

  .sep {
    height: 1px;
    width: 95%;
    margin: 0 auto;
    background-color: $gray-200;
  }
}

.tp-tooltip.text-tooltip {
  height: 56px;
  width: 250px;
  display: flex;

  .tp-content {
    margin: auto;
  }
}

.tp-arrow-wrapper.text-tooltip {
  transform: translateY(-1px);
}
