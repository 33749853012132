/* margin */
.mt_0-5 {
  margin-top: 4px;
}

.mt_1 {
  margin-top: 8px;
}

.mt_1-5 {
  margin-top: 12px;
}

.mt_2 {
  margin-top: 16px;
}

.mt_2-5 {
  margin-top: 20px;
}

.mt_3 {
  margin-top: 24px;
}

.mt_-3 {
  margin-top: -24px;
}

.mt_-1-25 {
  margin-top: -10px;
}

.mr_-1 {
  margin-right: -8px;
}

.mt_-1-5 {
  margin-top: -12px;
}

.mt_4-5 {
  margin-top: 36px;
}

.mt_5 {
  margin-top: 40px;
}

.mt_8-5 {
  margin-top: 52px;
}

.mr_0-5 {
  margin-right: 4px;
}

.mr_1 {
  margin-right: 8px;
}

.mr_1-5 {
  margin-right: 12px;
}

.mr_2 {
  margin-right: 16px;
}

.mr_2-5 {
  margin-right: 20px;
}

.mr_3 {
  margin-right: 24px;
}

.mr_4 {
  margin-right: 32px;
}

.ml_0-5 {
  margin-left: 4px;
}

.ml_1 {
  margin-left: 8px;
}

.ml_1-5 {
  margin-left: 12px;
}

.ml_2-5 {
  margin-left: 20px;
}

.ml_2-5- {
  margin-left: -20px;
}

.ml_3-5 {
  margin-left: 28px;
}

.mb_0 {
  margin-bottom: 0;
}

.mb_0-25 {
  margin-bottom: 2px;
}

.mb_0-5 {
  margin-bottom: 4px;
}

.mb_1 {
  margin-bottom: 8px;
}

.mb_1-5 {
  margin-bottom: 12px;
}

.mb_2 {
  margin-bottom: 16px;
}

.mb_2-5 {
  margin-bottom: 20px;
}

.mb_3 {
  margin-bottom: 24px;
}

.mb_5 {
  margin-bottom: 40px;
}

.mb_6 {
  margin-bottom: 48px;
}

.mb_7 {
  margin-bottom: 56px;
}

.my_2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.my_3 {
  padding-top: 24px;
  padding-bottom: 24px;
}
