@import '~app/styles/themes/base/variables';

:global {
  .edit-live .andt-dropdown {
    min-width: 300px !important;
  }
}

.vss-modal-title {
  line-height: 1 !important;

  .title-main {
    font-size: 28px;
    font-weight: $font-weight-light;
    display: block;
  }

  .title-sub-block {
    margin-top: 12px;
    height: 40px;
    display: flex;
    padding-right: 30px;
  }

  .title-sub {
    font-size: $font-size-large;
    font-weight: $font-weight-light;
    display: block;
    flex-grow: 1;
    margin-left: 11px;
    padding-top: 11px;
  }

  .title-sub-input {
    font-size: $font-size-large;
    font-weight: $font-weight-light;
    display: block;
    flex-grow: 1;
    margin-left: 15px;
    padding: 0 5px;
  }

  .title-date {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    display: block;
    margin-top: 3px;
  }
}

.type-image {
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  background-position: center;
}

.item-width {
  max-width: 300px !important;
}

.vss-modal-body {
  display: flex;
  flex-direction: column;

  .spinner {
    font-size: 14px;
  }

  hr {
    border-color: $blue-light;
    margin-left: -30px;
    width: calc(100% + 30px);
    border-width: 2px;
  }

  .vss-modal-body-part {
    display: flex;
  }

  .vss-modal-column {
    flex-grow: 1;
    flex-basis: 40%;
    display: flex;
    flex-direction: column;

    &.right {
      flex-basis: 60%;
    }
  }

  .vss-modal-section {
    margin-bottom: 15px;
    display: flex;
    flex-flow: row wrap;

    &.last {
      height: 118px;
      margin-bottom: 0;

      .spinner {
        margin: 7px 0 0 100px;
        font-size: 36px;
        line-height: 1;
      }

      .vss-section-text {
        white-space: nowrap;
        width: 240px;
      }
    }

    &.history {
      .vss-section-sub-header {
        flex-basis: 150px;
      }
    }

    &.md {
      .vss-section-header {
        padding-left: 10px;
        padding-right: 10px;
      }

      .vss-section-item {
        padding-left: 10px;
        padding-right: 10px;
      }

      .vss-section-sub-header {
        flex-basis: 100%;
      }
    }

    .vss-query {
      max-height: 72px;
      overflow: auto;
      background-color: #f3f8fa;
      padding: 5px 10px;
      font-size: 14px;
    }

    .vss-section-item {
      width: 50%;
      margin-bottom: 25px;
    }

    .vss-ignored {
      color: $gray-lighter4;
    }

    .vss-based-on {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 10px;
      position: relative;
      margin-top: -5px;
      font-weight: $font-weight-light;
      color: $blue-light2;
    }

    .vss-section-header {
      font-size: $font-size-h4;
      color: $gray-400;
      font-weight: $font-weight-light;
      margin-bottom: 5px;
    }

    .vss-section-sub-header {
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      flex-basis: 150px;
      margin-bottom: 10px;
    }

    .vss-section-text {
      font-size: $font-size-base;
      width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;

      :global .loading {
        font-size: 14px;
      }

      .vss-section-input {
        width: 300px;
        color: $gray-dark;
      }
    }

    .vss-metric-amount {
      font-size: 36px;
      line-height: 1;

      .loader-pos {
        margin: 0;
      }

      .spinner {
        font-size: 35px;
        line-height: 36px;
      }
    }
  }

  .vss-dm-list {
    margin-bottom: 10px;
    padding-bottom: 1px;
    border-bottom: solid 1px $blue-light;
    position: relative;
    max-height: 200px;
    overflow-x: auto;

    .vss-section-sub-header {
      flex-basis: 50%;
    }

    .vss-section-text {
      flex-basis: 50%;

      &.changes {
        font-size: 10px;

        span {
          display: inline-block;
          max-width: 175px;
          vertical-align: text-bottom;
        }
      }
    }

    .vss-even {
      background-color: #f3f8fa;
    }
  }
}

.metric-info-card-root {
  width: 210px;
  border: 2px solid $gray-lighter8;
  border-radius: 5px;
  padding: 10px;

  .item {
    .key {
      margin-right: 5px;
    }
  }

  .seperator {
    width: 100%;
    height: 2px;
    background-color: $gray-lighter8;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .general-section {
    background-color: white;
  }

  .what-section {
    .item {
      .key {
        margin-right: 5px;
        color: $green-dark;
      }
    }
  }

  .dimensions-section {
    .item {
      .key {
        margin-right: 5px;
        color: $blue6;
      }
    }
  }
}

.footer {
  bottom: 0;
  right: 0;
  position: absolute;
  padding-right: 25px !important;
  padding-bottom: 30px !important;
  flex-direction: row !important;

  :global {
    .btn-raised {
      margin-left: 15px !important;
      width: 126px;
    }
  }
}
