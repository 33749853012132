@import '~app/styles/v2/variables';

.root {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 1px;
  width: 100%;
  text-align: center;
  background-color: $blue-100;
  display: flex;
  justify-content: center;
  height: 70px;
  border-top: 2px solid $blue-300;
  min-width: 1140px;
}

.right {
  position: absolute;
  right: 30px;
  bottom: 16px;
}

.left {
  position: absolute;
  left: 30px;
  bottom: 0;
}
