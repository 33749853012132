/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 177px;

.body {
  :global {
    .carousel-slider {
      display: flex !important;

      .control-arrow {
        top: 32% !important;

        &.control-prev {
          left: -14px !important;
        }

        &.control-next {
          right: -14px !important;
        }

        &.control-disabled {
          display: flex !important;
          opacity: 0.4 !important;
        }

        &::before {
          width: 10px !important;
          height: 10px !important;
        }
      }

      .control-dots {
        bottom: 2px !important;
        margin: 0 !important;
        padding: 0 !important;

        .dot {
          width: 12px !important;
          height: 3px !important;
          border-radius: 25% !important;
          margin: 0 2px !important;
          background-color: $gray-400 !important;

          &.selected {
            background-color: $gray-500 !important;
          }
        }
      }
    }
  }
}

.body {
  @include body;

  height: $widgetHeight;

  &.gray {
    @include gray;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }

  .header {
    @include widget-title;
  }
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: -12px auto;

  .no-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(#{$widgetHeight} - 30px);

    span {
      font-size: 14px;
      font-weight: 500;
      color: $gray-400;
    }
  }

  &.static {
    :global {
      .carousel-slider .control-arrow.control-disabled {
        display: none !important;
      }
    }
  }

  .charts-group-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    .chart-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 98px;
      height: 147px;
      margin: 0 4px;
      opacity: 1;
      align-items: center;

      .feedback-name {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 55px;
        max-width: 85px;
        height: 28px;
        margin-bottom: 16px;
        padding: 0 4px;
        cursor: pointer;
        border-radius: 4px;
        background-color: #cdc7e0;

        span {
          font-weight: 500;
          color: $purple-500;
        }
      }
    }
  }
}
/* stylelint-enable */
