@import "~app/styles/themes/base/variables";

.group {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 0px;
  animation: grow-width 0.3 forwards;
  margin-left: 2.5px;

  &::-webkit-scrollbar {
    max-width: 5px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $blue-light2;
  }
}

@keyframes grow-width {
  from {max-width: 0px;}
  to {max-width: 1000px;}
}
