// Kai _ Anodot Design System

.text36 {
  font-size: 36px;
}

.title30 {
  font-size: 30px;
}

.title22 {
  font-size: 22px;
  font-weight: 300;
}

.text20med-gray600 {
  font-size: 20px;
  font-weight: 500;
  color: #27363e;
}

.text20light-gray600 {
  font-size: 20px;
  font-weight: 300;
  color: #27363e;
}

.text18med {
  font-size: 18px;
  font-weight: 500;
}

.text18reg {
  font-size: 18px;
}

.text16reg {
  font-size: 16px;
}

.text16med {
  font-size: 16px;
  font-weight: 500;
}

.text16bold {
  font-size: 16px;
  font-weight: bold;
}

.buttonText {
  font-weight: 500;
  font-size: 14px;
}

.text14med {
  font-weight: 500;
  font-size: 14px;
}

.text14med-gray400 {
  font-weight: 500;
  font-size: 14px;
  color: #8995a0;
}

.text14reg {
  font-size: 14px;
}

.text14reg-gray400 {
  font-size: 14px;
  color: #8995a0;
}

.text12med {
  font-size: 12px;
  font-weight: 500;
}

.lineHeight_14 {
  line-height: 14px;
}

.lineHeight_16 {
  line-height: 16px;
}

.lineHeight_18 {
  line-height: 18px;
}

.whiteSpace_nowrap {
  white-space: nowrap;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
