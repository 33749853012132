@import '~app/styles/themes/base/variables';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: solid 1px $gray-200;
  background-color: $gray-100;
  margin-bottom: 5px;
  cursor: pointer;
}

.titles {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px 0 0;

  > span {
    text-align: left;
    font-weight: 500;
    padding: 0 12px;

    &:first-child {
      width: 825px !important;
      margin-left: 30px;
      margin-right: 24px;
      padding-left: 7px;
    }

    &:last-child {
      display: block;
      text-align: right !important;
    }
  }
}
