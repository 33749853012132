@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

.root {
  background-color: white;
  padding: 20px;
  border-top: 1px solid $gray-300;
  transition: height ease-in-out 0.5s, border 1s, box-shadow 1s;
}

.avatar {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  border-radius: 50%;
}

.label-wrapper {
  margin-bottom: 16px;

  h3 {
    font-weight: 300;
  }
}

.label-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-container {
  display: flex;
}

.col {
  flex: 1 0 0;
}
