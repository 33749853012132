@import '~app/styles/v2/variables.scss';

.container {
  font-size: $font-size-16;
  line-height: $font-size-16;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  margin: 4px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $blue-100;
    border-radius: 4px;
  }
}

.name-and-count {
  padding-left: 26px;
  padding-right: 10px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  .label-container {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
  }

  .count {
    color: $gray-300;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    padding-left: 8px;
    margin-right: 0;
  }
}

.focused {
  background-color: rgba($blue-500, 0.05);
}

.chevron {
  padding-right: 12px;
}

.isSelected {
  position: absolute;
  left: 5px;
  color: $blue-500;
}
