@import '~app/styles/v2/variables';

.container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0 11px;
  background-color: rgba($gray-500, 0.2);
  color: $gray-500;
  border-radius: 13px;
  margin: 5px 10px 5px 0;

  .value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    .dismiss-section {
      visibility: visible;
    }
  }
}

.dismiss-section {
  visibility: hidden;
  position: absolute;
  display: flex;
  right: 0;

  .gradient {
    width: 15px;
    height: 26px;
    background-image: linear-gradient(to right, rgba(217, 219, 222, 0), rgb(217, 219, 222));
    display: flex;
    flex-direction: row-reverse;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
  }

  i {
    color: rgba($gray-500, 0.5);
    font-size: 16px;
    height: 26px;
    line-height: 30px;
    background-color: #d9dbde;
    padding-right: 6px;
    padding-left: 2px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;

    &:hover {
      color: $gray-500;
      cursor: pointer;
    }
  }
}
