/* size */
.height_1 {
  height: 100%;
}

.height_0 {
  height: 0;
}

.maxHeight_1 {
  max-height: 100%;
}

.height_1vh {
  height: 100vh;
}

.height_18 {
  height: 18px;
}

.width_1 {
  width: 100%;
}

.width_1vw {
  width: 100vw;
}

.maxWidth_1 {
  max-width: 100%;
}

.minWidth_1 {
  min-width: 100%;
}

.boxSizing_content-box {
  box-sizing: content-box;
}
