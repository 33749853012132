@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  padding-bottom: 20px;
}

.title {
  font-size: 22px;
  color: $blue;
  font-weight: 300;
  width: 100%;
  align-self: center;
}

.items {
  display: flex;
  flex-wrap: wrap;
}
