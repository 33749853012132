@import '~app/styles/themes/base/variables';

:global {
  .first-time-modal {
    width: 600px !important;
    height: 392px !important;
    margin-top: 250px !important;

    .modal-title {
      padding: 24px 4px 0 24px;
    }

    .modal-footer {
      text-align: center !important;
      background: #fff !important;
      border-top: none !important;
    }

    .modal-body {
      align-items: flex-start !important;
      padding: 4px 24px 24px 24px !important;

      p {
        font-size: 16px;
      }
    }

    .ftue-img-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 8px;

      .ftue-img {
        background-image: url('../images/ftue-popup.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 480px;
        height: 208px;
      }
    }
  }
}

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 110px;
  margin-right: 90px;
}

.top {
  display: flex;
  justify-content: space-between;
  color: $black1;
  margin-left: 40px;

  .header {
    color: #27363e;

    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
    }

    h2 {
      font-size: 30px;
      font-weight: normal;
      margin-top: 5px;
    }
  }
}

//.title {
//  display: flex;
//  flex: 1 0 0;
//}

.master-detail {
  display: flex;
  flex-grow: 1;
}

.separator {
  border-right: 1px solid $gray-300;
  margin-bottom: 40px;
  width: 3px;
  flex-shrink: 0;
}

.left {
  display: flex;
  flex-direction: row;
  flex-basis: 30%;
  min-width: 310px;
  padding-left: 40px;
  padding-top: 20px;
  margin-right: 1px;
}

.right {
  display: flex;
  flex-grow: 1;
  padding-left: 30px;
  padding-top: 20px;
}
