@import '~app/styles/v2/variables.scss';

.root {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
}

.left-btn {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  &:hover {
    opacity: 0.9;
  }

  &.disabled {
    opacity: 0.375;
    cursor: not-allowed;

    &:hover {
      opacity: 0.375;
    }
  }
}

.right-btn {
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &.disabled {
    //background-color: $gray-300;
    //color: rgba($gray-500, 0.5);
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }

  i {
    position: relative;
    top: 2px;
  }
}

.divider {
  background-color: $black-500;
  width: 1px;
  height: 80%;
  opacity: 0.3;
}

.popover {
  width: 50px;
  height: 300px;
  background-color: green;
}

:global {
  .split-button-popover-container {
    min-width: 0;
  }
}

.azure-500 {
  color: $white;
  background-color: $azure-500;
}

.gray-200 {
  color: $gray-500;
  background-color: $gray-200;
}

.gray-300 {
  color: $gray-500;
  background-color: $gray-300;
}

.gray-400 {
  color: $white;
  background-color: $gray-400;
}

.gray-500 {
  color: $white;
  background-color: $gray-500;
}

.indigo-500 {
  color: $white;
  background-color: $indigo-500;
}

.blue-500 {
  color: $white;
  background-color: $blue-500;
}

.red-500 {
  color: $white;
  background-color: $red-500;
}

.green-500 {
  color: $white;
  background-color: $green-500;
}

.white {
  color: $gray-500;
  background-color: $white;
}

.transparent {
  color: $gray-500;
  background-color: transparent;
}

.tall {
  height: 40px;
}

.regular-height {
  height: 35px;
}

.tight-30 {
  height: 30px;
}

.tight-28 {
  height: 28px;
}

.wide {
  padding: 0 40px;
}

.regular-width {
  padding: 0 20px;
}

.narrow {
  padding: 0 8px;
}
