@import '~app/styles/v2/variables';

.container {
  position: absolute;
  bottom: 0;
  display: flex;
  height: 70px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  width: 100%;
  margin-left: -25px;
}

.left-side-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.three-dots-button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: $gray-300;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-container {
  display: flex;
}

.more-option {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  &.button {
    cursor: pointer;

    &:hover {
      background-color: $blue-500-10percent;
    }
  }
}

:global {
  .red-tooltip {
    background-color: $red-500;
  }
}
