@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  align-items: baseline;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  margin-bottom: 20px;

  :global {
    .btn-icon {
      margin-left: 6px;
      flex-shrink: 0;

      .icn-action16-edit {
        margin-top: 6px;
        font-size: 16px;
      }
    }
  }

  &.disabled {
    .title {
      color: $gray-lighter6;
    }
  }
}

.title {
  width: 100%;
  align-self: center;
}
