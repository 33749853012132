@import '~app/styles/v2/variables';

.table {
  display: flex;
  flex-direction: column;
  color: $gray-400;
  margin-bottom: 30px;
  padding-right: 10px;
  padding-left: 20px;
}

.col-title {
  display: flex;
  align-items: center;
  line-height: 1;
  flex-grow: 1;
  font-size: $font-size-16;
  color: $black-500;

  .severity-wrapper {
    margin-right: 8px;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }
}

.col-owner {
  display: flex;
  align-items: center;
  line-height: 1;
  width: 200px;
  flex-shrink: 0;
  color: $gray-500;
  font-weight: $font-weight-regular-400;
}

.col-notification {
  display: flex;
  align-items: center;
  line-height: 1;
  width: 90px;
  flex-shrink: 0;
}

.col-link {
  display: flex;
  align-items: center;
  line-height: 1;
  width: 45px;
  flex-shrink: 0;
  color: $gray-500;

  a {
    color: $gray-500 !important;
    cursor: pointer;
    visibility: hidden;
  }
}

.table-row {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 48px;
  padding-left: 24px;
  border-top: 1px solid $gray-200;
  font-family: Roboto, sans-serif;

  &.header-row {
    border-top: none;

    .col-title,
    .col-owner,
    .col-notification,
    .col-link {
      font-size: $font-size-14;
      font-weight: $font-weight-medium-500;
      color: $gray-400;
    }
  }

  &.no-notification {
    .col-owner {
      width: 290px;
    }
  }
}

.table-row:hover {
  background-color: $blue-100;

  &.header-row {
    background-color: transparent;
  }

  .col-link {
    a {
      visibility: visible;
    }
  }
}

.spinner-wrapper {
  justify-content: center;
  display: flex;
}

.empty-list {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 130px;

  .empty-text {
    color: $indigo-500;
    font-family: Roboto, sans-serif;
    font-weight: $font-weight-medium-500;
    font-size: $font-size-18;
    margin-top: 16px;
    width: 100%;
    text-align: center;
    padding-right: 6px;
  }
}
