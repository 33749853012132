@import '~app/styles/v2/variables';

.root {
  margin-bottom: 20px;

  .label {
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    line-height: normal;
    color: $gray-500;
    height: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .first-label {
    margin-top: -24px; // height + marginBottom
  }
}
