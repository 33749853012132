/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 210px;

.body {
  :global {
    .carousel-slider {
      position: absolute !important;
      width: 320px !important;
      left: 9px;
      bottom: 59px;

      .slide {
        //display: block !important;
        opacity: 0.5;

        &.selected {
          opacity: 1;
        }
      }
    }
  }
}

.body {
  @include body;

  height: $widgetHeight;
  position: relative;

  &.gray {
    @include gray;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }

  .header {
    @include widget-title;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: -12px auto;

  .completed-task-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      font-size: 27px;
    }

    p {
      margin: 0;
      text-align: center;
      font-size: 16px;
      color: $gray-500;
    }
  }

  .title {
    margin: 13px 0 8px 0;
    font-size: 16px;
    color: $gray-400;
  }

  .alert-info-wrapper {
    width: 320px;
    height: 80px;
    padding: 5px 10px 10px 10px;
    border-radius: 8px;
    border: solid 1px $gray-200;
    background-color: white;
    transition: height 0.175s ease-in-out;

    .alert-title {
      margin: 0 0 7px 0;
      font-size: 14px;
      font-weight: 500;
      color: $gray-500;
    }

    .metric-dec-wrapper {
      opacity: 0;
      transition: opacity 0.175s ease-in-out;

      &.active {
        opacity: 1;
      }

      .metric-measure-dimensions {
      }

      .metric-delta {
        margin-top: 7px;
      }
    }

    .alert-dec-wrapper {
      position: absolute;
      bottom: 5px;
      left: 10px;
      width: 94%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .alert-timing-wrapper {
        font-size: 14px;
        color: $gray-400;

        > div {
          display: flex;

          > span {
            margin-right: 5px;
          }
        }

        .start {
          :global {
            .alert-date-wrapper {
              width: auto;
            }
          }
        }

        .duration {
          :global {
            .alert-duration-wrapper {
              width: auto;
            }
          }
        }
      }

      .alert-measure {
        font-size: 16px;
        font-weight: 500;
        color: $purple-500;
        cursor: pointer;
      }
    }
  }

  .feedback-wrapper {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    bottom: 13px;
    left: 11px;
    width: 320px;

    > div {
      display: flex;
      align-items: center;
      margin-right: 12px;

      p {
        margin: 0 0 0 5px;
        font-size: 16px;
        color: $gray-500;
        cursor: pointer;
      }
    }

    div:nth-of-type(3) {
      margin-right: 0;
      margin-left: auto;

      p {
        font-size: 14px;
        text-align: right;
        color: $gray-400;
      }
    }
  }
}
/* stylelint-enable */
