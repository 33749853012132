.gauge-container {
  display: flex;
  align-items: center;
  margin: 4px 32px 0 0;

  &.loading {
    width: 148px;
    height: 30px;
    background: url(./images/small_loader.gif);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .value {
      display: none;
    }
    .description {
      display: none;
    }
  }

  .value {
    font-size: 38px;
    font-weight: 300;
    color: #8995a0;
    margin: 0 8px 0 0;
    padding: 0;
    line-height: 0;
  }
  .description {
    white-space: pre;
    line-height: 1.1;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #8995a0;
  }
}
