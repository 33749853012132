@import '~app/styles/themes/base/_variables';

.root {
  position: relative;
  .dim-popover-select {
    margin-left: 0;
    margin-right: 0;
  }

  .quick-tip {
    font-size: 12px;
    font-weight: bold;
    color: inherit;
    position: absolute;
    right: 0;
    margin-top: 30px;
    z-index: 1;
  }
}
