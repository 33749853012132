@import '~app/styles/v2/variables.scss';

.root {
  width: 170px;
}

.item {
  padding: 4px;
}

.item-inner {
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  padding-right: 5px;

  i {
    padding: 1px 5px 0 5px;
    align-items: center;
    display: flex;
  }

  .item-label {
    line-height: 1;
    flex-grow: 1;
  }

  .toggle-wrapper {
    pointer-events: none;

    :global {
      label::after {
        top: 2px;
      }
    }
  }
}

.disable {
  color: $gray-300;
}

.item:hover {
  .item-inner {
    background-color: rgba($blue-500, 0.05);
  }
}
