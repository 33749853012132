/* font */
.fontSize_12 {
  font-size: 12px;
}

.fontSize_14 {
  font-size: 14px;
}

.fontSize_16 {
  font-size: 16px;
}

.fontSize_20 {
  font-size: 20px;
}

.fontSize_22 {
  font-size: 22px;
}

.fontWeight_300 {
  font-weight: 300;
}

.fontWeight_400 {
  font-weight: 400;
}

.fontWeight_500 {
  font-weight: 500;
}
