@import '~app/styles/v2/variables';

.action-toolbox {
  position: relative;
  top: 1px;
  display: flex;
  height: 46px;
  align-items: center;
  background: linear-gradient(to right, rgba(233, 240, 255, 0), $blue-100 30%);
  padding-left: 33px;
  padding-right: 20px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 33px;
    height: 100%;
    background: linear-gradient(to right, rgba(233, 240, 255, 0), $blue-100 72%);
  }
}

.btn {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
}

.button-like-dropdown {
  border-radius: 6px;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  height: 30px;
  width: 32px;
  padding: 0 8px;
  background-color: $gray-300;
  position: relative;
  cursor: pointer;

  &::before {
    position: relative;
    top: 7px;
    width: 16px;
    height: 16px;
    font-size: $font-size-16;
  }

  &:hover {
    opacity: 0.9;
  }
}

.dropdown-option-icon {
  padding-right: 10px;
  position: relative;
  top: 2px;
}
