@import '~app/styles/v2/variables.scss';

.root {
  position: relative;
  display: flex;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid $gray-200;
  align-items: center;
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
}

.top-border {
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  background-color: $blue-300;
  height: 1px;
  visibility: hidden;
}

.root:hover {
  background-color: $blue-100;
  border-color: $blue-300;

  .top-border {
    visibility: visible;
  }
}

.remove-btn {
  cursor: pointer;
  color: $gray-500;

  &:hover {
    color: $blue-500;
  }
}
