@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.descriptor {
  position: relative;
  border: 1px solid $blue4;
  border-radius: 30px;
  min-width: 300px;
  margin-bottom: 20px;

  &::after,
  &::before {
    bottom: 100%;
    left: 50px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: transparent;
    border-bottom-color: $gray-dark;
    border-width: 10px;
    margin-left: -10px;
  }

  &::before {
    border-color: transparent;
    border-bottom-color: $blue4;
    border-width: 12px;
    margin-left: -12px;
  }
}

.descriptor-text {
  color: $blue4;
  font-weight: 400;
  margin: 10px 20px;

  .emphasize {
    color: $white2;
  }

  .missing-val {
    color: $brand-danger;
  }
}
