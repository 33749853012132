@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  max-width: 360px !important;

  .input-description {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    display: block;
    color: $black1;
    line-height: $font-line-height-short;
    margin-bottom: 5px;
  }
}
