@import '~app/styles/v2/variables';

.footer-button-like {
  padding: 2px 20px;
  height: 31px;

  span {
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
  }

  i {
    padding-right: 8px;
    position: relative;
    font-size: $font-size-16;
    top: 2px;
  }
}

.modal-tags {
  h5 {
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
  }

  p {
    font-size: $font-size-14;
    font-weight: $font-weight-regular-400;
  }

  .modal-tags-buttons {
    display: flex;
    justify-content: space-between;

    .replace-add {
      button {
        margin-left: 12px;
      }
    }
  }
}
