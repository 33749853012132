.root {
  margin-bottom: 30px;
  .ico,
  .ico-placeholder {
    background-size: contain;
    width: 21px;
    height: 21px;
    margin-right: 10px;
  }

  .ico-placeholder {
    background-image: url('./images/circle-loader.gif');
  }
  .parent-stream {
    padding: 5px 0;

    .name {
      font-size: 16px;
      font-weight: bold;
      color: #3d4c59;
      margin: 10px 0 6px;
      display: flex;
    }
  }
}
