@import "~app/styles/themes/base/variables";

.root {
  width: 100%;
}

.inner-root {
  padding-left: 50px;
  color: white;
  align-items: baseline;
  display: flex;
  flex: 1 0 0;
  margin-bottom: 30px;
}

.item {
  display: inline-block;
  margin: 0 20px;
  width: 50%;
  //max-width: 300px;
}

.item-title {
  font-weight: $font-weight-light;
  font-size: $font-size-h4;
  display: block;
  margin-bottom: 1px;
  max-width: 300px;
}

.item-description {
  font-weight: $font-weight-light;
  font-size: $font-size-small;
  display: block;
  margin-bottom: 1px;
  max-width: 300px;
}

.item-width {
  max-width: 300px !important;
}

.collect-on {
  input[type=number] {
    flex: 1 0 0;
    color: $blue-light !important;
    border: 1px solid $blue1 !important;
    width: 80px;
    text-align: left;

    &::placeholder {
      color: $blue-light !important;
      font-weight: 300;
      text-align: center !important;
    }

    &.error {
      border: 1px solid $brand-danger !important;
    }
  }
}
