@import '~app/styles/v2/variables.scss';

.andt-button {
  border-radius: 6px;
  border: 0;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  margin: 0;
  height: 35px;
  padding: 0 16px;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background-color: $gray-300;
    color: rgba($gray-500, 0.5);
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }

  &.multi {
    width: 100%;
    padding: 0;
    line-height: 30px;
    border-radius: 4px;

    &.gray-300 {
      background-color: transparent;

      &:hover:enabled {
        background-color: rgba($blue-500, 0.2);
        color: $gray-500;
      }
    }

    &.blue-500 {
      background-color: rgba($blue-500, 0.7);

      &:hover:enabled {
        opacity: 1;
      }
    }
  }

  .container {
    display: flex;
    position: relative;
    align-items: center;
  }

  .text-container {
    display: flex;
    margin: auto;
  }
}

.icon-button {
  font-size: $font-size-16;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  //margin-top: -4px;

  &.icon-and-text {
    position: relative;
    top: -1px;
    //top: 1px;
  }

  &.only-icon {
    margin: 0;
  }
}

.azure-500 {
  color: $white;
  background-color: $azure-500;
}

.gray-200 {
  color: $gray-500;
  background-color: $gray-200;
}

.gray-300 {
  color: $gray-500;
  background-color: $gray-300;
}

.gray-400 {
  color: $white;
  background-color: $gray-400;
}

.gray-500 {
  color: $white;
  background-color: $gray-500;
}

.indigo-500 {
  color: $white;
  background-color: $indigo-500;
}

.blue-500 {
  color: $white;
  background-color: $blue-500;
}

.red-500 {
  color: $white;
  background-color: $red-500;
}

.green-500 {
  color: $white;
  background-color: $green-500;
}

.white {
  color: $gray-500;
  background-color: $white;
}

.transparent {
  color: $gray-500;
  background-color: transparent;
}

.transparent-blue {
  color: $blue-500;
  background-color: transparent;
}

.ghost-blue {
  color: $blue-500;
  border: 2px solid $blue-500;
  background-color: transparent;

  &:hover {
    background-color: $blue-100;
  }

  &:disabled {
    border: 2px solid $gray-300;
    color: rgba($gray-500, 0.5);
    background-color: transparent;
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
}

.ghost-blue-light {
  color: $blue-500;
  border: 2px solid $blue-300;
  background-color: $blue-100;

  &:hover {
    background-color: $blue-300;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.tall {
  height: 40px;
}

.regular-height {
  height: 35px;
}

.tight-30 {
  height: 30px;
}

.tight-28 {
  height: 28px;
}

.wide {
  padding: 0 40px;
}

.regular-width {
  padding: 0 20px;
}

.narrow {
  padding: 0 8px;
}

.narrowTight {
  padding: 0 10px;
}

.button_270 {
  min-width: 270px;
}

.color-bar {
  width: 4px;
  height: 12px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 2px;
  position: relative;
  top: 1px;
}

:global {
  .next-button {
    padding: 0 16px 0 20px;

    .icon-button {
      margin-right: 0;
      margin-left: 5px;
      top: 0;
    }

    &.next-disabled {
      border: 2px solid $gray-300;
      color: rgba($gray-500, 0.5);
      background-color: transparent;
      cursor: not-allowed;

      &:hover {
        opacity: 1;
      }
    }
  }

  .prev-button {
    padding: 0 16px 0 10px;

    .icon-button {
      margin-left: 0;
      margin-right: 5px;
      top: 0;
    }
  }

  .is-disabled {
    background-color: $gray-300;
    color: rgba($gray-500, 0.5);
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
}
