@import '~app/styles/v2/variables';

.color-marker {
  height: 12px;
  width: 4px !important;
  border-radius: 2px;
  flex-grow: 0;
  flex-shrink: 0;
}

.critical {
  background-color: $red-500;
}

.high {
  background-color: $orange-500;
}

.medium {
  background-color: $yellow-500;
}

.low {
  background-color: $green-500;
}

.info {
  background-color: $azure-500;
}
