@import '~app/styles/v2/variables';

.footer-button-like {
  padding: 2px 20px;
  height: 28px;

  span {
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
  }

  i {
    padding-right: 8px;
    position: relative;
    font-size: $font-size-16;
    top: 2px;
  }
}
