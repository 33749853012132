/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 121px;

.header {
  @include widget-title;
}

.body {
  @include body;

  height: $widgetHeight;

  &.gray {
    @include gray;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }
}

.progressBar-wrapper {
  width: 80%;
  margin: 0 auto;
  height: 17px;
  margin-bottom: 12px;
  border-radius: 9px;
  padding: 3px;
  background-color: $blue-300;

  .progressBar-fill {
    display: block;
    width: 0;
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
  }
}

.legend {
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: $gray-400;
}
/* stylelint-enable */
