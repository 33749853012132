@import '~app/styles/v2/variables';

$menu-width: 60px;
$margin: 90px;
$margin-small: 20px;

/* -- Widths -- */
$max-width: 2560px;
$width-step-1: 1680px;
$min-width: 1200px;

.page-layout {
  //margin: 0 $margin 0 $margin + $menu-width;
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1 1 0;
  height: 100%;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: $white;

  /* padding-bottom: 24px; */
  &.auto-scroll {
    overflow-y: auto;
  }

  &.bottomBar {
    max-height: calc(100% - 70px);
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-width: $width-step-1;
  min-width: $min-width;
  margin-left: $margin;
  margin-right: $margin;

  &.fullWidth {
    max-width: 100vw;
    margin-left: 0;
    margin-right: 0;
  }

  .content {
    justify-content: initial;
    display: flex;

    &.page-header {
      margin-top: 40px;
      margin-bottom: 40px;
      align-items: baseline;
      display: flex;
      width: 100%;
    }
  }
}

@media screen and (max-width: $min-width + $margin) {
  .page-layout {
    justify-content: initial;
    //margin: 0;
    //overflow-x: auto;
  }

  .content-wrapper {
    justify-content: initial;
    margin-left: $margin-small;
    margin-right: 0;
  }
}
