@import '~app/styles/v2/variables';

.container {
  width: 100%;
  height: calc(100% - 48px);
  display: flex;

  .tabs-container {
    width: 100%;
    height: 100%;
  }
}
