@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';

.table-header {
  display: flex;
  flex-shrink: 0;
  margin: 0 10px;
  padding-right: 10px;
  font-weight: $font-weight-medium-500;
}

.table-row {
  margin: 0 2px;
  flex-shrink: 0;

  &.expanded-row {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    margin-bottom: 15px;
  }
}

.table-row-header {
  display: flex;
  cursor: pointer;
  max-height: 100px;
  padding: 5px;

  &.expanded-row {
    background-color: rgba(38, 113, 255, 0.05);
  }
}

.col-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.col-pr {
  flex: 1;
  justify-content: left;
}

.col-date {
  flex: 0 0 100px;
  justify-content: left;
}

.col-author {
  flex: 0 0 180px;
  justify-content: left;
}

.col-env {
  flex: 0 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .icon {
      color: green;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.pr-description {
  color: gray;
}

.avatar {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
}
