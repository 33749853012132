@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  margin-left: 110px !important;
  margin-right: 90px;
}

$title-padding-left: 36px;

.top {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-content: space-between;
  padding-left: $title-padding-left;
  padding-right: 0;
  padding-bottom: 10px;
  color: $black1;

  &.back {
    margin-top: 22px;
    margin-bottom: -15px;
    align-items: center;
    font-weight: $font-weight-medium-500;
    color: $gray-400;
    width: 100px;

    i {
      margin-right: 4px;
    }
  }
}

.master-detail {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  padding-left: 40px;
  padding-right: 40px;
}
