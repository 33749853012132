@import "~app/styles/themes/base/variables";

.vert-cell {
  width: 1px;
  height: 200px;
  position: absolute;
  top: 0px;
  left: 9px;
}

.vert-cell-wrapper{
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;

  &.disabled {
    .vert-cell-metric,
    .vert-cell-dimension {
      background-color: $gray-lighter8;
    }
  }
}

.vert-cell-metric {
  background-color: $metric-color;
}

.vert-cell-dimension {
  background-color: $dimension-color;
}

.text {
  transform: rotate(90deg);
  position: absolute;
  top: 80px;
  left: -17.5px;
  font-size: 10px;
  font-weight: $font-weight-light;
  letter-spacing: 0.2px;
}

.header-sep-metric {
  background-color: $metric-color;
  width: 1px;
  height: 48px;
  position: absolute;
  bottom: 61px;
  left: 9px;
}

.header-sep-dimension {
  background-color: $dimension-color;
  width: 1px;
  height: 48px;
  position: absolute;
  bottom: 61px;
  left: 9px;
}

.header {
  display: flex;
  flex: 1 0 0;
  flex-direction:column;
  height: 100%;

  .top {
    background-color: $preview-header-bg;
    flex: 1 0 60px;
  }

  .bottom {
    flex: 1 0 55px;
    background: #fff;

    &.disabled{
      .header-sep-dimension,
      .header-sep-metric {
        background-color: $gray-dark2;
      }

      .header-metric,
      .header-dimension{
        color: $gray-lighter;
      }
    }
  }
}

.header-metric {
  color: $metric-color;
  left: -12px;
  top: 80px;
}

.header-dimension {
  color: $dimension-color;
}
