@import '~app/styles/themes/base/variables';

.header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  min-height: min-content;

  h4 {
    margin-right: 10px;
    margin-left: 0;
    font-weight: $font-weight-light;
    color: white;
  }
}

.plus {
  font-size: 20px;
  position: relative;
  top: 1px;
}

.concepts-panel-titles {
  display: flex;
  margin-top: 10px;

  div {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: hidden;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    color: $gray-lighter4;
    letter-spacing: 0.5px;

    &:first-of-type {
      margin-right: 5px;
    }
  }
}

.concepts-panel-popover {
  display: flex;
  max-height: 220px;
}

.popover {
  transition: 0.3s;
}

:global .popover-content {
  padding: 8px;
}
