@import '~app/styles/v2/variables.scss';

.ddl-btn {
  cursor: pointer;
  z-index: 2;
}

.form-container {
  min-width: 365px;
}

:global {
  .form-ddl-container-style {
    overflow: hidden;
    max-height: 500px;
    opacity: 0;
    left: -4000px;
    z-index: 1051;
    position: absolute;
    border-radius: 6px;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 36, 0.25);
    background-color: $white;

    &.overflowable {
      overflow: visible;
    }
  }

  .with-arrow.form-ddl-container-style {
    overflow: initial;
    margin-top: 16px;
  }

  .form-ddl-container-open {
    opacity: 1;
    animation-name: form-ddl-container-open;
    animation-duration: 0s;
  }

  .form-ddl-container-closed {
    opacity: 0;
    animation-name: form-ddl-container-close;
    visibility: collapse;
    animation-duration: 0s;
    transition: opacity 0s;
  }

  @keyframes :global(form-ddl-container-close) {
    0% {
      visibility: visible;
    }

    100% {
      max-height: 0;
      visibility: collapse;
    }
  }

  @keyframes :global(form-ddl-container-open) {
    0% {
      max-height: 500px;
      opacity: 0;
    }

    1% {
      max-height: 1px;
      opacity: 1;
    }

    100% {
      max-height: 500px;
    }
  }
}
