.popover {
  margin-top: -10px !important;
  box-shadow: 0 4px 12px -2px rgba(0, 0, 36, 0.25);
  border-radius: 6px;

  :global {
    .popover-content {
      padding: 0;
    }

    .arrow {
      display: none !important;
    }
  }
}

.root {
  flex-shrink: 0;
}

.button {
  font-size: 10px !important;
}
