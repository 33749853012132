@import '~alerts.management/components/editor/simulationFilters/variables';

.buttons-container {
  display: flex;
  flex-direction: row-reverse;

  & > button:first-child {
    margin-right: 0px;
  }

  & > button {
    margin-right: $btn-margin-right;
    min-width: $btn-yes-no-width;
    padding-right: 14px;
    padding-left: 14px;
    line-height: 1.1;
  }
}

.empty-container {
  width: 100%;
  height: 50px;
}
