@import '~app/styles/v2/variables';

.container {
  cursor: pointer;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background-color: rgba($blue-500, 0.1);
  border-radius: 6px;
  padding: 4px;
  width: fit-content;

  &.disable {
    opacity: 0.5;
  }

  .value {
    color: $blue-500;
  }

  .triangle {
    color: rgba($blue-500, 0.5);
  }
}
