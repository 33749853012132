@import '~app/styles/v2/variables';

.box {
  align-items: center;

  .top-line {
    flex: 1 0 0;
    display: flex;
    color: $gray-400;

    .box-name {
      font-size: $font-size-14;
      font-weight: $font-weight-regular-400;
      margin-left: 8px;
      line-height: 1.3;
    }
  }

  .bottom-line {
    color: $gray-500;
    font-size: $font-size-16;
    margin-top: 3px;
    max-width: 280px;
    display: block !important;
  }

  .alert-0-no-warning {
    color: $green-500;
  }

  .alert-1-warning-yellow {
    color: $orange-500;
    font-size: $font-size-14;
  }

  .alert-2-warning-red {
    color: $red-500;
    font-size: $font-size-14;
  }
}
