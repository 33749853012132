@import '~app/styles/v2/variables';

$menu-width: 60px;
$margin: 90px;

/* -- Widths -- */
$max-width: 2560px;
$width-step-1: 1680px;
$min-width: 1200px;

$border-column-width: 300px;

.page-layout {
  margin: 0 $margin 0 $margin;
  display: flex;
  justify-content: center;
  flex: 1 1 0;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 1 1 0;
  align-items: center;
  max-width: $width-step-1;
  min-width: $min-width;
  transition: all 0.2s ease-in-out;
}

.content {
  justify-content: initial;
  margin: 0;
}

.col1 {
  display: flex;
  width: $border-column-width;
  margin: 0 15px;

  span {
    font-size: $font-size-16;
    padding-left: 15px;
  }

  .checkbox {
    position: relative;
    top: 3px;
  }
}

.col2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col3 {
  display: flex;
  width: $border-column-width;
}

@media screen and (max-width: $min-width) {
  .page-layout {
    justify-content: initial;
    margin: 0 0 0 $margin;
  }
}
