@import '~app/styles/v2/variables.scss';

:global {
  .andt-welcome-banner {
    background: url(../images/welcome_banner.svg);
    width: 244px;
    height: 75px;
  }
}

.root {
  margin: -24px -24px -16px -24px;
  padding: 43px 78px 20px 78px;
  height: 400px;
  background-image: linear-gradient(to bottom, $blue-100, $white), linear-gradient(to bottom, $white, $white);
  display: flex;
  flex-direction: column;
}

.title {
  height: 28px;
  font-family: Roboto, sans-serif;
  font-size: $font-size-24;
  font-weight: $font-weight-bold-700;
  line-height: 28px;
  text-align: center;
  color: $gray-600;
  margin-top: 36px;
}

.sub-title {
  height: 18px;
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  line-height: 18px;
  text-align: center;
  color: $gray-500;
  margin-top: 6px;
  margin-bottom: 25px;
}

.input-pos {
  margin-bottom: 6px;
}

.footer {
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}
