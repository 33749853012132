.sorting {
  display: flex;

  & > div {
    width: 180px;
  }

  button {
    height: 50px;
    border: 0;
    background: transparent;
    width: 100px;
    padding-right: 10px;
  }
}
