.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
}

.left_0 {
  left: 0;
}

.right_0 {
  right: 0;
}

.top_0 {
  top: 0;
}

.stretch_absolute {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.zIndex_1 {
  z-index: 1;
}

.visibility_visible {
  visibility: visible;
}

.visibility_hidden {
  visibility: hidden;
}
