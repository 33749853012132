
.loading-bar {

  background: #7ed5f0;
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  height: 2px;
}
