@import '~app/styles/v2/variables';

.page-header {
  align-items: baseline;
  display: flex;
  width: 100%;
  justify-content: space-between;

  .container {
    display: flex;
  }

  h1 {
    margin-right: 8px;
  }

  h2 {
    margin-right: 16px;
  }
}

:global {
  .expend-dropdown-control {
    height: 36px;
    line-height: 36px;
  }
}

.buttons-wrap {
  display: flex;
}

.dropdown-option-wrap {
  width: 48px;
}

.dropdown-option-icon {
  padding-right: 10px;
  position: relative;
  top: 2px;
}

.button-like-dropdown {
  border-radius: 6px;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  height: 36px;
  width: 36px;
  padding: 0 9px;
  background-color: $gray-200;
  position: relative;
  cursor: pointer;

  &::before {
    position: relative;
    top: 9px;
    width: 16px;
    height: 16px;
    font-size: $font-size-16;
  }

  &:hover {
    opacity: 0.9;
  }
}

.metrics-wrap {
  border: 1px solid $gray-200;
  border-radius: 6px;
  width: 270px;
  height: 36px;
  margin-right: 24px;

  .metrics-text {
    display: inline-block;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    padding: 5px 12px;
  }

  .metrics-widget {
    display: inline-block;
    margin-top: 10px;
    position: absolute;

    .progressBar-wrapper {
      width: 140px;
      margin: 0 auto;
      height: 13px;
      margin-bottom: 12px;
      border-radius: 9px;
      padding: 3px;
      background-color: $blue-300;

      .progressBar-fill {
        display: block;
        width: 0;
        max-width: 100%;
        height: 100%;
        border-radius: 5px;
        transition: width 0.3s ease-in-out;
      }
    }
  }
}

.tooltip-wrap {
  text-align: left;
}

.new-channel {
  width: 135px;
}
