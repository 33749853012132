@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/mixins';

.left-container {
  max-width: 400px;
  min-width: 400px;
}

.middle-container {
  flex-basis: 40%;
  min-width: 410px;
  overflow-y: auto;
  margin-bottom: 50px;
  padding-bottom: 0 !important;
}

.centered-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-right: 40px;
}

.centered-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 22px;
  padding-right: 40px;

  .text {
    color: $gray-400;
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    line-height: $font-size-16;
    font-weight: $font-weight-regular-400;
    text-align: center;
    margin-top: 5px;
  }
}

.no-data-container {
  padding-top: 22px;

  .icon {
    text-align: center;
  }

  .title {
    font-family: Roboto, sans-serif;
    font-size: $font-size-18;
    font-weight: $font-weight-medium-500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $indigo_500;
    margin-top: 18px;
  }

  .text {
    color: $gray-400;
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    line-height: $font-size-16;
    font-weight: $font-weight-regular-400;
    text-align: center;
    margin-top: 5px;
  }
}

.mid-area {
  min-width: 450px;
}

.last-area {
  max-width: 400px;
}

.root {
  max-width: 500px;
  min-width: 400px;

  .block {
    display: flex;
    position: relative;
  }

  .select-block {
    display: inline-block;
    margin-bottom: 50px;
    flex-grow: 1;

    &.disabled {
      cursor: not-allowed;
      color: $gray-lighter6;
    }
  }
}

.coralogix-select-block {
  margin-bottom: 24px;
  position: relative;

  & > input {
    margin-bottom: 20px;
    height: 46px;
    width: 100%;
    display: block;
  }

  .input-title {
    font-size: $font-size-large;
    font-weight: $font-weight-regular;
    display: block;
    color: $black1;
    margin-bottom: 5px;
  }

  .input-description {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    display: block;
    color: $black1;
    margin-bottom: 1px;
    line-height: $font-line-height-short;
  }
}

.footer-button-like {
  padding: 2px 20px;
  height: 31px;

  span {
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
  }

  i {
    padding-right: 8px;
    position: relative;
    font-size: $font-size-16;
    top: 2px;
  }
}

.advance-root {
  margin-right: 18px;

  .remove-item {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .path-wrapper {
    width: 80%;
    margin-bottom: 20px;

    & > input {
      width: 100%;
    }
  }

  .values-wrapper {
    display: flex;

    .operator-wrapper {
      margin-bottom: 20px;
      margin-right: 12px;
      flex-basis: 100px;
      flex-shrink: 0;
    }

    .value-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .seperator {
    height: 1px;
    width: 100%;
    background-color: $gray-lighter3;
  }

  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .add-path-wrapper {
      color: $blue-500;
      font-size: 16px;
      cursor: pointer;

      span {
        margin-left: 5px;
      }
    }
  }
}
