@import '~app/styles/v2/variables';

.container {
  height: 50px;
  // background-color: rgba($blue-500, 0.1);
  display: flex;
  align-items: center;
  padding: 5px 0 0 16px;

  .option {
    text-transform: uppercase;
    color: rgba($blue-500, 0.7);
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    margin-right: 16px;
    cursor: pointer;

    &.selected {
      color: $blue-500;
    }
  }

  .selected-option {
    border-bottom: 2px solid $blue-500;
  }
}
