@import '~app/styles/themes/base/variables';

:global {
  .bc.modal-dialog.data-source-modal {
    width: 600px;

    .modal-content {
      overflow: visible;

      .modal-body {
        padding-top: 10px;
      }

      .modal-xer {
        display: flex;
        align-items: center;
        flex-direction: column;

        .btn.btn-primary {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .my-facebook-button-class {
    width: 240px;
    height: 40px;
    border-radius: 3px;
    margin: 24px 0 24px 60px;
    color: #fff;
    background-color: #1877f2;
    font-weight: bold;
    border: none;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.25px;
    overflow: hidden;
    text-align: center;
    text-overflow: clip;
    white-space: nowrap;
  }
}

// hex-border
.hex-border {
  width: 102px;
  height: 116px;

  :global {
    .source-logo-facebook {
      margin-top: 4px;
      margin-left: 1px;
      height: 75px;
    }

    .source-logo-segment {
      transform: scale(0.35) translate(-170px, -130px);
      clip-path: none;
    }

    .source-logo-adobe {
      height: 72px;
      transform: scale(0.9) translate(3px, 6px);
      clip-path: none;
    }

    .source-logo-s3 {
      margin-top: 4px;
      margin-left: 1px;
      height: 75px;
    }

    .source-logo-parquet {
      margin-top: 22px;
      margin-left: 0;
      height: 38px;
    }

    .source-logo-oracle {
      width: 78px;
      margin-top: 2px;
    }

    .source-logo-mysql,
    .source-logo-psql,
    .source-logo-mssql {
      margin-top: 13px;
      margin-left: 7px;
      height: 63px;
    }

    .source-logo-mariadb {
      margin-top: 5px;
      margin-left: -1px;
      height: 76px;
    }

    .source-logo-redshift {
      margin-top: 9px;
      margin-left: 7px;
      height: 65px;
    }

    .source-logo-snowflake {
      margin-top: 3px;
      margin-left: 0;
      height: 77px;
    }

    .source-logo-teradata,
    .source-logo-databricks,
    .source-logo-coralogix {
      margin-top: 3px;
      margin-left: 2px;
      height: 76px;
    }

    .source-logo-mparticle {
      transform: scale(0.7) translate(-16px, 27px);
      clip-path: none;
    }

    .source-logo-athena_sql {
      transform: scale(0.4) translate(-153px, -175px);
      clip-path: none;
    }

    .source-logo-timestream {
      transform: scale(0.4) translate(-153px, -175px);
      clip-path: none;
    }

    .source-logo-eventhubs {
      transform: scale(0.4) translate(-153px, -175px);
      clip-path: none;
    }

    .source-ga {
      transform: scale(0.7) translate(-15px, -10px);
      clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    }

    .source-sf {
      transform: scale(0.9) translate(1px, 27px);
      clip-path: none;
    }

    .source-kinesis {
      transform: scale(1) translate(-11px, 8px);
      clip-path: none;
    }
  }
}

.my-facebook-button-disabled {
  display: inline-block;
  padding-top: 8px;
  width: 240px;
  height: 40px;
  border-radius: 3px;
  margin: 24px 0 24px 60px;
  color: #fff;
  background-color: $gray-lighter6;
  font-weight: bold;
  border: none;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.25px;
  overflow: hidden;
  text-align: center;
  text-overflow: clip;
  white-space: nowrap;
}

.footer {
  bottom: 0;
  right: 0;
  position: absolute;
  padding-right: 25px !important;
  padding-bottom: 30px !important;
  flex-direction: row !important;

  :global {
    .btn-raised {
      margin-left: 15px !important;
      width: 126px;
    }
  }
}

.copy-clipboard-btn-pos {
  width: 26px;
  color: $blue;
  transform: translate(322px, -42px);
  margin-bottom: -4px;

  :global {
    .icn-icon-copy {
      font-size: 20px;
    }
  }
}

.inputs-wrapper {
  width: 350px;
  margin: 0 auto 50px;
  position: relative;

  & > input {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  & > textarea {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    align-items: center;
    padding: 5px 15px;
    color: $blue;
    transition: border-color 0.2s linear;
    background-color: transparent;
    font-size: $font-size-large;
    font-weight: 300;

    &::selection {
      background-color: #eee;
    }

    &:placeholder-shown {
      font-size: $font-size-base;
    }

    &::placeholder {
      color: $gray-lighter6;
      font-weight: 300;
      font-size: $font-size-small;
      font-style: italic !important;
      text-align: left;
      vertical-align: middle;
    }

    &:focus {
      border-color: $blue;

      &.invalid {
        border-color: $brand-danger;
      }
    }
  }

  .role-margin {
    margin-top: -12px;
  }

  .copy-btn-wrapper {
    position: absolute;
    top: 17px;
    right: 2px;
    color: $black1;

    :global {
      .copy-container {
        font-size: 22px;
      }
    }
  }

  .error-wrapper,
  .error-wrapper-athena {
    padding: 0;
    margin-bottom: 20px;
    margin-top: -5px;

    .error-message {
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      color: $brand-danger;

      i {
        position: relative;
        padding-right: 4px;
        top: 3px;
        font-size: 16px;
      }
    }
  }

  .error-wrapper-athena {
    margin-top: 10px;
  }

  .extra-info {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 500;
    width: 352px;

    a {
      padding: 0 5px;
    }
  }

  .source-type-name {
    margin-top: 5px;
  }

  .creator-section {
    margin-top: 5px;
    font-size: 14px;
    width: 500px;
    text-align: center;
  }

  .rename-section {
    width: 350px;
    margin-top: 25px;

    input {
      margin: 8px 0;
      width: 100%;
    }
  }

  //** Unique per source styles **//

  .region-dropdown {
    max-width: 350px !important;
    box-shadow: none !important;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.error-wrapper-s3 {
  padding: 0 15px;
  margin: 0;
}

.test-wrapper {
  width: 100%;
  position: relative;
  height: 38px;
  margin-bottom: 20px;
  margin-top: 10px;

  button {
    position: absolute !important;
    right: 0;
  }

  .text-with-spinner {
    width: 348px;
  }
}

.checkboxs-wrapper {
  position: relative;
}

.spinner,
.spinner-text {
  font-size: 20px;
  position: absolute;
  right: -23px;
  color: $blue;
}

.spinner {
  top: 4px;
}

.spinner-text {
  top: 8px;
}

.db-ddl {
  max-width: 350px !important;
  box-shadow: none !important;
  border: 1px solid $gray-lighter;
  margin-bottom: 8px;
}

.header {
  font-size: $font-size-h4;
  font-weight: $font-weight-regular;
  margin-bottom: 5px;
}

.facebook-header {
  text-align: center;
}

.server-account-form {
  display: flex;
  align-items: center;

  .file-name {
    width: 230px;
    margin-right: 10px;
    height: 40px !important;
  }

  .browse-btn {
    width: 110px;
    height: 38px;
  }

  .file-input {
    display: none !important;
  }
}

.seperator {
  display: flex;
  align-items: center;
  margin: 20px 0;

  .line {
    flex: 1 0 0;
    margin-left: 10px;
    margin-right: 10px;
    background-color: $black1;
    height: 1px;
  }
}

.ga-auth-btn {
  display: flex !important;
  flex: 1 0 0;
  height: 35px;
  min-width: 250px;
  margin: 0 auto;
  margin-bottom: 20px !important;
  padding: 0 !important;
  color: $white;
  align-items: center;
  background-color: #4285f4;

  &:hover,
  &:focus,
  &:active {
    color: $white !important;
  }

  &:focus:active {
    color: $white;
    background-color: #137d9f;
    border-color: #0e5d76;
  }

  .google-logo {
    display: flex;
    width: 31px;
    height: 31px;
    margin-left: 1px;
    background-color: #fff;
    flex-shrink: 0;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
      margin: 0 auto;
    }
  }

  span {
    display: flex;
    flex: 1 0 0;
    align-self: center;
    flex-direction: column;
  }
}

.radio-wrapper {
  width: 387px;
  border-bottom: 1px solid $gray-200;

  .radio-panel,
  .radio-panel-active {
    cursor: pointer;
    display: inline-block;
    margin: 0 9px;
    padding: 0;

    .box-text {
      font-size: 16px;
      line-height: 23px;
      font-weight: 500;
    }

    .box-border {
      height: 4px;
    }
  }

  .radio-panel {
    .box-border {
      border-top: 3px solid $white;
    }
  }

  .radio-panel-active {
    .box-text {
      color: $blue-500;
    }

    .box-border {
      border-top: 3px solid $blue-500;
      border-radius: 3px;
    }
  }
}

.pass-text {
  height: 80px;
}
