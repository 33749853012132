@import '~alerts.management/components/editor/simulationFilters/variables';

.buttons-container {
  display: flex;
  flex-direction: row-reverse;

  & > button:first-child {
    margin-right: 0px;
  }

  & > button {
    margin-right: $btn-margin-right;
    width: $btn-time-scale-size;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.text-num {
  font-size: 18px;
  line-height: 20px;
}

.text-label {
  font-size: 15px;
  line-height: 18px;
}

.empty-container {
  width: 100%;
  height: 50px;
}
