.display_flex {
  display: flex;
}

.display_inline-block {
  display: inline-block;
}

.display_table {
  display: table;
}

.display_table-row {
  display: table-row;
}

.display_table-cell {
  display: table-cell;
}
