@import '~app/styles/v2/variables';

.container {
  .action-menu-container {
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .icon {
      color: white;
      font-size: 16px;
      margin-left: 8px;
    }

    .disabled {
      cursor: default;
      opacity: 0.5;
    }

    .action-menu {
      background-color: $blue-500;
      height: 24px;
      color: white;
      width: 81px;
      border-radius: 4px;
      margin-top: -20px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .icon {
        font-size: 14px;
        padding: 6px;
        margin: 0;
        cursor: pointer;
      }
    }
  }

  &:hover {
    .action-menu-container {
      visibility: visible;
    }
  }
}
