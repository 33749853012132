@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import './dataManagerListVariables.scss';

.table {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
}

.row {
  display: flex;
  justify-content: flex-start;
  color: $gray-500;
  padding-top: 16px;
  cursor: default;
  height: 48px;

  .stream-chip {
    display: inline-block;
    height: 16px;
    padding: 0 4px;
    line-height: 16px;
    background-color: $gray-200;
    font-size: $font-size-12;
    border-radius: 2px;

    &.live {
      color: $eucaliptus-500;
    }

    &.paused {
      color: $gray-500;
    }

    &.init {
      color: $blue-500;
    }

    &.incomplete {
      color: #bc7001;
    }

    &.error {
      color: $red-500;
    }
  }

  &:hover {
    .stream-chip {
      background-color: $blue-300;
    }
  }

  &.paused {
    color: $gray-400;
  }

  .col-title,
  .col-status,
  .col-owner,
  .col-last {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: Roboto, sans-serif;
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
    line-height: 16px;
  }

  .col-title {
    flex: $title-width;
    margin-left: $gap-first-col;
    font-weight: $font-weight-medium-500;
  }

  .col-status {
    flex: 0 0 $status-width;
  }

  .col-owner {
    flex: 0 0 $owner-width;
  }
}

.linked {
  cursor: pointer;
}
