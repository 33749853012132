@import '~app/styles/v2/variables';

.container {
  align-self: center;
  width: 570px;
  background-color: #f4f7fb;
  margin: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-top: 32px;
  min-height: 124px;

  .title {
    font-size: $font-size-20;
    font-weight: $font-weight-light-300;
    display: flex;
    justify-content: center;
  }

  .message {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: $gray-400;
  }

  .try-buttons-container {
    margin: 52px 0 32px 0;

    .try-these {
      font-size: $font-size-16;
      font-weight: $font-weight-bold-700;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .button-container {
    margin: 0 12px;
    color: $gray-400;
  }
}
