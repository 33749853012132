/* stylelint-disable */
@import '~app/styles/v2/variables';

$vss2-tab-view-height: 500px;

.vs2-modal-root {
  padding-bottom: 0;
  padding-right: 8px;
  padding-top: 16px;
}

.header {
  i {
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
  }

  .title {
    flex-direction: column;
    display: flex;
  }

  .type-image {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 21px;
    margin-right: 4px;
    background-position: center;
  }

  .stream-name {
    font-family: Roboto, sans-serif;
    font-size: $font-size-22;
    font-weight: $font-weight-light-300;
    color: $gray-500;
    line-height: 1.2;
    margin-bottom: 1px;
  }

  .stream-source {
    font-family: Roboto, sans-serif;
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
    color: $gray-500;
    line-height: 1.2;
    display: flex;
    align-items: center;
  }
}

.body {
  position: relative;
  display: flex;
  padding-top: 25px;

  .sidebar {
    width: 183px;
    flex-shrink: 0;
  }

  .main-view {
    padding-left: 25px;
    padding-bottom: 16px;
    flex-grow: 1;
    height: $vss2-tab-view-height;
    display: flex;
    flex-direction: column;
  }
}

.overview-tab {
  display: flex;
  flex-grow: 1;
  height: $vss2-tab-view-height;

  .metric-amount {
    margin-bottom: 10px;

    .amount {
      font-family: Roboto, sans-serif;
      font-weight: $font-weight-regular-400;
      color: $gray-500;
      font-size: 28px;
      line-height: 1.2;
    }

    .amount-title {
      font-family: Roboto, sans-serif;
      font-weight: $font-weight-medium-500;
      color: $gray-400;
      font-size: $font-size-16;
      line-height: 1.2;
    }

    .amount-subtitle {
      font-family: Roboto, sans-serif;
      font-weight: $font-weight-medium-500;
      color: $gray-400;
      font-size: $font-size-14;
      line-height: 1.2;
    }
  }

  .last-run-error {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .last-run-spinner-wrapper {
    justify-content: center;
    display: flex;
    height: 72px;
  }
}

.created {
  margin-top: 20px;
  padding-bottom: 25px;

  .owner-icon {
    margin-right: 4px;
  }

  .sub-time {
    display: block;
    color: $gray-400;
    font-size: $font-size-14;
  }
}

.dim-reduce {
  border-radius: 6px;
  border: solid 2px $gray-200;
  background-color: $white;
  padding: 8px 10px 6px 10px;

  .icon {
    color: $gray-400;
    font-size: $font-size-16;
    display: block;
    margin-bottom: 4px;
  }

  span {
    font-family: Roboto, sans-serif;
    font-weight: $font-weight-regular-400;
    color: $gray-500;
    font-size: $font-size-14;
    line-height: 1.2;
    display: block;
    margin-bottom: 3px;
  }

  a {
    color: $blue-500 !important;
    line-height: 1.2;
    font-weight: $font-weight-medium-500;
  }
}

.vs-section-header {
  font-family: Roboto, sans-serif;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
  font-size: $font-size-14;
}

.vs-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 5px;
}

.vs-label {
  font-family: Roboto, sans-serif;
  font-weight: $font-weight-medium-500;
  color: $gray-400;
  font-size: $font-size-14;
  line-height: 1.25;
  flex-shrink: 0;
  padding-right: 10px;
}

.vs-value {
  font-family: Roboto, sans-serif;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  font-size: $font-size-16;
  line-height: 1.25;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  text-overflow: ellipsis;
  display: inline-block;

  &.with-ellipsis {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.spinner {
  font-size: $font-size-16;
  line-height: 1.2;
}

.section {
  padding-bottom: 15px;
}

.dimetrics-section {
  .vs-section-header {
    margin-bottom: 5px;
  }

  .vs-row {
    margin-bottom: 8px;
  }

  .vs-label {
    flex: 1 0 30%;

    &.with-val {
      color: $gray-500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .measure-wrapper {
    display: flex;
    align-items: center;
    flex: 1 0 70%;
    padding-right: 5px;

    i {
      font-size: $font-size-16;
      flex-shrink: 0;
    }
  }

  .measure {
    height: 28px;
    border-radius: 4px;
    background-color: rgba($purple-600, 0.2);
    font-family: Roboto, sans-serif;
    font-weight: $font-weight-medium-500;
    color: $purple-600;
    line-height: 1;
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .dimension-wrapper {
    display: flex;
    align-items: center;
    flex: 1 0 70%;
    padding-right: 5px;
  }

  .dimension {
    height: 28px;
    border-radius: 4px;
    background-color: rgba($gray-500, 0.2);
    font-family: Roboto, sans-serif;
    font-weight: $font-weight-medium-500;
    color: $gray-500;
    line-height: 1;
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .cardinality {
    line-height: 1;
    font-weight: $font-weight-medium-500;
    flex-shrink: 0;
  }

  .ignored {
    opacity: 0.5;
  }
}

.info-tab {
  position: relative;
  height: $vss2-tab-view-height;
  overflow-y: auto;
}

.query-tab {
  position: relative;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  height: $vss2-tab-view-height;

  .query-text {
    border: solid 1px $gray-400;
    background-color: $gray-200;
    flex-grow: 1;
    overflow: auto;
    padding: 8px 12px;
    font-family: CourierNewPSMT, monospace;
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
    line-height: 1.38;
    color: $gray-600;
  }

  .copy-link-wrapper {
    flex-shrink: 0;
    justify-content: flex-end;
    display: flex;
    margin-top: 12px;
  }

  .copy-link {
    color: $blue-500;
    display: flex;
    align-items: center;

    i {
      margin-right: 4px;
    }
  }
}
