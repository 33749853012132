@import '~app/styles/themes/base/variables';
@import './variables';

.sub-title {
  @include sub-title();

  margin: 20px 0 10px 0;
}

.buttons-container-yes-no {
  display: flex;

  & > button:first-child {
    margin-left: 0;
  }

  & > button {
    margin-left: $btn-margin-right;
    width: 100px;
    padding-right: 0;
    padding-left: 0;
  }
}

.time-scale {
  display: flex;
  align-items: center;
  font-size: 16px;

  > div:first-child {
    width: auto;
  }

  :global {
    .andt-dropdown-control {
      margin-right: 6px;
      min-height: auto;
      height: 20px;
      padding: 0 4px;
      background-color: rgba(38, 113, 255, 0.1);

      .icon {
        margin-right: 0 !important;
        color: rgba(38, 113, 255, 0.5) !important;
      }
    }

    .andt-dropdown-value-container {
      padding: 0;
      color: $blue_500;

      input {
        height: 0 !important;
      }
    }
  }
}
