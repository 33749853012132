@import '~app/styles/v2/variables';

.action-toolbar {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}

.expandable-header {
  border-top: 1px solid $gray-200;
}

.open {
  .expandable-header {
    height: 48px;
    background-color: $blue-100;
    padding-top: 1px;
    border-top: 0;
  }

  .action-toolbar {
    opacity: 1;
  }

  border-radius: 6px;
  box-shadow: 0 0 0 2px #e9e9f0;
  background-color: $white !important;
  height: 100% !important;
  margin-bottom: 15px;

  &:hover {
    background-color: $white !important;
  }
}

.list-item {
  /* transition: height ease-in-out 0.5s, margin 0.5s, border 1s, box-shadow 1s; */
  position: relative;
  height: 49px;
  overflow: hidden;

  &:hover {
    background-color: $blue-100;
  }

  &.force-hover {
    text-decoration: none;
  }

  .action-toolbar-timeout {
    opacity: 1;

    /* transition: all 0.15s; */
  }
}

.is-checked {
  .expandable-header {
    background-color: $blue-100;
  }
}
