@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

:global {
  .CsvPreviewModalCell {
    .public_fixedDataTableCell_cellContent {
      top: 0;
      border-left: 1px solid $gray-300;
      padding: 0 8px;
    }

    .public_fixedDataTableRow_main {
      background-color: $white_500;
    }

    .fixedDataTableCellLayout_main {
      background-color: $gray-200;
      border-left: $gray-300;
    }
  }
}
