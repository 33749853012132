@import '~app/styles/v2/variables';

.container {
  display: flex;
  justify-content: flex-start;

  .checkbox {
    flex: 0 0 37px;
    padding: 3px 4px 0 15px;
  }

  .title {
    flex: 13.663636;
  }

  .warnings {
    flex: 0 0 73px;
    margin-right: 29px;
    display: flex;
    justify-content: center;
  }

  .owner {
    flex: 3.613636364;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 20px;
    white-space: nowrap;
  }

  .dateModified {
    flex: 0 0 112px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 20px;
  }

  .status {
    flex: 0 0 75px;
    margin-right: 20px;
  }

  .estimation {
    flex: 0 0 82px;
  }
}

.list-item {
  height: 49px;
  align-items: center;

  /* border-top: 1px solid $gray-300; */
  color: rgba($gray-500, 0.6);
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  cursor: pointer;

  .title {
    font-size: $font-size-16;
    display: flex;
    align-items: center;
    position: relative;

    .title-text {
      margin-left: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    mark {
      background-color: $orange-200 !important;
      padding: 0;
      margin: 0;
    }
  }

  &.active {
    .title {
      color: $gray-600;
      font-weight: $font-weight-regular-400;
    }

    .field {
      color: $gray-500;
    }
  }

  .warnings {
    font-size: 16px;
    position: relative;
    top: 3px;
    display: flex;
    justify-content: center;
  }
}

/* Put this on a class */
.list-header {
  height: 20px;
  align-items: center;
  font-size: $font-size-14;
  color: $gray-400;
  font-weight: $font-weight-bold-700;
  padding-right: 10px;

  .field {
    cursor: pointer;
  }

  .ordered-by {
    color: $gray-500;
  }
}

.highlighter-circle {
  width: 13px;
  height: 13px;
  position: relative;

  &::after {
    display: block;
    position: absolute;
    margin-left: 10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: $orange-200;
    content: ' ';
  }
}
