@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';
@import './dataManagerListVariables.scss';

.container {
  height: 23px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;

  &.sep-down {
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.175);
  }
}

.header {
  flex-shrink: 0;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  color: $gray-400;

  &.title {
    flex-grow: 1;
    width: $title-width - $title-offset;
    margin-left: $gap-first-col;
  }

  &.status {
    width: $status-width;
  }

  &.owner {
    width: $owner-width + $header-right-margin;
  }
}
