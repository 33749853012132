@import './../../../app/styles/themes/base/mixins';
@import '~app/styles/v2/variables.scss';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;

  ul {
    padding-left: 0;
  }

  .section-header-wrapper {
    display: flex;
    align-self: baseline;
    width: 100%;
    justify-content: space-between;

    .section-header-btn-disabled {
      margin-left: -10px;
    }

    .section-header-btn {
      margin-left: -10px;
      color: $blue500;
    }

    .last-refresh {
      display: flex;
      align-items: center;
      margin-right: 50px;
      font-weight: 300;

      .prefix {
        margin-top: 5px;
      }

      .time {
        margin-left: 5px;
        font-size: 22px;
      }

      i {
        font-size: 22px;
        height: 21px;
        margin-right: 4px;
      }
    }
  }

  .section-list-wrapper {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-right: 30px;
  }
}

.list {
  overflow-y: auto;
}

.list-header {
  @include list-header();

  padding-top: 25px;
  padding-bottom: 5px;
  flex-shrink: 0;
}

.loader-pos {
  left: -15px;
}
