@import "~app/styles/themes/base/variables";
@import "./DiametricsDraggableList.module.scss";

.list-item {
  position: relative;
  height: 50px;
  border: 1px solid $borderColor;
  border-radius: 5px;
  background-color: $itemBgColor;
  display: flex;
  padding: 0 0 0 10px;
  align-items: center;
  color: $white;
  font-weight: 300;
  cursor: grab;
  cursor: -webkit-grab;
}

.possible-types {
  position: absolute;
  top: 2px;
  left: 1px;

  i {
    margin: 0 2px;
    color: $blue-light;
  }
}

.btn-remove {
  i {
    position: relative;
    top: 2px;
  }
}

.name {
  flex: 1 0 0;
  font-weight: 400;
}
