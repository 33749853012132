@import '~app/styles/themes/base/variables';

.root {
  position: relative;

  .month-yy {
    position: absolute;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    line-height: 1;
    top: 0;
    right: 42px;
    transform: translate(10px, 5px);
  }

  .dd-mm-yyyy {
    position: absolute;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    line-height: 1;
    top: 3px;
    transform: translate(10px, 5px);
  }
}
