.color_white {
  color: #fff;
}

.color_gray-400 {
  color: #8995a0;
}

.color_purple-500 {
  color: #6826ab;
}

.color_red-500 {
  color: #d10f37;
}

.color_blue-500 {
  color: #2671ff;
}

.color_inherit {
  color: inherit;
}

.bgcolor_white-500 {
  background-color: #fff;
}
