@import '~app/styles/v2/variables.scss';

.root {
  display: flex;
  flex: 1 0 0;
  justify-content: space-between;
}

.left-side {
  display: flex;
}

.right-side {
  display: flex;
}

.title-pos {
  margin-right: 22px;
}

.pop-over {
  display: flex;
  flex-direction: column;
}

.item {
  width: 150px;
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  display: flex;
  align-items: center;
  padding: 9px 8px;
  margin: 4px;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: $blue-100;
  }

  i {
    margin-right: 5px;
  }
}
