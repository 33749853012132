@import '~app/styles/v2/variables.scss';

.add-group-component {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 250px;
  height: 90px;
  border-radius: 10px;
  border: dashed 2px $gray-200;
  flex-shrink: 0;

  .center-row {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
  }

  span {
    color: $gray-400;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  i {
    font-size: 16px;
    line-height: 16px;
    margin-right: 9px;
  }
}
