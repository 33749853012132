@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

@mixin snoozed-indicator {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  top: 17px;
  right: 15px;
  border-radius: 50%;
  background-color: #fdb21c;
}

.star-icon {
  cursor: pointer;

  &.full {
    opacity: 1;
    pointer-events: auto;
    color: $blue6;
  }
}

.snooze-container {
  position: relative;
  top: -1px;

  .resume-time {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 24px;
    font-size: 9px;
    color: $gray-dark;
    font-weight: $font-weight-regular;
    white-space: nowrap;
  }

  .value-container {
    position: relative;
    left: 0;

    .snooze-icon {
      color: $gray-500;
      display: flex;
      justify-content: center;
      font-size: 24px;

      &.action {
        &:hover {
          color: $blue-500;
        }
      }

      &.snoozed-and-stl {
        &::after {
          @include snoozed-indicator;

          background-color: #d0021b;
        }
      }

      &.snoozed {
        &::after {
          @include snoozed-indicator;

          background-color: #fdb21c;
        }
      }

      &.stl {
        &::after {
          @include snoozed-indicator;

          background-color: #38aee8;
        }
      }
    }
  }
}

.snooze-modal-body {
  display: flex;
  border-bottom: 1px solid $gray-lighter;
  padding-bottom: 20px;
  margin-bottom: -20px;

  .right-panel {
    @include shell;

    background-color: $white2;
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    height: 322px;
    margin: 0 5px 5px 15px;
    padding: 8px;

    :global {
      .search-box-wrapper {
        border-color: $gray-lighter;
      }
    }

    .left-panel {
      :global {
        .andt-time-input {
          margin: 0 12px;
        }
      }
    }
  }
}
