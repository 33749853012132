@import '~app/styles/themes/base/variables';

.root {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
}

.text {
  color: $black1;
  font-weight: $font-weight-light;
  font-size: 14px;
  margin-left: 5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.empty-checkbox-image {
  background-image: url('/common/images/emptyChkBox.png');
}

.full-checkbox-image {
  background-image: url('/common/images/checkedChkBox.png');
}
