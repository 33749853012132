@import "~app/styles/themes/base/variables";
@import "~app/styles/themes/base/mixins";

.root {
  height: 80px;
  max-height: 80px;

  > div {
    max-width: 100% !important;
  }
}
