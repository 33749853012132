@import '~app/styles/v2/variables';

.container {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.expression-builder-container {
  padding: 20px;
  flex: 0 0 427px;
}

.preview {
  height: calc(100vh - 80px);
  overflow: auto;
  margin: 20px;
  border: 2px solid rgba(38, 113, 255, 0.3);
  border-radius: 8px;
  width: 100%;
}
