@import '~app/styles/v2/variables';

.container {
  cursor: pointer;
  display: flex;
  color: $gray-500;
  align-items: center;
  margin: 1px 0 1px 4px;

  .ico {
    margin-right: 6px;
  }

  .label {
    font-size: $font-size-14;
  }
}

.selected {
  color: $blue-500;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
