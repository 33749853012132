@import '~app/styles/themes/base/variables';

.root {
  input[type='file'] {
    display: none;
  }

  :global {
    .andt-section-header {
      margin-left: -10px;
    }
  }
}

.read-only {
  cursor: not-allowed;

  .disabled-style {
    pointer-events: none;
    color: $gray-lighter6;
  }
}
