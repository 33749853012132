@import '~app/styles/v2/variables';

.tooltip-root {
  padding: 5px 0;
  width: 250px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tooltip-header,
.tooltip-footer {
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $white;
  padding-bottom: 10px;
  width: 100%;
}

.tooltip-footer {
  font-weight: $font-weight-light-300;
  margin-top: 5px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  width: 122px;
  margin-bottom: 5px;

  &.even {
    margin-right: 6px;
  }
}

.item-header {
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-light-300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: $white;
}

.item-value {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: $white;
}
