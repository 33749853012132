@import '~app/styles/v2/variables';

.nav-bar {
  width: 60px;
  height: 100%;
  z-index: 12;
  background-color: $navbar-bg;
  display: flex;
  flex-direction: column;

  .main-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    cursor: pointer;
  }

  .nav-bar-top {
    display: block;
  }

  .nav-bar-bottom {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: $spacing-5;
  }

  .item-wrapper {
    margin-bottom: $spacing-2;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 60px;
      position: relative;
      margin: 0;

      &::before {
        content: '';
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        border-radius: 0 3px 3px 0;
        background-color: $navbar-menu-active-color;
        animation: navanimationback ease-in-out 0.3s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
      }

      .item-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30px;
        cursor: pointer;

        i {
          width: 24px;
          height: 24px;
          font-size: 24px;
          color: $navbar-icon-color;

          &.under {
            color: $navbar-icon-color-under;
          }
        }

        &:hover {
          background-color: $navbar-bg-active;

          i {
            color: $navbar-icon-color-hover;

            &.under {
              color: $navbar-icon-color;
            }
          }
        }
      }

      .badge {
        display: inline-block;
        position: absolute;
        z-index: 13;
        border-radius: 10px;
        color: $white;
        font-size: 11px;
        height: 15px;
        line-height: 16px;
        text-align: center;
        margin-left: 7px;
        margin-top: -10px;
        padding: 0 4px 0 4px;
        background-color: $navbar-menu-active-color;
        border: 1px solid $navbar-bg;
      }

      .plus {
        display: inline-block;
        position: absolute;
        z-index: 13;
        font-size: 16px;
        height: 14px;
        line-height: 16px;
        text-align: center;
        margin-left: 14px;
        margin-top: -10px;
        color: $navbar-menu-active-color;
      }

      &.active {
        background-color: $navbar-bg-active;

        &::before {
          opacity: 1;
          animation: navanimation ease-in-out 0.3s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
        }

        .badge {
          border-color: $navbar-bg-active;
        }

        .item-link {
          i {
            color: $navbar-icon-color-hover;

            &.under {
              color: $navbar-icon-color;
            }
          }
        }
      }

      &.user {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: none;
        padding: $spacing-5 0;

        .user-icon {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: $font-size-16;
          border-radius: 50%;
          border: 2px solid $gray-400;
          margin-top: 0;
          margin-left: 0;
          background: url('../../../../../react-client/src/app/images/profile_empty.svg') center no-repeat;

          &.gray {
            border-color: $gray-400;
          }

          &.rose {
            border-color: $rose-500;
          }

          &.mint {
            border-color: $mint-500;
          }

          &.yellow {
            border-color: $yellow-500;
          }

          &.teal {
            border-color: $teal-500;
          }

          &.indigo {
            border-color: $indigo-400;
          }

          &.lilac {
            border-color: $lilach-500;
          }

          &.tomato {
            border-color: $tomato-500;
          }

          &.eucaliptus {
            border-color: $eucaliptus-500;
          }
        }

        &:hover {
          i {
            border-color: $blue-500;
          }
        }
      }
    }

    &.user {
      margin-bottom: 0;

      .user-trigger-submenu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 100%;
        cursor: pointer;

        &:hover {
          .user-icon {
            border-color: $navbar-icon-color-hover;
          }
        }
      }
    }

    .tooltip,
    .sub-menu-wrapper {
      display: block;
      opacity: 0;
      position: absolute;
      left: 60px;
      bottom: 0;
      z-index: 9999;
      height: 48px;
      background-color: $navbar-bg-active;
      color: $white;
      text-align: center;
      overflow: hidden;
      box-shadow: $navbar-shadow;
      cursor: pointer;
      font-family: Roboto, sans-serif;
      font-size: $font-size-16;
      font-weight: $font-weight-light-300;
      font-style: normal;
      font-stretch: normal;

      &.with-badge {
        text-align: left;
        line-height: 38px;
        padding-top: 3px;

        .badge-tooltip {
          font-size: $font-size-14;
          line-height: 14px;
          margin-top: -7px;
        }
      }
    }

    .sub-menu-wrapper {
      height: auto;
      border-radius: 16px;
      opacity: 1;
      width: 240px;
      white-space: nowrap;
      padding-bottom: 3px;

      .title {
        color: $navbar-submenu-title-color;
        cursor: default;
        padding: $spacing-5 0 15px 22px;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
      }

      .sub-menu {
        margin: 0 $spacing-3 14px $spacing-3;
        font-size: 15px;

        .sub-menu-item a {
          color: $navbar-menu-item-color;
          display: inline-block;
          width: 215px;
          margin-left: -11px;
          padding-left: 10px;
          cursor: pointer;
        }
      }

      .sub-menu-item {
        color: $navbar-menu-item-color;
        font-weight: $font-weight-medium-500;
        line-height: 39px;
        height: 39px;
        text-align: left;
        padding-top: 0;
        padding-left: 10px;
        padding-bottom: 0;
        border-radius: $spacing;

        &:hover {
          background-color: $navbar-menu-item-bg-active;
          color: $navbar-menu-item-color-active;
          cursor: pointer;

          a {
            color: $navbar-menu-item-color-active;
          }
        }

        &.active {
          background-color: $navbar-menu-item-bg-active;
          color: $navbar-menu-item-color-active;

          a {
            color: $navbar-menu-item-color-active;
          }
        }

        .label {
          padding: 0 $spacing-2;
          font-size: $font-size-14;
          color: $white;
          font-weight: $font-weight-bold-700;
          text-transform: uppercase;
          line-height: 24px;
          border-radius: $spacing-3;
          margin-left: $spacing-2;

          &.new {
            background-color: $teal-500;
          }

          &.plus {
            background-color: $mint-500;
          }
        }
      }

      &.user {
        position: fixed;
        bottom: 20px;
        color: $gray-500;
        text-align: left;
        padding-top: 0;
        padding-bottom: $spacing-3;
        cursor: default;

        .user-data {
          color: $gray-400;
          font-size: $font-size-14;
          line-height: 1.1;
          font-weight: $font-weight-medium-500;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .user-name {
          display: flex;
          align-items: baseline;
          cursor: default;
          line-height: 1;
          color: $gray-500;
          font-size: $font-size-18;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .ellipsis {
            min-width: 0;
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.groups {
          .user-groups {
            display: block !important;
          }
        }
      }
    }
  }

  .separator {
    border-top: 1px solid $navbar-separator-color;
    padding: 0;
    line-height: 0;
    margin: 6px $spacing-2;
    height: 1px;
    width: 44px;
  }
}

:global {
  .sub-menu-item__animation {
    &-appear {
      opacity: 0;
      transform: translate(-10px, 0);
    }

    &-appear-active {
      opacity: 1;
      transform: translate(0, 0);
      transition: opacity 300ms, transform 300ms;
    }

    &-exit {
      opacity: 1;
      transform: translate(0, 0);
    }

    &-exit-active {
      opacity: 0;
      transform: translate(-10px, 0);
      transition: opacity 300ms, transform 300ms;
    }
  }
}

@keyframes navanimation {
  0% {
    opacity: 0;
    transform: translate(-10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes navanimationback {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(-10px, 0);
  }
}
