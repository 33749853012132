@import '~app/styles/v2/variables.scss';
@import '~app/styles/themes/base/mixins';

.root {
  width: 100%;
  min-width: 200px;
  flex-shrink: 0;
}

.action-bar {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 2px 12px;
  background-color: $gray-100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  .action-btn {
    color: $blue-500;
    height: 16px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: $font-weight-bold-700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    cursor: pointer;

    &.secondary {
      color: $gray-500;
    }

    &.disabled {
      color: $gray-300;
      cursor: not-allowed;

      span {
        pointer-events: none;
      }
    }
  }
}
