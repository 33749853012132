@import '~app/styles/themes/base/variables';

div .root {
  //height: 25%;
  min-height: 200px;

  /* background-image: url("bc/images/streamPreviewTableTopBG.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 35%;
  padding: 25px 0;
  display: flex;
  flex-shrink: 0;
  flex-basis: 20%;
  align-content: space-between;
}

.title-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;

  .section-header {
    line-height: 22px;
  }
}

.totals-container {
  align-items: flex-end;
  flex: 1 0 50%;
  color: $blue;
  position: relative;

  .number {
    font-size: 50px !important;
    font-weight: $font-weight-light;
    line-height: 50px;
    text-align: center;
    height: 50px;
  }

  .account-metrics-info {
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    width: 180px;
    position: relative;
    display: flex;
    text-align: left;

    .term {
      width: 50%;
      padding-left: 0;
    }

    .value {
      width: 50%;
      text-align: right;
      padding-right: 30px;
    }
  }

  .number-metrics-section {
    width: 170px;
    padding-left: 20px;
    text-align: center;

    .warning-message {
      pointer-events: none;
      opacity: 0;
      background-color: $blue9;
      color: white;
      width: 405px;
      height: 170px;
      position: absolute;
      right: 160px;
      top: -5px;
      padding: 20px;
      text-align: left;
      border-radius: 4px;
      transition: opacity 300ms cubic-bezier(0.44, 0.57, 0.29, 1);

      &.show {
        opacity: 1;
        pointer-events: auto;
      }

      &.warn-error {
        border-left: $error-border;
      }

      .header {
        span {
          font-size: 28px;
          font-weight: 300;
          line-height: 1;
        }

        button {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .body {
        font-size: 16px;
        font-weight: 300;
        padding-top: 15px;
      }

      .footer {
        position: absolute;
        bottom: 20px;

        a {
          color: $navbar-menu-active-color;

          :hover {
            color: $navbar-menu-hover-color;
          }
        }
      }
    }
  }

  &.warn {
    color: $brand-warning;
  }

  &.too-much {
    color: $brand-danger;
  }
}
