@import '~app/styles/v2/variables';

.root {
  > header {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $gray-600;
    font-weight: $font-weight-medium-500;
    font-size: $font-size-20;
    margin-bottom: 20px;
  }
}

/* for te inner fields */
.titleWrapper {
  font-weight: $font-weight-medium-500;
  font-size: $font-size-14;
  color: $gray-500;
  margin-bottom: 8px;
}

.inputWrapper {
  margin-bottom: 12px;
}

.textWrapper {
  font-weight: $font-weight-medium-500;
  font-size: $font-size-14;
  color: $gray-400;
  margin-top: 7px;
}

.slideWrapper {
  .slideWrapper-text {
    display: inline-block;
    float: left;
    margin-top: -2px;
  }

  .textInput,
  .textInput_2,
  .textInput_3 {
    box-sizing: border-box;
    height: 38px;
    font-size: $font-size-16;
    padding: 12px;
    background-color: $gray-200;
    border-radius: 6px;
    border: 2px solid transparent;
    width: 61px;
  }

  .textInput {
    margin-top: 3px;
  }

  .textInput_2,
  .textInput_3 {
    margin-top: 7px;
  }

  .textInput_3 {
    width: 71px;
  }

  .slideWrapper-slider {
    display: inline-block;
    width: 260px;
    height: 44px;
    margin-left: 20px;
    padding-top: 5px;
  }
}

.slideTextWrapper {
  font-weight: $font-weight-medium-500;
  font-size: $font-size-14;
  color: $gray-400;
  height: 65px;
}

.info {
  display: inline-block;
  margin-left: 8px;
}
