@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

/*
using separate file for SnoozeMetrics control cause it
may be separated to common/components
 */

.root {
  @include shell-col;

  .metrics-header {
    margin-bottom: 10px;
  }

  .metrics {
    @include shell-col;

    margin: 10px 0;

    .metrics-titles {
      display: flex;
      flex-shrink: 0;
      color: $gray-lighter3;
      font-size: $font-size-small;
      justify-content: flex-end;
      position: relative;
      border-bottom: 4px solid $gray-lighter8;
      padding-bottom: 7px;

      .title-wrapper {
        text-align: center;
        padding-right: 7px;

        &.stl {
          padding-right: 0;
        }

        &.alone {
          padding-right: 26px;
        }

        span {
          white-space: nowrap;
        }

        i {
          display: block;
        }

        :global {
          .icon {
            cursor: pointer;
            transform: translate(0, 2px);
          }
        }
      }

      .col-title {
        flex: 1 0 0;
      }
    }

    .metrics-list {
      flex: 1 0 0;

      .no-results {
        font-size: $font-size-small;
        text-align: center;
      }

      .metric-item {
        @include shell;

        height: 37px; //actualy 38px cause we are adding border
        border-bottom: 1px solid $gray-lighter8;

        .metric-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .status {
            display: flex;
            align-items: center;
            padding-right: 5px;
          }

          .metric-name-format2 .dimension {
            color: #000 !important;
          }
        }

        .snooze-checkbox,
        .stl-checkbox {
          width: 71px;
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
        }

        .stl-checkbox {
          width: 59px;
        }

        :global {
          .andt-icon-checkbox-full,
          .andt-icon-checkbox {
            font-size: $font-size-small;
            color: $gray-lighter3;
            cursor: pointer;
          }
        }
      }
    }
  }
}
