@import '~app/styles/v2/variables.scss';

.outer-ring {
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 50%;
}

.inner-ring {
  width: 28px;
  height: 28px;
  position: relative;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background-color: $white;
}

.badge-area {
  width: 24px;
  height: 24px;
  position: relative;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: $white;

  span {
    line-height: 24px;
    font-size: $font-size-12;
    font-weight: $font-weight-bold-700;
  }
}

.shadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
}

/* -- Color Themes -- */
.gray {
  background-color: $gray-400;

  .badge-area {
    background-color: $gray-300;
    color: $gray-400;
  }
}

.rose {
  background-color: $rose-500;

  .badge-area {
    background-color: $rose-500;
  }
}

.mint {
  background-color: $mint-500;

  .badge-area {
    background-color: $mint-500;
  }
}

.yellow {
  background-color: $yellow-500;

  .badge-area {
    background-color: $yellow-500;
  }
}

.teal {
  background-color: $teal-500;

  .badge-area {
    background-color: $teal-500;
  }
}

.indigo {
  background-color: $indigo-400;

  .badge-area {
    background-color: $indigo-400;
  }
}

.lilac {
  background-color: $lilach-500;

  .badge-area {
    background-color: $lilach-500;
  }
}

.tomato {
  background-color: $tomato-500;

  .badge-area {
    background-color: $tomato-500;
  }
}

.eucaliptus {
  background-color: $eucaliptus-500;

  .badge-area {
    background-color: $eucaliptus-500;
  }
}

.gray.reverse {
  .badge-area {
    background-color: $gray-400;
    color: $gray-300;
  }
}

.rose.reverse {
  .badge-area {
    background-color: $white;
    color: $rose-500;
  }
}

.mint.reverse {
  .badge-area {
    background-color: $white;
    color: $mint-500;
  }
}

.yellow.reverse {
  .badge-area {
    background-color: $white;
    color: $yellow-500;
  }
}

.teal.reverse {
  .badge-area {
    background-color: $white;
    color: $teal-500;
  }
}

.indigo.reverse {
  .badge-area {
    background-color: $white;
    color: $indigo-400;
  }
}

.lilac.reverse {
  .badge-area {
    background-color: $white;
    color: $lilach-500;
  }
}

.tomato.reverse {
  .badge-area {
    background-color: $white;
    color: $tomato-500;
  }
}

.eucaliptus.reverse {
  .badge-area {
    background-color: $white;
    color: $eucaliptus-500;
  }
}
