.cube-details {
  border-radius: 3px;
  border: 1px solid #c3c9d0;
  color: #3d4c59;
  width: calc(100% - 2px);
  margin: 0 0 8px 0;
  height: 48px;
  transition: 0.5s height;

  &:hover:not(.open) {
    background-color: #f2fbfe;
    border: 1px solid #8995a0;
  }
}

.spinner {
  font-size: 58px;
  width: 58px;
  height: 58px;
  line-height: 58px;
}
.open {
  height: 360px;
  overflow: hidden;
}

.cube-revealed-header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-left: 20px;
  }
}

.cube-name {
  font-size: 16px;
  color: #3d4c59;
  margin: 0 0 0 14px;
  width: 100%;
  cursor: pointer;
}

.edit-link {
  font-size: 11px;
}

.cube-all-details {
  display: flex;
  margin: 0 20px 20px 20px;
  padding: 30px 0;
  height: 290px;
  border-top: 1px solid #d9d9d9;
  p {
    margin: 0;
  }
}

.container-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  &.description {
    justify-content: normal;
    textarea {
      height: 100%;
      margin-top: 10px;
    }
  }
}
.column-1 {
  flex: 1;
}

.column-2 {
  flex: 2;
}

.column-3 {
  flex: 3;
}

.detail-title {
  color: #0d90c7;
  font-size: 17px;
}

.big-number {
  font-size: 45px;
}

.sub-title {
  font-weight: bolder;
}

.cube-dropdown {
  position: relative;
  top: -10px;
}
