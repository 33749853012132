@import '~app/styles/v2/variables.scss';

:global {
  .notifications-wrapper {
    box-shadow: 0 2px 6px 0 rgba(2, 44, 93, 0.6);

    div[class^='notifications'] {
      width: auto !important;
      padding: 0 !important;

      > div {
        width: auto !important;
        padding: 0 !important;
        border-radius: 0 !important;
        height: auto !important;
        background: transparent !important;
      }
    }

    .notifications-bl {
      left: 60px !important;

      .notification {
        left: 0 !important;
        bottom: 0 !important;
        transform: translate3d(110px, 50%, 0) !important;
        opacity: 0 !important;
        transition: all 0.275s ease-in-out !important;
      }

      .notification-hidden {
        opacity: 0 !important;
        transform: translate3d(110px, -50px, 0) !important;
      }

      .notification-visible {
        transform: translate3d(110px, -50px, 0) !important;
        opacity: 1 !important;
      }
    }
  }
}

.notification-child-container {
  position: relative;
  width: 440px;
  max-height: 500px;
  overflow-y: auto;
  padding: 12px 16px 12px 16px;
  background-color: #f6f6f6;
  color: #27363e;
  border-radius: 10px;
  transition: all 0.275s ease-in-out;

  i {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
    opacity: 0;
    color: $gray-600;
    transition: opacity 0.175s ease-in-out;

    &:hover {
      transition: none;
      opacity: 1 !important;
    }
  }

  h2 {
    margin: 0 0 4px 0;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    white-space: pre-line;
  }

  .actions-bar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;

    .more-info-btn {
      margin-left: 16px;
      font-size: 16px;
      line-height: normal;
      font-weight: 400;
      color: $blue-500;
      transition: opacity 0.175s ease-in-out;

      &:hover {
        cursor: pointer;
        color: lighten($blue-500, 5%);
      }
    }
  }

  &:hover {
    i {
      opacity: 0.5;
    }
  }

  &.error {
    box-shadow: 0 4px 16px 0 rgba($red-500, 0.2);
    background-color: $red-200;
    color: $red-500;

    .more-info-btn {
      color: $gray-600;

      &:hover {
        color: lighten($gray-600, 5%);
      }
    }
  }

  &.warning {
    box-shadow: 0 4px 16px 0 rgba($yellow-500, 0.15);
    background-color: $yellow-100;
    color: $yellow-700;
  }

  &.info {
    box-shadow: 0 4px 16px 0 rgba($blue-500, 0.24);
    background-color: $blue-300;
  }

  &.success {
    box-shadow: 0 4px 16px 0 rgba($eucaliptus-500, 0.2);
    background-color: $eucaliptus-200;
    color: $eucaliptus-600;

    .more-info-btn {
      color: $blue-500;

      &:hover {
        color: lighten($blue-500, 5%);
      }
    }
  }
}

.modal-message {
  white-space: pre-wrap;
}
