@import '~app/styles/v2/variables.scss';

.status-body {
  font-size: $font-size-14;
  color: $gray-500;
  font-weight: $font-weight-regular-400;
  margin-bottom: 30px;
}

.delete-body {
  font-size: $font-size-14;
  color: $gray-500;
  font-weight: $font-weight-regular-400;
  margin-bottom: 30px;
}

.header {
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  line-height: 18px;
  margin-top: 12px;
  margin-bottom: 3px;
}

.delegate-row {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;

  .delegate {
    display: flex;
    align-items: center;
  }

  .header {
    margin-top: 16px;
    margin-bottom: 4px;
  }

  label {
    display: flex;
    margin-bottom: 3px;
    margin-right: 8px;
  }

  span {
    margin-left: 6px;
  }
}

.streams-delegation {
  border-radius: 6px;
  border: solid 2px $orange-200;
  background-color: rgba($orange-200, 0.3);
  margin-top: 10px;
  padding: 8px 9px;
  display: flex;

  i {
    margin-right: 8px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 2px;
    color: $orange-500;
  }

  .row {
    font-size: $font-size-14;
    color: $black-500;
    font-weight: $font-weight-regular-400;
    line-height: 16px;
  }

  .bold {
    font-weight: $font-weight-bold-700;
  }
}
