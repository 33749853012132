@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables.scss';
@import './variables';

$section-margin: 10px;

.container {
  cursor: pointer;
}

.spacer {
  margin-top: 60px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$section-margin;

  .header {
    font-size: $font-size-22;
    font-weight: $font-weight-medium-500;
    line-height: 1;
    color: $gray-600;
    margin-bottom: 12px;
  }

  .section {
    flex: 1 0 275px;
    margin-left: $section-margin;
    margin-top: $section-margin * 2;

    &:first-child {
      margin-top: 0;
    }
  }

  .section-wide {
    flex: 1 1 460px;
  }

  .section-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.btn-metrics {
  position: relative;
  margin-top: 3px;
  padding: 0 !important;
  font-size: 16px !important;
  font-weight: 500;
  color: $blue-500;
  background-color: transparent;
}

.selected-what-wrapper {
  display: flex;

  button {
    height: 30px;
    padding: 0 8px;
  }
}

.what-text {
  color: $purple-500;
  font-weight: bold;
  font-size: 14px;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: rgba(137, 71, 205, 0.2);
}

.trigger-on {
  color: $gray-500;
  font-weight: $font-weight-regular-400;
  line-height: 1;
  font-size: $font-size-16;

  .on-measure {
    color: $purple-600;
    font-weight: $font-weight-medium-500;
    line-height: 1;
    padding-left: 4px;
  }
}

.errors {
  border-radius: 6px;
  background-color: $red-200;
  font-size: 14px;
  color: $red-500;
  padding: 12px;
  margin-left: 10px;
  margin-top: 16px;
}

.btn-add {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-left: -5px;
}

.show-more {
  position: absolute;
  bottom: 0;
  right: 7px;
  display: flex;
  flex-direction: row-reverse;
  font-size: 28px;

  &:hover {
    color: #fff;
  }
}

.error-loader {
  font-size: 24px;
  color: $gray-400;
}

.section-label {
  font-size: 16px;
}

.sub-conditions {
  position: relative;

  button {
    position: absolute;
    right: 0;
    bottom: 6px;
    height: 28px;
    padding: 0;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
