@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  .inputs-wrapper {
    margin-right: 12px;

    & > input {
      margin-bottom: 20px;
      width: 100%;
      display: block;
    }

    button {
      float: right;
      height: 38px;
      min-width: 88px;
    }

    .revert-btn {
      margin-right: 8px;
    }

    .input-title {
      font-size: $font-size-large;
      font-weight: $font-weight-regular;
      display: block;
      color: $black1;
      margin-bottom: 1px;
    }

    .input-header {
      color: $blue6;
      font-size: $font-size-h3;
      font-weight: $font-weight-light;
      margin: 45px 0 20px 0;
    }

    .input-description {
      font-size: $font-size-small;
      font-weight: $font-weight-light;
      display: block;
      color: $black1;
      margin-bottom: 1px;
      line-height: $font-line-height-short;
      margin-top: 20px;

      &.input-description-first {
        margin-top: 0;
      }
    }
  }

  .error-title {
    font-weight: bold;
    min-height: min-content;
    font-size: $font-size-small;
    color: $black1;
    margin-top: 10px;
  }

  .tabular-data {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }

  .file-pattern {
    margin-bottom: 20px;
    max-width: 100% !important;
  }

  .error {
    color: $brand-danger;
  }

  .row-error-num {
    margin-right: 20px;
  }
}

.item-width {
  max-width: 300px !important;
}
