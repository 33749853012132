/* stylelint-disable */
@import '~app/styles/v2/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 40px;
}

.container > button {
  border-radius: 5px;
  background-color: $blue-500;
  color: white;
  text-align: center;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 300;
}

.title {
  font-size: 24px;
  font-weight: 400;
  margin: 0;

  span {
    font-weight: 500;
  }
}

.delete-metrics-wrapper {
  textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-family: monospace;
    border-radius: 6px;
    background-color: $gray-100;
  }

  .submit-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      margin-bottom: 25px;
    }

    .result-wrapper {
      width: 100%;

      table {
        width: 100%;
        margin: 25px 0 45px 0;

        td {
          width: 50%;
          cursor: default;
        }
      }
    }
  }
}

.generate-templates-wrapper {
  .generate-templates-selections {
    display: flex;
    justify-content: space-between;
    margin: 55px 0 35px;
  }

  .generate-templates-submit {
    display: flex;
    justify-content: flex-end;
  }
}

.flushInfoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.tableAndButtonsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.tableButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.epsTable {
  display: grid;
  margin-top: 10px;
  width: 100%;

  tr {
    display: table;
    width: 100%;
    border-bottom: 1px solid silver;
    padding: 3px 0;

    td {
      cursor: default;
      padding: 2px 3px;
    }

    .metric {
      width: 93%;
      word-break: break-all;
    }

    .eps {
      width: 7%;
      font-weight: bold;
    }
  }
}

.metricsToDelete,
.epsTable td:last-child {
  text-align: right;
}

.getMetricsEpsContainer {
  display: flex;
  flex-direction: column;
}

.getMetricsEpsContainerHeader {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: center;
}
