@import "variables";
@import "mixins";


.bc {
  .andt-dropdown {
    position: relative;
    display: inline-block;
    max-width: 250px;
    width: 100%;
    border-radius: 3px;
    /*box-shadow: 0 0 2px 0 $black-alpha014;*/
    .floating-title {
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: $font-size-small;
      font-weight: 700;
      color: $gray-dark;
      transform: scale(1) translate(12px, -5px);
      transform-origin: bottom left;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      pointer-events: none;
    }

    .dropdown-toggle {
      padding: 0 15px 0 12px;
      border: none;
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      border-radius: 3px;
      background-color: #FFFFFF;
      .dropdown-value {
        display: inline-flex;
        flex-grow: 1;
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        padding-bottom: 9px;
      }
      .dropdown-value.dropdown-value-no-title {
        padding-bottom: 0;
      }
      .caret {
        display: inline-flex;
        margin-left: 12px;
      }

      &.btn-default:hover, &.btn-default:focus {
        background-color: #FFFFFF;
      }
    }

    .dropdown-menu {
      box-shadow: 0 0 8px 0 $black-alpha014;
      width: inherit;
      display: block;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 300ms;
      max-height: 250px;
      overflow-y: auto;
      li {
        a {
          font-size: $font-size-base;
          color: $gray-dark;
          font-weight: $font-weight-light;
          border-radius: 3px;
        }
        a:hover, a:focus {
          border-left: none !important;
          padding-left: 15px;
        }
        &.active {
          a {
            border-left: none;
            padding-left: 15px !important;
            color: $blue;
            font-weight: $font-weight-medium;
          }
          a:hover, a:focus {
            background-color: $gray-lightest;
          }
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        &.active.disabled {
          opacity: 1;
        }
      }
    }

    .dropdown-header {
      font-weight: $font-weight-light;
      font-size: $font-size-small;
      height: 20px;
      padding: 5px 0 0 10px;
      color: $gray-lighter4;
      letter-spacing: 0.2px;
      border: none;
    }

    &.btn-icon {
      //width: 48px;
      width: 100%;
    }

    &.open > .dropdown-menu {
      transform: scaleY(1);
    }

    &.open > .dropdown-toggle.btn-default,
    &.open > .dropdown-toggle.btn-default:hover,
    &.open > .dropdown-toggle.btn-default:focus {
      background-color: #FFFFFF;
    }

    &.dropup {
      .dropdown-menu {
        transform-origin: bottom;
      }

      .caret {
        border-bottom: 0;
        border-top: 4px dashed;
      }
    }

    &.disabled {
      .dropdown-value {
        opacity: 0.5;
      }

      .caret {
        opacity: 0.5;
      }
    }
  }

  .andt-dropdown.icon-dropdown {
    //display: flex;
    box-shadow: none;
    .dropdown-toggle {
      background-color: transparent;
      padding: 0;
      &.btn-default:hover, &.btn-default:focus {
        background-color: transparent;
      }

      .dropdown-value{
        padding-bottom: 0;
        .inner-value {
          display: flex;
          width: 100%;
        }
      }
    }

    .dropdown-menu {
      margin-top: -1px; //items that reveal themselves on onHover will cause the ddl menu to close when the mouse moves slowly. so this fix removes the gap between the ddl btn and ddl menu
      width: initial;
      background-color: $blue;
      border: none;
      transform: scale(0, 0);
      transition: transform 300ms;
      transform-origin: top left;
      box-shadow: 0 0 8px 0 $black-alpha014;
      &.dropdown-menu-right {
        transform-origin: top right;
      }
      li {
        a {
          color: white;
        }
        a:hover, a:focus {
          background-color: $blue1;
        }
        &.active {
          a {
            color: white;
          }
          a:hover {
            background-color: $blue1;
          }
        }
      }
    }

    &.open > {
      .dropdown-menu {
        transform: scale(1, 1);
      }

      .dropdown-toggle {
        background-color: transparent;
        &.btn-default:hover, &.btn-default:focus {
          background-color: transparent;
        }
      }
    }

    &.open {
      &.left-bubble-arrow {
        .dropdown-menu {
          padding: 5px 10px;
          z-index: 1;
        }
      }
      &.left-bubble-arrow:after {
        content: "";
        position: absolute;
        top: 42px;
        left: 16px;
        width: 0;
        z-index: 1;
        border-bottom: 8px solid #FFFFFF;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    }

    &.btn-icon {
      .dropdown-toggle {
        @include round-btn(48px);
      }
    }

    &.dropup {
      .dropdown-menu {
        transform-origin: bottom left;
        &.dropdown-menu-right {
          transform-origin: bottom right;
        }
      }
    }

    &.center-icon-dropdown {
      .dropdown-menu {
        width: 220px;
        left: -80px;
        right: auto;
        transform-origin: top center;
      }

      &.dropup {
        .dropdown-menu {
          transform-origin: bottom center;
        }
      }
    }
  }

  .andt-dropdown.white-style {
    .dropdown-menu {
      background-color: white;
      li {
        a {
          color: $gray-dark;
        }
        a:hover, a:hover:focus{
          background-color: $gray-lighter8;
        }
        a:focus {
          background-color: white;
        }
        &.active {
          a {
            color: $gray-dark;
          }
          a:hover, a:hover:focus{
            background-color: $gray-lighter8;
          }
          a:focus {
            background-color: white;
          }
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }

  .andt-dropdown.dropdown-null-value {
    .floating-title {
      transform: scale(1.17) translate(15px, -13px);
    }
  }

  .andt-dropdown.with-menu-header {
    .dropdown-menu {
      li {
        a {
          padding-left: 20px;
        }
        a:hover {
          padding-left: 20px;
        }
        a:focus {
          border-left: none !important;
        }
        &.active {
          a {
            padding-left: 20px !important;
          }
        }
      }
    }
  }

  .andt-dropdown.blue-style {
    .floating-title {
      color: white;
      font-weight: $font-weight-regular;
      letter-spacing: 0.2px;
    }

    .dropdown-toggle {
      background-color: $blue3;
      color: white;
      .dropdown-value {
        color: white;
        font-weight: $font-weight-light;
      }
      &.btn-default:hover, &.btn-default:focus {
        background-color: $blue3;
        color: white;
      }
    }

    .dropdown-menu {
      background-color: $blue3;
      border: none;
      li {
        a {
          color: white;
        }
        a:hover {
          background-color: $blue4;
        }
        &.active {
          a {
            color: white;
          }
          a:hover {
            background-color: $blue4;
          }
        }
      }
    }

    &.open > .dropdown-toggle.btn-default,
    &.open > .dropdown-toggle.btn-default:hover,
    &.open > .dropdown-toggle.btn-default:focus {
      background-color: $blue3;
    }
  }

  .andt-dropdown.dark-blue-style {
    .floating-title {
      color: white;
      font-weight: $font-weight-regular;
      letter-spacing: 0.2px;
    }

    .dropdown-toggle {
      background-color: $blue;
      color: white;
      .caret {
        color: $blue-light;
      }
      .dropdown-value {
        color: white;
        font-weight: $font-weight-light;
      }
      &.btn-default:hover, &.btn-default:focus {
        background-color: $blue;
        color: white;
      }
    }

    &.open > .dropdown-toggle.btn-default,
    &.open > .dropdown-toggle.btn-default:hover,
    &.open > .dropdown-toggle.btn-default:focus {
      background-color: $blue;
    }
  }

  .andt-dropdown.green-style {
    .floating-title {
      color: white;
      font-weight: $font-weight-regular;
      letter-spacing: 0.2px;
    }

    .dropdown-toggle {
      background-color: $green-dark;
      color: white;
      .dropdown-value {
        color: white;
        font-weight: $font-weight-light;
      }
      &.btn-default:hover, &.btn-default:focus {
        background-color: $green-dark;
        color: white;
      }
    }

    &.open > .dropdown-toggle.btn-default,
    &.open > .dropdown-toggle.btn-default:hover,
    &.open > .dropdown-toggle.btn-default:focus {
      background-color: $green-dark;
    }
  }

  .cyan-scrollbar {
    .dropdown-menu::-webkit-scrollbar {
      background-color: transparent;
      max-width: 5px
    }
    .dropdown-menu::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .dropdown-menu::-webkit-scrollbar-thumb {
      background-color: $cyan-scrollbar;
      border: 0;
    }
    .dropdown-menu:hover::-webkit-scrollbar-thumb {
      background-color: $cyan-scrollbar;
    }
    .dropdown-menu::-webkit-scrollbar-thumb:hover {
      background-color: $cyan-scrollbar;
    }
    .dropdown-menu::-webkit-scrollbar-thumb:active {
      background-color: $cyan-scrollbar;
    }
  }

  //alert console - date range\ status filters
  .alerts-dropdown-btn {
    max-width: unset !important;
    border-radius: 0 !important;
    margin-bottom: 3px;
    box-shadow: $light-centered-shadow-before ;
    transition: box-shadow .2s ease-in-out;
    &:hover {
      box-shadow: $light-centered-shadow ;
    }


    > button.dropdown-toggle {
      width: 100% !important;
      height: 50px ;
      //height: 50px !important;
      padding: 0 15px 0 12px !important;
      border: none !important;
      background-color: white ;
      color: $gray-dark !important;

      .dropdown-value {
        padding: 0 !important;
        color: $gray-dark !important;

        .floating-title {
          white-space: nowrap !important;
          flex-shrink: 0 !important;
          display: inline-flex !important;
          flex-grow: 1 !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          transform: none !important;
          position: static !important;
          color: $gray-dark !important;
        }
      }
    }

    .dropdown-menu {
      padding: 9px;
      background-color: white !important;

      li {
        position: relative;
        border-radius: 3px;
        background-color: white;
        cursor: pointer;
        margin-bottom: 3px;
        a {
          color: $gray-dark !important;
          padding: 9px !important;
          font-size: 14px;
          font-weight: 400 !important;
          &:hover {
            background-color: $gray-lightest !important;
          }

          i {
            position: absolute;
            top: 13px;
            right: 11px;
          }
        }
        &.active {
          a {
            padding: 9px !important;
            color: $blue6 !important;
            background-color: $blue-light;
            &:hover {
              background-color: $blue-light !important;
            }
          }
        }
      }
    }
  }

  // menu-items styles
  .menu-item-influence-metrics {
    margin-top: 4px;
    margin-bottom: 4px;
    box-shadow: 0 0 2px 0 $black-alpha014;
    a {
      color: $gray-dark;
    }
    a:hover, a:focus, a:hover:focus{
      background-color: white !important;
    }
    &.active {
      a {
        color: $blue !important;
        background-color: $blue-light2;
      }
      a:hover, a:focus, a:hover:focus{
        background-color: $blue-light2 !important;
      }
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

