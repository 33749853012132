
.public_fixedDataTable_main {
  color: #4a4a4a;
  flex: 1 0 0;
}

.fixedDataTableCellLayout_columnReorderContainer {
  background-color: transparent;
}

.public_fixedDataTableCell_hasReorderHandle {

  &:hover {
    .fixedDataTableCellLayout_columnReorderContainer {
      &::after {
        display: block;
      }
    }
  }

  .fixedDataTableCellLayout_columnReorderContainer {
    &::after {
      top: 88px;
      display: none;
    }
  }

  .public_fixedDataTableCell_cellContent {
    margin: 0px;
  }
}

.public_fixedDataTableCell_cellContent {
  position: absolute;
  width: 100%;
  top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public_fixedDataTableCell_columnResizerKnob{
  width: 1px;
  background-color: #4A4A4A;
}

.public_fixedDataTableRow_main {
  background-color: #f3f8fa; //rgba(9, 130, 182, .04);
  .ignored-cell {
    color: #9B9B9B;
  }
}

.public_fixedDataTableRow_even {
  .what-metric{
    background-color: #f5f7f3; //rgba(65, 117, 5, .04);
  }
}

.fixedDataTableCellLayout_main {
  border-width: 0;
  background-color: #f3f8fa;
}

.fixedDataTableLayout_main {
  border-width: 0px;
}

.fixedDataTableLayout_header, .fixedDataTableLayout_hasBottomBorder {
  border-bottom-width: 0px;
}

.fixedDataTableRowLayout_fixedColumnsDivider {
  border-left-width: 0px;
}

.public_fixedDataTable_header  {
  background: linear-gradient(to bottom, #e9e9f0 60px, #fff 55px);
}

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: #fff;
}
