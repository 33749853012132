@import '~app/styles/v2/variables';

.over {
  flex: 1;
}

.container {
  margin-bottom: 24px;
  position: relative;

  .top-line {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;

    &::after {
      content: '';
      border-bottom: 1px solid $indigo-500;
      width: 383px;
      position: absolute;
      bottom: 0;
      left: 4px;
    }
  }

  .holder-menu {
    display: flex;
    visibility: hidden;
    align-items: center;
    height: 18px;
  }

  .holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 34px;
    background-color: $indigo-500;
    border: 1px solid $indigo-500;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    opacity: 0.5;
  }

  .ico {
    color: white;
  }

  .letter {
    color: white;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
  }

  .invisible {
    background-color: transparent;

    .letter {
      color: $indigo-500;
    }

    .ico {
      color: $indigo-500;
    }
  }

  &.selected {
    .holder {
      opacity: 1;
    }
  }

  &:hover {
    .holder-menu {
      visibility: visible;
    }
  }
}

.eye-icon {
  margin-right: 8px;
}
