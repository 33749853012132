@import '~app/styles/v2/variables';

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
}

.title {
  font-size: $font-size-12;
  font-weight: $font-weight-medium-500;
  color: $gray-400;
  padding: 0;
  margin: 0;
}

.value {
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
  width: 120px;
  text-align: right;
}

.break-line {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin: 0;
}
