@import '~app/styles/v2/variables.scss';

:global {
  .conf-modal {
    .modal-content > div {
      padding: 20px 20px 17px 24px;
    }
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  color: $gray-600;
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  line-height: 19px;
  margin-bottom: 6px;
}

.body {
  flex-grow: 1;
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;

  button {
    min-width: 85px;
  }

  .cancel-btn {
    margin-right: 12px;
  }
}
