@import '~app/styles/v2/variables';

.root {
  > header {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $gray-600;
    font-weight: $font-weight-medium-500;
    font-size: $font-size-20;
    text-align: center;
    margin-bottom: 20px;
  }

  .modal-body {
    max-height: 515px;

    .modal-search {
      display: inline-block;
      width: 275px;
      margin-bottom: 12px;
    }

    .no-data-container {
      padding-top: 182px;

      .icon {
        text-align: center;
      }

      .title {
        font-family: Roboto, sans-serif;
        font-size: $font-size-18;
        font-weight: $font-weight-medium-500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $indigo_500;
        margin-top: 18px;
      }
    }

    .modal-content {
      height: 470px;

      .source-title {
        font-family: Roboto, sans-serif;
        font-size: $font-size-16;
        font-weight: $font-weight-medium-500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $gray-500;
        margin-top: 22px;
        margin-bottom: 14px;
      }

      .itemContainer {
        display: inline-block;
        margin-bottom: 11px;

        .titleWrapper {
          display: flex;
          background-color: $blue-100;
          border: 1px solid $blue-300;
          font-weight: $font-weight-medium-500;
          font-size: $font-size-16;
          border-radius: 15px;
          cursor: pointer;
          padding-right: 10px;

          &.selected {
            border: 1px solid $blue-500;
          }

          .logo,
          .name,
          .type {
            display: inline-block;
            position: relative;
          }

          .logo {
            width: 42px;
            height: 30px;
            top: -9px;
            position: relative;

            svg {
              transform: scale(0.5);
              position: absolute;
            }
          }

          .name {
            color: $gray-600;
            bottom: -3px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 370px;
            padding-right: 12px;
          }

          .type {
            color: $gray-400;
            bottom: -3px;
            padding: 0 5px 0 10px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    padding-top: 16px;

    .left-button {
      flex: 1 0 0;
    }
  }

  &.single-source {
    > header {
      text-align: inherit;
      font-size: $font-size-22;
      font-weight: $font-weight-regular-400;
    }

    .info {
      margin-bottom: 24px;
    }

    .list-title {
      font-size: $font-size-14;
      font-weight: $font-weight-medium-500;
      margin-bottom: 8px;
    }
  }
}
