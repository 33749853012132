.headerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.header {
  h1 {
    margin-bottom: 30px;
  }
}

.download-report-button {
  //width: 310px;
  //display: flex;
  //justify-content: flex-end;
}
