@import '~app/styles/v2/variables';

.container {
  margin-top: 4px;

  .icon {
    color: white;
    font-size: 16px;
    margin-left: 8px;
  }

  .action-menu-container {
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
  }

  .action-menu {
    background-color: $blue-500;
    height: 24px;
    color: white;
    width: 81px;
    border-radius: 4px;
    margin-top: -20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .icon {
      font-size: 16px;
      padding: 6px;
      margin: 0;
      cursor: pointer;
    }
  }

  &:hover {
    .action-menu-container {
      visibility: visible;
    }
  }
}

.functionDisplayName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.function {
  background-color: $gray-500;
  height: 24px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border: 2px solid $gray-500;

  &:hover {
    border: 2px solid $indigo-300;
  }
}

.selected {
  .function {
    border: 2px solid $indigo-300;
  }
}

.parameter {
  padding-top: 1px;
  margin-right: 4px;
}

.validation {
  position: absolute;
  right: 5px;
  color: $yellow-500;
  font-size: 16px;
}

.button {
  display: flex;
  align-items: center;
  font-size: $font-size-14;
  line-height: 15px;
  height: 24px;
  color: $white;
  background: transparent;
  margin: 0 5px;
}

.verticalAlign {
  display: flex;
  align-items: center;
}

:global {
  .validation-tooltip {
    .tooltip-inner {
      background: $red-500 !important;
    }

    &.tooltip.right {
      margin-left: 15px;

      .validation-tooltip.tooltip-arrow {
        border-right: 6px solid $red-500 !important;
        border-right-color: $red-500 !important;

        &::before {
          border-right: 6px solid $red-500 !important;
          border-right-color: $red-500 !important;
        }
      }
    }

    &.tooltip.left {
      margin-right: 15px;

      .validation-tooltip.tooltip-arrow {
        border-left: 6px solid $red-500 !important;
        border-left-color: $red-500 !important;

        &::before {
          border-left: 6px solid $red-500 !important;
          border-left-color: $red-500 !important;
        }
      }
    }
  }
}
