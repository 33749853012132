:global {
  .bc.modal-dialog.file-upload-progress {
    width: 400px;
    .modal-content {
      .modal-body {
        padding-top: 15px;
      }
      .modal-footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        .btn.btn-primary {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
}

.file-name {
  font-size: 14px;
}
.hex-cancel {
  padding: 0 !important;
  min-width: 40px !important;
  height: 53px !important;
}
