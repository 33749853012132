/* flex */

.justifyContent_center {
  justify-content: center;
}

.justifyContent_flex-start {
  justify-content: flex-start;
}

.justifyContent_flex-end {
  justify-content: flex-end;
}

.justifyContent_space-between {
  justify-content: space-between;
}

.flexDirection_column {
  flex-direction: column;
}

.alignItems_center {
  align-items: center;
}

.alignItems_baseline {
  align-items: baseline;
}

.alignItems_flex-start {
  align-items: flex-start;
}

.alignItems_stretch {
  align-items: stretch;
}

.flexGrow_1 {
  flex-grow: 1;
}

.flexBasis_50 {
  flex-basis: 50%;
}

.flexShrink_0 {
  flex-shrink: 0;
}

.flexWrap_wrap {
  flex-wrap: wrap;
}

.alignSelf_center {
  align-self: center;
}

.alignSelf_flex-end {
  align-self: flex-end;
}

.alignSelf_flex-start {
  align-self: flex-start;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
