@import "~app/styles/themes/base/variables";
$unit-select-height: 50px;
$unit-select-input-height: $unit-select-height - 2px;

.root {
  width: 100%;
  height: $unit-select-height;
  max-width: 310px;
  margin-bottom: 10px;

  :global {

    mark {
      background-color: transparent;
      color: $blue2;
    }

    .Select-control {
      border-color: $gray-lighter;
      cursor: pointer;
    }

    .Select-placeholder {
      color: $gray-lighter6;
      font-size: $font-size-base;
      font-weight: $font-weight-light;
    }

    .Select-arrow-zone, .Select-arrow-zone:hover {
      padding-right: 10px !important;
      .Select-arrow {
        border-width: 4px 4px 0px;
        border-top-color: $black1;
        top: -2px;
      }
    }

    .Select--single.is-open {
      .Select-input {
        input {
          top: 0 !important;
          border-top: none;
          padding-right: 10px;
          padding-left: 10px;
          color: $gray-dark;
        }
      }
    }

    .is-disabled {
      .Select-control {
        cursor: not-allowed !important;
      }
    }

    .is-focused:not(.is-open) {
      .Select-control {
        border-color: $gray-lighter;
        box-shadow: none;
      }
    }

    .Select--single {
      .Select-control {
        .Select-value {
          line-height: $unit-select-input-height;
          background-color: $white;
          padding-left: 15px;

          .item-selected {
            .item {
              font-size: $font-size-base;
              font-weight: $font-weight-light;
              display: inline-block;
              color: $black1;
              position: relative;
              top: -8px;
            }
            .item-msg {
              color: $black1;
              font-size: $font-size-small;
              font-weight: $font-weight-bold;
              line-height: $font-size-small;
              position: absolute;
              left: 15px;
              bottom: 8px;
              transform-origin: bottom left;
              transition: all .45s cubic-bezier(.23,1,.32,1) 0ms;
            }
          }

          .item-none-selected {
            .item-msg {
              transform: scale(1.17) translate(0,-9px);
            }
          }

        }
      }
    }

    .has-value {

    }

    .UnitsSelectOption {
      font-size: $font-size-base;
      color: $gray-dark;
      font-weight: $font-weight-light;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
    }
    .UnitsSelectFocusedOption {
      background-color: $gray-lightest;
    }
    .UnitsSelectDisabledOption {
      opacity: 0.5;
    }
    .UnitsSelectSelectedOption {
      color: $blue;
      font-weight: $font-weight-medium;
    }
  }
}

.category {
  color: $gray-lighter4 !important;
  font-size: 12px !important;
  opacity: 1;
  align-items: flex-end !important;
}
