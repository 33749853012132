@import "~app/styles/themes/base/variables";

/**
* Rangeslider
*/
.rangeslider {
  background: $gray-lighter5;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none;
}
.rangeslider .rangeslider__handle {
  background: $blue4;
  border-color: $blue4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 5px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: $blue4;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}
.rangeslider-horizontal .rangeslider__handle:after {
  //content: ' ';
  //position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  left: 5px;
  //border-radius: 50%;
  background-color: $white;
  box-shadow: none;
}
