@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables.scss';
@import '~alerts.management/components/editor/simulationFilters/variables';

$section-margin: 10px;

.container {
  cursor: pointer;
}

.spacer {
  margin-top: 60px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$section-margin;
  margin-top: -$section-margin;

  .section {
    flex: 1 0 275px;
    margin-left: $section-margin;
    margin-top: $section-margin * 3;
  }

  .section-wide {
    flex: 1 1 460px;
  }
}

.btn-metrics {
  height: 39px;
  margin-top: 9px;
}

.what-text {
  color: $blue4;
  font-weight: bold;
  font-size: 12px;
  margin-top: 7px;
}

.errors {
  font-size: 14px;
  color: $orange-500;
  margin: 0;
  padding: 0 0 0 19px;
  list-style: disc;
}

.btn-add {
  color: $white !important;
  font-size: 22px !important;
  font-weight: bold !important;
  margin-left: -5px;
}

.show-more {
  position: absolute;
  bottom: 0;
  right: 7px;
  display: flex;
  flex-direction: row-reverse;
  font-size: 28px;
  color: $gray-400;

  &:hover {
    color: #fff;
  }
}

.error-loader {
  font-size: 24px;
  color: $gray-400;
}
