@import '~app/styles/v2/variables';

.description-modal {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: space-between;
  align-items: center;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  flex-wrap: wrap;

  & > div {
    width: 100%;
  }

  p {
    display: block;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    margin-bottom: 23px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: $blue-500;
    }
  }

  .column-1 {
    flex: 0 0 270px;
    margin: 24px 0 24px 32px;
    display: flex;
    align-items: flex-start;

    div {
      display: flex;
    }

    .title {
      color: $gray-400;
      font-size: $font-size-22;
      font-weight: $font-weight-light-300;
    }

    .description {
      display: block;
      width: 305px;
      padding-right: 8px;
      font-size: $font-size-16;
      color: $gray-400;
      line-height: 22px;
      margin: 0 0 28px 0;
      overflow: hidden;

      p {
        margin: 0;
        line-height: 22px;
      }

      .direction {
        font-weight: $font-weight-medium-500;
        color: $gray-500;
      }

      .measure {
        display: inline-block;
        margin-bottom: -5px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: $font-weight-medium-500;
        color: $purple-600;
      }

      .space-right {
        margin-right: 5px;
      }

      .dimensions {
        font-weight: $font-weight-medium-500;
        color: $eucaliptus-500;
      }
    }
  }

  .marked-background {
    background-color: $gray-100;
    overflow: hidden;
    border-radius: 6px;
    height: 100%;
    padding: 12px;
    margin-top: 15px;
  }

  .column-2 {
    display: flex;
    margin: 24px 0 24px 0;
    flex: 0 0 168px;
    width: 168px;
    align-items: flex-start;
  }

  .column-3 {
    display: flex;
    flex: 1;
    width: 303px;
    max-width: 455px;
    margin: 24px 0 24px 0;
    align-items: flex-start;

    .details-button {
      display: none;
      background-color: transparent;
      border: 0;
      font-weight: $font-weight-medium-500;
      color: $blue-500;
      height: 15px;
      position: relative;
      top: -5px;
    }

    &:hover {
      .details-button {
        display: inline;
      }
    }
  }

  .column-5 {
    width: 16px;
  }
}

.description-content {
  margin-top: 0;
}

.modal-header {
  color: $gray-600;
  font-size: $font-size-20;
  font-weight: $font-weight-medium-500;
  margin-bottom: 26px;
}

.type-chips-row {
  display: flex;
  margin-bottom: 6px;

  .chip {
    display: flex;
    align-items: center;
    height: 16px;
    border-radius: 2px;
    padding: 0 4px;
    background-color: $gray-200;
    font-size: $font-size-12;
    font-weight: $font-weight-bold-700;
    line-height: 10px;
    margin-right: 4px;
  }

  .anomaly-chip {
    color: $blue-500;
  }

  .static-chip {
    color: rgb(201, 40, 40);
  }

  .no-data-chip {
    color: rgb(178, 115, 11);
  }
}

.margin {
  display: flex;
  flex: 0.1056105611;
  max-width: 64px;
  min-width: 32px;
}
