@import '~app/styles/v2/variables.scss';

// MultiSelectItem
.multi-root {
  width: 100%;
  height: 40px;
  background-color: $white;
  display: flex;
  position: relative;
  align-items: center;

  label {
    padding: 0 10px;
    width: 100%;
    height: 40px;
  }

  span {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $gray-500;
  }

  mark {
    color: $gray-500;
    background-color: $orange-200 !important;
    padding: 0;
  }
}

// HeaderItem
.header-root {
  width: 100%;
  height: 30px;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding: 0 12px 5px 12px;

  span {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $gray-400;
  }
}
