/* stylelint-disable */
@import '~app/styles/v2/variables';

$btnWidth: 60px;
$insightPanelWidth: 400px;

.open-panel-btn {
  position: fixed;
  z-index: 10;
  top: 40px;
  right: -8px;
  cursor: pointer;
  background: url(./images/insight_tab.svg);
  width: 40px;
  height: 35px;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  i {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 24px;
    color: $gray-500;
  }

  &:hover {
    right: 0;

    i {
      color: $blue-500;
    }
  }
}

.root {
  z-index: 10;
  position: fixed;
  justify-content: space-between;
  width: $insightPanelWidth;
  height: 100%;
  overflow-y: auto;
  right: #{$insightPanelWidth * -1};
  padding: 30px 24px;
  background: #fff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

  > header {
    display: flex;
    position: absolute;
    right: 24px;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 16px;

    span {
      font-size: 16px;
      font-weight: normal;
      line-height: 0.75;
      color: $gray-400;
    }
  }
}

:global {
  .insight-panel {
    right: #{$insightPanelWidth * -1};
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  .insight-panel-enter-done {
    right: 0;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
    transition: right 300ms, box-shadow 300ms;
  }

  .insight-panel-exit {
    right: 0;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
  }

  .insight-panel-exit-done {
    right: #{$insightPanelWidth * -1};
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: right 300ms, box-shadow 300ms;
  }
}
/* stylelint-enable */
