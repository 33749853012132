@use "sass:math";
@import '~app/styles/v2/variables.scss';
$modal-width: 1240px;
$modal-width-minimize: 265px;
$transition-time: 300ms;
$height-from-top: 70px;

.bg {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;

  &.show {
    animation: hiddenBGTransitionReversed $transition-time ease-in;
    opacity: 1;
    visibility: visible;
  }

  &.hide {
    animation: hiddenBGTransition $transition-time ease-in;
    opacity: 0;
    visibility: hidden;
  }
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 101;
  bottom: 0;
  right: calc(50% - #{math.div($modal-width, 2)});
  width: $modal-width;
  height: 100%;
  transition: all $transition-time ease-in;

  &.show {
    width: $modal-width;
    height: 100%;
  }

  &.hide {
    right: #{math.div($modal-width-minimize, 4)};
    width: $modal-width-minimize;
    height: #{$height-from-top};
  }
}

.wrapper {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: calc(100% - #{$height-from-top});
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  transition: all $transition-time ease-in;

  &.hide {
    position: relative;
    height: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    cursor: pointer;
  }

  &.show {
    height: calc(100% - #{$height-from-top});
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.title-when-minimize {
  position: relative;
  transition: opacity $transition-time ease-in;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin: 12px 0 0 0;

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }
}

.children {
  opacity: 1;
  transition: opacity 300ms ease-in;

  &.hide {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }
}

.action-buttons {
  height: $height-from-top;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 8px;
  position: relative;

  .btn {
    height: 36px;
    width: 36px;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &.small {
    z-index: 1;
    height: auto;
    bottom: -10px;
    right: 9px;

    .btn {
      width: 27px;
      height: 27px;
    }
  }
}

@keyframes hiddenBGTransition {
  0% {
    opacity: 1;
    visibility: visible;
  }

  99% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes hiddenBGTransitionReversed {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  1% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}
