@import '~app/styles/v2/variables.scss';

.container {
  header h2 {
    margin: 0 0 12px 0;
    padding: 0;
    font-size: $font-size-20;
    font-weight: $font-weight-medium-500;
    color: $gray-600;
  }

  .body-text {
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
    color: $gray-500;
  }

  input {
    width: 100%;
    height: 44px;
    background-color: $gray-200;
    border: 0;
    border-radius: 6px;
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
    color: $gray-500;
    padding: 12px;
    margin: 40px 0 74px 0;
  }

  .buttons {
    display: flex;
    justify-content: center;

    button {
      margin: 0 8px 0 8px;
    }
  }
}
