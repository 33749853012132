@import '~app/styles/v2/variables';

.dropdownNoSearchLight {
  white-space: nowrap;
  max-width: 99%;

  div {
    transition: all 0.7s;
    color: #fff;
    position: relative;

    &.blue {
      color: #2ea3dd;
    }
  }

  small {
    transition: all 0.7s;
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    top: 16px;
  }

  &.selected {
    div {
      top: -7px;
    }

    small {
      font-size: 12px;
      top: 28px;
    }
  }
}

.click-area-ripple {
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1;
  padding: 12px;
  border-radius: 50%;
}

:global {
  .menu-style {
    overflow: hidden;
    max-height: 300px;
    opacity: 0;

    input[type='text'],
    input[type='number'],
    input[type='password'] {
      height: 40px;
    }
  }

  mark {
    margin: 0 -2px;
    padding: 0;
    background-color: rgba(#05f, 0.08) !important;
  }

  .menu-open {
    opacity: 1;
    animation-name: menu-open;
    animation-duration: 1ms;

    /* animation-timing-function: ease-in-out; */
    //transition-delay: 0.2s;
    //transition: opacity 0.1s;
  }

  .menu-closed {
    opacity: 0;
    animation-name: menu-close;

    /* animation-timing-function: ease-in-out; */
    animation-duration: 1ms;
    transition: opacity 1ms;
  }

  @keyframes :global(menu-close) {
    100% {
      max-height: 0;
    }
  }

  @keyframes :global(menu-open) {
    0% {
      max-height: 300px;
      opacity: 0;
    }

    1% {
      max-height: 1px;
      opacity: 0;
    }

    100% {
      max-height: 300px;
    }
  }

  .THEME_BLUE {
    color: #fff !important;
  }

  .THEME_LIGHT {
    color: #212121 !important;
  }

  .THEME_GREEN {
    color: #fff !important;
  }

  .THEME_LIGHT_BLUE {
    color: #212121 !important;
  }

  .THEME_GREY_LIGHT_BLUE {
    color: #3d4c59 !important;
  }

  .andt-dropdown-uno-multivalue,
  .andt-dropdown-delimiter-multivalue {
    display: none;
  }

  .andt-dropdown-uno-multivalue:first-of-type,
  .andt-dropdown-delimiter-multivalue:first-of-type {
    display: flex;
    align-items: center;
  }

  .andt-dropdown-option-header {
    position: relative;
    align-items: center;
    width: auto !important;
    background-color: $white !important;
    cursor: auto !important;
    margin: 0 12px 5px 12px !important;
    height: 100% !important;
    line-height: 40px !important;
    padding: 0 !important;
    border-radius: 0 !important;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      height: 2px;
      background-color: $gray-300;
    }
  }

  .andt-dropdown-option-selected-signed-header {
    height: 100%;
    border-bottom: 2px solid $gray-300;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: $gray-400;
    display: flex !important;
    align-items: center;
    margin-top: 6px;
  }

  .andt-dropdown-option-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      visibility: hidden;
    }

    &:hover {
      .info {
        visibility: visible;
      }
    }
  }
}

.no-value {
  color: #212121;
  position: absolute;
  top: 50%;
  font-weight: bold;
}

.count {
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  line-height: 1;
  color: $gray-300;
  padding-left: 9px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  flex-grow: 1;
}

.custom-value {
  margin: 0 2px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  overflow: hidden;
}

.option-signed {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  line-height: 1;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icon-signed {
  color: $blue-500;
  font-size: 16px;
  opacity: 0;
  float: right;
}

.option-selected {
  color: $blue-500;

  i {
    opacity: 1;
  }

  .highlighted {
    display: flex;
  }
}

.input-search {
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  background-color: rgba($blue-500, 0.1);
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 12px;
  border-bottom: 1px solid rgba($blue-500, 0.5);

  i {
    padding-right: 8px;
    padding-top: 3px;
    font-size: 16px;
    color: rgba($gray-500, 0.7);
  }

  input {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: $font-weight-medium-500 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba($gray-500, 0.7) !important;
  }

  .pseudo-placeholder {
    position: absolute;
    left: 35px;
    opacity: 0.7;
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    line-height: 1;
    letter-spacing: normal;
    color: $gray-500;
  }
}

.dropdown-option-icon {
  padding-right: 4px;
  top: 2px;
  font-size: 16px;
  position: relative;
}

.icon-clear {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
