/*****
  used 'font-ranger' (https://www.npmjs.com/package/font-ranger) package to split an unicode font to smaller subset font files so only needed parts in use will be loaded

  browsers know to load only the relevant fonts that were used by property 'unicode-range'
 *****/

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Roboto Light'),
 local('Roboto-Light'),
    url('../../styles/fonts/roboto/roboto-300.latin.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
 U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
    U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
 U+FEFF,
 U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Roboto Light'),
 local('Roboto-Light'),
    url('../../styles/fonts/roboto/roboto-300.latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Roboto Light'),
 local('Roboto-Light'),
    url('../../styles/fonts/roboto/roboto-300.cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Roboto Light'),
 local('Roboto-Light'),
    url('../../styles/fonts/roboto/roboto-300.cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Roboto Light'),
 local('Roboto-Light'),
    url('../../styles/fonts/roboto/roboto-300.greek.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Roboto Light'),
 local('Roboto-Light'),
    url('../../styles/fonts/roboto/roboto-300.greek-ext.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Roboto Light'),
 local('Roboto-Light'),
    url('../../styles/fonts/roboto/roboto-300.vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url('../../styles/fonts/roboto/roboto-400.latin.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
 U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
    U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
 U+FEFF,
 U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Roboto'),
 local('Roboto-Regular'),
    url('../../styles/fonts/roboto/roboto-400.latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Roboto'),
 local('Roboto-Regular'),
    url('../../styles/fonts/roboto/roboto-400.cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Roboto'),
 local('Roboto-Regular'),
    url('../../styles/fonts/roboto/roboto-400.cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url('../../styles/fonts/roboto/roboto-400.greek.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Roboto'),
 local('Roboto-Regular'),
    url('../../styles/fonts/roboto/roboto-400.greek-ext.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Roboto'),
 local('Roboto-Regular'),
    url('../../styles/fonts/roboto/roboto-400.vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Roboto Medium'),
 local('Roboto-Medium'),
    url('../../styles/fonts/roboto/roboto-500.latin.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
 U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
    U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
 U+FEFF,
 U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Roboto Medium'),
 local('Roboto-Medium'),
    url('../../styles/fonts/roboto/roboto-500.latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Roboto Medium'),
 local('Roboto-Medium'),
    url('../../styles/fonts/roboto/roboto-500.cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Roboto Medium'),
 local('Roboto-Medium'),
    url('../../styles/fonts/roboto/roboto-500.cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Roboto Medium'),
 local('Roboto-Medium'),
    url('../../styles/fonts/roboto/roboto-500.greek.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Roboto Medium'),
 local('Roboto-Medium'),
    url('../../styles/fonts/roboto/roboto-500.greek-ext.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Roboto Medium'),
 local('Roboto-Medium'),
    url('../../styles/fonts/roboto/roboto-500.vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
 local('Roboto-Bold'),
    url('../../styles/fonts/roboto/roboto-700.latin.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
 U+0131,
 U+0152-0153,
 U+02BB-02BC,
 U+02C6,
 U+02DA,
 U+02DC,
 U+2000-206F,
 U+2074,
 U+20AC,
    U+2122,
 U+2191,
 U+2193,
 U+2212,
 U+2215,
 U+FEFF,
 U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
 local('Roboto-Bold'),
    url('../../styles/fonts/roboto/roboto-700.latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
 local('Roboto-Bold'),
    url('../../styles/fonts/roboto/roboto-700.cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
 local('Roboto-Bold'),
    url('../../styles/fonts/roboto/roboto-700.cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
 local('Roboto-Bold'),
    url('../../styles/fonts/roboto/roboto-700.greek.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
 local('Roboto-Bold'),
    url('../../styles/fonts/roboto/roboto-700.greek-ext.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Roboto Bold'),
 local('Roboto-Bold'),
    url('../../styles/fonts/roboto/roboto-700.vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
