@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  border: none;
  margin: 8px;
  width: 184px;
  height: 234px;
  padding: 15px 20px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  background-color: $white;
  box-shadow: 0 2px 4px 0 $black-alpha020;
  overflow: hidden;
  cursor: default;

  .action-wrapper {
    position: absolute;
    bottom: 17px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .action {
      min-width: 77px;
      padding: 7px 16px;
      text-align: center;
      border-radius: 6px;
      background: $blue-500;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      box-shadow: 0 -6px 20px 12px #fff;
      opacity: 0;
      transition: opacity 0.075s ease-in-out;

      &.upgrade {
        background-color: #3f51b6;
      }
    }
  }

  &:hover {
    box-shadow: 0 3px 13px 0 $black-alpha020;
    transition: all 0.175s ease-in-out;

    .action {
      opacity: 1;
    }
  }

  .menu-header {
    float: left;
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  //** images **/
  .image-athena_sql {
    background-image: url('../../../bc/images/dsTypes/Athena_sql.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-adobe {
    background-image: url('../../../bc/images/dsTypes/ADOBE.png');
    transform: scale(0.9) translateX(-1px);
    width: 72px;
    clip-path: none;
  }

  .image-salesforce {
    background-image: url('../../../bc/images/dsTypes/SALESFORCE.png');
    transform: scale(0.6) translate(-28px, 4px);
    width: 105px;
    clip-path: none;
  }

  .image-mysql_to_s3_agent {
    background-image: url('../../../bc/images/dsTypes/MYSQL_TO_S3.png');
    transform: scale(0.9) translateX(-1px);
    width: 72px;
    clip-path: none;
  }

  .image-kinesis {
    background-image: url('../../../bc/images/dsTypes/KINESIS.png');
    transform: scale(0.9) translate(-21px, 0);
    width: 100px;
    clip-path: none;
  }

  .image-lookup {
    background-image: url('../../../bc/images/dsTypes/LOOKUP.png');
    transform: scale(0.9) translate(-21px, 0);
    width: 100px;
    clip-path: none;
  }

  .image-adtechsample {
    background-image: url('../../../bc/images/dsTypes/ADTECHSAMPLE.png');
    transform: scale(0.65) translate(-20px, 7px);
    width: 94px;
    clip-path: none;
  }

  .image-ecomsample {
    background-image: url('../../../bc/images/dsTypes/ECOMSAMPLE.png');
    transform: scale(0.65) translateX(-24px);
    width: 100px;
    clip-path: none;
  }

  .image-ridesample {
    background-image: url('../../../bc/images/dsTypes/RIDESAMPLE.png');
    transform: scale(0.55) translate(-55px, 4px);
    width: 130px;
    clip-path: none;
  }

  .image-analyticssample {
    background-image: url('../../../bc/images/dsTypes/SITETRAFFICSAMPLE.png');
    transform: scale(0.6) translateX(-30px);
    width: 107px;
    clip-path: none;
  }

  .image-codahale {
    background-image: url('../../../bc/images/dsTypes/CODAHALE.png');
    transform: scale(0.75);
    width: 77px;
    left: -2px;
    clip-path: none;
  }

  .image-redshift {
    background-image: url('../../../bc/images/dsTypes/int_redshift.svg');
    transform: scale(0.75);
    width: 77px;
    left: -2px;
    clip-path: none;
  }

  .image-coralogix {
    background-image: url('../../../bc/images/dsTypes/Coralogix.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-timestream {
    background-image: url('../../../bc/images/dsTypes/Timestream.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-snowflake {
    background-image: url('../../../bc/images/dsTypes/snowflake.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-mariadb {
    background-image: url('../../../bc/images/dsTypes/MARIADB.jpg');
    top: 7px;
    left: 2px;
    clip-path: none;
  }

  .image-google_analytics {
    background-image: url('../../../bc/images/dsTypes/GOOGLE_ANALYTICS.jpg');
  }

  .image-google_ads,
  .image-google_auctions {
    background-image: url('../../../bc/images/dsTypes/GOOGLE_SERVICE.jpg');
  }

  .image-facebook_ads {
    background-image: url('../../../bc/images/dsTypes/FACEBOOK_ADS.jpg');
  }

  .image-local_file {
    background-image: url('../../../bc/images/dsTypes/LOCAL_FILE.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-s3 {
    background-image: url('../../../bc/images/dsTypes/int_s3.svg');
  }

  .image-aws_cur {
    background-image: url('../../../bc/images/dsTypes/AWS.png');
  }

  .image-aws_usage {
    background-image: url('../../../bc/images/dsTypes/AWS.png');
  }

  .image-athena {
    transform: scale(0.5);
    margin-left: -40px;
    width: 150px;
    background-image: url('../../../bc/images/dsTypes/PARQUET.png');
    clip-path: unset;
  }

  .image-avro {
    background-image: url('../../../bc/images/dsTypes/AVRO.png');
  }

  .image-bigquery {
    background-image: url('../../../bc/images/dsTypes/GOOGLE_BIGQUERY.jpg');
  }

  .image-google_storage {
    background-image: url('../../../bc/images/dsTypes/int_gs.svg');
  }

  .image-oracle {
    background-image: url('../../../bc/images/dsTypes/ORACLE.png');
  }

  .image-mixpanel {
    background-image: url('../../../bc/images/dsTypes/MIXPANEL.jpg');
  }

  .image-rest_api {
    background-image: url('../../../bc/images/dsTypes/REST_API.jpg');
    left: 2px;
  }

  .image-python_sdk {
    background-image: url('../../../bc/images/dsTypes/PYTHON_SDK.jpg');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-mongo_db {
    background-image: url('../../../bc/images/dsTypes/MONGO_DB.jpg');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-influx_db {
    background-image: url('../../../bc/images/dsTypes/INFLUX_DB.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-elasticsearch {
    background-image: url('../../../bc/images/dsTypes/ELASTICSEARCH.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-victoriametrics {
    background-image: url('../../../bc/images/dsTypes/VICTORIA.svg');
    transform: scale(1.6);
    clip-path: none;
  }

  .image-amazon_cloudwatch {
    background-image: url('../../../bc/images/dsTypes/AMAZON_CLOUDWATCH.jpg');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-google_search_console {
    background-image: url('../../../bc/images/dsTypes/GOOGLE_SEARCH_CONSOLE.jpg');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-statsd {
    background-image: url('../../../bc/images/dsTypes/STATSD.jpg');
  }

  .image-anodot_relay {
    background-image: url('../../../bc/images/dsTypes/ANODOT_RELAY.jpg');
  }

  .image-collectd {
    background-image: url('../../../bc/images/dsTypes/COLLECTD.jpg');
    transform: scale(0.95);
    clip-path: none;
  }

  .image-mysql,
  .image-jdbc_agent,
  .image-mysql_agent {
    background-image: url('../../../bc/images/dsTypes/MYSQL_AGENT.jpg');
  }

  .image-sage_agent {
    background-image: url('../../../bc/images/dsTypes/sage.png');
  }

  .image-splunk_agent {
    background-image: url('../../../bc/images/dsTypes/splunk.svg');
    transform: scale(0.8);
    clip-path: none;
  }

  .image-directory_agent {
    background-image: url('../../../bc/images/dsTypes/directory.svg');
    transform: scale(0.8);
    clip-path: none;
  }

  .image-psql,
  .image-psql_agent {
    background-image: url('../../../bc/images/dsTypes/PSQL.jpg');
    transform: scale(0.8);
    top: 7px;
    clip-path: none;
  }

  .image-databricks {
    background-image: url('../../../bc/images/dsTypes/int_databricks.svg');
    transform: scale(0.8);
    top: 7px;
    clip-path: none;
  }

  .image-mssql {
    background-image: url('../../../bc/images/dsTypes/MSSQL.jpg');
    transform: scale(0.8);
    top: 7px;
    clip-path: none;
  }

  .image-prometheus {
    background-image: url('../../../bc/images/dsTypes/PROMETHEUS.jpg');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-mparticle {
    background-image: url('../../../bc/images/dsTypes/MPARTICAL.png');
    transform: scale(0.75) translate(-15px, 22px);
    width: 91px;
    height: 41px;
    clip-path: none;
  }

  .image-apache_kafka {
    background-image: url('../../../bc/images/dsTypes/APACHE_KAFKA.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-segment {
    background-image: url('../../../bc/images/dsTypes/SEGMENT.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-zabbix_agent {
    background-image: url('../../../bc/images/dsTypes/Zabbix.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-coralogix_agent {
    background-image: url('../../../bc/images/dsTypes/Coralogix.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-teradata {
    background-image: url('../../../bc/images/dsTypes/Teradata.png');
    transform: scale(0.85);
    clip-path: none;
  }

  .image-clickhouse {
    background-image: url('../../../bc/images/dsTypes/ClickHouse.png');
    transform: scale(0.8);
    top: 7px;
    clip-path: none;
  }

  .image-cacti {
    background-image: url('../../../bc/images/dsTypes/Cacti.png');
    transform: scale(0.8);
    top: 7px;
    clip-path: none;
  }

  .image-eventhubs {
    background-image: url('../../images/dsTypes/EVENTHUBS.png');
    transform: scale(0.9) translate(-21px, 0);
    width: 100px;
    clip-path: none;
  }

}

.soon-mark {
  position: absolute;
  font-size: 9px;
  width: 130px;
  right: -28px;
  top: 12px;
  height: 20px;
  transform: rotate(30deg);
  text-align: center;
  background-color: $blue6;
  color: $white;
  display: none;
  line-height: 21px;
  font-weight: $font-weight-medium;
}

.logo-section {
  text-align: center;
  margin-bottom: 10px;

  .logo-name {
    text-align: center;
    font-size: $font-size-small;
    margin-top: 3px;
    color: $black1;
  }
}

.name {
  font-size: $font-size-small;
  margin-top: 3px;
  color: $black1;
}

.info {
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  margin-top: 3px;
  color: $gray-lighter5;
  line-height: $font-line-height-short;
}

.soon {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 3px 0 $black-alpha020;

  .info,
  .logo-section {
    opacity: 0.5;
  }

  &:hover {
    box-shadow: 0 3px 8px 0 $black-alpha020;
    background-color: rgba(255, 255, 255, 0.7);
    transition: all 0.175s ease-in-out;

    .action {
      display: none;
    }

    .info,
    .logo-section {
      opacity: 0.7;
    }
  }
}

.upgrade {
  &:hover {
    .action {
      display: block;
      opacity: 1;
    }
  }
}

.type-image {
  background-size: 100%;
  width: 71px;
  height: 73px;
  top: 5px;
  left: 3px;
  position: relative;
  background-repeat: no-repeat;
  //clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}
