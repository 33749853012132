@import '~app/styles/themes/base/variables';

.root {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  padding: 0 90px;
}

.loading-container {
  background: linear-gradient(to bottom, rgba(2, 28, 42, 1), rgba(105, 121, 128, 1));
  align-items: center;
  justify-content: center;
  color: $blue3;
}

.message {
  line-height: 1.2;
  margin-bottom: 25px;
  text-align: center;

  .main-msg {
    font-size: $font-size-h1;
    font-weight: $font-weight-light;
  }

  .sub-msg {
    font-size: 20px;
    font-weight: $font-weight-regular;
  }
}
