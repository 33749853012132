@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';

$gap-between-cols: 12px;
$date-width: 85px;
$duration-width: 60px;
$score-width: 45px;
$severity-width: 75px;

$status-width: 49px;
$right-space-from-table-container: 0;
$table-titles-padding: 0;

$right-space: 130px;

.charts {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.page-title {
  font-size: 30px;
  max-width: 1680px;
  width: 100%;
}

.container {
  min-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.filters {
  width: 388px;
  margin-right: 20px;
  overflow-y: auto;
}

.row-wrapper {
  display: flex;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #f5f8ff;
  }

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: $gap-between-cols;
    flex-shrink: 0;

    &:first-child {
      flex: 0 0 $status-width;
      margin-right: 0;
    }

    &:nth-child(8) {
      // can't be the last child because once the user hove the row we have another child.
      margin-right: 0;
    }
  }
}

.group-sep {
  width: 97%;
  border-top: 1px solid $gray_200;
  left: 1.5%;
  position: relative;
}

.highlighter {
  background-color: #fcd176;
}

.showing {
  font-size: 16px;
  font-weight: bold;
}

.modal-header {
  font-size: $font-size-30;
  padding-bottom: 20px;
}
