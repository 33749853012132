@import '~app/styles/v2/variables';

$size: 60px;

.root {
  height: $size;
  width: $size;
  background-color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $red-500;
    border-radius: 50%;
    font-size: 24px;
    transition: transform ease-in-out 100ms;
  }

  &:hover {
    i {
      transform: scale(1.1);
    }
  }
}

.wrapper {
  font-weight: $font-weight-medium-500;
  line-height: 16px;
  padding: 12px 0;
}

.tooltipTitle {
  color: $red-500;
  padding-bottom: $spacing;
}

.userInfo {
  color: $gray-400;
}
