@import '~app/styles/v2/variables';

.root {
  position: relative;

  :global {
    .leaflet-control-zoom {
      width: 72px;
      height: 40px;
      border-radius: 8px;
      box-shadow: 0 7px 16px 4px rgba(0, 0, 0, 0.15);
      background-color: rgba($white, 0.8);
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    .leaflet-control-zoom-out,
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out:hover,
    .leaflet-control-zoom-in:hover {
      width: 24px;
      height: 24px;
      border-radius: 6px !important;
      color: $white;
      background-color: $gray-500;
      display: flex;
      justify-content: center;
      line-height: 22px;
      font-size: 18px;
      border: none;
    }

    .leaflet-disabled {
      opacity: 0.5;
      color: $white !important;
      background-color: $gray-500 !important;
    }
  }
}

.spinner {
  margin: 40vh 0 0 50%;
}

.map-data-is-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba($white, 0.15);
  z-index: 2;
}
