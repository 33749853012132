@import '~app/styles/v2/variables';

:global {
  #topology-map {
    position: relative;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: $white;
  }

  g.d3-overlay * {
    pointer-events: visiblePainted !important;
    font-family: Roboto, sans-serif;
  }

  .leaflet-control-zoom {
    margin-top: 32px !important;
    margin-right: 38px !important;
  }
}

.hide-svg-defs {
  height: 0;
  width: 0;
}
