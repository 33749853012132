@import '~app/styles/v2/variables.scss';

.navigation-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $gray-300;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 23px;
  margin-bottom: 24px;

  > button {
    border: none;
    font-size: 20px;
    font-weight: 300;
    margin: 0 10px;
    color: $gray-500;
  }
}

.active-tab {
  color: $blue-500 !important;
  font-weight: bold;
}
