@import '~app/styles/themes/base/variables';

$unit-select-height: 50px;
$unit-select-input-height: $unit-select-height - 2px;

.root {
  display: inline-block;
  width: 100%;

  :global {
    .Select-value {
      font-weight: $font-weight-light;
    }
    .Select-placeholder {
      color: $black1;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
    }
    .Select-option {
      font-weight: $font-weight-light;
      &.is-focused {
        background-color: $gray-lightest;
      }
      &.is-selected {
        background-color: $gray-lightest;
        color: $blue;
        font-weight: $font-weight-medium;
      }
    }

    .Select-control {
      border: none;
    }

    .is-open .Select-arrow-zone {
      .Select-arrow {
        border-width: 4px 4px 0;
        border-top-color: $black1;
        top: -2px;
      }
    }
    .Select-arrow {
      border-width: 4px 4px 0;
      border-top-color: $black1;
      top: -2px;
    }

    .Select--single.is-open {
      .Select-control {
        border-radius: 3px;
      }

      .Select-input {
        width: 100% !important;
        left: 0px !important;
        top: 52px !important;
        border: none !important;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        input {
          top: 0 !important;
          padding-right: 10px;
          padding-left: 10px;
          color: $blue;
        }
      }
    }
  }
}
