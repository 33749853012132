@import '~app/styles/v2/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 26px;
  overflow: auto;

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    margin-bottom: 12px;
  }

  .row-container {
    display: flex;
  }

  .column-1 {
    flex: 0 0 240px;
    margin-left: 16px;
  }

  .column-2 {
    flex: 0 0 240px;
  }

  .column-3 {
    flex: 0 0 190px;

    .recipient-button {
      display: none;
      background-color: transparent;
      border: 0;
      font-weight: $font-weight-medium-500;
      color: $blue-500;
      height: 15px;
      position: relative;
      top: -4px;
      white-space: nowrap;
    }

    &:hover {
      .recipient-button {
        display: inline;
      }
    }
  }

  .margin {
    flex: 0.1333333333;
    min-width: 32px;
    max-width: 64px;
  }
}

mark {
  background-color: $orange-200 !important;
}

.tags-modal {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: space-between;
  align-items: center;
}

.recipients-modal {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .top-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    .recipients-container {
      column-width: 173px;
      column-gap: 16px;
      width: 100%;
    }
  }
}

.modal-header {
  color: $gray-600;
  font-size: $font-size-20;
  font-weight: $font-weight-medium-500;
  margin-bottom: 26px;
  width: 100%;
}

.description {
  max-height: 110px;
  overflow: hidden;

  .description-button {
    display: none;
    background-color: transparent;
    border: 0;
    font-weight: $font-weight-medium-500;
    color: $blue-500;
    height: 17px;
    position: relative;
    top: -5px;
  }

  &:hover {
    .description-button {
      display: inline;
    }
  }
}

.dashboard-link {
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    white-space: nowrap;
    color: $blue-500 !important;
  }
}
