@use "sass:math";
@import '~app/styles/v2/variables.scss';
$switch-width: 31px;
$switch-height: 9px;
$switch-color-on: $blue-500;
$switch-color-off: $gray-300;
$switch-handle-size: 16px;
$switch-handle-color-on: $indigo-500;
$switch-handle-color-off: $gray-400;

.toggle {
  display: none;
  position: relative;

  + label {
    display: inline-block;
    height: $switch-height;
    width: $switch-width;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      background-color: $switch-color-off;
      border-radius: math.div($switch-height, 2);
      height: 100%;
      width: 100%;
      transition: background-color 0.25s;
    }

    &::after {
      content: '';
      display: inline-block;
      border-radius: 50%;
      height: $switch-handle-size;
      width: $switch-handle-size;
      background-color: $switch-handle-color-off;
      position: absolute;
      top: 3px;
      left: 0;
      transition: left 0.25s;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &:checked + label {
    &::before {
      background-color: $switch-color-on;
    }

    &::after {
      background-color: $switch-handle-color-on;
      left: 15px;
    }
  }

  &:disabled + label {
    opacity: 0.5;
    cursor: not-allowed;

    &::before {
      background-color: $switch-color-off;
    }
  }

  &.readonly:disabled + label {
    opacity: 1;

    &::before {
      background-color: $switch-color-on;
    }
  }
}
