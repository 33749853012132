@import '~app/styles/v2/variables.scss';

.root {
  display: inline-block;
  color: $gray-500;

  input[type='checkbox'] {
    display: none;
  }

  /* to hide the checkbox itself */
  input[type='checkbox'] + label::before {
    font-family: andt;
    display: inline-block;
  }

  label {
    margin: 0;
    padding: 0;
    line-height: 1;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    align-items: center;

    .label-text {
      padding-left: 5px;
    }

    mark {
      background-color: $orange-200 !important;
      padding: 0;
    }
  }
  // '\E951' - half
  // '\E935' - empty
  // '\E936' - checked

  /* unchecked */
  input[type='checkbox'] + label::before {
    content: '\e935';
    font-size: 16px;
    vertical-align: middle;
    color: $gray-500;
  }

  /* space between checkbox and label */

  /* checked */
  input[type='checkbox']:checked + label::before {
    content: '\e936';
    font-size: 16px;
    color: $blue-500;
    vertical-align: middle;
  }

  /* allow space for check mark */

  input[type='checkbox']:disabled + label {
    color: $gray-300;
  }

  input[type='checkbox']:disabled + label::before {
    color: $gray-300;
  }

  /* disabled state to checkbox */
}

.root.full-checked {
  input[type='checkbox']:checked + label::before {
    content: '\e936';
  }
}

.root.half-checked {
  input[type='checkbox']:checked + label::before {
    content: '\E951';
  }
}

.root.disabled {
  input {
    pointer-events: none;
  }

  label {
    pointer-events: none;
  }
}
