.root {
  width: 330px;

  .filters-row {
    width: 100%;
    margin-bottom: 20px;

    &.mid {
      width: 208px;
    }

    :global {
      .andt-dropdown-control {
        height: 36px;
        line-height: 36px;
      }
    }
  }
}
