@import '~app/styles/themes/base/variables';

$col-min-width: 150px;

.root {
  overflow-y: auto;
}

.table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}

.td-no-time {
  height: 1px;
  padding-top: 0;
  margin-top: -1px;
}

.td-time {
  height: 120px;
  vertical-align: top;
  padding: 0 2px 10px;

  &.td-timedefenision {
    padding-top: 27px;
  }

  &.td-timestamp-type {
    padding-left: 36px;
    padding-right: 65px;
    align-items: center;
    flex-direction: column;
    display: flex;
  }
}

.td-diametrics {
  border-top: 1px solid $blue1;
  border-left: 1px solid $blue1;
  height: 100%;
}

.left-root {
  position: relative;

  .spacer {
    position: absolute;
    right: -1px;
    width: 4px;
    height: 100%;
    top: 0;
    border-right: 1px solid #0d90c7;
    border-left: 1px solid #0d90c7;
  }
}

.col {
  width: 100%;
  height: 100%;
  padding: 0 50px 0 25px;
}

.col-unassigned {
  composes: col;
  min-width: $col-min-width;
}

.col-time {
  composes: col;
  height: 100px;
  min-width: $col-min-width;
}

.col-diametrics {
  composes: col;
  min-width: $col-min-width;
  padding-top: 30px;
}

.title {
  font-size: $font-size-h4;
  color: $white;
  font-weight: 300;
  padding-left: 10px;
}

.av-fields-title {
  margin-bottom: 3px;
}

.search-wrapper {
  padding: 0 15px 0 10px;
}

:global {
  .grabbing {
    user-select: none;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}
