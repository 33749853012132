@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';

.container {
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-sources {
  margin-top: 6px;
  font-size: $font-size-18;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
}

.change-filters {
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  color: $gray-400;
}
