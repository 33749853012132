@import 'variables';


.modal-backdrop.in {
  opacity: 0.26 !important;
}

.bc.modal-dialog {
  border-radius: 3px;

  &.modal-sm {
    width: 400px;
  }

  &.modal-lg {
    width: 750px;
  }

  &.modal-xlg {
    width: 900px;
  }

  &.modal-p80 {
    width: 80%;
  }

  .modal-content {
    border-radius: 3px;

    .bc-modal-header {
      padding: 0;
      display: flex;
      padding-top: 25px;
      padding-left: 30px;

      .modal-title {
        width: 100%;
        font-size: 28px;
        color: $black1;
        font-weight: $font-weight-light;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;

        i {
          position: relative;
          top: 2px;
          font-size: 14px;
        }
      }
    }

    .modal-body {
      padding-top: 20px;
      padding-bottom: 55px;
      min-height: initial;
      font-size: $font-size-large;
      font-weight: $font-weight-light;
      color: $black1;

      .modal-body-message {
        width: 100%;
        text-align: left;

        .instruction-list {
          margin: 20px 0 0 -20px;
        }
      }
    }

    .modal-footer {
      background-color: transparent;
      border: none;
      padding: 0;
      padding-bottom: 15px;
      padding-right: 6px;

      .btn {
        min-width: 80px;

        &.btn-primary {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }

  &.overflow-override {
    .modal-content {
      overflow: visible;
    }
  }

  &.modal-body-right-zero {
    .modal-body {
      padding-right: 0;
    }
  }

  &.bc-view-modal {
    width: 1100px;
    border-radius: 8px;

    .modal-content {
      border-radius: 8px;
    }
  }
}
