@import '~app/styles/themes/base/variables';

.filtered-concepts {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    max-width: 5px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $blue-light2;
  }
}

.group-button-icon {
  position: absolute;
  bottom: 1px;
  right: 3px;
}

.highlight {
  color: $blue;
}

.item-name {
  flex: 1 0 0;
}
