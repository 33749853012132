:global {
  .email-digest-json-modal {
    width: 955px !important;
  }
}

.main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 400px;
}

.download-report-button {
  width: 310px;
  display: flex;
  justify-content: flex-end;
}

.modal-wrapper {
  padding: 20px;

  h3 {
    font-weight: 400;

    span {
      font-weight: 500;
    }
  }

  textarea {
    width: 100%;
    height: 625px;
    padding: 10px;
    line-height: 23px;
    outline: none;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ababab;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #ababab;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: #ababab;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #ababab;
  }

  .send-btn-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 10px;
  }

  .get-champion-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 36px;
  }
}
