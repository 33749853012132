@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.buttons-container {
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;

  & > button:first-child {
    margin-left: 0;
  }

  & > button {
    margin-left: $btn-margin-right;
    width: $btn-yes-no-width;
    padding-right: 0;
    padding-left: 0;
  }
}

.input-container {
  display: flex;
  justify-content: flex-end;
  width: 95px;
}

.form-container {
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
}

.sub-title {
  @include sub-title();

  display: flex;
  align-items: center;
  margin: 2px 0;
}
