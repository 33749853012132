@import '~app/styles/v2/variables.scss';

.select-items-wrapper {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 40px;
}

.action-bar-position {
  position: absolute;
  bottom: 0;
}

.dark-green-btn {
  padding: 4px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: rgba($eucaliptus-500, 0.1);

  span {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $eucaliptus-500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 3px;
  }

  i {
    font-size: 12px;
    margin-top: 5px;
    color: $eucaliptus-500;
    margin-left: 4px;
  }
}
