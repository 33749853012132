@import '~app/styles/themes/base/variables';

$transition: border-color 0.2s linear;


.bc {
  .filters-view {
  }

  input[type='text'],
  input[type='number'],
  input[type='password'] {
    height: 48px;
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    align-items: center;
    padding: 5px 15px;
    color: $blue;
    transition: $transition;
    background-color: transparent;
    font-size: $font-size-large;
    font-weight: 300;

    &::selection {
      background-color: #eee;
    }

    &:placeholder-shown {
      font-size: $font-size-base;
    }

    &::placeholder {
      color: $gray-lighter6;
      font-weight: 300;
      font-size: $font-size-small;
      font-style: italic !important;
      text-align: left;
      vertical-align: middle;
    }

    &:focus {
      border-color: $blue;

      &.invalid {
        border-color: $brand-danger;
      }
    }

    &.dark-input {
      border-color: $gray-dark2;
      color: $white1;

      &::placeholder {
        color: $gray-lighter5;
        font-style: normal;
        font-weight: 300;
        vertical-align: middle;
      }

      &:focus {
        border-color: $white;

        &.invalid {
          border-color: $brand-danger;
        }
      }
    }

    &.light-placeholder {
      &::placeholder {
        color: $gray-lighter3;
        font-size: $font-size-base;
        font-style: normal !important;
        font-weight: 300;
        text-align: left;
        vertical-align: middle;
      }
    }

    &.invalid {
      border-color: $brand-danger;
    }
  }

  input[type='number'] {
    padding: 5px 0 5px 7px;
  }

  input[type='radio'] {
    width: initial;
  }

  input[type='password'] {
    font-size: 30px;

    &:placeholder-shown {
      font-size: $font-size-base;
    }
  }

  input[readonly] {
    -webkit-text-fill-color: #b4b4b4; /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
    color: #b4b4b4;
  }
}
