@import '~app/styles/themes/base/variables';

.property {
  font-size: $font-size-h1;
  color: $blue5;
  font-weight: $font-weight-light;
  margin-left: -3px;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.what-property {
    color: $green-dark;
  }
}

.action {
  font-size: $font-size-large;
  color: $gray-dark2;
  font-weight: $font-weight-medium;
  position: absolute;
  transform: translateY(-6px);
}

.transformRow {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}

.removeTransformRowButton {
  position: absolute !important;
  right: 0;
}

.footer {
  padding-right: 20px !important;
  padding-bottom: 20px !important;
}

.transformInner {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}

.transformFuncDdl,
.columnDdl {
  height: 48px;
  width: 200px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  flex-shrink: 0;

  button {
    height: 48px !important;
  }

  ul li a {
    padding: 0 0 0 15px;

    div {
      padding: 10px 15px 10px 0;
    }
  }
}

.columnDdl {
  margin-right: 0;
  width: 207px !important;
}

.transformInput {
  margin-right: 10px;
  width: 200px;

  &[disabled] {
    cursor: not-allowed;
  }

  height: 54px !important;
}

.transformInputInvalid {
  border-color: $brand-danger !important;
}

.readOnlyTransform {
  .transformFuncDdl {
    pointer-events: none;
  }
}

.newColumnNameInput {
  flex-grow: 1;
  margin-right: 46px;
  height: 54px !important;
}

.newColumnValueInput {
  width: 100%;
  margin-right: 46px;
}

.addColumnButtonRow {
  width: 48px;
  margin-left: 210px;
}

.joinWrapper {
  display: flex;
  width: 100%;

  .delimeterInput {
    width: 200px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .singleColumnRoot {
    display: flex;
  }
}

.invisible {
  visibility: hidden;
  pointer-events: none;
}

.hidden {
  display: none;
}

.plusIcon {
  position: relative;
  top: 2px;
  color: $blue;
  font-size: 22px;

  &.disabled {
    color: $gray-lighter6;
  }
}

.xIcon {
  position: relative;
  top: 2px;
  color: $blue;
  font-size: 18px;

  &.disabled {
    color: $gray-lighter6;
  }
}

.transformBtn {
  height: 38px;
}

.newMeasureColumn {
  .joinWrapper {
    margin-left: 211px;
  }

  input[type='text'] {
    color: $green-dark !important;

    &:focus {
      border-color: $green-dark !important;
    }
  }
}

.aggregation-ddl {
  margin-top: 10px;
  max-width: 200px !important;

  &.in-modal {
    margin-right: 45px;
  }
}

.deviderWrapper {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  left: -48px;
  margin-right: -48px;
  position: relative;
}

.smallDevider {
  margin: 0 auto;
  font-size: 18px;
  color: #82bd36;
}

.bigDevider {
  margin: 0 auto;
  font-size: 26px;
  color: #82bd36;
}

.dropdown-select {
  width: 200px;
}

:global {
  .transform-function-form-dropdown-option-header {
    font-weight: 300;
    font-size: 12px;
    height: 20px;
    padding-top: 5px;
    color: #9b9b9b;
    letter-spacing: 0.2px;
  }
}
