@import '~app/styles/v2/variables';

.modal-body {
  max-height: 650px;

  .itemContainer {
    margin-bottom: 24px;

    .textWrapper {
      font-weight: $font-weight-medium-500;
      font-size: $font-size-14;
      color: $gray-400;
      margin-top: 7px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    padding-top: 16px;

    .left-button {
      flex: 1 0 0;
    }
  }
}
