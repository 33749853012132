/* ---- COLORS ---- */

/* -- primary colors -- */

$azure-500: rgb(0, 183, 241);
$indigo-500: rgb(23, 64, 111);
$blue-500: rgb(38, 113, 255);
$black-500: rgb(31, 41, 46);
$white: rgb(255, 255, 255);
$indigo-400: rgb(63, 81, 182);
$indigo-300: rgb(86, 115, 144);
$blue-300: rgb(201, 219, 255);
$blue-100: rgb(233, 240, 255);

/* -- system colors -- */

/* -- red colors -- */
$red-100: rgb(250, 231, 235);
$red-200: rgb(250, 203, 213);
$red-300: rgb(237, 159, 175);
$red-400: rgb(222, 87, 115);
$red-500: rgb(209, 15, 55);

$orange-500: rgb(235, 111, 7);
$orange-200: rgb(255, 208, 165);

$yellow-100: rgb(255, 245, 221);
$yellow-200: rgb(255, 232, 178);
$yellow-500: rgb(244, 185, 45);
$yellow-700: rgb(212, 146, 0);

$green-500: rgb(65, 202, 90);

/* -- gray colors -- */

$gray-100: rgb(244, 247, 251);
$gray-200: rgb(233, 233, 240);
$gray-300: rgb(212, 217, 224);
$gray-350: rgb(181, 187, 195);
$gray-400: rgb(137, 149, 160);
$gray-500: rgb(61, 76, 89); //default text color
$gray-600: rgb(39, 54, 62);

/* -- Data (Graphs) Colors -- */

$rose-500: rgb(233, 30, 99);
$rose-400: rgb(240, 98, 146);

$tomato-500: rgb(255, 86, 83);
$tomato-400: rgb(255, 119, 119);

$lime-500: rgb(124, 179, 66);
$lime-400: rgb(175, 213, 128);

$mint-600: rgb(26, 137, 106);
$mint-500: rgb(26, 227, 149);

$eucaliptus-600: rgb(0, 100, 53);
$eucaliptus-500: rgb(0, 147, 78);
$eucaliptus-200: rgb(193, 233, 201);

$teal-500: rgb(0, 187, 212);
$teal-400: rgb(128, 222, 234);

$lilach-600: rgb(140, 13, 175);
$lilach-500: rgb(200, 60, 238);
$lilach-400: rgb(208, 131, 232);

$purple-600: rgb(104, 38, 171);
$purple-500: rgb(137, 71, 205);
$purple-200: rgb(225, 209, 242);

$brown-500: rgb(141, 86, 53);
$brown-400: rgb(191, 133, 99);

$navyblue-500: rgb(31, 44, 156);

/* -- special colors (not in color schema -- */

$blue-500-10percent: rgba(245, 248, 255, 1);

/* ---- FONTS ---- */

/* -- Weights -- */

$font-weight-light-300: 300;
$font-weight-regular-400: 400;
$font-weight-medium-500: 500;
$font-weight-bold-700: 700;
$font-weight-black-900: 900;

/* -- sizes -- */

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-36: 36px;

/* -- spacing -- */

$spacing: 4px;
$spacing-2: $spacing * 2;
$spacing-3: $spacing * 3;
$spacing-4: $spacing * 4;
$spacing-5: $spacing * 5;
$spacing-6: $spacing * 6;
$spacing-7: $spacing * 7;

/* -- Navbar -- */

$navbar-bg: $white; //in use - appNavBar
$navbar-bg-active: $white; //in use - appNavBar button on hover
$navbar-icon-color: $gray-500;
$navbar-icon-color-under: #cedeee;
$navbar-icon-color-hover: $blue-500;
$navbar-menu-item-color: $gray-500;
$navbar-menu-item-color-active: $blue-500;
$navbar-menu-item-bg-active: $blue-100;
$navbar-menu-active-color: $blue-500;
$navbar-menu-hover-color: #0dc4ff;
$navbar-shadow: 0 $spacing 14px -1px rgba(0, 0, 0, 0.15);
$navbar-separator-color: #737373;
$navbar-submenu-title-color: $gray-350;
