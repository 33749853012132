@import '~app/styles/v2/variables.scss';

:global {
  body.modal-open > .page-layout {
    filter: blur(3px);
  }

  .andt-modal-v2 {
    width: 600px;
    filter: blur(0);

    &.centered {
      height: calc(100vh - 60px);
      display: flex;
      align-items: center;
      justify-content: center;

      .modal-content {
        flex-grow: 1;
      }
    }

    //modal sizes
    &.size-small {
      width: 400px;
    }

    &.size-medium {
      width: 600px;
    }

    &.size-large {
      width: 800px;
    }

    &.size-xlarge {
      width: 1000px;
    }

    &.size-xxlarge {
      width: 1200px;
    }

    &.size-maxwidth {
      width: 95vw;
    }

    &.modal-hidden {
      transition: none;

      .modal-content {
        height: 0;
        margin-top: 100px;
      }
    }

    &.overflowVisible {
      .modal-content {
        overflow: visible;
      }
    }
  }
}

.content-root {
  padding: 24px 24px 16px 24px;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.4;
  font-size: 20px;
  color: $gray-500;
  cursor: pointer;
}
