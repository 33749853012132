@import '~app/styles/v2/variables';

.container {
  height: 38px;
  padding: 12px;
  background-color: $gray-200;
  border-radius: 6px;
  color: $blue-500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pristine {
    color: $gray-500;
    opacity: 0.7;
  }

  .label {
    flex-grow: 1;
    font-size: $font-size-16;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ico {
    flex-shrink: 0;
    color: $gray-500;
  }
}

.container.blue-style {
  color: white;
  width: 180px;
  background-color: $blue-500;

  :global {
    .icon {
      color: $white;
    }
  }

  &.pristine {
    background-color: $gray-200;

    :global {
      .icon {
        color: $gray-500;
      }
    }
  }

  .pristine {
    color: $gray-500;
  }
}

.container.blue-lean {
  color: $blue-500;
  background-color: $blue-100;
  padding: 0 4px;
  line-height: 24px;
  height: 24px;

  .pristine {
    color: $gray-400;
  }

  .ico {
    color: $blue-500;
  }
}

.container.full {
  width: 100%;
}
