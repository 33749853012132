@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';
@import './alertsListVariables.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.row-wrapper {
  display: flex;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: $blue-100 !important;
  }

  &.open {
    background-color: $blue-100 !important;
  }

  &.markAsRead {
    background-color: $gray-100 !important;

    &:hover {
      background-color: $blue-100 !important;
    }
  }

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: $gap-between-cols;
    flex-shrink: 0;

    &:first-child {
      flex: 0 0 $status-width;
      margin-right: 0;
    }

    &:nth-child(8) {
      // can't be the last child because once the user hove the row we have another child.
      margin-right: 0;
    }
  }
}

.group-sep {
  width: 97%;
  border-top: 1px solid $gray_200;
  left: 1.5%;
  position: relative;
}

.highlighter {
  background-color: #fcd176;
}
