@import '~app/styles/v2/variables';

.root {
  display: block;
  width: 285px;
  border-radius: 4px;
  background-color: $white;
  border: 2px solid $gray-200;
  position: relative;
  padding: 18px 20px 0 20px;
}

.filters-row {
  width: 100%;
  margin-bottom: 20px;
}

.filters-row-header {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  display: block;
}

.option-wrap,
.option-wrap-selected {
  position: relative;
  display: flex;
  height: 30px;
  margin-top: 4px;

  .logo {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    top: -18px;
    left: -6px;

    svg {
      transform: scale(0.5);
      position: absolute;
    }
  }

  .title {
    left: 6px;
    top: -10px;
    color: $gray-500;
    position: relative;
  }
}

.option-wrap-selected {
  color: $blue-500;
}
