/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 121px;

.body {
  @include body;

  height: $widgetHeight;

  &.gray {
    @include gray;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }

  .header {
    @include widget-title;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  .legend {
    &.positive {
      .number {
        color: $blue-500;
      }
    }

    .number {
      display: block;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $indigo-500;
    }
  }
}
/* stylelint-enable */
