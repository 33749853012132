@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';

$user-row-height: 49px;

.table {
  height: 100%;
}

.table-header {
  display: flex;
  flex-shrink: 0;
  margin: 0 10px 0 6px;
  font-weight: $font-weight-medium-500;
  height: $user-row-height;
}

.table-body {
  overflow: auto;
  height: calc(100% - 82px);
}

.table-row {
  margin: 0 2px;
  flex-shrink: 0;
}

.table-row-header {
  display: flex;
  cursor: pointer;
  max-height: 100px;
  padding: 5px;
  align-items: center;
  height: $user-row-height;
}

.col-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-checkbox {
  flex: 0 0 42px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.col-avatar {
  flex: 0 0 32px;
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.col-name {
  flex: 1 0 140px;
  justify-content: left;
  margin-right: 20px;
  font-weight: $font-weight-medium-500;
}

.col-role {
  flex: 0 0 110px;
  justify-content: left;
  margin-right: 20px;
}

.col-organization {
  flex: 0 1 200px;
  justify-content: left;
  margin-right: 20px;
  min-width: 130px;
}

.col-groups {
  flex: 0 1 200px;
  justify-content: left;
  margin-right: 20px;
  display: flex;
  min-width: 130px;
}

.def-group-name {
  max-width: 160px;
}

.col-email {
  flex: 0 1 200px;
  justify-content: left;
  margin-right: 20px;
  min-width: 130px;
}

.col-status {
  flex: 0 0 70px;
  justify-content: left;
}

.col-lastActive {
  flex: 0 0 100px;
  justify-content: left;
}

.user-disabled {
  .col-name,
  .col-role,
  .col-email,
  .col-organization,
  .col-groups,
  .col-lastActive,
  .col-status {
    opacity: 0.6;
  }
}

.add-group-ddl {
  color: $gray-400;
  font-size: $font-size-14;
  height: 49px;
  display: flex;
  align-items: center;
  position: relative;
  top: -1px;

  i {
    margin-right: 1px;
    margin-top: 2px;
  }
}

.user-no-groups {
  visibility: hidden;
}

.table-row-header:hover {
  .user-no-groups {
    visibility: visible;
  }
}

.enforce-visible {
  visibility: visible;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
