@import '~app/styles/themes/base/layout';

.main-container {
  @include shell-col;

  overflow: auto;

  /* margin-left: 60px; */

  .sub-header {
    padding-left: 40px;
  }
}

.events-wrapper {
  flex-shrink: 0;
  background: #fff;
  border: 1px solid #cecece;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  width: 800px;
  display: block;
  margin: 0 auto;
  border-radius: 3px;
  color: #2f2f2f;
}
