@import '~app/styles/themes/base/variables';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: solid 1px $gray-200;
  background-color: $white-500;
  margin-bottom: 5px;
  cursor: pointer;
}

.noOpen {
  cursor: default;
}

//.loader {
//}

.arrows {
  display: flex;
  justify-content: center;
  width: 48px;
}

.titles {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px 0 0;

  > div {
    text-align: left;
    font-weight: 500;
    padding: 0 12px;

    &:first-child {
      width: 825px !important;
      margin-right: 24px;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.left {
  > div {
    &:last-child {
      text-align: left;
    }
  }
}
