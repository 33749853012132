@import '~app/styles/v2/variables';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: rgba($white, 0.6);
  opacity: 0;
  animation-name: modal-loading-open;
  animation-duration: 1s;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@keyframes modal-loading-open {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
