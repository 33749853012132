@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';

@mixin acknowledged {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 19px;
  height: 19px;
  font-size: 10px;
  font-weight: 500;
  color: $gray-600;
  border: $gray-600 2px solid;
  border-radius: 50%;
}

.ack-action {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-icon {
    top: 1px;
    color: $gray-500;
    font-size: 20px;
    position: relative;

    &:hover {
      color: $blue-500;
    }
  }
}

.ack-indicator {
  display: flex;
  align-items: center;

  .wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    background: $blue-100;
    color: $blue-500;
    padding: 0 5px 0 3px;
    border-radius: 2px;
    height: 16px;
  }

  .checked-icon {
    font-size: 15px;
  }
}

.read-only-user {
  cursor: default;
  opacity: 0.5;
}
