@import "~app/styles/themes/base/variables";

$critical: red;
$high: orange;
$medium: yellow;
$low: green;
$info: #1697BE;


.severity-critical{
  background-color: $critical;
}

.severity-high {
  background-color: $high;
}

.severity-medium {
  background-color: $medium;
}

.severity-low {
  background-color: $low;
}

.severity-info {
  background-color: $info;
}


