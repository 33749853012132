@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/mixins';

.left-container {
  flex-basis: 30%;
  max-width: 400px;
  min-width: 300px;
}

.middle-container {
  flex-basis: 40%;
  min-width: 410px;
  overflow-y: auto;
  margin-bottom: 50px;
  padding-bottom: 0 !important;
}

.kinesis-select-block {
  margin-bottom: 24px;
  position: relative;

  & > input {
    margin-bottom: 20px;
    height: 46px;
    width: 100%;
    display: block;
  }

  .input-title {
    font-size: $font-size-large;
    font-weight: $font-weight-regular;
    display: block;
    color: $black1;
    margin-bottom: 5px;
  }

  .input-description {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    display: block;
    color: $black1;
    margin-bottom: 1px;
    line-height: $font-line-height-short;
  }
}

.record-title {
  font-weight: bold;
  font-size: $font-size-small;
  color: $black1;
  padding-right: 5px;
}

.record-data {
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  color: $black1;
}

.advance-root {
  margin-right: 18px;

  .remove-item {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .path-wrapper {
    width: 80%;
    margin-bottom: 20px;

    & > input {
      width: 100%;
    }
  }

  .values-wrapper {
    display: flex;

    .operator-wrapper {
      margin-bottom: 20px;
      margin-right: 12px;
      flex-basis: 100px;
      flex-shrink: 0;
    }

    .value-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .seperator {
    height: 1px;
    width: 100%;
    background-color: $gray-lighter3;
  }

  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .add-path-wrapper {
      color: $blue-500;
      font-size: 16px;
      cursor: pointer;

      span {
        margin-left: 5px;
      }
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
