@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.main {
  border: 1px solid $gray-lighter5;
  border-radius: 3px;
  padding: 7px;
  position: relative;
  margin-top: 7px;
}

.title {
  font-weight: 300;
  color: $white1;
  font-size: $font-size-large;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 1.2;

  &.disabled {
    opacity: 0.5;
  }
}

.sub-title {
  @include sub-title();

  margin-top: 5px;
}

.close-btn {
  position: absolute !important;
  top: -5px;
  right: -6px;
  font-size: 13px !important;
  color: $gray-lighter3;
}

.remove-condition {
  cursor: pointer;
  color: $gray-400;

  &:hover {
    color: #fff;
  }
}
