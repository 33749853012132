@import '~app/styles/themes/base/variables';

:global {
  .bc.modal-dialog.sql-query-modal {
    width: 850px;

    #query-preview-table-container > div {
      margin-left: 0 !important;
    }

    .modal-content {
      .modal-body {
        padding-top: 10px;
      }

      .modal-footer {
        display: block;
        padding-right: 30px;
        padding-left: 30px;
        position: relative;

        .btn.btn-flat {
          position: absolute;
          left: 30px;
          padding: 0 10px;
          display: flex;
          align-items: center;
        }

        .icn-view-icon {
          font-size: 34px;
          padding-right: 10px;
          transform: translateY(2px);
        }
      }

      i.ion-load-c {
        margin-right: 10px;
      }
    }
  }
}

.confirm-modal {
  margin-top: 145px !important;
}

.root {
  width: 100%;
  min-height: 550px;
}

.editor-wrapper {
  //border: 1px solid black;
}

.actions-wrapper {
  text-align: right;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .btn-preview-wrap {
    display: flex;
    align-items: center;

    .dirty-msg {
      margin-right: 10px;
      color: $brand-danger;
      font-size: $font-size-small;
    }
  }
}

.info-wrapper {
  height: 280px;

  .tabs-block {
    border-bottom: 1px solid #8995a0;
    margin-bottom: 18px;

    .tab {
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      margin-right: 24px;
      padding-bottom: 5px;

      &.active {
        color: #2671ff;
        border-bottom: 3px solid #2671ff;
      }
    }
  }

  .header {
    font-weight: $font-weight-light;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .guidelines-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 250px;
    overflow: auto;
  }

  .items-wrapper {
    color: #3d4c59;
    font-size: 14px;
    display: flex;
    flex-basis: 33%;
    padding-right: 20px;
    margin-bottom: 20px;
  }

  .item-num {
    font-size: 16px;
    flex-basis: 21px;
    width: 21px;
    height: 21px;
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    border: 1px solid #2671ff;
    color: #2671ff;
    border-radius: 50%;
    padding-left: 5px;
    line-height: 19px;
  }

  .item-text {
    padding-left: 11px;
  }
}

.errors-container {
  border: 1px solid $brand-danger;
  padding: 5px;
  border-radius: 5px;
}

.info-wrap {
  margin-left: 50%;
  margin-top: 25px;
}

.info {
  display: inline-block;
  font-size: 14px;
  border-radius: 6px;
  background-color: #f4f7fb;
  padding: 10px 25px;
  position: fixed;
  left: 50%;
  margin-top: 25px;
  transform: translate(-50%, -50%);
}

.error {
  color: $brand-danger;
}

.query {
  overflow: auto;
  height: 250px;
  margin-bottom: 20px;
  font-family: monospace;
}

.table {
  display: flex;
  flex: 1 0 0;
  overflow: auto;
  min-height: 150px;
  border: 1px solid #f4f7fb;

  &.no-results {
    align-items: center;
    flex-direction: column;
  }

  :global {
    .fixedDataTable {
      .main {
        margin-left: 0 !important;
      }
    }

    .public_fixedDataTable_main {
      font-size: 12px;
    }

    .public_fixedDataTable_header {
      .fixedDataTableCellLayout_main {
        background-color: $white;
        font-weight: 500;
        background-image: none;
      }

      .fixedDataTableCellGroupLayout_cellGroupWrapper {
        background-color: $white;
      }
    }

    .public_fixedDataTableCell_cellContent {
      top: 0;
      padding: 0 2px;
    }

    .fixedDataTableCellLayout_main {
      border-left: 1px solid $blue-light;
    }

    //scrollbars

    /*
    .public_Scrollbar_main,
    .public_Scrollbar_main.public_Scrollbar_mainActive {
      border-left: none;
    }

    .ScrollbarLayout_mainHorizontal {
      border-left: none;
      height: 15px !important;
      .ScrollbarLayout_faceHorizontal {
        left: -2px;
      }
      .ScrollbarLayout_faceHorizontal:after {
        background-color: $blue-light;
        bottom: 4px;
        top: 7px;
      }
    }

    .public_Scrollbar_mainActive,
    .ScrollbarLayout_mainHorizontal:hover {
      .ScrollbarLayout_faceHorizontal:after {
        background-color: $blue-light;
        bottom: 4px;
        top: 7px;
      }
    }

    .ScrollbarLayout_mainVertical {
      .ScrollbarLayout_faceVertical:after {
        background-color: $blue-light;
        right: 0px;
        left: 11px;
      }
    }

    .ScrollbarLayout_mainVertical:hover {
      .ScrollbarLayout_faceVertical:after {
        background-color: $blue-light;
        right: 0px;
        left: 11px;
      }
    }
    */
  }
}
