@import '~app/styles/v2/variables';

.modal-content {
  button {
    float: right;
    margin-bottom: 24px;
  }
}

.modal-recipients {
  h5 {
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
  }

  p {
    font-size: $font-size-14;
    font-weight: $font-weight-regular-400;
  }

  .modal-recipients-buttons {
    float: right;
    margin-bottom: 20px;

    button {
      margin-left: 12px;
    }
  }
}

.delete-button {
  margin-top: 2px;
}

.assign-owner-modal {
  width: 310px;
}
