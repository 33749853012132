@import './../../../app/styles/themes/base/variables';

.root {
  display: flex;
  border-radius: 3px;
  border: 1px solid #c3c9d0;
  height: 48px;
  margin-bottom: 10px;
  padding: 6px;
  color: $black1;
  width: calc(100% - 2px);
  margin-left: 1px;

  &:hover {
    background-color: #f2fbfe;
    border: 1px solid #8995a0;
  }
}

.type-image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  background-position: center;
}

.stream-name-wrapper {
  padding-left: 10px;
  justify-content: center;
  padding-bottom: 0;
  overflow: hidden;

  .stream-name {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
  }

  .running {
    cursor: pointer;

    &:hover {
      //text-decoration: isScrolling;
      color: $blue4;
    }
  }
}

.dim-reduce {
  color: $gray_300;
  margin-right: 20px;
  margin-top: 10px;
}

.stream-exec-report {
  margin: 5px 50px 0 0;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
}

.stream-status-wrapper {
  display: flex;
  align-items: center;
  width: 115px;

  i {
    flex-shrink: 0;
  }

  .stream-status-text {
    display: inline-flex;
    color: $gray-lighter4;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    height: 100%;
    vertical-align: top;
    align-items: center;
    padding: 0 0 0 10px;
  }
}

.status-icon {
  font-size: 25px;
  text-align: center;
  align-self: center;
}

.sun {
  color: $orange-sun;
}

.moon {
  color: $blue-moon;
}

.initializing {
  color: $green-init;
}

.incomplete {
  color: $orange-incomplete;
}

.error {
  color: $red-fail;
}

.complete {
  color: $blue6;
}

.more-dropdown {
  :global {
    position: relative;

    .dropdown {
      top: -6px;
      left: 6px;
    }
  }
}

.hidden-file {
  display: none;
}
