@import '~app/styles/themes/base/variables';

.dropdown-width {
  max-width: 215px !important;
}

.root {
  max-width: 340px !important;

  .button-wrap {
    margin-top: 20px;
  }

  .input-description {
    font-size: $font-size-small;
    font-weight: $brand-danger;
    display: inline-block;
    color: $black1;
    height: 165px;
    margin-right: 20px;
    overflow-x: auto;
  }

  .error {
    height: 100px;
    color: $brand-danger;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    margin-top: 10px;
  }
}
