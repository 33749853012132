@import '~app/styles/themes/base/variables';
// This is a duplication of the definitions in SelectAndtStyling
.autocomplete-wrapper {
  position: relative;
}

.autocomplete-selection {
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: auto;
  display: block;
  padding: 1px;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.175);
  min-height: 0;
  max-height: 330px;

  li {
    cursor: pointer;
    min-width: 100px;
    font-size: 16px;
    font-weight: 400;
    color: $gray_500;
    padding: 6px 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 0 3px 4px;
    width: calc(100% - 8px);
    border-radius: 4px;
    height: 32px;
    line-height: 32px;
    background-color: $white;

    &:hover {
      background-color: rgba(38, 113, 255, 0.05);
    }
  }

  .active {
    background-color: rgba(38, 113, 255, 0.05);
  }
}
