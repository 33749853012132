@import '~app/styles/v2/variables';

@mixin tooltip {
  content: attr(title);
  display: block;
  position: absolute;
  top: -20px;
  background-color: $gray-400;
  color: $white;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px;
  white-space: nowrap;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}

@mixin active {
  background: $blue-100;
  color: $blue-500;
}

@mixin position {
  position: absolute;
  right: 0;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 6px;
  width: 30px;
  height: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.rdw-option-active {
  box-shadow: none;
}

.textEditor {
  &-toolbar {
    padding: 0;
    border-radius: 0;
    border: none;
    background-color: transparent;
  }

  &-text-area {
    height: 100px;
    padding-left: 5px;
    overflow-y: visible;
    padding-top: 5px;

    span {
      background-color: transparent !important;
    }

    a {
      span {
        color: $blue-500 !important;
        text-decoration: underline !important;
      }
    }
  }

  &-wrapper {
    background-color: $gray-200;
    border-radius: 6px;
    padding: 8px;
    border: 2px solid transparent;

    &.focus {
      background-color: $white;
      border: 2px solid $blue-300;

      .textEditor-toolbar,
      .textEditor-text-area {
        background-color: $white;
      }

      .rdw-option-active {
        @include active;
      }
    }
  }

  &-option {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    position: relative;
    border: none;
    padding: 0;
    background: transparent;
    margin: 0 8px 0 0;
    border-radius: 2px;
    font-size: 16px;

    &-wrapper {
      margin-left: 8px;
    }

    &:hover {
      box-shadow: none;

      &::after {
        @include tooltip;
      }
    }

    &.active {
      @include active;
    }
  }

  &-divider {
    &::after {
      content: '';
      position: relative;
      width: 1px;
      height: 15px;
      right: 0;
      background: $gray-350;
    }

    &-left {
      &::before {
        content: '';
        position: relative;
        width: 1px;
        height: 15px;
        left: -9px;
        background: $gray-350;
      }
    }
  }

  &-link-popup {
    width: 400px;
    height: auto;
    border-radius: 6px;

    .rdw-link-modal-target-option {
      display: none;
    }

    input {
      height: 44px;
      padding: 0 12px;
      background: $gray-200;
      outline: none;
      border: none;
      border-radius: 6px;
      color: $gray-500;
    }

    .rdw-link-modal-buttonsection {
      display: flex;
      justify-content: flex-start;
      flex-direction: row-reverse;
      margin: 0;

      .rdw-link-modal-btn {
        border: none;
        outline: none;
        border-radius: 6px;
        height: 36px;

        &:hover {
          box-shadow: none;
        }

        &:disabled {
          opacity: 0.5;
        }

        &:nth-child(1) {
          color: $white;
          background: $blue-500;
        }

        &:nth-child(2) {
          background: $gray-200;
        }
      }
    }
  }
}

.rdw-option-disabled {
  opacity: 1;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.rdw-link-decorator-wrapper {
  &::before {
    @include position;

    content: '';
    display: none;
    background:
      no-repeat center
      url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxMS41QzEyIDExLjc3NjEgMTEuNzc2MSAxMiAxMS41IDEySDIuNUMyLjIyMzg2IDEyIDIgMTEuNzc2MSAyIDExLjVWMi41QzIgMi4yMjM4NiAyLjIyMzg2IDIgMi41IDJINEM0LjU1MjI4IDIgNSAxLjU1MjI4IDUgMUM1IDAuNDQ3NzE1IDQuNTUyMjggMCA0IDBIMkMwLjg5IDAgMCAwLjkgMCAyVjEyQzAgMTMuMSAwLjg5IDE0IDIgMTRIMTJDMTMuMSAxNCAxNCAxMy4xIDE0IDEyVjEwQzE0IDkuNDQ3NzEgMTMuNTUyMyA5IDEzIDlDMTIuNDQ3NyA5IDEyIDkuNDQ3NzIgMTIgMTBWMTEuNVpNOCAwQzcuNDQ3NzIgMCA3IDAuNDQ3NzE1IDcgMUM3IDEuNTUyMjggNy40NDc3MiAyIDggMkgxMC41OUw1LjQ2NSA3LjEyNUM1LjA3NTY0IDcuNTE0MzYgNS4wNzU2NCA4LjE0NTY0IDUuNDY1IDguNTM1QzUuODU0MzYgOC45MjQzNiA2LjQ4NTY0IDguOTI0MzYgNi44NzUgOC41MzVMMTIgMy40MVY2QzEyIDYuNTUyMjggMTIuNDQ3NyA3IDEzIDdDMTMuNTUyMyA3IDE0IDYuNTUyMjggMTQgNlYwLjVDMTQgMC4yMjM4NTggMTMuNzc2MSAwIDEzLjUgMEg4WiIgZmlsbD0iIzNENEM1OSIvPgo8L3N2Zz4K'),
      $white;
  }

  &:hover {
    &::before {
      display: block;
    }
  }
}

.rdw-link-decorator-icon {
  @include position;

  opacity: 0;
}

.rdw-emoji-wrapper {
  &:hover {
    &::after {
      @include tooltip;
    }
  }
}
