@import '~alerts.management/components/editor/simulationFilters/variables';

.main {
  & > button:last-child {
    margin-right: 0px;
  }

  & > button {
    margin: $btn-margin-right $btn-margin-right 0 0;
    width: 118px;
  }
}
