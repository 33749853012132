@import '~app/styles/v2/variables';

.filters-wrapper {
  flex-shrink: 0;
  width: 0;
  margin-right: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &.open {
    width: 285px;
    margin-right: 20px;

    .spacer {
      width: 285px;
      height: 25px;
    }

    .color-mask {
      width: 285px;
      height: 35px;
      position: fixed;
      bottom: 0;
      pointer-events: none;
      background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 100%);
    }
  }
}

.panel-root {
  display: block;
  width: 285px;
  border-radius: 8px;
  background-color: $white;
  border: 2px solid $gray-200;
  position: relative;
  padding: 18px 20px 20px 20px;
  transition: all 0.3s ease-in-out;

  .close-button {
    position: absolute;
    cursor: pointer;
    right: 8px;
    top: 8px;
    opacity: 0.5;
    color: $gray-500;
    font-size: 16px;
  }

  .header-row {
    width: 100%;
    margin-bottom: 16px;
    font-family: Roboto, sans-serif;

    .header {
      font-size: $font-size-16;
      font-weight: $font-weight-medium-500;
      line-height: normal;
      color: $gray-500;
      display: inline-block;
      margin-right: 10px;
    }

    .clear-all {
      font-size: $font-size-12;
      font-weight: $font-weight-medium-500;
      line-height: normal;
      color: $blue-500;
      display: inline-block;
      cursor: pointer;
    }
  }
}

.filters-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;

  .filters-actions {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: $font-weight-regular-400;
    color: $blue-500;
    cursor: pointer;
    flex-shrink: 0;

    i {
      margin-right: 8px;
    }
  }

  .select-wrapper {
    :global .andt-dropdown-control > div:first-child {
      height: 1px;
    }
  }

  &.hidden {
    justify-content: flex-end;

    .select-wrapper {
      display: none;
    }
  }
}
