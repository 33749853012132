@import '~app/styles/v2/variables';

.tooltip-icon {
  visibility: hidden;
  color: $gray-300;
  margin-left: 4px;

  &:hover {
    color: $gray-500;
  }
}

.container {
  height: 40px;
  margin: 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;

  &:hover {
    background-color: rgba($blue-500, 0.1);

    .tooltip-icon {
      visibility: visible;
    }
  }

  .label {
    color: $gray-500;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.tooltip {
      width: auto;
    }
  }
}

.selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $blue-500;

  .label {
    color: $blue-500;
  }
}

.disabled {
  .label {
    color: $gray-200;
  }
}

.header {
  font-size: $font-size-14;
  text-transform: uppercase;
  font-weight: bold;
  pointer-events: none;
  cursor: default;

  .label {
    color: $gray-400;
    width: 100%;
    box-shadow: 0 2px 0 0 $gray-300;
  }

  &:hover {
    background-color: transparent;
  }
}

.icon {
  padding-right: 11px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
