@import '~app/styles/v2/variables.scss';

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  height: 245px;
  border-radius: 5px;
  background-color: $gray-100;
  margin-bottom: 37px;
}

.errorCounts {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

.errorCounts * {
  margin: 0;
  padding: 0;
}

.countContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70%;
}

.alertsCountContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  height: 100%;
}
