@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';

$user-row-height: 49px;

.table {
  height: 100%;
}

.table-header {
  display: flex;
  flex-shrink: 0;
  margin: 0 18px 0 6px;
  font-weight: $font-weight-medium-500;
  height: 33px;
}

.tableRow {
  margin: 0 2px;
  flex-shrink: 0;
}

.tableRowOpen {
  height: 100% !important;
  max-height: 300px;
  margin-bottom: 10px !important;
}

.table-row-header {
  display: flex;
  cursor: pointer;
  max-height: 100px;
  padding: 5px;
  align-items: center;
  height: $user-row-height;
}

.table-body {
  display: block;
  max-height: 220px;
  overflow-y: auto;
}

.col-header {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: $font-weight-medium-500;
    font-size: $font-size-14;
  }

  &.col-title {
    padding-left: 5px;
  }
}

.col-title {
  flex: 1 0 200px;
  justify-content: left;
  font-weight: $font-weight-medium-500;
}

.col-category {
  flex: 0 0 180px;
  justify-content: left;
}

.col-startDate {
  flex: 0 0 115px;
  justify-content: left;
}

.col-endDate {
  flex: 0 0 115px;
  justify-content: left;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

// expendable panel
.container {
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  padding: 14px 6px 14px 14px;
  flex-wrap: wrap;
}

.property {
  height: 28px;
  border-radius: 4px;
  background-color: $gray-300;
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding-right: 4px;
  flex-shrink: 0;
  margin-bottom: 8px;

  .key {
    color: $gray-500;
    font-weight: $font-weight-regular-400;
    line-height: 1;
    padding: 0 8px;
  }

  .value {
    height: 22px;
    border-radius: 4px;
    background-color: $gray-400;
    font-weight: $font-weight-medium-500;
    color: $white;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 0 6px;
    flex-shrink: 0;
  }
}
