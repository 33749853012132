@import '~app/styles/v2/variables';

.item-row {
  /* transition: all ease-in-out 0.5s; */
  position: relative;
  margin: 0 3px;
  padding-right: 10px;

  & > div {
    width: 100%;
  }

  .checkbox {
    position: absolute;
    z-index: 2;
    width: 44px;
    margin-right: 20px;
    padding: 3px 14px 0 12px;
    top: 14px;
    visibility: hidden;

    &.should-show {
      visibility: visible;
    }
  }

  &:hover {
    .checkbox {
      visibility: visible;
    }
  }
}
