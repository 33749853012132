@import '~app/styles/v2/variables';

.itemContainer {
  position: relative;
  display: flex;
  height: 30px;
  margin-bottom: 22px;
  margin-top: 2px;

  .titleWrapper {
    display: flex;
    background-color: $blue-100;
    border: 1px solid $blue-300;
    font-weight: $font-weight-medium-500;
    font-size: $font-size-16;
    border-radius: 15px;
    cursor: pointer;
    padding-right: 10px;
    max-width: calc(100% - 180px);

    .arrow,
    .logo,
    .name,
    .type {
      display: inline-block;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
    }

    .arrow,
    .logo,
    .name {
      text-overflow: ellipsis;
    }

    .arrow {
      left: 10px;
      top: 5px;
    }

    .logo {
      width: 45px;
      height: 37px;
      left: 4px;
      top: -10px;
      position: relative;

      svg {
        transform: scale(0.5);
        position: absolute;
      }
    }

    .name {
      color: $gray-600;
      bottom: -3px;
    }

    .type {
      color: $gray-400;
      bottom: -3px;
      padding: 0 15px 0 10px;
    }

    .actions-wrap {
      position: absolute;
      text-align: right;
      background: linear-gradient(to right, rgba(233, 240, 255, 0), $blue-100 30%);
      display: none;
      width: 103px;
      height: 24px;
      right: 0;
      top: 0;

      .action-button,
      .action-select {
        .btn-icon {
          top: 1px;
          color: $gray-400;
          font-size: 20px;
          position: relative;

          &:hover {
            color: $blue-500;
          }
        }
      }

      .action-button {
        margin-right: 6px;
      }

      .action-select {
        position: relative;
        display: inline-block;
        //top: -8px;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .actions-wrap {
        display: block;
      }
    }
  }

  .button {
    visibility: hidden;
    position: absolute;
    right: 10px;
    top: -2px;
  }

  &:hover,
  &:focus,
  &:active {
    .button {
      visibility: visible;
      cursor: pointer;
    }
  }
}
