@import '~app/styles/v2/variables';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -10%;
}

.error-message,
.error-message-no-triggered {
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    color: $indigo-500;
    font-size: $font-size-18;
    line-height: 21px;
    font-weight: $font-weight-medium-500;
    margin: 21px 0 8px 0;
  }

  h5 {
    color: $gray-400;
    font-size: $font-size-16;
    font-weight: $font-weight-regular-400;
    margin: 0;
  }
}

.error-message-no-triggered {
  margin-top: 40px;

  h3 {
    color: $gray-500;
    font-size: $font-size-14;
    width: 165px;
    text-align: center;
  }
}
