@import './v2/utils/_flex.scss';
@import './v2/utils/_display.scss';
@import './v2/utils/_margin.scss';
@import './v2/utils/_padding.scss';
@import './v2/utils/_size.scss';
@import './v2/utils/_position.scss';
@import './v2/utils/_font-size.scss';
@import './v2/utils/_border-radius.scss';
@import './v2/utils/_colors.scss';
@import './v2/utils/_typography.scss';

.display_inline {
  display: inline;
}

.display_block {
  display: block;
}

/* overflow */
.overflowY_auto {
  overflow-y: auto;
}

.overflowX_hidden {
  overflow-x: hidden;
}

.userSelect_none {
  user-select: none;
}
