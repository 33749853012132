/* stylelint-disable */
@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

.root {
  display: flex;
  flex-direction: column;
  margin: 24px 5%;

  header.header {
    display: flex;

    .title {
      flex-shrink: 0;
      width: 385px;
      color: $gray-dark;
    }
  }

  .loading {
    height: 90px;
    width: 90px;
    position: absolute;
    z-index: 1;
    left: calc(50% + 90px);
    top: 50%;
  }

  main.main {
    display: flex;
    height: 100%;
    margin-top: 30px;
  }

  .toggle-all-checkbox {
    font-size: 19px;
  }

  :global {
    .andt-icon-checkbox-full {
      color: $blue-500;
    }
  }

  .radio-wrapper {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
  }

  .feature-global-header {
    display: flex;
    cursor: default;
  }

  .feature-global-name {
    font-size: 14px;
    font-weight: 400;
    display: block;
    max-width: 185px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 9px;

    &.customers {
      max-width: 227px;
      font-weight: 400;
    }
  }

  .feature-global-label {
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    height: 9px;
    width: 9px;
    text-align: center;
    font-size: 9px;
    color: $gray-400;
    border-radius: 50%;
  }

  .hor-switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 198px;

    &.disabled {
      opacity: 0.375;
      pointer-events: none;
    }
  }

  .table {
    display: flex;
    flex: 1 0 0;
    overflow: auto;
    height: 100%;
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.275s ease-in-out;

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &.no-results {
      align-self: center;
    }

    :global {
      .public_fixedDataTable_main {
        min-height: 100%;
        font-size: 12px;
        border-radius: 8px;
        background-color: white;
        border: 2px solid $gray_200;
        margin-left: 0 !important;
      }

      .public_fixedDataTable_header {
        .fixedDataTableCellLayout_main {
          background-color: $white;
          background-image: none;
        }

        .fixedDataTableCellGroupLayout_cellGroupWrapper {
          background-color: $white;
        }
      }

      .public_fixedDataTableCell_cellContent {
        position: relative;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
      }

      .fixedDataTableCellLayout_main {
        border-left: 1px solid $blue-light;
      }

      .public_Scrollbar_main,
      .public_Scrollbar_main.public_Scrollbar_mainActive {
        border-left: none;
      }

      .ScrollbarLayout_mainHorizontal {
        border-left: none;
        height: 15px !important;

        .ScrollbarLayout_faceHorizontal {
          left: -2px;
        }

        .ScrollbarLayout_faceHorizontal::after {
          background-color: $blue-light;
          bottom: 4px;
          top: 5px;
        }
      }

      .public_Scrollbar_mainActive,
      .ScrollbarLayout_mainHorizontal:hover {
        .ScrollbarLayout_faceHorizontal::after {
          background-color: $blue-light2;
          bottom: 4px;
          top: 5px;
        }
      }

      .ScrollbarLayout_mainVertical {
        .ScrollbarLayout_faceVertical::after {
          background-color: $blue-light;
          right: 0;
          left: 11px;
        }
      }

      .ScrollbarLayout_mainVertical:hover {
        .ScrollbarLayout_faceVertical::after {
          background-color: $blue-light;
          right: 0;
          left: 11px;
        }
      }
    }
  }
}
