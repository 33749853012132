@import '~app/styles/v2/variables.scss';

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.conveyor-belt-root {
  display: flex;
  width: 100%;
}

.group-items-wrapper {
  overflow: hidden;
  display: flex;
}

.moving-rail {
  display: flex;
  overflow-y: auto;
  transition: transform 0.3s;
}

.group-item {
  margin-right: 20px;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
  }
}

.button-area {
  width: 36px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  visibility: hidden;

  i {
    width: 24px;
    align-items: center;
    display: flex;
    color: $gray-500;
  }

  i:hover {
    color: $blue-500;
  }

  i::before {
    margin: 0 auto;
  }
}

.arrow-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: 1px solid $gray-300;
}

.right-button-area {
  flex-direction: row-reverse;
}

.hide-handles {
  .button-area {
    visibility: hidden !important;
  }
}

.conveyor-belt-root:hover {
  .button-area {
    visibility: visible;
  }
}
