@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  .input-description {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    display: block;
    color: $black1;
    margin-left: 50px;
    margin-bottom: 20px;
  }
}
