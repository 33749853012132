@import '~app/styles/themes/base/variables';

.button {
  transition-duration: 0.4s;
  color: $blue;
  text-align: left;
  text-overflow: ellipsis;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 1px;
  margin-left: 1px;
  margin-bottom: 4px;
  margin-right: 5px;
  border: 1px solid transparent;
  box-shadow: 0 0 2px 0 $black-alpha014;
  border-radius: 3px;
  min-height: min-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.group-button-icon {
  position: absolute;
  bottom: 1px;
  right: 3px;
  color: $blue;
}

.button-inactive {
  composes: button;
  color: $gray-dark2;
  background-color: transparent;
  outline: 0;

  &:focus {
    border: 1px solid transparent;
  }

  .group-button-icon {
    color: $blue-light2;
  }
}

.button-disabled {
  composes: button;
  color: $gray-lighter6;
  background-color: transparent;
  outline: 0;
  border: 0;

  &:active {
    background-color: red;
  }

  .group-button-icon {
    color: $gray-lighter6;
  }
}

.button-active {
  composes: button;
  color: $blue;
  background-color: $blue-light2;
  outline: 0;
  box-shadow: none;

  &:focus {
    border: 1px solid $blue-light2;
  }
}

:global .concept-tooltip {
  color: $blue2;
  text-align: left;
  font-weight: $font-weight-light !important;

  hr {
    border-color: $blue2;
  }
}

:global .conflict-list {
  display: flex;
  flex-wrap: wrap;

  span {
    margin: 5px;
    padding: 5px;
    border: 1px solid $gray-dark2;
    white-space: nowrap;
  }
}
