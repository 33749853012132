@import '~app/styles/v2/variables.scss';

.root {
  width: 100%;
  position: relative;
  flex-shrink: 0;
  margin-bottom: 30px;

  &.short {
    margin-bottom: 10px;
  }
}

.header {
  color: $gray-500;
  font-size: $font-size-22;
  font-weight: $font-weight-medium-500;

  .counter {
    color: $gray-300;
    font-size: $font-size-18;
    margin-left: 10px;
  }

  .icon {
    margin-left: 8px;
    font-size: 16px;
    line-height: 16px;
  }
}

.clickable-header {
  cursor: pointer;
}

.panel {
  width: calc(100% + 72px);
  margin-left: -36px;
  opacity: 1;
  padding-top: 10px;
}

.hide-panel {
  opacity: 0;
  height: 0;
  visibility: hidden;
}

.group-items-wrapper {
  overflow-x: hidden;
  display: flex;
}

.delete-body {
  font-size: $font-size-14;
  color: $gray-500;
  font-weight: $font-weight-regular-400;
  margin-bottom: 30px;

  .header {
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
    line-height: 18px;
    margin-top: 12px;
    margin-bottom: 3px;
  }

  .group-assets {
    display: flex;
    flex-direction: column;

    .bold {
      font-weight: $font-weight-bold-700;
    }

    .table {
      display: flex;
      flex-direction: row;
    }

    .col {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .is-loading {
      height: 17px;
      margin-top: 2px;
      margin-bottom: 2px;
      border-radius: 9px;
      margin-right: 20px;
      background-image:
        linear-gradient(
          100deg,
          rgb(233, 233, 240) 3%,
          rgb(212, 224, 242) 61%,
          rgb(233, 233, 240) 94%,
          rgb(233, 233, 240) 94%
        );
    }
  }
}

.customer-section {
  flex-grow: 1;
}

.delegate-row {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;

  .delegate {
    display: flex;
    align-items: center;
  }

  .header {
    margin-top: 16px;
    margin-bottom: 4px;
  }

  label {
    display: flex;
    margin-bottom: 3px;
    margin-right: 8px;
  }

  span {
    margin-left: 6px;
  }
}
