@import '~app/styles/v2/variables';

.root {
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  width: fit-content;
  max-width: 200px;
  padding-left: 12px !important;
  padding-right: 12px !important;
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  color: $white !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gr-icon {
  margin-right: 6px;
}

/* -- Color Themes -- */
.gray {
  background-color: $gray-400;
}

.rose {
  background-color: $rose-500;
}

.mint {
  background-color: $mint-500;
}

.yellow {
  background-color: $yellow-500;
}

.teal {
  background-color: $teal-500;
}

.indigo {
  background-color: $indigo-400;
}

.lilac {
  background-color: $lilach-500;
}

.tomato {
  background-color: $tomato-500;
}

.eucaliptus {
  background-color: $eucaliptus-500;
}
