@import '~app/styles/v2/variables';

.root {
  display: flex;
  font-size: 16px;
  flex-direction: column;
}

.what-with-value {
  color: $purple-500;
  font-weight: $font-weight-bold-700;
  font-size: $font-size-14;
  padding: 0 8px;
  border-radius: 4px;
  line-height: 1;
  height: 28px;
  display: flex;
  align-items: center;
  background-color: $purple-200;
}

.what-empty {
  color: $blue-500;
  font-weight: $font-weight-regular-400;
  font-size: $font-size-16;
  border-radius: 6px;
  margin-right: 6px;
  height: 20px;
  padding: 0 2px 0 6px;
  line-height: 1;
  display: flex;
  align-items: center;
  background-color: rgba(38, 113, 255, 0.1);

  i {
    opacity: 0.5;
    margin-top: 4px;
    margin-left: 1px;
  }
}

.add-match-property-button {
  border-radius: 6px;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  height: 28px;
  width: 28px;
  padding: 6px;
  position: relative;
  cursor: pointer;
  background-color: $blue-500;
  color: $white;

  &::before {
    width: 16px;
    height: 16px;
    font-size: $font-size-16;
  }

  &:hover {
    color: $white;
  }
}

.advanced-button {
  position: relative;
  display: inline-flex;
  top: 1px;
  margin-top: 1px;
  margin-bottom: 1px;

  button {
    line-height: 1;
    padding: 0 12px;
  }
}
