@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

$root-left-padding: 96px;

.root {
  @include shell-col;
  margin-left: 150px;
  margin-right: 90px;
}

.header {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  margin-top: 5px;

  .title {
    width: 500px;
    display: flex;
    flex-direction: column;
    color: $black1;
  }
}

.andt-section-sub-header {
  font-size: 14px;
  font-weight: normal;
}

.editor {
  @include shell;
  margin: 30px 0 25px 0;

  .go-back-pane {
    display: flex;
  }

  .dimetrics-pane {
    @include shell-col;
    padding-right: 24px;
    border-right: 1px solid #dad8d8;

    div {
      flex-shrink: 0;
    }

    .cube-measures {
      display: flex;
      flex-direction: column;
      /*flex: 1 0 0;*/
      flex-grow: 1;
      flex-shrink: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      max-height: 100px;
    }

    .cube-dimensions {
      display: flex;
      flex-direction: column;
      /*flex: 1 0 0;*/
      flex-grow: 1;
      flex-shrink: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      max-height: 300px;
    }

    .cube-preview {
      @include shell-col;
    }
  }

  .metadata-pane {
    @include shell-col;
    max-width: 470px;
    padding-left: 24px;

    .cube-status {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
    }

    .cube-description {
      @include shell-col;
    }
  }
}

.cube-not-found {
  align-self: center;
  padding-top: 20px;
}
