.container {
  margin: 0;
  padding: 0 0 0 23px;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
}

.last {
  text-align: right;
}
