@import '~app/styles/v2/variables';

.modal-body {
  max-height: 650px;

  .itemContainer {
    margin-bottom: 24px;

    .titleWrapper {
      font-weight: $font-weight-medium-500;
      font-size: $font-size-14;
      color: $gray-500;
      margin-bottom: 8px;
    }

    .textWrapper {
      font-weight: $font-weight-medium-500;
      font-size: $font-size-14;
      color: $gray-400;
      margin-top: 7px;
    }

    .textInput {
      box-sizing: border-box;
      height: 38px;
      font-size: $font-size-16;
      padding: 12px;
      background-color: $gray-200;
      border-radius: 6px;
      border: 2px solid transparent;
      width: 61px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    padding-top: 16px;

    .left-button {
      flex: 1 0 0;
    }
  }
}
