@import '~app/styles/v2/variables';

.modal-content {
  p {
    margin-bottom: 30px;
  }

  button {
    float: right;
    margin-bottom: 24px;
  }
}
