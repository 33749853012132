@import '~app/styles/themes/base/variables';

.root {
  display: flex;
  position: relative;
  flex: 1 0 0;

  > div {
    padding-left: 30px;
    border-left: 1px solid $gray-lighter;
    padding-bottom: 50px;
    padding-right: 10px;

    &:first-child {
      padding-left: 50px;
      border: none;
    }
  }
}
