@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';
@import '../components/alertsList/alertsListVariables';
@import '~app/styles/v2/variables';

.page-content-wrapper {
  display: flex;
  flex: 1 0 0;
}

.alert-list-container {
  flex-grow: 1;

  .date-range-wrapper {
  }
}

.filters-wrapper {
  flex-shrink: 0;
  width: 0;
  margin-right: 0;
  margin-top: 3px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  overflow-y: auto;

  &.open {
    width: 345px;
    padding-right: 20px;
    margin-right: 20px;
  }
}

.table-wrapper {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.spinner {
  margin: 400px 0 0 50%;
}

.page-container {
  flex: 1 1 0;
  overflow: hidden;
  position: relative;
}

.page-footer-container {
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .footer {
    display: flex;
    align-items: center;
    height: 0;
    overflow: hidden;
    background-color: $blue-500-10percent;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;

    & > div {
      height: 0;
    }
  }

  &.with-footer {
    .footer {
      height: 70px;

      & > div {
        height: initial;
      }
    }
  }
}
