@import "~app/styles/themes/base/variables";

.title {
  height: 30px !important;
  font-size: 14px !important;
  color: $gray-dark2 !important;
  border: none !important;
  margin: 0 -5px;
  padding: 0 5px !important;

  &:hover {
    border-color: $gray-light;
    box-shadow: 0 0 10px $gray-light;
    margin-right: 5px;
    padding-right: 5px !important;
  }

  &:focus {
    border: 1px solid $gray-light !important;
    box-shadow: none;
    width: 100%;
  }
}

.hidden {
  color: $gray-lighter4 !important;
}

.not-original-dimension {
  color: $dimension-color !important;
}

.not-original-metric {
  color: $metric-color !important;
}

.origin {
  position: absolute;
  font-size: 10px;
  bottom: 0px;
  padding-right: 5px;
  width: 100%;
  color: $gray-lighter4;
}
