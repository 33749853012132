@import '~app/styles/v2/variables.scss';

.multi-button-wrapper {
  background-color: rgba($gray-300, 0.6);
  border-radius: 6px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  padding: 4px 2px;
  width: 100%;

  button {
    margin: 0 2px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  &.menu-footer {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: auto;
    padding: 2px;

    button {
      font-size: 14px;
      margin: 0 6px 0 0;

      &:nth-child(2) {
        margin: 0 120px 0 6px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}
