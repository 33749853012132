@import '~alerts.management/components/editor/simulationFilters/variables';

.buttons-container {
  display: flex;
  margin-top: 85px;
  justify-content: flex-end;

  & > button:first-child {
    margin-left: 0px;
  }

  & > button {
    margin-left: $btn-margin-right;
    min-width: $btn-yes-no-width;
    padding-right: 14px;
    padding-left: 14px;
  }
}
