@import '~app/styles/v2/variables';

.container {
  height: 40px;
  margin: 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;

  &:hover {
    background-color: rgba($blue-500, 0.1);
  }

  .label {
    color: $gray-500;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.selected {
  background-color: rgba($blue-500, 0.1);
}

.disabled {
  .label {
    color: $gray-200;
  }
}

.header {
  font-size: $font-size-14;
  text-transform: uppercase;
  font-weight: bold;
  pointer-events: none;
  cursor: default;

  .label {
    color: $gray-400;
    width: 100%;
    box-shadow: 0 2px 0 0 $gray-300;
  }

  &:hover {
    background-color: transparent;
  }
}
