@import '~app/styles/themes/base/variables';

//conditions containers
$slider-container-margins: 40px 15px 30px 15px;
$condition-container-min-width: 365px;
$btn-margin-right: 5px;
$value-label-font-size: 26px;
$btn-time-scale-size: 48px;
$input-height: 48px;

@mixin sub-title() {
  font-size: 13px;
  color: #d8d8d8;
  line-height: 1.3;
  font-weight: 300;
}
