/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 121px;

.header {
  @include widget-title;
}

.body {
  @include body;

  height: $widgetHeight;

  &.gray {
    @include gray;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 13px auto 0;

  .no-feedback {
    width: 41px;
    height: 41px;
    border-radius: 50%;
    background-color: $gray-300;
  }

  .legend {
    cursor: pointer;
    margin-top: 5px;

    &.positive {
      .number {
        color: $blue-500;
      }
    }

    .number {
      display: block;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $indigo-500;

      span {
        margin-right: 8px;
      }
    }

    .text {
      color: $gray-400;
    }
  }
}
/* stylelint-enable */
