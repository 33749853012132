@import '~app/styles/v2/variables';

.container {
  color: $gray-500;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  & > div {
    display: inline;
    margin-right: 4px;
    position: relative;
    top: 2px;
  }
}
