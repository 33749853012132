.root {
  width: 200px;
  margin: 0 auto;
  background-color: transparent;
}

.text {
  stroke-width: 1.3;
  stroke: black;
}

.bar-bg {
  fill: none;
  stroke-width: 6;
  stroke: #f2fae9;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.bar-progress {
  fill: none;
  stroke-width: 1.5;
  stroke: #82bd36;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  transition: stroke-dashoffset 500ms linear;
}
