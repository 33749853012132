@import '~app/styles/v2/variables.scss';

.properties {
  width: 352px;
  margin-top: 18px;
}

.cat-label {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  line-height: 14px;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
}

.group-title-pos {
  margin-top: 6px;
  margin-bottom: 16px;
}

.group-color-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.members {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.members-header {
  display: flex;
  align-items: center;
}

.members-body {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  width: 313px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 315px;

  &.content-box {
    padding-right: 8px;
    width: 323px;
    box-sizing: content-box;
  }
}

.user-selection-wrapper {
  width: 200px;
  margin-left: 12px;
}
