@import '~app/styles/v2/variables';

.container {
  .handle {
    position: absolute;
    color: #b5bbc3;
    top: 12px;
    left: -18px;
    opacity: 0;
    cursor: grab;

    &:hover {
      opacity: 1;
    }
  }

  .action-menu-container {
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .icon {
      color: white;
      font-size: 16px;
      margin-left: 8px;
    }

    .disabled {
      cursor: default;
      opacity: 0.5;
    }

    .action-menu {
      background-color: $blue-500;
      height: 24px;
      color: white;
      border-radius: 4px;
      margin-top: -20px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .icon {
        font-size: 14px;
        padding: 6px;
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .element {
    &:hover {
      .action-menu-container {
        visibility: visible;
      }

      .handle {
        opacity: 1;
      }
    }
  }
}

.not-selected {
  position: relative;
  opacity: 0.5;
}

.selected {
  position: relative;
  opacity: 1;
}

.you-are-viewing {
  position: absolute;
  background-color: $gray-200;
  width: 75px;
  border-radius: 4px;
  font-size: $font-size-12;
  left: -80px;
  padding: 4px;
  text-align: right;
  line-height: normal;
  font-weight: $font-weight-medium-500;
  margin-top: 4px;
}

.landing-indicator {
  background: $blue-500;
  width: 100%;
  height: 2px;
  position: relative;
  top: 3px;
}

.tree-container {
  .switch-ico {
    position: absolute;
    color: #3d4c59;
    top: -5px;
    left: -15px;
    height: 16px;
    cursor: pointer;
    opacity: 0;

    &:hover svg path {
      fill: $blue-500;
    }
  }

  &:hover {
    .switch-ico {
      opacity: 1;
    }
  }
}
