/* stylelint-disable */
@import '~app/styles/themes/base/variables';

//-----------
$spaceBetweenItems: 0;

$listDragOverBgColor: $blue2;
$listBgColor: $blue;

$itemDraggingBgColor: $blue4;
$itemBgColor: $blue3;

$borderColor: $blue1;
//-----------

.list {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow-y: auto;
  background-color: $listBgColor;
  padding: 10px;
  border-radius: 5px;

  &.no-scroll {
    overflow-y: hidden;
    padding-top: 11px;
    border: 1px solid $blue1;
  }

  div {
    flex-shrink: 0;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $blue8;
      border: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $blue8;
      border: none;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: $blue8;
      border: 0;
    }
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue8;
    border: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $blue8;
    border: none;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $blue8;
    border: 0;
  }
}

.list-dragging-over {
  background-color: $listDragOverBgColor;
}

.list-item-container {
  user-select: none;
  margin: 0 0 $spaceBetweenItems 0;
}

//Safri only fix
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .list-item-container {
      top: auto !important;
      left: auto !important;
    }
  }
}

.list-item-container-dragging {
  background-color: $itemDraggingBgColor;
}
/* stylelint-enable */
