.toggle-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 22px;
  margin: 0;
  cursor: pointer;
}

.onoffswitch .onoffswitch-switch {
  width: 16px;
  height: 16px;
  margin: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: 16px;
  transition: right 0.15s ease-in-out;
}

.toggle-group input[type='radio']:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
  right: 0;
  box-shadow: none;
}

.onoffswitch {
  position: relative;
  width: 32px;
  display: inline-block;
}

.onoffswitch.disabled {
  opacity: 0.3;
}

.onoffswitch .onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin: 0;
}

.onoffswitch.disabled .onoffswitch-label {
  cursor: default;
}

.onoffswitch .onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  transition: all 0.15s ease-in-out;
}

.onoffswitch .onoffswitch-inner .before,
.onoffswitch .onoffswitch-inner .after {
  float: left;
  width: 50%;
  height: 8px;
  padding: 0;
  box-sizing: border-box;
}

.onoffswitch .onoffswitch-inner .before {
  padding-left: 10px;
}

.onoffswitch .onoffswitch-inner .after {
  padding: 1px 8px 0;
  text-align: right;
}

.toggle-group input[type='radio'] {
  position: absolute;
  left: 10px;
  display: none;
}

.toggle-group input[type='radio']:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.toggle-group input[type='radio']:focus ~ .onoffswitch {
  outline: thin dotted #333;
  outline: 0;
}

.toggle-group label {
  position: absolute;
  cursor: pointer;
  padding-left: 65px;
  display: inline-block;
  text-align: left;
  line-height: 24px;
  width: 100%;
  z-index: 1;
  height: 24px;
  font-weight: 200;
}
