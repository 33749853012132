@import '~app/styles/v2/variables';

:global {
  .column-chart-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 115px;
    padding: 2px 5px;
    background-color: #17406f;
    font-size: 12px;
    font-family: 'Roboto', 'Helvetica', 'Lucida Grande', sans-serif;
    color: #fff;
    border-radius: 3px;
    cursor: default;
    white-space: nowrap;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  }

  .xAxis-label,
  .yAxis-label {
    span {
      font-size: 12px !important;
      font-weight: 500 !important;
      font-family: 'Roboto', 'Helvetica', 'Lucida Grande', sans-serif !important;
      color: $gray-400 !important;
    }
  }
}
