@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.time-scale {
  margin-top: 14px;

  & > div:first-child {
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.sub-title {
  @include sub-title();
  margin: 20px 0 10px 0;
}

.buttons-container-yes-no {
  display: flex;

  & > button:first-child {
    margin-left: 0px;
  }

  & > button {
    margin-left: $btn-margin-right;
    width: 100px;
    padding-right: 0px;
    padding-left: 0px;
  }
}
