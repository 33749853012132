@import '~app/styles/v2/variables';

.options-container {
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    height: 30px;
    width: 180px;
    margin: 4px;
    font-size: $font-size-14;
    font-weight: $font-weight-regular-400;
    color: $gray-500;

    &:hover {
      background-color: $blue-500-10percent;
      border-radius: 4px;
      cursor: pointer;
    }

    .warning {
      color: $red-500;
    }
  }
}

.split-button-container {
  position: relative;
}

.save-alert-error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
  font-size: $font-size-16;
  font-weight: $font-weight-bold-700;
  background-color: $red-500;
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  height: 40px;
  position: absolute;
  top: -50px;
  left: -127px;
  width: max-content;
}

.left-side-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.three-dots-button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: $gray-300;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-option {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  &.button {
    cursor: pointer;

    &:hover {
      background-color: $blue-500-10percent;
    }
  }
}
