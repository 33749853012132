.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    margin-top: -75px;

    > p {
      font-size: 22px;
    }

    > i {
      font-size: 135px;
      color: rgb(38, 113, 255);
    }
  }
}
