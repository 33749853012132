@import '~app/styles/v2/variables.scss';

.container {
  font-size: $font-size-14;
  line-height: $font-size-16;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  margin: 4px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .info {
    visibility: hidden;
    margin-right: 18px;

    &.withCheckmark {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: $blue-100;
    border-radius: 4px;

    .info {
      visibility: visible;
    }
  }

  &.header {
    cursor: initial;
    color: $gray-400;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
      background-color: transparent;
      border-radius: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 12px;
      bottom: 4px;
      height: 1px;
      width: 260px;
      border-bottom: solid 2px $gray-300;
    }
  }
}

.name-and-count {
  padding-left: 8px;
  padding-right: 10px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;

  .label-container {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .count {
    color: $gray-300;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    padding-left: 8px;
    margin-right: 0;
  }
}

.focused {
  background-color: rgba($blue-500, 0.05);
}

.chevron {
  padding-right: 12px;
}

.isSelected {
  position: relative;
  margin-right: 4px;
  color: $blue-500;
}

//.withCheckmark {
//  margin-right: 0;
//}
