.form-container {
  position: relative;
  width: 84%;
  margin: 25px auto;

  .input-fields {
    div {
      position: relative;
    }

    label {
      font-size: 12px;
      color: #5e5e5e;
      font-weight: bold;
      margin: 12px 0 0 0;
      display: block;
    }

    input {
      width: 100%;
    }

    .error-field {
      border-color: #ee3f37;
    }

    .error-message {
      position: absolute;
      left: 0;
      bottom: -23px;
      color: #ee3f37;
    }
  }
}

.submit-section {
  margin: 15px 0 25px 0;
  display: flex;
  flex-direction: row-reverse;
}

.btn-loader {
  position: relative;
  margin-left: 10px;
  top: 1px;
}
