@import '~app/styles/v2/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;

  .column {
  }

  .column-1 {
    flex: 1 0 0;
    margin: 0 46px;
    min-width: 396px;
  }

  .spinner {
    padding-top: 100px;
    padding-left: 50%;
  }
}
