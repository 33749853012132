@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.spacer {
  margin-top: 20px;
}

.sub-title {
  margin-bottom: 2px;

  @include sub-title();

  max-width: 280px;
}

.containers-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: -20px;
}

.input-container {
  margin-top: 20px;
  margin-left: 20px;
  flex: 1 0 300px;
}

.input-ctrl-wrapper {
  display: flex;
  justify-content: flex-end;
}

.bounds {
  display: flex;
  justify-content: flex-end;

  & > div {
    margin-left: 5px;

    &:first-child {
      width: 70px;
    }

    input {
      height: $input-height !important;
    }
  }
}

.emphasize {
  font-weight: bold;
}
