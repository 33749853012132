@import '~app/styles/v2/variables.scss';

:global {
  .group-modal {
    .modal-content {
      border-radius: 8px !important;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.container {
  height: 419px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 552px;
}

.header {
  font-family: Roboto, sans-serif;
  font-size: $font-size-22;
  font-weight: $font-weight-light-300;
  color: $gray-500;
  line-height: 25px;
  flex-shrink: 0;
}

.body {
  flex-grow: 1;
  display: flex;
}

.sidebar {
  width: 183px;
}

.tabs-menu {
  margin-top: 21px;
}

.main {
  flex-grow: 1;
  padding-left: 24px;
}

.footer {
  flex-shrink: 0;
  align-self: center;

  .cancel-btn {
    margin-right: 20px;
  }
}
