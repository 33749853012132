@import './../../../app/styles/themes/base/variables';

.delete-metrics {
  padding-top: 20px;
}

.delete-metrics-label {
  font-size: $font-size-large;
  font-weight: $font-weight-light;
  color: $black1;
  margin-left: 10px;
}
