@import '~app/styles/v2/variables';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .option {
    width: 30px;
    height: 30px;
    margin-right: 16px;
    font-size: $font-size-16;
    color: $gray-600;
    background-color: $gray-300;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .selected {
    color: $blue-500;
    background-color: #e9f0ff;
  }

  .disabled {
    color: $white;
    background-color: $gray-200;
    cursor: default;
  }
}
