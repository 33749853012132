@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.slider-container {
  margin: $slider-container-margins;
}

.value-label {
  display: flex;
  flex-direction: row-reverse;
  font-size: $value-label-font-size;
  color: $white1;
  margin: -5px 5px 0 0;
}

.input-container {
  display: flex;
  flex-direction: row-reverse;
}
