@import "~app/styles/themes/base/variables";

:global {
  .bc.modal-dialog.file-settings {
    .modal-content {
      overflow: visible;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.file-name {
  margin: -25px 0 0 0;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  &:last-of-type {
    margin-top: 16px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-width: 250px;
  justify-content: center;
}

.title {

}

.sub-title {
  font-size: $font-size-base;
  margin: 10px 0 20px 0;
  line-height: $font-line-height-short;
}

.footer {

}

.toggle-info {
  margin: -3px 0 0 30px;
  font-size: 12px;
}
