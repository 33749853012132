@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables.scss';

.root {
  max-width: 340px !important;
  margin-bottom: 30px;
  overflow-y: hidden;
  overflow-x: scroll;

  .hidden-file {
    display: none;
  }

  .button-wrap {
    margin-top: 20px;
  }

  .collect-button {
    margin-left: 226px;
  }

  .item-width {
    width: 280px;
    max-width: 300px !important;
  }

  .file-data {
    font-size: $font-size-small;
    font-weight: $brand-danger;
    display: inline-block;
    color: $black1;
  }

  .label {
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    line-height: normal;
    color: $gray-500;
    height: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .error {
    height: 100px;
    color: $brand-danger;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    margin-top: 10px;
  }

  .radio-wrapper {
    margin-top: -22px;

    .radio-block {
      height: 32px;

      label {
        padding-left: 12px;
      }
    }
  }
}
