@import '~app/styles/v2/variables';

h4 {
  margin-left: 150px;
  font-size: $font-size-24 !important;
}

h6 {
  font-size: $font-size-24 !important;
}
//general
.presentation {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $gray-300;
  border-radius: 10px;
  padding: 50px;
  margin-left: 150px;
  margin-bottom: 150px;
  max-width: 1600px;

  .item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    min-width: 150px;
    margin: 5px;
  }

  .format-block {
    margin-bottom: 150px;
  }
}

// for checkbox section
.chk-violet {
  color: $purple-500 !important;

  span {
    font-weight: 500;
  }
}

.icons-component {
  .item {
    flex-shrink: initial;
  }

  .icon-list {
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;

    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      border: 1px solid $gray-300;
      border-radius: 3px;
      padding: 10px;
      margin: 10px;
      width: 300px;
      overflow: hidden;
    }

    .icon-list-item {
      font-size: $font-size-16;
      margin-right: 10px;
    }

    .icon-name {
      width: 100%;
    }
  }
}
