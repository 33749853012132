@import '~app/styles/v2/variables';

.title {
  font-size: $font-size-12;
  line-height: $font-size-14;
  font-weight: $font-weight-regular-400;
  color: $gray-400;
  text-transform: uppercase;
  margin: 0 0 6px 0;
  padding: 0;

  &.small {
    text-transform: none;
    font-size: $font-size-12;
    font-weight: $font-weight-medium-500;
  }
}

.value {
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  margin: 0;
  padding: 0;
}

.break-line {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
