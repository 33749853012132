@import '~app/styles/themes/base/variables';
@import './variables';

.root {
  display: flex;
  font-size: 16px;
  flex-direction: column;

  .regular-input {
    width: 100px;
    min-width: 100px;
    height: 35px;
    flex-shrink: 0;
  }

  .connection {
    align-items: center;
    display: flex;
    margin: 0 8px;
  }

  :global {
    .andt-dropdown-control {
      margin-right: 6px;
      min-height: auto;
      height: 20px;
      padding: 0 4px;
      background-color: rgba(38, 113, 255, 0.1);

      .icon {
        margin-right: 0 !important;
        color: rgba(38, 113, 255, 0.5) !important;
        margin-top: 3px !important;
      }
    }

    .andt-dropdown-value-container {
      padding: 0;
      color: $blue_500;
      line-height: 20px;

      input {
        height: 0 !important;
      }
    }
  }
}
