@import "~app/styles/themes/base/variables";
$unit-select-height: 50px;
$unit-select-input-height: $unit-select-height - 2px;

:global {
  .unit-select .dropdown-no-icon {
    font-size: 12px !important;
    color: #9b9b9b;
    opacity: 0.5;
  }
}
.root {
  width: 100%;
  height: $unit-select-height;
  display: flex;

  .dropdown-selector {
    width: 100%;
  }

  .clear-button {
    margin-top: 5px;
  }

  :global {
    mark {
      background-color: transparent;
      color: $green-dark;
    }

    .Select-control {
      border-color: $gray-lighter;
      cursor: pointer;
    }

    .Select-placeholder {
      color: $gray-lighter6;
      font-size: $font-size-base;
      font-weight: $font-weight-light;
    }

    .Select--single.is-open {
      .Select-input {
        input {
          top: 0 !important;
          border-top: none;
          padding-right: 10px;
          padding-left: 10px;
          color: $green-dark;
        }
      }
    }

    .Select-clear-zone {
      padding-right: 10px;
      color: $green-dark;
      .Select-clear {
        font-size: 24px;
        font-weight: 300;
        position: relative;
        top: -1px;
      }

      &:hover {
        color: $green-darkest;
      }
    }

    .Select-arrow-zone {
      padding-right: 10px;
      .Select-arrow {
        border-width: 5px 5px 0px;
      }
    }

    .is-focused:not(.is-open) {
      .Select-control {
        border-color: $gray-lighter;
        box-shadow: none;
      }
    }

    .Select--single {
      .Select-control {
        .Select-value {
          line-height: $unit-select-input-height;
          background-color: $black1;
          padding-left: 15px;
          .unit-selected {
            .unit {
              font-size: $font-size-base;
              font-weight: $font-weight-light;
              display: inline-block;
              color: #FFFFFF;
              position: relative;
              top: -2px;
            }
            .unit-cat {
              color: $green-dark;
              font-size: $font-size-small;
              font-weight: $font-weight-medium;
              line-height: $font-size-small;
              position: absolute;
              left: 15px;
              bottom: 5px;
            }
          }
        }
      }
    }

    .has-value {
      .Select-arrow-zone {
        display: none;
      }
    }

    .UnitsSelectOption {
      font-size: $font-size-base;
      color: $gray-dark;
      font-weight: $font-weight-light;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
    }
    .UnitsSelectFocusedOption {
      background-color: $gray-lightest;
    }
    .UnitsSelectDisabledOption {
      opacity: 0.5;
    }
    .UnitsSelectSelectedOption {
      color: $blue;
      font-weight: $font-weight-medium;
    }
  }
}

.category {
  color: $gray-lighter4 !important;
  font-size: 12px !important;
  opacity: 1;
  align-items: flex-end !important;
}
