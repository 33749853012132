@import '~app/styles/themes/base/variables';

.left-container {
  flex-basis: 30%;
  max-width: 400px;
  min-width: 300px;

  > div {
    margin-bottom: 20px;
  }

  .panel {
    display: flex;
    position: relative;
  }

  .radio-panel {
    position: absolute;
    display: inline-block;
    width: 30px;
    flex-grow: 0;
  }

  .select-panel {
    display: inline-block;
    width: 450px;
    margin-left: 30px;
    margin-bottom: 20px;
    flex-grow: 1;
  }

  .btn-compose {
    width: 150px;
  }
}

.salesforce-select {
  margin-bottom: 24px;
  margin-left: 30px;
  min-width: 100px;
  width: 100%;

  .input-description {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    display: block;
    color: $black1;
    margin-bottom: 1px;
    line-height: $font-line-height-short;
  }
}

.record-title {
  font-weight: bold;
  font-size: $font-size-small;
  color: $black1;
  padding-right: 5px;
}

.record-data {
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  color: $black1;
}
