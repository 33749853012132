@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  max-width: 500px;
  min-width: 400px;

  .block {
    display: flex;
    position: relative;
  }

  .block-col {
    display: flex;
    position: relative;
    flex-direction: column;
  }

  .radio-block {
    position: absolute;
    display: inline-block;
    width: 30px;
    flex-grow: 0;
  }

  .select-block {
    display: inline-block;
    margin-left: 30px;
    margin-bottom: 50px;
    flex-grow: 1;

    &.disabled {
      cursor: not-allowed;
      color: $gray-lighter6;
    }
  }

  .btn-compose {
    width: 150px;
  }

  .use-case-header {
    font-family: Roboto, sans-serif;
    font-size: $font-size-large;
    font-weight: $font-weight-medium;
    line-height: 19px;
    color: $gray_600;
    margin-bottom: 15px;

    &.disabled {
      cursor: not-allowed;
      color: $gray-lighter6;
    }
  }

  .use-case-loader {
    width: 55px;
    left: 35px;
  }
}
