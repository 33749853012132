@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';

.category-name {
  color: $gray-600;
  font-weight: $font-weight-regular-400;
  font-size: $font-size-22;
  margin-top: 20px;
}

.categories-boxes-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 915px;
}

.search-results {
  font-size: $font-size-22;
  font-weight: $font-weight-regular-400;
  color: $gray-600;
}
