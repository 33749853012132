@import "~app/styles/themes/base/variables";

.root {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  padding: 0 10px;

  > div {
    //max-width: none !important;
    margin: 2px;
  }
}
