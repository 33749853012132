@import './3rdParty/ionicons-variables';
@import './3rdParty/ionicons-icons';
@import 'variables';

//@import url(//fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);

$andt-font-name: 'andt';

@charset "UTF-8";

@import './fonts-roboto';

// Order is important here (browser interperter): First we load our Icons
@font-face {
  font-family: $andt-font-name;
  src: url('../fonts/anodot_v2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $ionicons-font-family;
  src: url('../fonts/ionicons.woff?v=2.0.0') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font-family: $ionicons-font-family;
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.close-button {
    vertical-align: top;
  }
}

body {
  font-family: 'Roboto', 'Helvetica', 'Lucida Grande', sans-serif !important;
  color: $gray-500 !important;
}

.input-group-addon {
  &.icon {
    display: table-cell;
  }
}

[data-icon]::before {
  font-family: $andt-font-name !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icn-']::before,
[class*=' icn-']::before {
  font-family: $andt-font-name !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-arrow16-triangleup::before {
  content: '\e939';
}

.icn-arrow16-triangleright::before {
  content: '\e93a';
}

.icn-arrow16-triangleleft::before {
  content: '\e93b';
}

.icn-arrow16-triangledown::before {
  content: '\e93c';
}

.icn-arrow16-chevrondown::before {
  content: '\e93d';
}

.icn-arrow16-chevronright::before {
  content: '\e93e';
}

.icn-arrow16-chevronleft::before {
  content: '\e93f';
}

.icn-arrow16-chevronup::before {
  content: '\e940';
}

.icn-arrow16-arrowright::before {
  content: '\e941';
}

.icn-arrow16-arrowleft::before {
  content: '\e942';
}

.icn-arrow16-arrowup::before {
  content: '\e943';
}

.icn-arrow16-arrowdown::before {
  content: '\e944';
}

.icn-other16-webhook::before {
  content: '\e95a';
}

.icn-other16-msteams::before {
  content: '\e949';
}

.icn-other16-jira::before {
  content: '\e945';
}

.icn-other16-opsgenie::before {
  content: '\e946';
}

.icn-other16-pagerduty::before {
  content: '\e947';
}

.icn-other16-slack::before {
  content: '\e948';
}

.icn-Catalog24::before {
  content: '\e978';
}

.icn-nav24-integrations::before {
  content: '\e976';
}

.icn-nav24-settings::before {
  content: '\e920';
}

.icn-nav16-goto::before {
  content: '\e917';
}

.icn-nav24-anomalies::before {
  content: '\e919';
}

.icn-nav24-dashboards::before {
  content: '\e91a';
}

.icn-nav24-data::before {
  content: '\e91b';
}

.icn-nav24-help::before {
  content: '\e91c';
}

.icn-nav24-summary::before {
  content: '\e921';
}

.icn-nav16-logout::before {
  content: '\e900';
}

.icn-nav16-settings::before {
  content: '\e95f';
}

.icn-nav16-info::before {
  content: '\e955';
}

.icn-nav24-info::before {
  content: '\e91d';
}

.icn-nav24-management::before {
  content: '\e91e';
}

.icn-nav24-metricsearch::before {
  content: '\e91f';
}

.icn-nav24-alertconsole::before {
  content: '\e918';
}

.icn-nav24-alertconsole1::before {
  content: '\e901';
}

.icn-Minimize16::before {
  content: '\e97b';
}

.icn-general16-drag2::before {
  content: '\e977';
}

.icn-general16-3dot::before {
  content: '\e902';
}

.icn-general16-actions::before {
  content: '\e903';
}

.icn-general16-aggregation::before {
  content: '\e904';
}

.icn-general16-anomaly::before {
  content: '\e905';
}

.icn-general16-asterisk::before {
  content: '\e906';
}

.icn-general16-average::before {
  content: '\e907';
}

.icn-general16-calendar::before {
  content: '\e908';
}

.icn-general16-cascade::before {
  content: '\e909';
}

.icn-general16-chat::before {
  content: '\e90a';
}

.icn-general16-checkmark::before {
  content: '\e90b';
}

.icn-general16-closea::before {
  content: '\e90c';
}

.icn-general16-closeb::before {
  content: '\e90d';
}

.icn-general16-delta::before {
  content: '\e90e';
}

.icn-general16-direction::before {
  content: '\e90f';
}

.icn-general16-dockbottom::before {
  content: '\e910';
}

.icn-general16-dockright::before {
  content: '\e911';
}

.icn-general16-document::before {
  content: '\e912';
}

.icn-general16-draghandle::before {
  content: '\e913';
}

.icn-general16-filter::before {
  content: '\e914';
}

.icn-general16-fullscreen_exit::before {
  content: '\e915';
}

.icn-general16-fullscreen::before {
  content: '\e916';
}

.icn-general16-functions::before {
  content: '\e922';
}

.icn-general16-group::before {
  content: '\e923';
}

.icn-general16-help::before {
  content: '\e924';
}

.icn-general16-hide::before {
  content: '\e925';
}

.icn-general16-impact::before {
  content: '\e926';
}

.icn-general16-info::before {
  content: '\e927';
}

.icn-general16-live::before {
  content: '\e928';
}

.icn-general16-magic::before {
  content: '\e929';
}

.icn-general16-mail::before {
  content: '\e92a';
}

.icn-general16-marker::before {
  content: '\e92b';
}

.icn-general16-money::before {
  content: '\e92c';
}

.icn-general16-placeholder::before {
  content: '\e92d';
}

.icn-general16-refresh::before {
  content: '\e92e';
}

.icn-general16-search::before {
  content: '\e92f';
}

.icn-general16-share::before {
  content: '\e930';
}

.icn-general16-show::before {
  content: '\e931';
}

.icn-general16-sigma::before {
  content: '\e932';
}

.icn-general16-split::before {
  content: '\e933';
}

.icn-general16-tags::before {
  content: '\e934';
}

.icn-general16-timescale::before {
  content: '\e94a';
}

.icn-general16-unsubscribe::before {
  content: '\e94b';
}

.icn-general16-user::before {
  content: '\e94c';
}

.icn-general16-warning::before {
  content: '\e94d';
}

.icn-general24-3dot::before {
  content: '\e94e';
}

.icn-general24-actions::before {
  content: '\e94f';
}

.icn-general24-dataerror::before {
  content: '\e950';
}

.icn-general24-dislike::before {
  content: '\e952';
}

.icn-general24-functions::before {
  content: '\e953';
}

.icn-general24-insight::before {
  content: '\e954';
}

.icn-general24-like::before {
  content: '\e956';
}

.icn-general24-placeholder::before {
  content: '\e957';
}

.icn-general24-refresh::before {
  content: '\e958';
}

.icn-icon-resume::before {
  content: '\62';
}

.icn-icon-chart-area::before {
  content: '\65';
}

.icn-icon-zoom-out::before {
  content: '\66';
}

.icn-icon-chart-line::before {
  content: '\67';
}

.icn-icon-chart-polls::before {
  content: '\68';
}

.icn-icon-chart-spline::before {
  content: '\69';
}

.icn-icon-dashboard::before {
  content: '\6a';
}

.icn-icon-drag-dash::before {
  content: '\6b';
}

.icn-icon-function::before {
  content: '\6d';
}

.icn-icon-plus-mini::before {
  content: '\77';
}

.icn-icon-minus-mini::before {
  content: '\78';
}

.icn-icon-unsubscribe::before {
  content: '\63';
}

.icn-icon-list-wrap::before {
  content: '\7a';
}

.icn-icon-grid::before {
  content: '\79';
}

.icn-icon-ban::before {
  content: '\61';
}

.icn-icon-time::before {
  content: '\47';
}

.icn-icon-time-filter::before {
  content: '\48';
}

.icn-icon-triangle-right::before {
  content: '\42';
}

.icn-icon-refresh::before {
  content: '\44';
}

.icn-icon-bookmark-full::before {
  content: '\45';
}

.icn-icon-bookmark-empty::before {
  content: '\46';
}

.icn-icon-enlarge-win::before {
  content: '\4e';
}

.icn-icon-reduce-win::before {
  content: '\4f';
}

.icn-alert-on-tile::before {
  content: '\50';
}

.icn-events::before {
  content: '\51';
}

.icn-alert-on-tile-empty::before {
  content: '\54';
}

.icn-icon-3-dots::before {
  content: '\55';
}

.icn-icon-blackarrow-down::before {
  content: '\57';
}

.icn-icon-blue-plus::before {
  content: '\59';
}

.icn-icon-breadcrumbs::before {
  content: '\5a';
}

.icn-icon-help::before {
  content: '\30';
}

.icn-icon-hamburger::before {
  content: '\32';
}

.icn-icon-dimmet-group::before {
  content: '\34';
}

.icn-icon-table-ignore::before {
  content: '\23';
}

.icn-icon-white-dropdown::before {
  content: '\25';
}

.icn-icon-white-plus::before {
  content: '\26';
}

.icn-icon-table-units::before {
  content: '\2a';
}

.icn-icon-tablepopup-plus::before {
  content: '\2c';
}

.icn-icon-table-filter::before {
  content: '\2d';
}

.icn-icon-table-delete::before {
  content: '\2f';
}

.icn-icon-table-aggregation::before {
  content: '\3a';
}

.icn-icon-side-menu::before {
  content: '\3b';
}

.icn-icon-search::before {
  content: '\3c';
}

.icn-icon-include::before {
  content: '\2b';
}

.icn-icon-error::before {
  content: '\33';
}

.icn-icon-initializing::before {
  content: '\3e';
}

.icn-icon-night-paused::before {
  content: '\3f';
}

.icn-icon-incomplete::before {
  content: '\40';
}

.icn-icon-sun-live-stream::before {
  content: '\5b';
}

.icn-icon-copy::before {
  content: '\31';
}

.icn-icon-checkbox-full::before {
  content: '\38';
}

.icn-icon-checkbox::before {
  content: '\5d';
}

.icn-icon-finetune::before {
  content: '\60';
}

.icn-icon-pin-off::before {
  content: '\29';
}

.icn-icon-pin-on::before {
  content: '\5e';
}

.icn-icon-extrainfo::before {
  content: '\5f';
}

.icn-icon-triangle-equal-func::before {
  content: '\7c';
}

.icn-icon-triangle-func::before {
  content: '\7e';
}

.icn-icon-triangle-left::before {
  content: '\41';
}

.icn-icon-clear-filter::before {
  content: '\7b';
}

.icn-icon-anomaly-type-transient::before {
  content: '\5c';
}

.icn-icon-anomaly-type-pattern-change::before {
  content: '\e000';
}

.icn-view-icon::before {
  content: '\e002';
}

.icn-text-icon::before {
  content: '\e001';
}

.icn-settings-icon::before {
  content: '\e003';
}

.icn-edit-pen-icon::before {
  content: '\e004';
}

.icn-time-stamp-icon::before {
  content: '\39';
}

.icn-numbers-icon::before {
  content: '\e005';
}

.icn-display-style-icon::before {
  content: '\e006';
}

.icn-complete-icon::before {
  content: '\e008';
}

.icn-warning-icon::before {
  content: '\24';
}

.icn-signed-v-icon::before {
  content: '\e019';
}

.icn-email-grey-9-icon::before {
  content: '\e01b';
}

.icn-slack-grey-9-icon::before {
  content: '\e01d';
}

.icn-icon-white-close::before {
  content: '\e009';
}

.icn-webhook-grey-9-icon::before {
  content: '\e01e';
}

.icn-users-grey-9-icon::before {
  content: '\e01f';
}

.icn-open-alert-icon::before {
  content: '\e011';
}

.icn-snooze-icon::before {
  content: '\e023';
}

.icn-anomaly::before {
  content: '\e02e';
}

.icn-data-management::before {
  content: '\e02f';
}

.icn-management::before {
  content: '\e031';
}

.icn-support::before {
  content: '\e032';
}

.icn-settings::before {
  content: '\e034';
}

.icn-alert::before {
  content: '\e02d';
}

.icn-dashboard::before {
  content: '\e035';
}

.icn-search-metrics::before {
  content: '\e030';
}

.icn-alert-console::before {
  content: '\e036';
}

.icn-avatar::before {
  content: '\e033';
}

.icn-icon-andt-mark-me-empty-icon::before {
  content: '\64';
}

.icn-DuplicateExpression16::before {
  content: '\e979';
}

.icn-Switch16::before {
  content: '\e97a';
}

.icn-format_align_center_black_24dp2x1::before {
  content: '\e986';
}

.icn-format_align_left_black_24dp2x1::before {
  content: '\e987';
}

.icn-format_align_right_black_24dp2x1::before {
  content: '\e988';
}

.icn-format_bold_black_24dp2x1::before {
  content: '\e989';
}

.icn-format_color_text_black_24dp2x1::before {
  content: '\e98a';
}

.icn-format_italic_black_24dp2x1::before {
  content: '\e98b';
}

.icn-format_list_bulleted_black_24dp2x1::before {
  content: '\e98c';
}

.icn-format_list_numbered_black_24dp2x1::before {
  content: '\e98d';
}

.icn-format_size_black_24dp2x1::before {
  content: '\e98e';
}

.icn-format_underlined_black_24dp2x1::before {
  content: '\e98f';
}

.icn-insert_emoticon_black_24dp2x1::before {
  content: '\e990';
}

.icn-insert_link_black_24dp2x1::before {
  content: '\e991';
}

.icn-link_off_black_24dp2x1::before {
  content: '\e992';
}

.icn-action16-send::before {
  content: '\e975';
}

.icn-action16-acknowledge::before {
  content: '\e959';
}

.icn-action16-clear::before {
  content: '\e95b';
}

.icn-action16-collapse::before {
  content: '\e95c';
}

.icn-action16-copy::before {
  content: '\e95d';
}

.icn-action16-delete::before {
  content: '\e95e';
}

.icn-action16-download::before {
  content: '\e960';
}

.icn-action16-edit::before {
  content: '\e961';
}

.icn-action16-expand::before {
  content: '\e962';
}

.icn-action16-favorite::before {
  content: '\e963';
}

.icn-action16-impersonate::before {
  content: '\e964';
}

.icn-action16-paste::before {
  content: '\e965';
}

.icn-action16-pause::before {
  content: '\e966';
}

.icn-action16-plusa::before {
  content: '\e967';
}

.icn-action16-plusb::before {
  content: '\e968';
}

.icn-action16-resume::before {
  content: '\e969';
}

.icn-action16-snooze::before {
  content: '\e96a';
}

.icn-action16-unfavorite::before {
  content: '\e96b';
}

.icn-action16-upload::before {
  content: '\e96c';
}

.icn-action24-acknowledge::before {
  content: '\e96d';
}

.icn-action24-copy::before {
  content: '\e96e';
}

.icn-action24-delete::before {
  content: '\e96f';
}

.icn-action24-plusa::before {
  content: '\e971';
}

.icn-action24-plusb::before {
  content: '\e972';
}

.icn-action24-snooze::before {
  content: '\e973';
}

.icn-action24-impersonate::before {
  content: '\e970';
}

.icn-action24-stopimp::before {
  content: '\e974';
}

.icn-selection16-checkboxhalf::before {
  content: '\e951';
}

.icn-selection16-checkboxoff::before {
  content: '\e935';
}

.icn-selection16-checkboxon::before {
  content: '\e936';
}

.icn-selection16-radiooff::before {
  content: '\e937';
}

.icn-selection16-radioon::before {
  content: '\e938';
}
