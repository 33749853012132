@import '~app/styles/v2/variables';

.alert-1-warning-yellow {
  color: $orange-500;
}

.alert-2-warning-red {
  color: $red-500;
}

.alert-0-no-warning {
  visibility: hidden;
}
