@import '~app/styles/v2/variables';

.root {
  display: block;
  width: 285px;
  border-radius: 4px;
  background-color: $white;
  border: 2px solid $gray-200;
  position: relative;
  padding: 18px 20px 0 20px;
}

.filters-row {
  width: 100%;
  margin-bottom: 20px;

  :global {
    .select-menu {
      width: 180px;
    }

    .andt-dropdown-control {
      height: 36px;
      line-height: 36px;
    }
  }
}

.filters-row-header {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  display: block;
}
