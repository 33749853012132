@import '~app/styles/v2/variables.scss';

.container {
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;

  &.bool {
    align-items: center;
  }

  h3 {
    margin: 0;
  }

  span {
    color: $gray-500;
    font-size: 16px;
  }
}

.bool-indicator {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;

  &.positive {
    background-image: -webkit-radial-gradient(-45px 45px, circle cover, #6bd02d, #4c9e19);
  }

  &.negative {
    background-image: -webkit-radial-gradient(-45px 45px, circle cover, #d02d2d, #ab1212);
  }
}
