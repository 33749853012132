@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

.page-container {
  flex: 1 1 0;
  overflow: hidden;
  position: relative;

  .page-content-wrapper {
    display: flex;
    flex: 1 0 0;

    .data-manager-list-container {
      flex-grow: 1;
    }
  }
}
