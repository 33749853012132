@import '~app/styles/v2/variables.scss';

.container {
  font-size: $font-size-16;
  line-height: $font-size-16;
  font-weight: $font-weight-regular-400;
  color: $gray-500;
  padding-left: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.name-and-count {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 6px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .count {
    color: $gray-300;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    padding-left: 12px;
  }
}

.focused {
  background-color: rgba($blue-500, 0.05);
}

.chevron {
  padding-right: 12px;
}

.header {
  text-transform: uppercase;
  color: $gray-500;
  font-weight: $font-weight-bold-700;
  font-size: $font-size-14;
  cursor: initial;
}

.footer {
  color: $gray-500;
  font-size: $font-size-12;
  cursor: initial;
}

.container-footer {
  margin: 0;
  padding: 0;
  background-color: $gray-100;
  width: 100%;
  display: flex;
  justify-content: center;

  .name-and-count {
    margin: 0;
    border-bottom: 0;
  }
}

.container-header {
  .name-and-count {
    width: 100%;
    margin: 0 5px 0 0;
    padding: 0;
    border-bottom: 2px solid $gray-300;
  }
}

.ico {
  margin-right: 6px;

  &.selected {
    color: $blue-500;
  }
}

mark {
  background-color: $orange-200 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.highlighter {
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-selected {
  border-bottom: 1px solid $gray-300;
}

.type-icon {
  transform: scale(0.333);
  margin: 0 -8px 0 -18px;
}

.count-sources {
  color: $gray-400;
  font-size: $font-size-14;
  margin-left: 6px;
}
