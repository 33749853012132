@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.drop-icon-btn {
  font-size: 24px;
  color: $white;
  width: 100%;
  left: 0 !important;
  top: 0 !important;
  cursor: pointer;
  margin-left: 20px;
}

.title {
  font-weight: 300;
  color: $white1;
  font-size: $font-size-x-large;
}

.main {
  margin-top: 5px !important;
  margin-bottom: -10px !important;
}

.hidden {
  visibility: hidden;
}

.multiple-ico {
  width: 24px !important;
}

.plus-dropdown {
  width: 50px;
  padding: 10px;

  input {
    height: 0;
    width: 0;
  }
}

.select-menu {
  display: flex;
  align-items: center;
}
