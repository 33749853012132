@import '~app/styles/v2/variables.scss';

.container {
  position: relative;
}

.info-icon {
  color: $gray-350;
  padding: 10px 4px 10px 10px;

  &:hover {
    color: $gray-500;
  }
}

.info-panel {
  .bridge {
    background-color: transparent;
    position: absolute;
    width: 100px;
    height: 50px;
    left: -20px;
  }

  .title {
    font-size: $font-size-20;
  }

  .example-title {
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    font-style: italic;
    color: $gray-400;
    margin-top: 16px;
  }

  .example-box {
    height: 35px;
    padding: 8px;
    background-color: $gray-200;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
    margin-bottom: 12px;
  }

  .read-more {
    color: $blue-500;
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
    cursor: pointer;
  }
}

:global {
  .info-panel-container {
    width: 305px;
    padding: 12px;
  }
}
