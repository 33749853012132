@import '~app/styles/v2/variables.scss';
@import '~app/styles/themes/base/mixins';

.description {
  color: red;
  width: 100%;
  font-size: 14px;
  line-height: 1.3;
  margin-top: 5px;
}

.new-close {
  top: 3px !important;
  font-size: 20px !important;
  color: $gray-300 !important;
}

.alerts-name-header {
  width: 100%;
}

.alerts-name-wrapper {
  background-color: rgba($gray-100, 0.5);
  border: 2px solid $gray-300;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 0 14px;
  margin-bottom: 10px;

  span {
    color: $gray-500;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
}

.measure-dimension-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  :global {
    .title-color1 {
      color: $indigo-500;
      font-weight: $font-weight-medium-500;
    }

    .title-color2 {
      color: $eucaliptus-500;
      font-weight: $font-weight-medium-500;
    }

    .title-bold {
      font-weight: $font-weight-medium-500;
    }
  }
}

.row {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex: 1 0 0;
  padding-right: 8px;

  &.normal {
    font-size: 14px;
  }
}

.even {
  background-color: rgba($gray-200, 0.5);
}

.header {
  color: $gray-400;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.col-measure {
  color: $purple-500;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  align-items: center;
  cursor: pointer;

  i {
    padding: 8px;
    position: relative;
    margin-top: 5px;
    font-size: 15px;
    color: $gray-500;
  }
}

.pad-left-8 {
  padding-left: 8px;
}

.actions-footer-section {
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: row-reverse;

  &.center {
    justify-content: center;
    padding-top: 30px;
  }
}

.alerts-count {
  display: flex;
  align-items: center;
  margin-right: 13px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-500;
}

.adblock-icon {
  background-repeat: no-repeat;
  width: 73px;
  height: 73px;
  flex-shrink: 0;
  align-self: center;
  margin: 1px auto 70px auto;
}

.checkbox-spacer {
  margin-right: 6px !important;
}
