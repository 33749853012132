@import '~app/styles/v2/variables';

.container {
  //width: 100%;
  // margin-right: 4px;
  flex: 1;

  .sizer {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    height: 0;
    overflow: scroll;
    white-space: pre;
    font-size: $font-size-14;
  }

  input {
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 24px;
    color: $gray-500;
    font-size: $font-size-16;
    min-width: 30px;

    &:focus {
      outline: 0 !important;
    }
  }

  &.inner {
    flex: 0 0 auto;

    input {
      font-size: $font-size-14;
      height: 20px;
      margin: 0 3px;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 0 0 2px #6298ff;
      max-width: 150px;
    }
  }
}
