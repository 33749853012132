@import '~app/styles/v2/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: 13px !important;
  font-size: 47px;
  color: $gray-500;
}

h4 {
  font-weight: 300 !important;

  b {
    font-weight: 400 !important;
  }
}
