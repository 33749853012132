/* padding */
.px_4 {
  padding-left: 32px;
  padding-right: 32px;
}

.p_1-5 {
  padding: 12px;
}

.pt_1 {
  padding-top: 8px;
}

.pt_1-5 {
  padding-top: 12px;
}

.pr_1 {
  padding-right: 8px;
}

.pr_2 {
  padding-right: 16px;
}

.pb_1-5 {
  padding-bottom: 12px;
}

.pb_2 {
  padding-bottom: 16px;
}

.pb_2-5 {
  padding-bottom: 20px;
}

.pb_3 {
  padding-bottom: 24px;
}

.pr_4 {
  padding-right: 32px;
}

.pl_0 {
  padding-left: 0;
}

.pl_0-5 {
  padding-left: 4px;
}

.pl_1-5 {
  padding-left: 12px;
}
