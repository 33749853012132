@import '~app/styles/v2/variables.scss';

.container {
  display: flex;
  align-items: center;
  padding-left: 42px;
  border-radius: 6px;
  margin: 0 auto;
  background-color: rgba($blue-500, 0.1);
  height: 59px;
  width: 278px;

  &.centered {
    justify-content: center;
    padding-left: 0;
  }

  span {
    font-size: $font-size-16;
    line-height: $font-size-16;
    color: $gray-500;
    font-weight: $font-weight-regular-400;
  }

  .value {
    display: flex;
    align-items: center;
    color: $gray-500;
    font-size: 35.4px;
    line-height: 35.4px;
    margin-left: 9px;
  }

  .scale-block {
    font-size: $font-size-14;
    line-height: $font-size-14;
    width: 40px;
    height: 29px;
    font-weight: $font-weight-bold-700;
    margin-left: 3px;
  }
}

.error {
  background-color: rgba($red-500, 0.1);
  padding: 0 20px;

  span {
    color: $red-500;
    text-align: center;
  }
}

.disabled {
  visibility: hidden;
}

.not-enough-data {
  background-color: transparent;
  border: solid 2px $gray-200;
  box-sizing: border-box;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  flex-direction: column;
  padding: 0;
  justify-content: center;

  p {
    margin: 0;
  }
}
