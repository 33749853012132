@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';

$left-panel-width: 390px;
$left-panel-inner-width: 344px;

.outer-layer {
  pointer-events: none;
  position: absolute;
  top: 92px;
  left: 40px;
  height: 50px;
  width: 440px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.inner-layer {
  pointer-events: auto;
  height: 50px;
  width: 64px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.minimize-btn {
  height: 32px;
  width: 32px;
  box-shadow: 0 7px 16px 4px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $gray-500;
  position: absolute;
  right: 0;
  top: 0;
  display: none;

  &.show {
    display: flex;
  }

  &:hover {
    background-color: $blue-100;
    color: $blue-500;
  }
}

.root-left-panel {
  width: $left-panel-width;
  border-radius: 13px;
  box-shadow: 0 6px 26px 4px rgba(0, 0, 0, 0.25);
  border: solid 2px $gray-200;
  background-color: $white;
  position: absolute;
  z-index: 1;
  top: 92px;
  left: 40px;
  bottom: 29px;
  padding: 16px;
  padding-top: 0;
  overflow: hidden;
  height: calc(100% - 121px);
  transition: height ease-in-out 0.3s;
}

.issues-type-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 20px;

  .issues-ddl-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .issues-label {
    margin-right: 8px;
    margin-left: 8px;
    font-family: Roboto, sans-serif;
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
    line-height: 0.88;
    color: $gray-400;
    flex-shrink: 0;
  }

  .date-range-wrapper {
    max-width: 140px;
  }
}

.issues-items-wrapper {
  width: $left-panel-inner-width;
  padding-bottom: 50px;
}

.investigation-wrapper {
  height: 55px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
}

.empty-wrapper,
.loading-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 50px 0;

  .empty-text {
    color: $indigo-500;
    font-family: Roboto, sans-serif;
    font-weight: $font-weight-medium-500;
    font-size: $font-size-18;
    margin-top: 8px;
    width: 100%;
    text-align: center;
    padding-right: 6px;
  }
}

.issue-pos {
  margin-bottom: 12px;
}

.issue-item {
  display: flex;
  border-radius: 6px;
  border: solid 1px $gray-200;
  background-color: $white;
  padding: 10px;
  cursor: pointer;
  height: 80px;

  &:hover {
    border-color: $blue-300;
    background-color: $blue-100;
  }

  &.selected {
    border-color: $blue-300;
    background-color: $blue-100;
  }
}

.status-icon {
  background: url(../../images/open_alert.svg);
  width: 16px;
  height: 17px;
  background-repeat: no-repeat;
  background-color: transparent;
}

.icon-section {
  display: flex;
  flex-shrink: 0;
  width: 16px;
  margin-right: 8px;
}

.data-section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-right: 8px;

  .name {
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    font-weight: $font-weight-medium-500;
    margin-bottom: 5px;
    margin-top: 1px;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .started {
    font-family: Roboto, sans-serif;
    font-size: $font-size-12;
    font-weight: $font-weight-regular-400;
    color: $gray-400;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
  }

  .date {
    font-family: Roboto, sans-serif;
    font-size: $font-size-14;
    font-weight: $font-weight-regular-400;
    color: $gray-500;
    padding-left: 3px;
    padding-right: 10px;
    line-height: 1;
  }

  .chip {
    display: flex;
    align-items: center;
    height: 16px;
    border-radius: 2px;
    padding: 0 4px;
    background-color: $gray-200;
    font-size: $font-size-12;
    font-weight: $font-weight-bold-700;
    line-height: 10px;
    margin-right: 4px;

    &.static-chip {
      color: rgb(201, 40, 40);
    }

    &.no-data-chip {
      color: rgb(178, 115, 11);
    }
  }
}

.summery-section {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;

  .number {
    font-family: Roboto, sans-serif;
    font-size: 26px;
    font-weight: $font-weight-medium-500;
    color: $gray-500;
    line-height: normal;
    margin-top: 2px;
  }

  .sub-text {
    font-family: Roboto, sans-serif;
    font-size: $font-size-12;
    font-weight: $font-weight-medium-500;
    color: $gray-400;
  }
}

.back-button {
  position: absolute;
  top: -6px;
  display: flex;
  cursor: pointer;

  i {
    font-size: $font-size-16;
    line-height: $font-size-16;
    padding-right: 4px;
  }

  span {
    font-size: $font-size-12;
    line-height: $font-size-16;
    font-weight: $font-weight-bold-700;
  }
}

.selected-issue-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: $left-panel-inner-width;

  .selected-issue-name-wrapper {
    display: flex;
    margin-bottom: 7px;

    .name {
      font-size: $font-size-16;
      height: 18px;
      margin-top: 0;
      margin-bottom: 1px;
      margin-right: 8px;
    }
  }
}

.metric-item {
  border-radius: 6px;
  border: solid 1px $gray-200;
  background-color: $white;
  margin: 16px 0;
  overflow: hidden;
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }

  .metric-name-wrapper {
    padding: 10px;

    .metric-name {
      width: 100%;
    }
  }

  .chart {
    height: 180px;
    width: 100%;
  }
}

.tp-search-panel {
  .back-arrow {
    cursor: pointer;
  }

  .tp-asset-item {
    border-radius: 4px;
    display: flex;
    position: relative;
    height: 56px;
    align-items: center;

    .arrow {
      visibility: hidden;
      width: 40px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }

    &.selected,
    &:hover {
      background-color: $blue-100;
      cursor: pointer;

      .arrow {
        visibility: visible;
      }
    }
  }
}
