/* stylelint-disable */
@import '~app/styles/v2/variables';
@import '../insightsWidgets.module.scss';
$widgetHeight: 155px;

.header {
  @include widget-title;
}

.body {
  @include body;

  height: $widgetHeight;

  &.gray {
    @include gray;
  }

  &.white {
    @include white;
  }

  &.loader {
    @include loader;

    height: $widgetHeight;
  }
}

.main-container {
  display: flex;
  justify-content: center;

  .no-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(#{$widgetHeight} - 30px);

    span {
      font-size: 14px;
      font-weight: 500;
      color: $gray-400;
    }
  }
}
/* stylelint-enable */
