/* border-radius */
.borderRadius_4 {
  border-radius: 4px;
}

.borderRadius_6 {
  border-radius: 6px;
}

.borderRadius_10 {
  border-radius: 10px;
}
