@import '~app/styles/themes/base/variables';

$hex-loader-size: 100px;
$hex-loader-size-sm: 55px;
$hex-loader-size-lg: 120px;
$hex-loader-size-xlg: 160px;

.wrapper {
  position: relative;
  height: $hex-loader-size;
  width: $hex-loader-size;
  margin: 0 auto;
  background-color: transparent;
  .text {
    position: absolute;
    font-size: $font-size-base;
    width: $hex-loader-size;
    height: $hex-loader-size;
    color: $blue3;
    text-align: center;
    line-height: $hex-loader-size;
    z-index: 1;
  }

  &.loader-sm {
    height: $hex-loader-size-sm;
    width: $hex-loader-size-sm;
    .text {
      height: $hex-loader-size-sm;
      width: $hex-loader-size-sm;
      line-height: $hex-loader-size-sm;
    }
  }

  &.loader-lg {
    height: $hex-loader-size-lg;
    width: $hex-loader-size-lg;
    .text {
      height: $hex-loader-size-lg;
      width: $hex-loader-size-lg;
      line-height: $hex-loader-size-lg;
    }
  }
  &.loader-xlg {
    height: $hex-loader-size-xlg;
    width: $hex-loader-size-xlg;
    .text {
      height: $hex-loader-size-xlg;
      width: $hex-loader-size-xlg;
      line-height: $hex-loader-size-xlg;
    }
  }
}
