:global {
  .react-grid-item > .react-resizable-handle::after {
    right: 4px;
    bottom: 4px;
    width: 8px;
    height: 8px;
    border-right: 2px solid #8995a0;
    border-bottom: 2px solid #8995a0;
    border-bottom-right-radius: 3px;
  }

  .react-resizable-handle {
    background-image: none;
  }

  .react-grid-item.react-grid-placeholder {
    background: #2671ff;
    border-radius: 6px;
    z-index: 0;
  }
}
