@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/mixins';

.root {
  margin-bottom: 20px;
  max-width: 340px !important;
}

.dropdown-width {
  max-width: 310px !important;
}
