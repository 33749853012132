@import '~app/styles/v2/variables';

.container {
  .title {
    font-weight: $font-weight-light-300;
    font-size: $font-size-24;
    margin-bottom: 12px;
    color: $gray-500;
  }

  .subtitle {
    color: $gray-400;
    font-size: 16px;
    margin-bottom: 12px;
  }
}
