.container {
  position: absolute;
  width: 85%;
  right: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  > div {
    display: flex;
    justify-content: space-between;
  }
}
