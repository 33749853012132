@import './../../../app/styles/themes/base/mixins';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  position: relative;

  ul {
    padding-left: 0;
  }

  .section-header-wrapper {
    display: flex;
    flex-direction: column;
    align-self: baseline;

    .section-header-btn {
      margin-left: -10px;
      font-weight: 300;
      color: $blue500;

      &.disabled {
        color: $gray-lighter6;
      }

      i.section-header-btn-icon {
        margin-left: -5px;
      }
    }

    &.disabled {
      cursor: not-allowed;

      .section-header-btn {
        pointer-events: none;
      }
    }
  }

  .section-list-wrapper {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-right: 40px;
  }
}

.list {
  overflow-y: auto;
}

.list-header {
  @include list-header();

  padding-top: 25px;
  padding-bottom: 5px;
  flex-shrink: 0;
}

.loader-pos {
  left: -20px;
}

.no-data-pos {
  margin-top: 50px;
}

.no-sources {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  color: $blue2;
}
