@import '~app/styles/v2/variables';

.nav-button {
  background-color: $gray-200;
  color: $gray-500;
  font-size: $font-size-20;
  width: 34px;
  height: 82px;
  border-radius: 6px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    background-color: rgba($blue-500, 0.2);
    color: $blue-500;
  }
}
