@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';

.root {
  display: flex;
  align-items: center;
}

.prev-dummy {
  display: inline-block;
  width: 105px;
}

.next-dummy {
  display: inline-block;
  width: 96px;
}
