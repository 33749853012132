@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';

.page-title {
  font-size: 30px;
  max-width: 1680px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
}

.filter-container {
  margin-right: 60px;
  flex: 0 0 328px;
}

.boxes-container {
  width: 100%;
}
