@import './../../../app/styles/themes/base/mixins';
@import '~app/styles/v2/variables.scss';

.no-data-pos {
  margin-top: 50px;
}

.no-sources {
  font-size: $font-size-base;
  font-weight: $font-weight-light;
  color: $blue2;
}

.no-streams-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.no-streams-source-type {
  font-family: Roboto, sans-serif;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-500;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 40px;
}

.no-streams-image {
  background-image: url('/bc/images/ftue-empty-streams-2.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 205px;
  height: 122px;
  flex-shrink: 0;
  align-self: center;
  margin: 71px auto 20px auto;
}

.not-found-streams-image {
  background-image: url('/bc/images/ftue-not-found-stream.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 139px;
  height: 110px;
  flex-shrink: 0;
  align-self: center;
  margin: 76px auto 26px auto;
}

.no-streams-title {
  font-family: Roboto, sans-serif;
  font-size: $font-size-18;
  font-weight: $font-weight-medium-500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $indigo_500;
  margin-top: 10px;
}

.no-streams-description {
  color: $gray-400;
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-regular-400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 20px;
}

.no-stream-create-stream {
  color: $blue-500;
  cursor: pointer;
  margin-right: 5px;
}

.disabled {
  .no-sources {
    color: $gray-lighter6;
  }

  .no-streams-wrapper {
    margin-top: 50px;
    opacity: 0.7;
    pointer-events: none;
  }

  .no-stream-create-stream {
    color: $gray-400;
  }
}
