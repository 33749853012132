@import '~app/styles/v2/variables';

/* -- Types -- */

.andt-title {
  color: $gray-600;
  font-weight: $font-weight-regular-400;
  margin: 0;
}

.page-title {
  font-size: $font-size-30;
  line-height: $font-size-30;
}

.page-line-counter {
  font-size: $font-size-20;
  line-height: $font-size-30;
}
