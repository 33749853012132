@import '~app/styles/v2/variables.scss';

.page-container {
  flex: 1 1 0;
}

.page-footer-container {
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.page-content-wrapper {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
}

.main {
  display: flex;
  flex: 1 1 0;
}
