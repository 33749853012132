@import '~app/styles/v2/variables.scss';

:global {
  .user-edit-modal {
    .modal-content {
      border-radius: 8px !important;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.container {
  margin-top: -7px;
  min-height: 480px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 352px;
}

.center {
  justify-content: center;
  align-items: center;
}

.header {
  font-family: Roboto, sans-serif;
  font-size: $font-size-22;
  font-weight: $font-weight-light-300;
  color: $gray-500;
  line-height: 25px;
  flex-shrink: 0;
}

.label {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
  line-height: 14px;
  margin-bottom: 8px;

  .sub {
    font-size: $font-size-14;
    color: $gray-300;
    margin-left: 9px;
  }
}

.names-row {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  .name-inputs {
    min-width: 100px;
  }

  .item {
    width: 166px;
  }
}

.row {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
}

.roles-group-row {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;

  .groups {
    width: 196px;
  }

  .roles {
    width: 140px;
  }
}

.status-row {
  flex-grow: 1;
  justify-content: flex-end;

  .label {
    margin-bottom: 12px;
  }

  label {
    display: flex;
    margin-bottom: 3px;
  }

  span {
    margin-left: 6px;
  }
}

.default-group-row {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end;

  .label {
    margin-bottom: 0;
    margin-right: 12px;
  }
}

.footer {
  margin-top: 6px;
  width: 100%;
  display: flex;
  align-items: center;
}

.org-name {
  font-family: Roboto, sans-serif;
  font-size: $font-size-16;
  font-weight: $font-weight-medium-500;
  color: $gray-400;
  flex-grow: 1;
}
