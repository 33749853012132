@import '~app/styles/v2/variables';

.height-limiter {
  max-height: 140px;
  overflow-y: auto;
  box-sizing: border-box;
  border: 2px solid $gray-200;
  border-radius: 6px;

  &.open {
    border: 2px solid rgba($blue-500, 0.3);
  }

  &:hover {
    border: 2px solid $gray-300;
  }
}

.container,
.expression-line-container {
  display: flex;
  align-items: center;
  min-height: 44px;
  background-color: $gray-200;
  padding: 4px;
  overflow: hidden;
  flex-wrap: wrap;

  &.open {
    background-color: $white;
  }
}

.is-isnot-option {
  width: 300px;
  display: flex;
  align-items: center;

  &.selected {
    color: $blue-500;
  }

  .checkbox {
  }

  .symbol {
    color: $gray-400;
    background-color: $gray-200;
    border-radius: 3px;
    width: 50px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    height: 20px;
    align-items: center;
    font-weight: $font-weight-medium-500;
  }

  .label {
    width: 100px;
  }
}

.expression-line-container {
  box-sizing: border-box;
  border-radius: 6px;
  background-color: transparent;
  margin-left: -8px;
  cursor: pointer;

  &:hover {
    border: rgba($blue-500, 0.2) 2px solid;
    margin-left: -10px;
  }
}
