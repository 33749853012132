@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';

.root {
  margin-top: 10px;
}

.components-row {
  $margin: 2px;

  position: relative;
  display: flex;
  flex-shrink: 0;

  & > input {
    margin: $margin;
  }

  & > button {
    margin: $margin;
  }

  :global {
    .andt-dropdown {
      margin: $margin;
    }
  }

  .regular-input {
    width: 90px;
    flex-shrink: 0;
    height: $input-height !important;
  }

  .btn-x-sub-condition {
    color: $white;
  }
}

.sub-condition-func-ddl {
  width: 110px !important;
  flex-shrink: 0;
}

.sub-condition-bound-ddl {
  width: 95px !important;
  flex-shrink: 0;
}

.btn-remove {
  color: $white !important;
  font-size: 22px !important;

  i {
    margin-top: 8px;
  }
}

.remove-condition {
  position: absolute;
  top: 19px;
  left: 297px;
  display: table;
  cursor: pointer;
  color: $gray-400;

  &:hover {
    color: #fff;
  }
}
