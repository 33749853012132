@import '~app/styles/themes/base/variables';
@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';

.button-container {
  position: absolute;
  bottom: 2px;
  background: linear-gradient(180deg, rgba(247, 250, 255, 0) 0%, #f7faff 40.21%);
  visibility: hidden;
  width: 100%;
  height: 68px;
  left: 0;
  padding: 20px;
}

.container {
  width: 284px;
  box-sizing: border-box;
  height: 182px;
  border-radius: 6px;
  border: 2px solid $gray-200;
  padding: 16px;
  margin: 10px 24px 10px 0;
  position: relative;

  &:hover {
    background-color: #f7faff;
    border-color: $blue-300;

    .button-container {
      visibility: visible;
    }
  }
}

.featured {
  width: 435px;
}

.title {
  font-size: $font-size-18;
  font-weight: $font-weight-medium-500;
  color: $gray-500;
}

.description {
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  color: $gray-400;
  overflow: hidden;
  max-height: 65px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.new,
.soon,
.inUse {
  background-color: $green-500;
  border-radius: 4px;
  color: $white;
  padding: 0 4px;
  height: 20px;
  font-size: $font-size-14;
  font-weight: $font-weight-medium-500;
  margin-left: 8px;
  min-width: 36px;
}

.soon {
  background-color: $lilach-500;
}

.inUse {
  background-color: $blue-500;
  position: absolute;
  top: 12px;
  right: 12px;
}

.disable {
  opacity: 0.5;
}

.action {
  border-radius: 6px;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  height: 35px;
  padding: 0 16px;
  text-align: center;
  background: $blue-500;
  cursor: pointer;
  color: #fff;
  display: flex;
  width: fit-content;
  align-items: center;
}
