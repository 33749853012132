.image-gs {
  background-image: url('../../bc/images/dsTypes/int_gs.svg');
}

.image-aws {
  background-image: url('../../bc/images/dsTypes/int_s3.svg');
}

.image-aws_cur {
  background-image: url('../../bc/images/dsTypes/AWS.png');
}

.image-aws_usage {
  background-image: url('../../bc/images/dsTypes/AWS.png');
}

.image-athena {
  background-image: url('../../bc/images/dsTypes/PARQUET.png');
}

.image-fu {
  background-image: url('../../bc/images/dsTypes/LOCAL_FILE.png');
}

.image-ga {
  background-image: url('../../bc/images/dsTypes/GOOGLE_ANALYTICS.jpg');
}

.image-segment {
  background-image: url('../../bc/images/dsTypes/SEGMENT.png');
}

.image-gads {
  background-image: url('../../bc/images/dsTypes/GOOGLE_SERVICE.jpg');
}

.image-facebook_ads {
  background-image: url('../../bc/images/dsTypes/FACEBOOK_ADS.jpg');
}

.image-databricks {
  background-image: url('../../bc/images/dsTypes/int_databricks.svg');
}

.image-adobe {
  background-image: url('../../bc/images/dsTypes/ADOBE.png');
}

.image-local_file {
  background-image: url('../../bc/images/dsTypes/ADOBE.png');
}

.image-mysql {
  background-image: url('../../bc/images/dsTypes/MYSQL_AGENT.jpg');
}

.image-salesforce {
  background-image: url('../../bc/images/dsTypes/SALESFORCE.png');
}

.image-psql,
.image-psql_agent {
  background-image: url('../../bc/images/dsTypes/PSQL.jpg');
}

.image-mssql {
  background-image: url('../../bc/images/dsTypes/MSSQL.jpg');
}

.image-mariadb {
  background-image: url('../../bc/images/dsTypes/MARIADB.jpg');
}

.image-redshift {
  background-image: url('../../bc/images/dsTypes/int_redshift.svg');
}

.image-snowflake {
  background-image: url('../../bc/images/dsTypes/snowflake.png');
}

.image-coralogix {
  background-image: url('../../bc/images/dsTypes/Coralogix.png');
}

.image-athena_sql {
  background-image: url('../../bc/images/dsTypes/Athena_sql.png');
}

.image-timestream {
  background-image: url('../../bc/images/dsTypes/Timestream.png');
}

.image-bigquery {
  background-image: url('../../bc/images/dsTypes/GOOGLE_BIGQUERY.jpg');
}

.image-oracle {
  background-image: url('../../bc/images/dsTypes/ORACLE.png');
}

.image-mparticle {
  background-image: url('../../bc/images/dsTypes/mParticle_Small.png');
}

.image-kinesis {
  background-image: url('../../bc/images/dsTypes/KINESIS.png');
}

.image-eventhubs {
  background-image: url('../../bc/images/dsTypes/EVENTHUBS.png');
}

.image-lookup {
  background-image: url('../../bc/images/dsTypes/LOOKUP.png');
}

.image-apache_Kafka {
  background-image: url('../../bc/images/dsTypes/APACHE_KAFKA.png');
}

.image-prometheus {
  background-image: url('../../bc/images/dsTypes/PROMETHEUS.jpg');
}

.image-adblock {
  background-image: url('../../bc/images/no-adblocker.png');
}

.image-influx_db {
  background-image: url('../../bc/images/dsTypes/INFLUX_DB.png');
}

.image-mongo_db {
  background-image: url('../../bc/images/dsTypes/MONGO_DB.jpg');
}

.image-apache_kafka {
  background-image: url('../../bc/images/dsTypes/APACHE_KAFKA.png');
}

.image-victoria {
  background-image: url('../../bc/images/dsTypes/VICTORIA.svg');
}

.image-directory_agent {
  background-image: url('../../bc/images/dsTypes/directory.svg');
}

.image-splunk_agent {
  background-image: url('../../bc/images/dsTypes/splunk.svg');
}

.image-elasticsearch {
  background-image: url('../../bc/images/dsTypes/ELASTICSEARCH.png');
}

.image-sage_agent {
  background-image: url('../../bc/images/dsTypes/sage.png');
}

.image-zabbix_agent {
  background-image: url('../../bc/images/dsTypes/Zabbix.svg');
}

.image-coralogix_agent {
  background-image: url('../../bc/images/dsTypes/Coralogix.svg');
}
