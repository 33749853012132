@import "~app/styles/themes/base/variables";

$height: 48px;

.root {
  justify-content: space-between;
  min-height: $height;
  height: $height;
  align-items: center;
}

.title {
  font-size: $font-size-h3;
  color: $white;
  margin-left: 20px;
  font-weight: 300;
}

.actions {
  margin-right: 70px;
  justify-content: flex-end;
  flex-direction: row !important;

  button {
    display: flex;
    stroke: $blue3;
    stroke-width: 2px;
    font-size: $font-size-base !important;
    font-weight: $font-weight-light !important;
    text-transform: uppercase;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    height: $height;
    margin-left: 15px;
    margin-right: 15px;
    line-height: $height;

    i {
      flex: 1 0 0;
      font-size: 30px;
      margin-top: 4px;
    }
  }
}

.hr {
  border-top: 1px solid $blue1;
  margin-top: 10px;
  width: 100%;
  margin-left: 20px;
}
