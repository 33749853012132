@import '~app/styles/themes/base/variables';

.button {
  display: flex !important;
  color: $gray-dark2 !important;
  padding: 9px 8px !important;
  margin: 4px !important;
  border-radius: 4px !important;
  width: 150px;
  text-align: left !important;
  box-sizing: border-box !important;

  &:hover {
    background-color: $blue_100;
  }

  :global .icn-icon-table-delete {
    font-size: 14px;
  }
}

.icon {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
}

.disabled {
  pointer-events: none;
}
