@import '~app/styles/themes/base/variables';

.stream-it {
  padding-top: 2;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}

.on-live-warning {
  background-color: rgba($red_500, 0.1);
  color: $red_500;
  padding: 3px 6px;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 12px;
  display: inline-block;
}

.disabled {
  color: $gray-lighter4 !important;
  cursor: not-allowed !important;
}

.error-msg {
  height: 75px;
  color: $brand-danger;
  font-size: $font-size-small;
  font-weight: $font-weight-light;
  margin-top: 10px;
}

.error-top {
  margin-bottom: 10px;
}

.error-bottom {
  margin-top: 10px;
}

.error-item {
  color: $brand-danger;
  font-size: 14px;
}

.stream-it-icon {
  padding-top: 10px !important;
}

.stream-it-no-icon {
  padding-top: 5px !important;
}

.select-live-wrapper {
  display: inline-block;
  width: 320px;
}

.bottom-spacer {
  margin-bottom: 10px;
}

.bold {
  font-weight: $font-weight-bold;
}

.btn-left {
  display: inline-block;
  float: left;
  margin-left: 30px;
}

.btn-center {
  display: inline-block;
  margin-right: 111px;
}

.btn-right {
  display: inline-block;
  float: right;
  margin-right: 30px;
}
