@import './../../../app/styles/themes/base/variables';

.root {
  display: flex;
  border-radius: 3px;
  border: 1px solid #c3c9d0;
  height: 48px;
  margin-bottom: 10px;
  padding: 6px;
  color: $black1;
  width: calc(100% - 2px);
  min-width: 250px;
  margin-left: 1px;

  &.active {
    border: 2px solid $blue5;

    &:hover {
      border: 2px solid $blue5;
    }
  }

  &:hover {
    background-color: #f2fbfe;
    border: 1px solid #8995a0;
    cursor: pointer;
  }
}

.type-image {
  background-size: contain;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-position: center;
}

.source-name-wrapper {
  padding-left: 10px;
  justify-content: flex-end;
  overflow: hidden;

  .source-name {
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    margin-bottom: 5px;
  }

  .source-type {
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
  }
}

.source-status-wrapper {
  width: 36px;
  display: inline-flex;
}

.active {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  border: 2px solid $blue5;
}

.not-active {
  .type-image,
  .source-status-wrapper,
  .more-icon {
    opacity: 0.5;
  }
}

.status-icon {
  font-size: 25px;
  text-align: center;
  align-self: center;
  margin: 0 auto;
}

.sun {
  color: $orange-sun;
}

.error {
  color: $red-fail;
}

.more-dropdown {
  position: relative;

  :global {
    .dropdown {
      top: -6px;
      left: 6px;
    }
  }
}
