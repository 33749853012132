@import '~app/styles/themes/base/variables';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.file-name {
  margin: -30px 0 35px 0;
}

.created {
  margin: -30px 0 30px 0;
  line-height: 19px;
}

.label-secondary {
  margin-left: 26px;
  font-size: 11px;
  margin-top: -4px;
}

.table {
  display: flex;
  flex: 1 0 0;
  overflow: auto;
  min-height: 500px;

  &.no-results {
    align-self: center;
  }

  :global {
    .public_fixedDataTable_main {
      font-size: 12px;
    }

    .public_fixedDataTable_header {
      .fixedDataTableCellLayout_main {
        background-color: $white;
        font-weight: 500;
        background-image: none;
      }

      .fixedDataTableCellGroupLayout_cellGroupWrapper {
        background-color: $white;
      }
    }

    .public_fixedDataTableCell_cellContent {
      top: 0;
      padding: 0 2px;
    }

    .fixedDataTableCellLayout_main {
      border-left: 1px solid $blue-light;
    }

    //scrollbars
    .public_Scrollbar_main,
    .public_Scrollbar_main.public_Scrollbar_mainActive {
      border-left: none;
    }

    .ScrollbarLayout_mainHorizontal {
      border-left: none;
      height: 15px !important;

      .ScrollbarLayout_faceHorizontal {
        left: -2px;
      }

      .ScrollbarLayout_faceHorizontal::after {
        background-color: $blue-light;
        bottom: 4px;
        top: 7px;
      }
    }

    .public_Scrollbar_mainActive,
    .ScrollbarLayout_mainHorizontal:hover {
      .ScrollbarLayout_faceHorizontal::after {
        background-color: $blue-light;
        bottom: 4px;
        top: 7px;
      }
    }

    .ScrollbarLayout_mainVertical {
      .ScrollbarLayout_faceVertical::after {
        background-color: $blue-light;
        right: 0;
        left: 11px;
      }
    }

    .ScrollbarLayout_mainVertical:hover {
      .ScrollbarLayout_faceVertical::after {
        background-color: $blue-light;
        right: 0;
        left: 11px;
      }
    }
  }
}
