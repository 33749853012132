@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';

.root {
  overflow: auto;

  .item {
    margin: 20px 0;
  }
}
