@import '~app/styles/themes/base/variables';
@import '~app/styles/themes/base/layout';
@import '~alerts.console/styles/severity';
@import './alertsListVariables.scss';

.loader {
  > div {
    display: flex;
    justify-content: center;
    width: 100% !important;
    margin-top: 5%;
  }
}

.container {
  height: calc(100% - 43px);
  overflow-y: auto;

  .group-by-sep {
    flex-shrink: 0;
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-items: center;
  margin-top: 100px;

  .nr-title {
    font-size: $font-size-large;
  }

  .nr-tip {
    font-size: $font-size-small;
  }
}
