@import '~app/styles/themes/base/variables';

.root {
  width: 75px;
  height: auto;
  margin: 0 auto;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.14));
  &:active {
    filter: none;
  }
}

.image {
  cursor: pointer;
  fill-opacity: 1;
  stroke: #000;
  stroke-width: 0;
}

.image-shadow {
  fill-opacity: 1;
  fill: white;
}
