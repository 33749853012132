.page-header {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;

  .container {
    display: flex;

    &.right {
      > div,
      > button {
        margin-left: 12px;
      }
    }
  }

  .onBoarding-container {
    position: relative;
  }

  h1 {
    margin-right: 8px;
  }

  h2 {
    margin-right: 16px;
  }
}

:global {
  .new-alert {
    position: absolute !important;
    top: 58px;
    right: 1.3%;
    transition: all 0.5s ease-out !important;

    .modal-content {
      border-radius: 8px !important;
      box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.3);
    }
  }

  .name-popup {
    width: 600px !important;
  }
}
