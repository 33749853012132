@import '~app/styles/v2/variables';
@import '~app/styles/themes/base/layout';

$metric-table-row-height: 54px;

.table {
  width: 100%;
}

.table-header {
  display: flex;
  flex-shrink: 0;
  font-weight: $font-weight-medium-500;
}

.tableRowRoot {
  flex-shrink: 0;
}

.tableRow {
  height: $metric-table-row-height;
  box-shadow: 0 0 0 1px #e9e9f0;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: transparent !important;

  header {
    border-top: none !important;
    background-color: transparent !important;
    height: $metric-table-row-height !important;
    padding-top: 0 !important;
  }
}

.tableRowOpen {
  height: 100% !important;
  max-height: 300px;
  margin-bottom: 10px !important;
}

.table-row-header {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: $metric-table-row-height;
}

.col-header {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: $font-weight-medium-500;
    font-size: $font-size-14;
  }
}

.col-is-open {
  width: 35px;
  justify-content: left;
  padding-left: 10px;
  align-items: center;
  display: flex;
}

.col-title {
  width: 485px;
  justify-content: left;
  font-weight: $font-weight-medium-500;
  padding-right: 20px;
}

.col-impact {
  width: 80px;
  justify-content: left;
  font-size: 12px;
  color: $gray-400;
}

.col-startTime {
  width: 150px;
  justify-content: left;
}

.col-duration {
  width: 90px;
  justify-content: left;
}

.col-deltaPercentage {
  width: 90px;
  justify-content: left;
}

.col-score {
  width: 50px;
  justify-content: left;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

// expendable panel
.container {
  width: 100%;
  height: calc(100% - 53px);
  display: flex;
  padding: 0 11px;
  position: relative;
}
