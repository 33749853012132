@import '~app/styles/v2/variables.scss';

.andt-input {
  box-sizing: border-box;
  height: 44px;
  font-size: $font-size-16;
  padding: 12px;
  background-color: $gray-200;
  border-radius: 6px;
  border: 2px solid transparent;
  min-width: 200px;
  width: 100%;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $gray-400;
  }

  &:hover {
    border: 2px solid $gray-300;
  }

  &:focus {
    background-color: $gray-100;
    border: 2px solid rgba($blue-500, 0.3);
    color: $gray-500;

    &::placeholder {
      color: $gray-400;
    }
  }

  &:disabled {
    background-color: $gray-100;
    color: $gray-500;
    cursor: default;
    padding: 10px;

    &::placeholder {
      color: rgba($gray-500, 0.5);
    }

    &:hover {
      border: 2px solid transparent;
    }
  }
}

.invalid {
  color: $red-500;
  border: 2px solid rgba($red-500, 0.3);
  padding: 10px;
}

.invalid-message {
  font-size: 14px;
  font-weight: 500;
  color: $red-500;
  margin-top: 2px;
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

.full-size {
  width: 100%;
  height: 100%;
  min-width: auto;
}
