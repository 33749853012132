@import '~app/styles/v2/variables.scss';

:global {
  .loader {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 5;
    font-size: $font-size-16;
    font-weight: $font-weight-medium-500;
    color: $gray-500;
    background-color: $gray-300;
    visibility: visible;
    opacity: 1;
    transition: opacity 0s linear;
  }

  .loader-fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0s, opacity 0s linear;
  }

  /* stylelint-disable */
  // used when pre-loading the app by dom elements inside index.html
  @keyframes:global (lds-spinner) {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes:global (lds-spinner) {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
  /* stylelint-enable */

  .lds-css {
    margin-top: 200px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .lds-spinner div {
    left: 93px;
    top: 48px;
    position: absolute;
    -webkit-animation: lds-spinner linear 0.8s infinite;
    animation: lds-spinner linear 0.8s infinite;
    background: $indigo-500;
    width: 14px;
    height: 24px;
    border-radius: 90%;
    -webkit-transform-origin: 7px 52px;
    transform-origin: 7px 52px;
  }

  .lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-delay: -0.72s;
    animation-delay: -0.72s;
  }

  .lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
    -webkit-animation-delay: -0.64s;
    animation-delay: -0.64s;
  }

  .lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(72deg);
    transform: rotate(72deg);
    -webkit-animation-delay: -0.56s;
    animation-delay: -0.56s;
  }

  .lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(108deg);
    transform: rotate(108deg);
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
  }

  .lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(216deg);
    transform: rotate(216deg);
    -webkit-animation-delay: -0.24s;
    animation-delay: -0.24s;
  }

  .lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(252deg);
    transform: rotate(252deg);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(288deg);
    transform: rotate(288deg);
    -webkit-animation-delay: -0.08s;
    animation-delay: -0.08s;
  }

  .lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(324deg);
    transform: rotate(324deg);
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .lds-spinner {
    width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  }
}
