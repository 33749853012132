@use "sass:math";
@import 'variables';
$modal-width: 1240px;

@mixin list-header() {
  font-size: $font-size-h4;
  font-weight: $font-weight-regular;
  color: $gray_500;
}

@mixin round-btn($diameter) {
  width: $diameter;
  height: $diameter;
  border-radius: 50%;
  padding: 0;
}

@mixin x-btn-icon($size, $color, $width) {
  display: inline-block;
  width: $size + px;
  height: $size + px;
  position: relative;
  transform: rotate(45deg);

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    background: $color;
    border-radius: $width + px;
  }

  &::before {
    left: 50%;
    width: $width + px;
    margin-left: - math.div($modal-width, 2) + px;
    height: 100%;
  }

  &::after {
    top: 50%;
    left: 0;
    height: $width + px;
    margin-top: - math.div($modal-width, 2) + px;
    width: 100%;
  }
}

@mixin andt-button($color, $backgroundColor, $size) {
  height: $size;
  min-width: 77px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  padding: 0 21px;
  align-items: center;
  background-color: $backgroundColor;

  &:hover {
    background-color: scale-color($backgroundColor, $lightness: 10%);
  }

  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $color;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
  }
}
