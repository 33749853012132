@import '~app/styles/v2/variables';

.container {
  display: flex;
  align-items: center;
  font-size: $font-size-14;
  font-weight: $font-weight-regular-400;
  border-radius: 4px;
  width: min-content;
  height: 28px;
  position: relative;
  white-space: nowrap;
  margin: 2px;
  min-width: unset;
  cursor: pointer;
  padding-right: 2px;
  max-width: 99.3%;

  &.measure {
    color: $purple-600;
    background-color: rgba($purple-600, 0.2);
    font-weight: $font-weight-medium-500;
    padding: initial;
  }

  &.legacy {
    color: #8995a0;
    background-color: #f4f7fb;
    font-weight: $font-weight-medium-500;
    padding: initial;
    border: 2px solid #b5bbc3;
  }

  &.dimension {
    color: $eucaliptus-500;
    background-color: rgba($eucaliptus-500, 0.2);
  }

  &.filter {
    color: $gray-500;
    background-color: rgba($gray-500, 0.25);
  }

  &.only-children {
    background-color: transparent;
  }

  .value {
    margin: 0 2px 0 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .text-input-container {
    min-width: 10px;
    height: 20px;
    border: 2px solid rgba($blue-500, 0.3);
    border-radius: 4px;
    box-sizing: border-box;
    background-color: white;
  }

  .is-not {
    color: $red-500;
    font-weight: $font-weight-bold-700;
    font-style: italic;
    padding: 0 3px;
    flex: 0 0 auto;
  }
}
