@import '~app/styles/themes/base/variables';
@import '~alerts.management/components/editor/simulationFilters/variables';
@import '~app/styles/v2/variables.scss';

.sub-header {
  display: flex;

  .header-digit {
    font-size: 17px;
    font-weight: 500;
    margin: -3px 5px 0 0;
    color: $white1;
    flex-shrink: 0;
  }

  .header-title {
    font-size: $font-size-base;
    color: $white1;
  }

  .header-sub-title {
    @include sub-title();
  }

  .instruction {
    font-size: 14px;
    color: $orange-500;
  }
}
